import React, { useEffect, useState } from "react";
import Container from "../../Component/Container/Container";
import styles from "./accomo.module.css";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { token_api } from "../../Utils/network";
import CustomCalendar from "../../Component/CustomCalendar/CustomCalendar";
import {
  calendarEvent,
  roomFour,
  roomThree,
  roomTwo,
} from "../../Utils/calendarDummy";

import OpenCalendar from "../../Component/OpenCalendar/OpenCalendar";

const AccommodationCalendar = () => {


  return (
  
    <>
      <OpenCalendar />
    </>
  );
};

export default AccommodationCalendar;

const dummyHotel = [
  {
    id: 1,
    title: "Taj",
  },
  {
    id: 2,
    title: "Oberoi",
  },
];

const dummyRooms = [
  {
    id: 1,
    title: "Room-101",
  },
  {
    id: 2,
    title: "Room-202",
  },
  {
    id: 3,
    title: "Room-303",
  },
  {
    id: 2,
    title: "Room-404",
  },
];
