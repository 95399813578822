export const Validate = (value) => {
  return new Promise((resolve, reject) => {
    const {
      aadhar_number,
      cin_number,
      gst_register_number,
      msme_register_number,
      pan_card_number,
    } = value;

    const errors = { error: false };
    const mobileRegex = /^\d{10}$/;
    if (!aadhar_number) {
      errors.aadhar_number = "Please enter aadhar number";
      errors.error = true;
    }
    if (!cin_number) {
      errors.cin_number = "Please enter cin number";
      errors.error = true;
    }
    if (!gst_register_number) {
      errors.gst_register_number = "Please enter gst number";
      errors.error = true;
    }
    if (!msme_register_number) {
      errors.msme_register_number = "Please enter msme register number";
      errors.error = true;
    }
    if (!pan_card_number) {
      errors.pan_card_number = "Please pancard number";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
