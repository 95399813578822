import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { useContext } from "react";
import { useSelector } from "react-redux";

const CustomMenu = () => {
  const { user, access_token } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout } = useContext(UserCredsContext);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };
  return (
    <div>
      <Button
      className="btnLinearGradient"
        variant="contained"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {`${user?.first_name || ""} ${user?.last_name || ""}`}
      </Button>
      <Menu
        sx={{ zIndex: "1700" }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default CustomMenu;
