export const Validate = (value, pilotSelected, selectedBlock) => {
  return new Promise((resolve, reject) => {
    const { start_date, end_date, start_time, end_time } = value;

    const errors = { error: false };
    // if (!pincode || pincode.length < 6) {
    //   errors.pincode = "Please enter valid pincode";
    //   errors.error = true;
    // }
    if (!start_date) {
      errors.start_date = "Please fill start date";
      errors.error = true;
    }
    if (!end_date) {
      errors.end_date = "Please fill end date";
      errors.error = true;
    }

    if (start_date && end_date) {
      if (new Date(end_date) < new Date(start_date)) {
        errors.end_date = "End date cannot be earlier than start date";
        errors.error = true;
      }
    }

    if (!start_time) {
      errors.start_time = "Please enter start time";
      errors.error = true;
    }

    if (!end_time) {
      errors.end_time = "Please enter end time";
      errors.error = true;
    }

    if (!pilotSelected || !pilotSelected[0]) {
      errors.pilotSelected = "Please select pilot";
      errors.error = true;
    }
    if (!selectedBlock || !selectedBlock[0]) {
      errors.selectedBlock = "Please select block";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
