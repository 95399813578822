import React, { useContext, useEffect, useState } from "react";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import CustomButton from "../../Component/CustomButton/CustomButton";
import CustomDate from "../../Component/CustomDate/CustomDate";
import filterIcon from "../../Assets/filterIcon.png";
import CustomTable from "../../Component/CustomTable/CustomTable";
import { token_api, base_url } from "../../Utils/network";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { Checkbox } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardCard from "../../Component/DashboardCard/DashboardCard";
import { serviceOrderCard } from "../../Utils/constants";
import CustomTabs3 from "../../Component/CustomTabsNew/CustomTabs3";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import styles from "./index.module.css";

import { Button } from "@mui/material";
import {
  extractDateTime,
  getTooltipComponent,
  helperdayMonthYearFull,
  formatDate,
} from "../../Utils/helper";
// import PushNotification from "../../Component/PushNotification/PushNotification";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import axios from "axios";
import ServiceBookingCountCard from "../../Component/ServiceBookingCountCard/ServiceBookingCountCard";
import { nlNL } from "@mui/x-date-pickers";
const ServiceOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user_permissions } = useContext(UserCredsContext);

  console.log(user_permissions, "serviceBookingUserPermissions");

  const [countData, setCountData] = useState({});
  const [filters, setfilters] = useState({
    service_booking_start: null,
    service_booking_end: null,
    start_date: null,
    end_date: null,
    completed_start: null,
    completed_end: null,
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [bookingList, setBookingList] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [typedValue, setTypedValue] = useState("");
  const [advFilter, setAdvFilter] = useState(false);

  console.log(page, "yserhsd");
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Service Bookings</div>,
  ];

  const handleRedirect = (id) => {
    console.log("IFIDCOME", id);
    navigate(`/bookings/service-booking-details/${id}`);
  };

  const getStatusStyles = (item) => {
    switch (item?.status) {
      case 1:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(87, 182, 122)",
              border: "1px solid white",
              color: "white",
            }}
          >
            {item?.status_str}
          </div>
        );
      case 3:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(245, 199, 99)",
              border: "1px solid white",
              color: "white",
            }}
          >
            {item?.status_str}
          </div>
        );
      case 2:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(235, 137, 235)",
              border: "1px solid white",
              color: "white",
            }}
          >
            {item?.status_str}
          </div>
        );
      case 4:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(87, 194, 255)",
              // border: "1px solid rgb(81, 79, 255)",
              color: "white",
            }}
          >
            {item?.status_str}
          </div>
        );
      default:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "black",
              border: "1px solid white",
              color: "white",
            }}
          >
            None
          </div>
        );
    }
  };
  const getAllServiceBookings = (finalFilters) => {
    token_api
      .get(
        `service/booking`,

        {
          params: { ...finalFilters },

          paramsSerializer: {
            indexes: null,
          },
        }
      )
      .then((response) => {
        setPaginationData(response?.data);
        let modifiedArray = response?.data?.data?.map((item) => {
          // const { bookingaddonservice } = item;

          return {
            // checkBox: (
            //   <Checkbox
            //     checked={false}
            //     // onChange={handleChange}
            //     inputProps={{ "aria-label": "controlled" }}
            //   />
            // ),
            id: (
              <div
                onClick={() => handleRedirect(item?.id)}
                style={{ cursor: "pointer" }}
              >
                {item?.id}
              </div>
            ),
            name: (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleRedirect(item?.id)}
              >
                <div style={{ fontSize: "15px" }}>{`${item?.full_name}`}</div>
                <div>{`${item?.contact_no}`}</div>
              </div>
            ),
            dealer_code: item?.dealer_code || "N.A",
            date: item?.date || "N.A",
            time: item?.time || "N.A",
            block_name:
              (
                <div>
                  <p>{item?.block_name || "N.A"} </p>
                  <p>{item?.city}</p>
                </div>
              ) || "N.A",
            area_in_acres_price: (
              <div>
                {item?.area_in_acres ? item?.area_in_acres : "N.A"}{" "}
                <span style={{ margin: "0px 6px" }}>/</span>
                {item?.total_amt ? `₹${item?.total_amt}` : "N.A"}
              </div>
            ),

            time: (
              <div>
                {item?.service_start_datetime ? (
                  <div>
                    {helperdayMonthYearFull(item?.service_start_datetime)},{" "}
                    {extractDateTime(item?.created)}
                  </div>
                ) : (
                  "N.A"
                )}{" "}
                <span style={{ margin: "0px 6px" }}>/</span>
                {item?.service_completed_datetime ? (
                  <div>
                    {helperdayMonthYearFull(item?.service_completed_datetime)},{" "}
                    {extractDateTime(item?.service_completed_datetime)}
                  </div>
                ) : (
                  "N.A"
                )}
              </div>
            ),
            created: (
              <div>
                <div>{helperdayMonthYearFull(item?.created)}</div>
                <div>{extractDateTime(item?.created)}</div>
              </div>
            ),
            created_by: item?.created_by_name || "N.A",
            serviceDate:
              (item?.serviceDate &&
                helperdayMonthYearFull(item?.serviceDate)) ||
              "N.A",
            category:
              getTooltipComponent(item?.bookingaddonservice, "category") ||
              "N.A",
            service:
              getTooltipComponent(item?.bookingaddonservice, "services") ||
              "N.A",

            // drone: item?.drone || "N.A",
            // pilot: item?.pilot || "N.A",

            start_date:
              (item?.start_date && helperdayMonthYearFull(item?.start_date)) ||
              "N.A",

            end_date:
              (item?.end_date && helperdayMonthYearFull(item?.end_date)) ||
              "N.A",
            status: getStatusStyles(item),
          };
        });
        console.log("PAginationData", modifiedArray);
        setBookingList([...modifiedArray]);
      })
      .catch((err) => {
        console.log("BookingErr", err);
      });
  };

  const handleAdvanceFilters = () => {
    setAdvFilter(!advFilter);
  };

  useEffect(() => {
    const timout = setTimeout(() => {
      const finalFilters = {};
      finalFilters.page = pageState;
      finalFilters.q = filters?.q;

      if (filters?.category) {
        const ids = filters?.category?.map((info) => info?.id);
        finalFilters.category = ids;
      }
      if (filters?.service) {
        const ids = filters?.service?.map((info) => info?.slug);
        finalFilters.service = ids;
      }

      if (tabValue) {
        finalFilters.status = tabValue;
      }

      if (filters?.service_booking_start) {
        finalFilters.service_booking_start = formatDate(
          filters?.service_booking_start
        );
      }
      if (filters?.service_booking_end) {
        finalFilters.service_booking_end = formatDate(
          filters?.service_booking_end
        );
      }
      if (filters?.start_date) {
        const filter_start_date = formatDate(filters?.start_date);

        finalFilters.start_date = filter_start_date;
      }

      if (filters?.end_date) {
        const filter_end_date = formatDate(filters?.end_date);

        finalFilters.end_date = filter_end_date;
      }

      if (filters?.completed_start) {
        finalFilters.completed_start = formatDate(filters?.completed_start);
      }
      if (filters?.completed_end) {
        finalFilters.completed_end = formatDate(filters?.completed_end);
      }
      if (filters?.location) {
        // finalFilters.location = filters?.location[0]?.id;
        finalFilters.location = 395;
      }

      getAllServiceBookings(finalFilters);
    }, 600);
    return () => clearTimeout(timout);
  }, [
    pageState,
    filters,
    filters?.category,
    filters?.service,
    tabValue,
    filters?.start_date,
    filters?.end_date,
    filters?.location,
    filters?.service_booking_start,
    filters?.service_booking_end,
    filters?.completed_start,
    filters?.completed_end,
  ]);

  const getCount = (finalFilters) => {
    token_api
      .get(`/service/booking_service_count`, {
        params: { ...finalFilters },

        paramsSerializer: {
          indexes: null,
        },
      })
      .then((response) => {
        let newresponse = response?.data?.data;
        setCountData(newresponse);

        console.log(response, "newres");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getCategory() {
    token_api
      .get(`/service/category`)
      .then((response) => {
        console.log(response?.data?.data, "newcatresponwse");
        setCategoryList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getServiceList = () => {
    token_api
      .get(`service?page_size=100`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setServiceList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCount();
    getCategory();
    getServiceList();
  }, []);

  useEffect(() => {
    const timout = setTimeout(() => {
      const finalFilters = {};
      if (filters?.service_booking_start) {
        finalFilters.service_booking_start = formatDate(
          filters?.service_booking_start
        );
      }
      if (filters?.service_booking_end) {
        finalFilters.service_booking_end = formatDate(
          filters?.service_booking_end
        );
      }

      if (filters?.start_date) {
        finalFilters.start_date = formatDate(filters?.start_date);
      }
      if (filters?.end_date) {
        finalFilters.end_date = formatDate(filters?.end_date);
      }

      if (filters?.completed_start) {
        finalFilters.completed_start = formatDate(filters?.completed_start);
      }

      if (filters?.completed_end) {
        finalFilters.completed_end = formatDate(filters?.completed_end);
      }

      getCount(finalFilters);
    }, 600);
    return () => clearTimeout(timout);
  }, [
    filters?.start_date,
    filters?.end_date,
    filters?.service_booking_start,
    filters?.service_booking_end,
    filters?.completed_start,
    filters?.completed_end,
  ]);

  console.log(filters, serviceList, "hariServiceList");

  const handleFilter = (e, type, selectName, multiple) => {
    console.log("HANDLERSS", e, type);
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleTabChange = (newTabValue) => {
    console.log(newTabValue, "newtabvalue");
    setPageState(1);

    setTabValue(newTabValue);
  };

  console.log(filters, "filyers");
  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  const handleChangeforSearch = (event, newValue) => {
    setTypedValue(newValue);
    setfilters((prev) => ({
      ...prev,
      location: [newValue],
    }));
  };

  const getLocationList = (value) => {
    let apiEndpoint = "/course/location";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data.data, "newresdata");
          let userloc = res.data.data?.map((info) => ({
            id: info?.id,
            title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
          }));
          setLocationList(userloc);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getLocationList(typedValue);
      }, 100);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);

  //Export Logic....

  const handleExportData = async () => {
    try {
      const response = await axios.get(`${base_url}service/booking/export`);
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };

  const handleResetFilters = () => {
    setfilters({
      service_booking_start: null,
      service_booking_end: null,
      start_date: null,
      end_date: null,
      completed_start: null,
      completed_end: null,
    });
  };

  console.log(filters, "hariFiltersss");
  return (
    <Container>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div> */}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>
        <div>{/* <p>Total Services</p> */}</div>

        {user_permissions?.write && (
          <Link to="/bookings/service-bookings/create-service">
            <Button variant="contained" className="btnLinearGradient">
              + Add Service Booking{" "}
            </Button>
          </Link>
        )}
      </div>
      <div style={{ display: "flex", columnGap: "20px" }}>
        {/* cards sections */}

        {serviceOrderCard?.map((item) => {
          return (
            <ServiceBookingCountCard
              cardTitle={item?.title}
              cardIcon={item?.icon}
              primaryGardient={item?.primaryGradient}
              secondaryGradient={item?.secondarayGradient}
              value={item?.value || "N.A"}
              countvalue={countData}
              hint={item?.hint}
              acres_hint={item?.acres_hint}
              amount_hint={item?.amount_hint}
              present={true}
              eclipseIcon={item?.eclipseIcon}
            />
          );
        })}
      </div>
      <div style={{ marginTop: "12px" }}>
        <CustomTabs3
          tabHead={tabsForServiceOrders}
          currentTab={tabValue}
          setTabVal={handleTabChange}
        />
      </div>

      <FilterContainer
        style={{ maxHeight: "40px", marginTop: "20px" }}
        // className={`${styles.marginTopBox}`}
      >
        <CustomSearchFilter
          placeholder="Search by name, mobile no, dealer code..."
          onChange={(e) => {
            setPageState(1);
            setfilters({ ...filters, q: e });
          }}
        />

        <SearchAndDropdown
          label="Filter by Location"
          value={filters?.location}
          onChange={handleChangeforSearch}
          listing={locationList}
          typedValue={typedValue}
          setTypedValue={setTypedValue}
        />

        {/* <CustomSelect
          label={"Filter by location"}
          listArray={[]}
          minWidth={"175px"}
        /> */}

        <CustomSelectChip
          multiple={false}
          label="Filter by service "
          name="service"
          listArray={serviceList}
          onChange={handleFilter}
          onDelete={handleDeleteChip}
          value={filters?.service}
        />

        <CustomButton
          text={"ADVANCE FILTERS"}
          btnIcon={<img src={filterIcon} alt="adv-filter" />}
          // maxWidth="170px"
          onClick={() => setAdvFilter((prev) => !prev)}
        />
        <CustomButton
          text={"RESET"}
          maxWidth="110px"
          onClick={handleResetFilters}
        />
        <CustomButton
          text={"Export"}
          maxWidth="110px"
          onClick={handleExportData}
        />
      </FilterContainer>

      {advFilter && (
        <>
          <FilterContainer style={{ maxHeight: "40px", marginTop: "20px" }}>
            {/* <CustomSelect label={"Date"} listArray={[]} minWidth={"150px"} /> */}

            {/* <CustomDate
          label="Service start date"
          handleChange={(e) =>
            handleFilter(e.target.value, "select", "start_date", false)
          }
          name="start_date"
          value={filters?.start_date}
        /> */}

            <CustomDatePicker
              label="Created Start Date"
              value={filters?.service_booking_start}
              onChange={(newValue) =>
                setfilters({
                  ...filters,
                  service_booking_start: newValue,
                })
              }
            />
            <CustomDatePicker
              label="Created End Date"
              value={filters?.service_booking_end}
              onChange={(newValue) =>
                setfilters({
                  ...filters,
                  service_booking_end: newValue,
                })
              }
            />
            <CustomDatePicker
              label="Scheduled Start Date"
              value={filters?.start_date}
              onChange={(newValue) =>
                setfilters({
                  ...filters,
                  start_date: newValue,
                })
              }
            />
            <CustomDatePicker
              label="Scheduled End Date"
              value={filters?.end_date}
              onChange={(newValue) =>
                setfilters({
                  ...filters,
                  end_date: newValue,
                })
              }
              // error={formErrors?.date}
            />
            <CustomDatePicker
              label="Completed Start Date"
              value={filters?.completed_start}
              onChange={(newValue) =>
                setfilters({
                  ...filters,
                  completed_start: newValue,
                })
              }
            />

            {/* <CustomDate
          label="Service end date"
          handleChange={(e) =>
            handleFilter(e.target.value, "select", "end_date", false)
          }
          name="end_date"
          value={filters?.end_date}
        /> */}
            <CustomDatePicker
              label="Completed End Date"
              value={filters?.completed_end}
              onChange={(newValue) =>
                setfilters({
                  ...filters,
                  completed_end: newValue,
                })
              }
            />

            {/* <CustomSelect
          label={"Filter by Service Type"}
          listArray={[]}
          minWidth={"195px"}
        /> */}

            {/* <CustomSelectChip
              multiple={false}
              label="Filter by service "
              name="service"
              listArray={serviceList}
              onChange={handleFilter}
              onDelete={handleDeleteChip}
              value={filters?.service}
            /> */}

            {/* <CustomSelectChip
              multiple={false}
              label="Filter by service category"
              name="category"
              listArray={categoryList}
              onChange={handleFilter}
              onDelete={handleDeleteChip}
              value={filters?.category}
            /> */}
          </FilterContainer>
        </>
      )}

      <div style={{ marginTop: "25px" }}>
        <CustomTable
          columns={tableColumns}
          data={bookingList}
          handleClick={handleRedirect}
          tableFor="serviceOrders"
        />
      </div>

      <div style={{ marginTop: "15px" }}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>
      {/* <PushNotification/> */}
    </Container>
  );
};

export default ServiceOrder;

const tableColumns = [
  // {
  //   id: "checkBox",
  //   label: "",
  // },
  {
    id: "id",
    label: "Service ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "dealer_code",
    label: "Dealer Code",
  },
  {
    id: "created",
    label: "Service Created",
  },
  {
    id: "created_by",
    label: "Service Created By",
  },
  // {
  //   id: "serviceDate",
  //   label: "Service Date",
  // },
  // {
  //   id: "time",
  //   label: "Time",
  // },

  {
    id: "block_name",
    label: "Block Name",
  },
  {
    id: "area_in_acres_price",
    label: "Area  / Price ",
  },
  {
    id: "time",
    label: "Time",
  },
  // {
  //   id: "category",
  //   label: "Category",
  // },
  {
    id: "service",
    label: "Service",
  },
  // {
  //   id: "drone",
  //   label: "Drone",
  // },
  // {
  //   id: "pilot",
  //   label: "Pilot",
  // },

  {
    id: "start_date",
    label: "Start date",
  },

  // {
  //   id: "end_date",
  //   label: "End date",
  // },
  {
    id: "status",
    label: "Status",
  },
];

const tabsForServiceOrders = [
  {
    id: 0,
    title: "All Bookings",
  },
  {
    id: 4,
    title: "New Bookings",
  },
  {
    id: 3,
    title: "Scheduled Bookings",
  },

  {
    id: 1,
    title: "Completed Bookings",
  },
  {
    id: 2,
    title: "Cancelled Bookings",
  },
];

const testToolTip = [
  {
    category: { title: "RTRTR" },
  },
  {
    category: { title: "PLQWQMMM" },
  },
  {
    category: { title: "POWQROQWP" },
  },
  {
    category: { title: "PYLPYLYJ" },
  },
];
