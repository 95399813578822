import styled from "@emotion/styled";
import { Box } from "@mui/material";

const Divider = styled(Box)({
  marginTop: "4px",
  marginBottom: "14px",
  borderBottom: "0.3px solid rgba(0, 0, 0, 0.5)",
});

export default Divider;
