import React, { useState } from "react";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";

// import exampleImage from "./react.jpeg";
import "./CustomServiceCalendar.css";
import av_s from "../../Assets/av_s.svg";
import av_d from "../../Assets/av_d.svg";
import b_d from "../../Assets/b_d.svg";
import b_s from "../../Assets/b_s.svg";
import { convertDateToYMD, convertYMDtoDate } from "../../Utils/helper";
var now = new Date();

const CustomServiceCalendar = ({
  startDate,
  endDate,
  handleDate,
  occupancy,
  showImage = true,
  showDoubleView = true,
  selectRange = true,
  setCurrentMonth = () => {},
  dateList = [],
}: Props) => {
  const [datesRange, setDatesRange] = useState([startDate]); // Default to today for both start and end dates

  const handleDateChange = (date) => {
    setDatesRange(date);
    if (selectRange) {
      handleDate(date[0], date[1]);
    } else {
      handleDate(date, null);
    }
  };
  const tileContent = ({ date }: any) => {
    const dateText = date.getDate();

    const isToday = date.toDateString() === new Date().toDateString();
    let single = false;
    let double = false;
    let find = false;
    let price = null;
    if (
      showImage &&
      occupancy &&
      occupancy.length > 0 &&
      occupancy[0].hasOwnProperty("occupancy_info")
    ) {
      find = occupancy[0]?.occupancy_info.find((item) => {
        // filter does not mutates the array
        // very first item
        const showImage = item.date == convertDateToYMD(date) ? true : false;
        return {
          showImage: showImage,
          single: item?.single_room,
          double: item?.double_room,
        };
      });
    } else {
      console.log("sakhare");
    }

    if (!showImage && dateList) {
      find = dateList.map((item) => {
        if (item.date == convertDateToYMD(date)) {
          price = item.price;
          return item.price;
        }
        // const value = item.date == convertDateToYMD
        // filter does not mutates the array
        // very first item
        // const value = item.date == convertDateToYMD(date) ? true : false;
        // console.log(item.date, convertDateToYMD(date), "hello date");
        // return {
        //   price: item.price,
        // };
      });
    }
    return (
      <div>
        <div>
          {/* {isToday && ( */}
          <div style={{ display: "flex", gap: "5px" }}>
            <div>
              <div
                style={{
                  fontSize: "0.8em",
                  margin: "0px",
                  marginBottom: "0",
                  color: "#099A06",
                }}
              >
                <img
                  src={!single ? av_s : b_s}
                  style={{
                    width: "12px",
                    display: `${!showImage ? "none" : ""}`,
                  }}
                />
              </div>
            </div>
            <div>
              <div
                style={{
                  fontSize: "0.8em",
                  margin: "0px",
                  marginBottom: "0",
                  color: "#099A06",
                }}
              >
                <img
                  src={!double ? av_d : b_d}
                  style={{
                    width: "12px",
                    display: `${!showImage ? "none" : ""}`,
                  }}
                />
              </div>
            </div>
          </div>
          {/* )} */}
          <p style={{ textAlign: "center", color: "green", margin: "5px 0px" }}>
            &#8377;{price}
          </p>
        </div>
        {/* {isToday && (
          <img
            src={exampleImage}
            alt="Today"
            style={{ width: "20px", height: "20px" }}
          />
        )} */}
        {/* You can add text or other elements here */}
      </div>
    );
  };
  const handleActiveStartDateChange = (activeStartDate: any) => {
    setCurrentMonth(activeStartDate.activeStartDate);
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        <Calendar
          tileContent={tileContent}
          selectRange={selectRange}
          showNavigation={true}
          showNeighboringMonth={false}
          showDoubleView={showDoubleView}
          value={datesRange}
          onChange={(date) => handleDateChange(date)}
          onActiveStartDateChange={handleActiveStartDateChange}
        />
      </div>
    </div>
  );
};

export default CustomServiceCalendar;
