import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./ServiceProducts.module.css";
import Container from "../../Component/Container/Container";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import { Button } from "@mui/material";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import dummyBrand from "../../Assets/dummyBrandIcon.svg";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import InputFields from "../../Component/InputFields/InputFields";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { token_api, base_url } from "../../Utils/network";
import { ValidateProduct, ValidateBrand } from "./Utility";
import axios from "axios";
import { useSelector } from "react-redux";
import dummyProduct from "../../Assets/dummyProductIcon.svg";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ServiceProducts = () => {
  const { access_token } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const { user_permissions } = useContext(UserCredsContext);
  console.log(user_permissions, "ProductsUserPermissions");
  const [value, setTabValue] = useState(0);
  const [filters, setfilters] = useState({});
  const [productsData, setProductsData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [addTabValue, setAddTabValue] = useState(0);

  //Add Brand Data...

  const [brandForm, setBrandForm] = useState({
    id: null,
    brand_name: "",
  });
  const [brandFormErrors, setBrandFormErrors] = useState({});
  const brandIconInput = useRef(null);
  const [brandIconObj, setBrandIconObj] = useState("");
  const [brandIconPreview, setBrandIconPreview] = useState("");

  //Add Product Data...
  const [productForm, setProductForm] = useState({
    id: null,
    product_name: "",
    brand: [],
    description: "",
    category: [],
    mrp: "",
    selling_price: "",
    gst: "",
    zoho_id: "",
  });
  const [productFormErrors, setProductFormErrors] = useState({});
  const productIconInput = useRef(null);
  const [productIconObj, setProductIconObj] = useState("");
  const [productIconPreview, setProductIconPreview] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setProductBrandList] = useState([]);
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Products & Brands</div>,
  ];

  //Image Uploading....

  function handleDrop(e, type) {
    e.preventDefault();

    if (type === "brand") {
      if (e?.target?.files) {
        setBrandIconObj(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setBrandIconPreview(imageUrl);
      } else if (e?.dataTransfer?.files) {
        setBrandIconObj(e?.dataTransfer?.files[0]);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setBrandIconPreview(imageUrl);
      }
    } else if (type === "product") {
      if (e?.target?.files) {
        setProductIconObj(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setProductIconPreview(imageUrl);
      } else if (e?.dataTransfer?.files) {
        setProductIconObj(e?.dataTransfer?.files[0]);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setProductIconPreview(imageUrl);
      }
    }
  }

  const handleClick = (type) => {
    if (type === "brand") {
      brandIconInput.current.click();
    } else if (type === "product") {
      productIconInput.current.click();
    }
  };
  function handleDragOver(e) {
    e.preventDefault();
  }

  //Tab Changing....
  const handleTabChange = (event, newValue) => {
    setPageState(1);
    setTabValue(newValue);
  };

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  //PRODUCTS CRUD OPERATIONS.....
  const getProductsList = async (filters) => {
    try {
      const fetchList = await token_api.get(`service/booking/products`, {
        params: { ...filters },
        paramsSerializer: {
          indexes: null,
        },
      });
      if (fetchList?.status !== 200) {
        throw new Error("Network Error");
      }
      setPaginationData(fetchList?.data);

      let modifiedArray = fetchList?.data?.data?.map((item) => {
        return {
          id: item?.id,

          product_name: item?.product_name || "N.A",
          thumbanail: (
            <img
              src={item?.thumbnail ? item?.thumbnail : dummyProduct}
              alt="product_icon"
              style={{ width: "80px", height: "80px" }}
            />
          ),
          brand: item?.brand?.name || "N.A",
          description: item?.description || "N.A",
          category: item?.category?.name || "N.A",
          selling_price: item?.selling_price
            ? `Rs. ${item?.selling_price}`
            : "N.A",

          action: (
            <div className={styles.actionContainer}>
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleEditProduct(item)}
                >
                  <img src={editIcon} />
                </div>
              )}
              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleDeleteProduct(item?.id, item?.product_name)
                  }
                >
                  <img src={deleteIcon} />
                </div>
              )}
            </div>
          ),
        };
      });
      setProductsData([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditProduct = (item) => {
    const {
      id,
      product_name,
      brand,
      category,
      mrp,
      selling_price,
      zoho_books_id,
      description,
      gst_percent,
      thumbnail,
    } = item;
    let brandItem = brandList.filter((item) => item?.id === brand?.id);
    let categoryItem = categoryList.filter((item) => item?.id === category?.id);

    setOpenDrawer(true);
    setAddTabValue(0);
    setProductForm({
      id: id,
      product_name: product_name,
      brand: brandItem,
      category: categoryItem,
      mrp: mrp,
      selling_price: selling_price,
      zoho_id: zoho_books_id,
      description: description,
      gst: gst_percent,
    });
    setProductIconPreview(thumbnail);
  };

  const handleDeleteProduct = (productId, productTitle) => {
    token_api
      .delete(`service/booking/products/${productId}/`)
      .then((response) => {
        if (
          response?.status === 201 ||
          response?.status === 200 ||
          response?.status === 204
        ) {
          alert(`${productTitle} product is deleted successfully!`);
          getProductsList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //BRANDS CRUD OPERATIONS.....
  const getBrandsList = async (filters) => {
    try {
      const fetchList = await token_api.get(`service/booking/product_brand`, {
        params: { ...filters },
        paramsSerializer: {
          indexes: null,
        },
      });
      if (fetchList?.status !== 200) {
        throw new Error("Network Error");
      }
      setPaginationData(fetchList?.data);
      let modifiedArray = fetchList?.data?.data?.map((item) => {
        return {
          id: item?.id,

          brand_name: item?.name || "N.A",
          brand_image: (
            <img
              src={item?.icon ? item?.icon : dummyBrand}
              alt="brand_image"
              style={{ width: "80px", height: "80px" }}
            />
          ),
          action: (
            <div className={styles.actionContainer}>
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleEditBrand(item)}
                >
                  <img src={editIcon} />
                </div>
              )}
              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteBrand(item?.id, item?.name)}
                >
                  <img src={deleteIcon} />
                </div>
              )}
            </div>
          ),
        };
      });
      setBrandsData([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditBrand = (brandItem) => {
    const { id, name, icon } = brandItem;
    setOpenDrawer(true);
    setAddTabValue(1);
    setBrandForm({
      id: id,
      brand_name: name,
    });
    setBrandIconPreview(icon);
  };

  const handleDeleteBrand = (brandId, brandTitle) => {
    token_api
      .delete(`service/booking/product_brand/${brandId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert(`${brandTitle} brand is deleted successfully!`);
          getBrandsList();
        }
      });
  };

  const getProductCategoryList = () => {
    token_api
      .get(`service/booking/product_category`)
      .then((res) => {
        if (res.data.data) {
          let newList = res?.data?.data.map((item) => {
            return {
              id: item?.id,
              title: item?.name,
            };
          });

          setCategoryList(newList);
        }
      })
      .catch((err) => console.log(err));
  };

  const getProductBrandsList = () => {
    token_api
      .get(`service/booking/product_brand`)
      .then((res) => {
        if (res.data.data) {
          let newList = res?.data?.data.map((item) => {
            return {
              id: item?.id,
              title: item?.name,
            };
          });

          setProductBrandList(newList);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getProductCategoryList();
    getProductBrandsList();
  }, [value]);

  useEffect(() => {
    if (value === 0) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};
        if (pageState) {
          filtersObject.page = pageState;
        }

        if (filters?.q) {
          filtersObject.q = filters?.q;
        }

        getProductsList(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    } else if (value === 1) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};
        if (pageState) {
          filtersObject.page = pageState;
        }

        if (filters?.q) {
          filtersObject.q = filters?.q;
        }

        getBrandsList(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [filters?.q, value, pageState]);

  //Products Add Drawer....

  const handleAddProduct = () => {
    setOpenDrawer(true);
    setAddTabValue(0);
    setProductFormErrors({});
    setProductForm({});
    setProductIconObj("");
    setProductIconPreview("");
  };

  const handleAddBrand = () => {
    setOpenDrawer(true);
    setAddTabValue(1);
    setBrandForm({
      id: null,
      brand_name: "",
    });
    setBrandFormErrors({});
    setBrandIconObj("");
    setBrandIconPreview("");
  };

  const handleAddTabChange = (event, newValue) => {
    setAddTabValue(newValue);
    //Brand..
    setBrandForm({
      brand_name: "",
    });
    setBrandFormErrors({});
    //Product Form...
    setProductForm({
      product_name: "",
      brand: [],
      description: "",
      category: [],
      mrp: "",
      selling_price: "",
      gst: "",
      zoho_id: "",
    });
    setProductFormErrors({});
  };

  //Add Brand.....

  const handleBrandChange = (event) => {
    const { name, value } = event.target;
    setBrandForm({
      ...brandForm,
      [name]: value,
    });
  };

  //Add Product...
  const handleProductChange = (event) => {
    const { name, value } = event.target;
    setProductForm({
      ...productForm,
      [name]: value,
    });
  };

  const handleChangeCategory = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setProductForm({
        ...productForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setProductForm({
        ...productForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setProductForm({
        ...productForm,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleDeleteChipCategory = (id, selectName) => {
    setProductForm({
      ...productForm,
      [selectName]: productForm?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
  };

  console.log(
    productForm,
    productIconObj,
    productIconPreview,
    "hariProductFormmm"
  );

  const handleCreateProduct = (e) => {
    e.preventDefault();
    let data = {
      product_name: productForm?.product_name,
      ...(productForm?.brand && { brand_id: productForm?.brand[0]?.id }),
      description: productForm?.description,
      ...(productForm?.category && {
        category_id: productForm?.category[0]?.id,
      }),

      mrp: productForm?.mrp,
      selling_price: productForm?.selling_price,
      gst_percent: productForm?.gst,
      zoho_books_id: productForm?.zoho_id,
    };

    ValidateProduct(productForm)
      .then(async (response) => {
        if (response == "success") {
          if (!productForm?.id) {
            const productData = await token_api.post(
              `service/booking/products/`,
              data
            );

            if (productIconObj) {
              let productIconItem = new FormData();
              productIconItem.append("thumbnail", productIconObj);

              await axios({
                method: "patch",
                url: `${base_url}service/booking/products/${productData?.data?.data?.id}/`,
                data: productIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            alert(`Product is created successfully!`);
            setOpenDrawer(false);
            setProductForm({
              ...productForm,
              id: null,
              product_name: "",
              brand: [],
              description: "",
              category: [],
              mrp: "",
              selling_price: "",
              gst: "",
              zoho_id: "",
            });
            getProductsList();
            setProductIconObj("");
            setProductIconPreview("");
          } else {
            const productData = await token_api.patch(
              `service/booking/products/${productForm?.id}/`,
              data
            );

            if (productIconObj) {
              let productIconItem = new FormData();
              productIconItem.append("thumbnail", productIconObj);

              await axios({
                method: "patch",
                url: `${base_url}service/booking/products/${productData?.data?.data?.id}/`,
                data: productIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            alert(`Product is updated successfully!`);
            setOpenDrawer(false);
            setProductForm({
              ...productForm,
              id: null,
              product_name: "",
              brand: [],
              description: "",
              category: [],
              mrp: "",
              selling_price: "",
              gst: "",
              zoho_id: "",
            });
            getProductsList();
            getProductBrandsList();
            getProductCategoryList();

            setProductIconObj("");
            setProductIconPreview("");
          }
        }
      })
      .catch((error) => {
        console.log(error, "kahsysys");
        setProductFormErrors(error);
      });
  };

  const handleCreateBrand = (e) => {
    e.preventDefault();
    let data = {
      name: brandForm?.brand_name,
    };

    ValidateBrand(brandForm)
      .then(async (response) => {
        if (response == "success") {
          if (!brandForm?.id) {
            const brandData = await token_api.post(
              `service/booking/product_brand/`,
              data
            );

            if (brandIconObj) {
              let brandIconItem = new FormData();
              brandIconItem.append("icon", brandIconObj);

              await axios({
                method: "patch",
                url: `${base_url}service/booking/product_brand/${brandData?.data?.data?.id}/`,
                data: brandIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            alert(`Brand is created successfully!`);
            setOpenDrawer(false);
            setBrandForm({
              ...brandForm,
              id: null,
              brand_name: "",
            });
            setTabValue(1);
            getBrandsList();
            setBrandIconObj("");
            setBrandIconPreview("");
          } else {
            const brandData = await token_api.patch(
              `service/booking/product_brand/${brandForm?.id}/`,
              data
            );

            if (brandIconObj) {
              let brandIconItem = new FormData();
              brandIconItem.append("icon", brandIconObj);

              await axios({
                method: "patch",
                url: `${base_url}service/booking/product_brand/${brandData?.data?.data?.id}/`,
                data: brandIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            alert(`Brand is updated successfully!`);
            setOpenDrawer(false);
            setBrandForm({
              ...brandForm,
              id: null,
              brand_name: "",
            });
            setTabValue(1);
            getBrandsList();
            getProductBrandsList();
            getProductCategoryList();
            setBrandIconObj("");
            setBrandIconPreview("");
          }
        }
      })
      .catch((error) => {
        console.log(error, "kahsysys");
        setBrandFormErrors(error);
      });
  };

  console.log(brandFormErrors, "harIformErrr");
  return (
    <Container>
      <div className={styles.breadCrumbsCon}>
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Products" {...a11yProps(0)} />
          <Tab label="Brands" {...a11yProps(1)} />
        </Tabs>
      </Box>

      {value === 0 && (
        <div>
          <FilterContainer style={{ maxHeight: "40px", marginTop: "20px" }}>
            <div style={{ width: "400px" }}>
              <CustomSearchFilter
                placeholder="Search by product name..."
                onChange={(e) => {
                  setfilters({ ...filters, q: e });
                }}
              />
            </div>

            {user_permissions?.write && (
              <Button
                sx={{ height: "53px" }}
                variant="contained"
                onClick={handleAddProduct}
              >
                + Add Product
              </Button>
            )}
          </FilterContainer>
          <div className={styles.tableContainer}>
            <CustomTable columns={productTableColumns} data={productsData} />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>{" "}
        </div>
      )}

      {value === 1 && (
        <div>
          <FilterContainer style={{ maxHeight: "40px", marginTop: "20px" }}>
            <div style={{ width: "400px" }}>
              <CustomSearchFilter
                placeholder="Search by brand name..."
                onChange={(e) => {
                  setfilters({ ...filters, q: e });
                }}
              />
            </div>

            {user_permissions?.write && (
              <Button
                sx={{ height: "53px" }}
                variant="contained"
                onClick={handleAddBrand}
              >
                + Add Brand
              </Button>
            )}
          </FilterContainer>
          <div className={styles.tableContainer}>
            <CustomTable columns={brandTableColumns} data={brandsData} />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>{" "}
        </div>
      )}

      <DrawerComp
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
      >
        {addTabValue === 0 && (
          <DrawerContainer>
            <DrawerTitle
              text={productForm?.id ? "Update Product" : "Add Product"}
              handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
            />

            <div style={{ marginTop: "20px", rowGap: "10px" }}>
              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>
                  Product Name<span>*</span>
                </h3>
                <InputFields
                  label="Product Name"
                  required
                  name="product_name"
                  value={productForm?.product_name || ""}
                  onChange={handleProductChange}
                  error={productFormErrors?.product_name}
                  helperText={productFormErrors?.product_name}
                />
              </div>

              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>
                  Brand Name<span>*</span>
                </h3>
                <CustomSelectChip
                  multiple={false}
                  label="Select Brand"
                  name="brand"
                  onChange={handleChangeCategory}
                  onDelete={handleDeleteChipCategory}
                  value={productForm?.brand}
                  listArray={brandList}
                  error={productFormErrors?.brand}
                />
              </div>

              <div className={`${styles.formContainer} mt-20`}>
                <div className={`${styles.compoHead}`}>Thumbnail:</div>

                <div style={{ margin: "20px 0px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "product")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("product")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>{/* <img src={dragDrop} alt="dradanddrop" /> */}</p>
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={productIconInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        // multiple
                        onChange={(e) => handleDrop(e, "product")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              {productIconPreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        // style={{ color: palette?.primary[500] }}
                        className={`${styles.compoHead}`}
                      >
                        Tumbnail Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <img
                            src={productIconPreview}
                            alt="icon"
                            width={200}
                            height={140}
                          />
                          <div onClick={() => handleClick("product")}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>Description:</h3>
                <textarea
                  rows={10}
                  style={{
                    padding: "20px 10px",
                    boxSizing: "border-box",
                    width: "100%",
                  }}
                  placeholder="Description"
                  name="description"
                  value={productForm?.description}
                  onChange={handleProductChange}
                />
              </div>
              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>Category:</h3>
                <CustomSelectChip
                  multiple={false}
                  label="Select Category"
                  name="category"
                  onChange={handleChangeCategory}
                  onDelete={handleDeleteChipCategory}
                  value={productForm?.category}
                  listArray={categoryList}
                  error={productFormErrors?.category}
                />
              </div>

              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>MRP:</h3>
                <InputFields
                  label="MRP"
                  type="number"
                  name="mrp"
                  value={productForm?.mrp || ""}
                  onChange={handleProductChange}
                  error={productFormErrors?.mrp}
                  helperText={productFormErrors?.mrp}
                />
              </div>

              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>Selling Price:</h3>
                <InputFields
                  label="Selling Price"
                  type="number"
                  name="selling_price"
                  value={productForm?.selling_price || ""}
                  onChange={handleProductChange}
                  error={productFormErrors?.selling_price}
                  helperText={productFormErrors?.selling_price}
                />
              </div>
              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>GST Percentage:</h3>
                <InputFields
                  label="GST Percentage"
                  name="gst"
                  value={productForm?.gst || ""}
                  onChange={handleProductChange}
                  error={productFormErrors?.gst}
                  helperText={productFormErrors?.gst}
                />
              </div>

              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>Zoho Books Id:</h3>
                <InputFields
                  label="Zoho Books ID"
                  name="zoho_id"
                  value={productForm?.zoho_id || ""}
                  onChange={handleProductChange}
                  error={productFormErrors?.zoho_id}
                  helperText={productFormErrors?.zoho_id}
                />
              </div>

              <div className={styles.buttonContainer}>
                <Button variant="contained" onClick={handleCreateProduct}>
                  Submit
                </Button>
              </div>
            </div>
          </DrawerContainer>
        )}

        {addTabValue === 1 && (
          <DrawerContainer>
            <DrawerTitle
              text={brandForm?.id ? "Update Brand" : "Add Brand"}
              handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
            />
            <div style={{ marginTop: "20px", rowGap: "10px" }}>
              <div>
                <h3 className={styles.compoHead}>
                  Brand Name<span>*</span>
                </h3>
                <InputFields
                  label="Brand Name"
                  required
                  name="brand_name"
                  value={brandForm?.brand_name || ""}
                  onChange={handleBrandChange}
                  error={brandFormErrors?.brand_name}
                  helperText={brandFormErrors?.brand_name}
                />
              </div>

              <div className={`${styles.formContainer} mt-20`}>
                <div className={`${styles.compoHead}`}>Thumbnail:</div>

                <div style={{ margin: "20px 0px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "brand")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("brand")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>{/* <img src={dragDrop} alt="dradanddrop" /> */}</p>
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={brandIconInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        // multiple
                        onChange={(e) => handleDrop(e, "brand")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              {brandIconPreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        // style={{ color: palette?.primary[500] }}
                        className={`${styles.compoHead}`}
                      >
                        Thumbnail Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <img
                            src={brandIconPreview}
                            alt="icon"
                            width={200}
                            height={140}
                          />
                          <div onClick={() => handleClick("brand")}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className={styles.buttonContainer}>
                <Button variant="contained" onClick={handleCreateBrand}>
                  Submit
                </Button>
              </div>
            </div>
          </DrawerContainer>
        )}
      </DrawerComp>
    </Container>
  );
};

export default ServiceProducts;

const productTableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "product_name",
    label: "Product Name",
  },
  {
    id: "thumbanail",
    label: "Icon",
  },
  {
    id: "brand",
    label: "Brand",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "category",
    label: "Category",
  },
  {
    id: "selling_price",
    label: "Selling Price",
  },
  {
    id: "action",
    label: "Actions",
  },
];

const brandTableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "brand_image",
    label: "Brand Image",
  },
  {
    id: "brand_name",
    label: "Brand Name",
  },

  {
    id: "action",
    label: "Actions",
  },
];

const productTableData = [
  {
    id: 1,
    product_name: "Kalichakara L",
    brand: "IPL",
    description: "Kalichakara L",
    category: 2,
    selling_price: 200,
  },
  {
    id: 2,
    product_name: "Kalichakara L",
    brand: "IPL",
    description: "Kalichakara L",
    category: 2,
    selling_price: 200,
  },
  {
    id: 3,
    product_name: "Kalichakara L",
    brand: "IPL",
    description: "Kalichakara L",
    category: 2,
    selling_price: 200,
  },
  {
    id: 4,
    product_name: "Kalichakara L",
    brand: "IPL",
    description: "Kalichakara L",
    category: 2,
    selling_price: 200,
  },
  {
    id: 5,
    product_name: "Kalichakara L",
    brand: "IPL",
    description: "Kalichakara L",
    category: 2,
    selling_price: 200,
  },
  {
    id: 6,
    product_name: "Kalichakara L",
    brand: "IPL",
    description: "Kalichakara L",
    category: 2,
    selling_price: 200,
  },
  {
    id: 7,
    product_name: "Kalichakara L",
    brand: "IPL",
    description: "Kalichakara L",
    category: 2,
    selling_price: 200,
  },
];

const brandsTableData = [
  {
    id: 1,
    brand_image: dummyBrand,
    brand_name: "Kalichakara L",
  },
  {
    id: 2,
    brand_image: dummyBrand,
    brand_name: "Sanjeevani",
  },
  {
    id: 3,
    brand_image: dummyBrand,
    brand_name: "Bionse",
  },
  {
    id: 4,
    brand_image: dummyBrand,
    brand_name: "Sagarika",
  },
];

// const brandList = [
//   {
//     id: 1,
//     title: "Kalichakara L",
//   },
//   {
//     id: 2,
//     title: "Sanjeevani",
//   },
//   {
//     id: 3,
//     title: "Bionse",
//   },
//   {
//     id: 4,
//     title: "Sagarika",
//   },
//   {
//     id: 5,
//     title: "Kalichakara",
//   },
// ];
