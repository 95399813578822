import moment from "moment";
import styles from "./index.module.css";

export function convertCalendarDate1(_data) {
  var custom_data = [..._data];
  for (var i = 0; i < custom_data.length; i++) {
    const { year, month, date } = getDate(custom_data[i].date);
    const {
      hr: s_hr,
      min: s_min,
      sec: s_sec,
    } = getTime(custom_data[i].commence);
    const {
      hr: e_hr,
      min: e_min,
      sec: e_sec,
    } = getTime(custom_data[i].conclude);
    const checkBlock =
      custom_data[i]?.block && custom_data[i]?.block.hasOwnProperty("id");
    const checkPilot =
      custom_data[i]?.pilot && custom_data[i]?.pilot.hasOwnProperty("id");

    custom_data[i].title = (
      <div style={{ fontSize: "12px", fontWeight: "500", lineHeight: 1.3 }}>
        <div
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            display: "flex",
          }}
          className={styles.calendarObject}
        >
          <div className={styles.blocks}>
            <p className={styles.heading}>Block</p>
            <p className={styles.subHeading}>
              {checkBlock ? custom_data[i].block.block_name.toLowerCase() : "-"}{" "}
            </p>
          </div>
          <div className={styles.blocks}>
            <p className={styles.heading}>Pilot</p>
            <p className={styles.subHeading}>
              {checkPilot ? custom_data[i].pilot.first_name : "-"}
            </p>
          </div>
        </div>
      </div>
    );
    custom_data[i].start = new Date(year, month - 1, date, s_hr, s_min, s_sec);
    custom_data[i].end = new Date(year, month - 1, date, e_hr, e_min, e_sec);
  }
  return custom_data;
}

export function getDate(_date) {
  var myDate = _date.split("-");
  const year = Number(myDate[0]);
  const month = Number(myDate[1]);
  const date = Number(myDate[2]);
  return { year, month, date };
}

export function getTime(_time) {
  var myTime = _time.split(":");
  const hr = Number(myTime[0]);
  const min = Number(myTime[1]);
  const sec = Number(myTime[2].split(".")[0]);
  // return { hr: myTime[0], min: myTime[1], sec: myTime[2] };
  return { hr: hr, min: min, sec: sec };
}

export function getCurrentDateTime() {
  const _date = new Date();
  const year = _date.getFullYear();
  const month = _date.getMonth();
  const date = _date.getDate();
  return { year, month, date };
}

export function helperdayMonthYearFull(date_) {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date(date_);
  let day = d.getDate();
  let name = month[d.getMonth()];
  let year = d.getFullYear();

  return day + " " + name + " " + year;
}

export const formatDate = (inputDate) => {
  const formattedDate = moment(inputDate).format("Do MMMM YYYY");
  return formattedDate;
};

export const getStatusStyles = (status) => {
  switch (status) {
    case 1:
      return {
        color: "rgba(3, 98, 2, 1)",
        backgroundColor: "rgba(198, 239, 205, 1)",
      };
    case 2:
      return { color: "white", backgroundColor: "rgba(38, 38, 38, 1)" };
    case 3:
      return {
        color: "rgba(118, 0, 0, 1)",
        backgroundColor: "rgba(255, 170, 170, 1)",
      };
    case 4:
      return {
        color: "rgba(119, 78, 0, 1)",
        backgroundColor: "rgba(255, 233, 192, 1)",
      };
    default:
      return { color: "black", backgroundColor: "lightgray" };
  }
};

export const getStatusStylesforDrones = (status) => {
  switch (status) {
    case "Working":
      return { backgroundColor: "rgba(222, 255, 228, 1)" };
    case "Sold":
      return { backgroundColor: "rgba(242, 242, 242, 1)" };
    case "Broken":
      return { backgroundColor: "rgba(222, 255, 228, 1)" };
    case "Under Repair":
      return { backgroundColor: "rgba(222, 255, 228, 1)" };
    default:
      return { backgroundColor: "lightgray" };
  }
};
