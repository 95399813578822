import axios from "axios";

// this one is prod url
// export const base_url = "http://3.111.121.244/";

//this is the staging url
// export const base_url = "http://65.0.241.66/";
export const base_url = "https://api.thedronedestination.com/"; // prod 2.0


export var appToken = "";

if (typeof window !== "undefined" && localStorage.getItem("_token") != null) {
  var _token = localStorage.getItem("_token");
  appToken = JSON.parse(_token);
}

export const open_api = axios.create({
  baseURL: base_url,
  timeout: 300000,
  headers: { "Content-Type": "application/json" },
  validateStatus: (status) => status < 500,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

const setApiCall = () => {
  let instance = axios.create({
    baseURL: base_url,
    timeout: 300000,
    headers: { "Content-Type": "application/json" },
    validateStatus: (status) => status < 500,
    transformRequest: [
      function (data, headers) {
        return JSON.stringify(data);
      },
    ],
  });

  instance.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem("dd_admin_token"));
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  instance.interceptors.response.use((res) => {
    if (res.data.error && res.data.error.error_code === 10) {
      localStorage.clear();
      setTimeout(() => {
        window.location.href = "/login";
      }, 100);
    }
    if (res.status === 401) {
      localStorage.removeItem("dd_admin_token");
      window.location.reload();
      return res;
    }
    return res;
  });

  return instance;
};

export const token_api = setApiCall();

export var token_api1 = axios.create({
  baseURL: base_url,
  timeout: 300000000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${checkLocal()}`,
  },
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

function checkLocal() {
  if (typeof window == "undefined") return;

  if (localStorage.getItem("_token") == null) {
    return null;
  } else {
    return JSON.parse(localStorage.getItem("_token"));
  }
}

export function setToken(_token) {
  return new Promise((resolve, reject) => {
    try {
      localStorage.setItem("_token", JSON.stringify(_token));
      appToken = _token;
      token_api1 = axios.create({
        baseURL: base_url,
        timeout: 300000000,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
        transformRequest: [
          function (data, headers) {
            return JSON.stringify(data);
          },
        ],
      });
      resolve("Successfully set dd_website token.");
    } catch (error) {
      reject("Error to ser dd_website token");
    }
  });
}
