import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import backgroundImg from "../Assets/loginBackgroud.png";
import { Button, useTheme } from "@mui/material";
import InputFields from "../Component/InputFields/InputFields";
import CustomButton from "../Component/CustomButton/CustomButton";
import CustomTabs3 from "../Component/CustomTabsNew/CustomTabs3";
import OtpComponent from "./OtpComponent";
import MobileLogin from "./MobileLogin";
import EmailLogin from "./EmailLogin";
import droneLogin from "../Assets/loginDrone.png";
// import droneLogin from "../Assets/adminPanelLoginBg.png"

const initialStates = {
  mobile: "",
  username: "",
  password: "",
  code: "",
  token: "",
  otp: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
};

const Login = () => {
  const [tabVal, setTabVal] = useState(2);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loginStates, setLoginStates] = useState(initialStates);
  const theme = useTheme();
  const {
    palette: { primary },
  } = theme;

  useEffect(() => {
    // handleTabs();
  }, [tabVal]);

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.leftSection}`}>
          <div
            style={{ display: "flex", flexDirection: "column", rowGap: "20px" }}
            className={`${styles.authBox}`}
          >
            <div
              style={{ color: primary[500] }}
              className={`${styles.mainLogin}`}
            >
              Login
            </div>

            <div style={{ marginTop: "15px" }}>
              <CustomTabs3
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "100%",
                }}
                tabHead={tabHead}
                setTabVal={setTabVal}
                currentTab={tabVal}
              />
            </div>

            <div className={styles.loginTypesBox}>
              {/* {tabVal === 1 && (
                <MobileLogin
                  loginStates={loginStates}
                  isDisabled={isDisabled}
                  setLoginStates={setLoginStates}
                />
              )} */}
              {tabVal === 2 && (
                <EmailLogin
                  loginStates={loginStates}
                  isDisabled={isDisabled}
                  setLoginStates={setLoginStates}
                />
              )}

              {/* <OtpComponent isDisabled={isDisabled} />
              <EmailLogin isDisabled={isDisabled} /> */}
            </div>
          </div>
        </div>
        <div className={`${styles.rightSection}`}>
          {/* <div style={{ position: "absolute", left: "30px", top: "30px" }}>
            <img src={droneLogin} alt="Drone Logo" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Login;
const tabHead = [
  // {
  //   id: 1,
  //   title: "Mobile Number",
  // },
  {
    id: 2,
    title: "Email",
  },
];
