export const validateBanner = (values) => {
    return new Promise((resolve, reject) => {

        const {
            web,
            tab,
            mobile,
            detail,
            redirection,
            status,
            alt_text
        } = values

        const errors = { error: false };
        
        if (!web) {
            errors.web = "Add Banner for Website";
            errors.error = true;
        }
        if (!tab) {
            errors.tab = "Add Banner for Tab";
            errors.error = true;
        }
        if (!mobile) {
            errors.mobile = "Add Banner for mobile";
            errors.error = true;
        }
        if (!status || !status[0]) {
            errors.status = "Enter status";
            errors.error = true;
        }
        if (!redirection || !redirection[0]) {
            errors.redirection = "Enter Redirection";
            errors.error = true;
        }
        if (!detail || !detail?.id) {
            errors.detail = "Enter Details";
            errors.error = true;
        }
       
        
        if (!alt_text) {
            errors.alt_text = "Enter Alt text";
            errors.error = true;
        }

        if (errors.error) {
            reject(errors);
        } else {
            resolve("success");
        }
    })
}