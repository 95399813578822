import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

export default function ControlledCheckbox({
  isChecked,
  handleCheck = () => {},
  index,
  ...props
}) {
  const handleChange = (event) => {
    handleCheck(event.target.checked, index);
  };
  return (
    <Checkbox
      checked={isChecked}
      onChange={handleChange}
      size="small"
      inputProps={{ "aria-label": "controlled" }}
    />
  );
}
