export class CourseService {
  constructor() {}
}

export class BannerAndMediaService {
  constructor(courseMedia, banner) {
    console.log("CLASSACTIVE", courseMedia);
    this.courseMedia = courseMedia;
    this.banner = banner;
  }

  addCourseMedia() {
    if (this.courseMedia?.length > 1) {
      let imgFiles = [];
      let imgsPReview = [];
      for (let i = 0; i < this.courseMedia.length; i++) {
        imgFiles.push({
          id: null,
          headline: "",
          tagline: "",
          index: null,
          media: this.courseMedia[i],
          media_preview: URL.createObjectURL(this.courseMedia[i]),
        });
        imgsPReview.push(URL.createObjectURL(this.courseMedia[i]));
      }
      return {
        imgFiles,
        imgsPReview,
      };
    } else {
      return {
        imgsPReview: [URL.createObjectURL(this.courseMedia[0])],
        imgFiles: [
          {
            id: null,
            headline: "",
            tagline: "",
            index: null,
            media: this.courseMedia[0],
            media_preview: URL.createObjectURL(this.courseMedia[0]),
          },
        ],
      };

      //     ...bannersPreview,
      //     URL.createObjectURL(e?.target?.files[0]),
      //   ]);
      //   setBanners([...banners, e?.target?.files[0]]);
    }
  }
}

export const mediaDataForApi = (mediaData) => {
  console.log("MEDIADATA", mediaData);
  let patchData = [];
  let postData = [];
  for (let i = 0; i < mediaData?.length; i++) {
    if (!mediaData[i]?.id) {
      postData.push(mediaData[i]);
    } else {
      patchData.push(mediaData[i]);
    }
  }

  return {
    patchData,
    postData,
  };
};
