export const validateSlot = (value, isDescriptionsEmpty) => {
  return new Promise((resolve, reject) => {
    const { course_id, location_id, start_date, end_date, type } = value;

    const errors = { error: false };

    if (isDescriptionsEmpty) {
      errors.descriptions = "Enter description";
      errors.error = true;
    }

    if (!course_id || !course_id[0]) {
      errors.course_id = "Please fill course name";
      errors.error = true;
    }

    if (!start_date) {
      errors.start_date = "Enter start date";
      errors.error = true;
    }

    if (!end_date) {
      errors.end_date = "Enter end date";
      errors.error = true;
    }

    if (!location_id || !location_id[0]) {
      errors.location_id = "Please fill location";
      errors.error = true;
    }

    if (!type || !type[0]) {
      errors.type = "Please fill course type";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
