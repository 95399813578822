import React, { useRef, useState } from "react";
import './Testing.css'
function Testing(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className={`main-content ${isOpen ? "shifted" : ""}`}>
        <h1>Welcome</h1>
        <p>Main content goes here.</p>
      </div>
    </div>
  );
}

export default Testing;



const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className={`sidebar ${isOpen ? 'open' : 'collapsed'}`}>
      <button className="toggle-btn" onClick={toggleSidebar}>
        &#9776;
      </button>
      <ul>
        <li data-icon="home"><a href="#">Home</a></li>
        <li data-icon="about"><a href="#">About</a></li>
        <li data-icon="services"><a href="#">Services</a></li>
        <li data-icon="contact"><a href="#">Contact</a></li>
      </ul>
    </div>
  );
};
