import React, { useEffect, useState } from 'react'
import CustomTable from '../CustomTable/CustomTable';
import { token_api } from '../../Utils/network';
import styles from "../../Pages/PilotModule/pilot.module.css"

const PilotTabsTable = () => {
    const [pilotList, setPilotList] = useState([]);

    const handleRedirect = (id) => {
      };
    

    const getPilotData = async () => {
        try {
          const fetchList = await token_api.get(`users/profile/pilot`);
          if (fetchList?.status !== 200) {
            throw new Error("Network Error");
          }
    
          let modifiedArray = fetchList?.data?.data?.map((item) => {
            // const { bookingaddonservice } = item;
    
            return {
              id: item?.id,
    
              name: (
                <div className={styles.profileCon}>
                  <img src={item?.name?.profile} />
                  <p>
                    {item?.first_name} {item?.middle_name} {item?.last_name}
                  </p>
                </div>
              ),
              mobile: item?.mobile,
              email: item?.email,
              location: (
                <p style={{ textDecoration: "underline" }}>{item?.location}</p>
              ),
              gender: item?.gender === "1" ? "Male" : "Female",
              license: item?.license?.license_no,
              status: item?.active === true ? "Active" : "Inactive",
              onboarding: item?.joining_date,
              // device: (
              //   <div>
              //     <img src={item?.device} />
              //   </div>
              // ),
    
              // login: (
              //   <div className={styles.languageCon}>
              //     <p className = {styles.loginDate}>{item?.login?.date}</p>
              //     <p className = {styles.loginTime}>({item?.login?.time})</p>
              //   </div>
              // ),
            };
          });
    
          setPilotList([...modifiedArray]);
        } catch (error) {
          console.log("FFFFFFsSS", error);
        }
      };
    
      
      useEffect(() => {
        getPilotData();
      }, []);
  return (
    <div  style={{ height: "300px", overflow: "auto" }}>
           <CustomTable
          columns={tableColumns}
          data={pilotList}
          handleClick={handleRedirect}
          tableFor="pilotList"
        />
      
    </div>
  )
}

export default PilotTabsTable


const tabData = [
    {
      id: 1,
      title: "Active",
    },
    {
      id: 2,
      title: "Inactive",
    },
  ];
  
  const tableColumns = [
    {
      id: "checkBox",
      label: "",
    },
    {
      id: "id",
      label: "User ID",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "mobile",
      label: "Mobile No.",
    },
    {
      id: "email",
      label: "Email",
    },
    // {
    //   id: "time",
    //   label: "Time",
    // },
    {
      id: "location",
      label: "Location",
    },
  
    {
      id: "gender",
      label: "Gender",
    },
    {
      id: "license",
      label: "License",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "onboarding",
      label: "Onboarding Date",
    },
    {
      id: "device",
      label: "Device",
    },
    {
      id: "login",
      label: "Last Login",
    },
  ];