import React from "react";
import styles from "./index.module.css";
import editIcon from "../../Assets/editIcon.png";

const BookingDetailsCard = ({
  cardTitle = "Title",
  children,
  childrenStyle,
  isEditable = true,
  handleEditClick = () => {},
  tabValue = 1,
}) => {
  const clickOnEdit = (value) => {
    handleEditClick(value, tabValue);
  };

  return (
    <div style={{ width: "100%" }}>
      <h3 style={{ marginLeft: "10px", marginBottom: "10px" }}>{cardTitle}</h3>

      <div className={`${styles.cardContainer}`}>
        {isEditable && (
          <div
            style={{
              position: "absolute",
              right: "5px",
              top: "5px",
              cursor: "pointer",
            }}
          >
            <div onClick={() => clickOnEdit(true)}>
              <img src={editIcon} alt="edit" />
            </div>
          </div>
        )}

        <div style={childrenStyle}>{children}</div>
      </div>
    </div>
  );
};

export default BookingDetailsCard;
