import React, { useEffect, useState } from "react";
import Container from "../../Component/Container/Container";
import CustomDataGrid from "../../Component/CustomDataGrid/CustomDataGrid";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import CustomButton from "../../Component/CustomButton/CustomButton";
import filterIcon from "../../Assets/filterIcon.png";
import styles from "./services.module.css";
import { useSelector } from "react-redux";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import ServiceOrder from "./ServiceOrder";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getServices } from "../../state/actions/serviceActions";
import CustomDate from "../../Component/CustomDate/CustomDate";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Stack } from "@mui/material";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";

const Services = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const serviceMain = useSelector((state) => state.service);
  const [serviceTable, setServiceTable] = useState([]);
  const { all_services } = serviceMain;
  console.log("AllServices", all_services);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [filters, setfilters] = useState({
    q: "",
  });

  const [serviceOrderObj, setServiceOrderObj] = useState({
    plotno: "Plot-3",
    status: <div style={{ backgroundColor: "yellow" }}>Scedules</div>,
  });
  // const count = useSelector((state) => state);
  // console.log("StateCheck", count);

  const handleCancelDrw = () => {
    setOpenDrawer(false);
  };

  const handleRedirectFromService = (redirect) => {
    navigate(`/services/${redirect}`);
    console.log("FF");
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(getServices({ ...filters, page: pageState }));
    }, [600]);
    return () => clearTimeout(timeOut);
  }, [filters?.q, pageState]);

  useEffect(() => {
    let modifiedArray = all_services?.data?.map((item) => {
      return {
        serviceId: item?.id,
        service_title: item?.title,
        drones: item?.drone || "N.A",
        category: item?.category || "N.A",
        ratings: item?.avg_rating || "N.A",
        actions: "Edit/Delete",
      };
    });

    setServiceTable(modifiedArray);
  }, [all_services]);
  console.log("MOdifiedData", serviceTable);
  return (
    <>
      <Container>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        <div>{/* <CustomTab /> */}</div>
        <FilterContainer
          style={{ maxHeight: "40px" }}
          className={`${styles.marginTopBox}`}
        >
          <CustomSearchFilter
            onChange={(e) => {
              setfilters({ ...filters, q: e });
            }}
          />

          <CustomSelect
            label={"Filter by Location"}
            listArray={[]}
            minWidth={"175px"}
          />

          {/* <CustomSelect label={"Date"} listArray={[]} minWidth={"150px"} /> */}
          {/* <CustomDate /> */}
          <CustomDatePicker />

          <CustomSelect
            label={"Filter by Service Type"}
            listArray={[]}
            minWidth={"195px"}
          />
          <CustomButton
            text={"ADVANCE FILTERS"}
            btnIcon={<img src={filterIcon} alt="adv-filter" />}
            // maxWidth="170px"
          />
          <CustomButton
            text={"Export"}
            maxWidth="110px"
            onClick={() => setOpenDrawer(true)}
          />
        </FilterContainer>
        <div className={`${styles.marginTopBox}`}>
          {/* {serviceTable?.length > 0 && (
            <CustomDataGrid columns={columns} rows={serviceTable || []} />
          )} */}

          <CustomTable
            columns={tableColumns}
            data={serviceTable}
            handleClick={() => setOpenDrawer(true)}
          />
        </div>
        <div
          onClick={() => handleRedirectFromService("add-service")}
          className={`${styles.newServiceButton}`}
        >
          + New Service
        </div>
        <div
          onClick={() => handleRedirectFromService("quotation")}
          className={`${styles.newServiceButton}`}
        >
          + Create Quotation
        </div>
        <div style={{ alignSelf: "end" }}>
          <CustomPagination setPageState={setPageState} />
        </div>
        <DrawerComp
          onClose={() => setOpenDrawer(false)}
          open={openDrawer}
          anchor={"right"}
          width="40%"
        >
          <ServiceOrder
            serviceOrderObj={serviceOrderObj}
            handleCancelDrw={handleCancelDrw}
          />
        </DrawerComp>
      </Container>
    </>
  );
};

export default Services;

const columns = [
  {
    field: "id",
    headerName: "Service ID",
    flex: 1,
  },
  {
    field: "date",
    headerName: "Date",
    flex: 1,
  },
  {
    field: "time",
    headerName: "Time",
    flex: 1,
  },
  {
    field: "location",
    headerName: "Location",
    flex: 1,
  },
  {
    field: "serviceType",
    headerName: "Service Type",
    flex: 1,
  },
  {
    field: "subType",
    headerName: "Sub-Type",
    flex: 1,
  },
  {
    field: "drone",
    headerName: "Drone",
    flex: 1,
  },
  {
    field: "pilot",
    headerName: "Pilot",
    flex: 1,
  },
];

const rows = [
  {
    id: 1,
    date: "21st Sept 2023",
    time: "4:00 PM",
    location: "Mumbai",
    serviceType: "Precision Agriculture",
    subType: "Crop Insurance",
    drone: "XYZ",
    pilot: "Meenal Saxena",
  },
  {
    id: 2,
    date: "21st Sept 2023",
    time: "5:00 PM",
    location: "Mumbai",
    serviceType: "Precision Agriculture",
    subType: "Crop Insurance",
    drone: "XYZ",
    pilot: "Meenal Saxena",
  },
  {
    id: 3,
    date: "21st Sept 2023",
    time: "6:00 PM",
    location: "Mumbai",
    serviceType: "Precision Agriculture",
    subType: "Crop Insurance",
    drone: "XYZ",
    pilot: "Meenal Saxena",
  },
];

const tableColumns = [
  {
    id: "serviceId",
    label: "Service ID",
  },
  {
    id: "service_title",
    label: "Service",
  },
  {
    id: "category",
    label: "Category",
  },
  {
    id: "drones",
    label: "Drones",
  },
  {
    id: "ratings",
    label: "Ratings",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

const tableRows = [
  {
    id: 1,
    serviceId: "SP12000",
    service_title: "Crop Insurance",
    category: "Precision Agriculture",
    drone: "XYZ",
  },
  {
    id: 2,
    serviceId: "SP12000",
    service_title: "Crop Insurance",
    category: "Precision Agriculture",
    drone: "XYZ",
  },
];

const tabHead = [
  {
    title: "Requests",
    id: 1,
  },
];

const breadcrumbs = [
  <div className="orange-1 bread">Home</div>,
  <div className="orange-1 bread">Services</div>,
];
