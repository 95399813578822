import moment from "moment";

export function convertCalendarDate(_data) {
  var custom_data = [..._data];
  for (var i = 0; i < custom_data.length; i++) {
    const { year, month, date } = getDate(custom_data[i].date);
    const {
      hr: s_hr,
      min: s_min,
      sec: s_sec,
    } = getTime(custom_data[i].commence);
    const {
      hr: e_hr,
      min: e_min,
      sec: e_sec,
    } = getTime(custom_data[i].conclude);

    // custom_data[i].title = (
    //   <div style={{ position: "relative", fontSize: "12px", fontWeight: "500", lineHeight: 1.3 }}>
    //     <div style={{ color: "#000" }}>{custom_data[i].title}</div>
    //     {
    //       custom_data[i]?.event_type?.id === 3 ? (
    //         <>
    //           <div style={{ color: "#476800", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
    //             {custom_data[i].service?.title}
    //           </div>
    //         </>
    //       ) : custom_data[i]?.event_type?.id === 2 ? (
    //         <>
    //           <div style={{ color: "#983500", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
    //             {custom_data[i]?.course?.name}
    //           </div>
    //         </>
    //       ) : custom_data[i]?.event_type?.id === 1 ? (
    //         <>
    //           <div style={{ color: "#000000", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
    //             {custom_data[i]?.course?.name}
    //           </div>
    //         </>
    //       ) : ""
    //     }

    //     <div>{custom_data[i].batch?.location?.city}</div>
    //     {/* <div>{custom_data[i].chapter?.name}</div> */}
    //     {Array.isArray(custom_data[i]?.instructor) &&
    //       custom_data[i]?.instructor?.length > 0 ? (
    //       <div style={{ display: "flex", flexWrap: "wrap" }}>
    //         <div
    //           style={{
    //             border: "1px solid",
    //             padding: "3px",
    //             borderRadius: "7px",
    //             margin: "3px",
    //           }}
    //         >
    //           {custom_data[i]?.instructor.length > 0 &&
    //             custom_data[i].instructor?.map((v, i) => {
    //               return v?.name;
    //             })}
    //         </div>
    //       </div>
    //     ) : (
    //       <div>
    //         <div style={{ color: "#000" }}>
    //           {/* Service Booking Id: {custom_data[i]?.service_booking?.id || 0} */}
    //           ID: {custom_data[i]?.service_booking?.id || custom_data[i]?.course?.id}
    //         </div>
    //         {/* {custom_data[i]?.service?.title ? (
    //           <div
    //             style={{
    //               // border: "1px solid",
    //               padding: "3px",
    //               borderRadius: "7px",
    //               // margin: "3px",
    //               color:"#476800",
    //             }}
    //           >
    //             {custom_data[i]?.service?.title}
    //           </div>
    //         ) : (
    //           <>
    //           {
    //             custom_data[i]?.service?.title
    //           }
    //           </>
    //         )} */}
    //         <div style={{ display: "", gap: "8px" }}>
    //           {custom_data[i]?.pilot?.id ? (<>
    //             <div style={{
    //               color: "#000", textTransform: "capitalize", display: "flex",
    //               alignItems: "center", gap: "3px",
    //             }}>
    //               <img width={25} height={25} src={custom_data[i]?.pilot?.avatar ? custom_data[i]?.pilot?.avatar : custom_data[i]?.pilot?.default_avatar} />   {custom_data[i]?.pilot?.first_name}

    //             </div>
    //           </>) : ""}


    //           {custom_data[i]?.service_user?.profile?.name ? (
    //             <div
    //               style={{
    //                 // border: "1px solid",
    //                 // padding: "3px",
    //                 borderRadius: "7px",
    //                 margin: "3px",
    //                 display: "flex",
    //                 alignItems: "center",
    //                 justifyContent: "flex-end",
    //                 gap: "3px",
    //                 color: "#000"
    //               }}
    //             >
    //               <img width={25} height={25} style={{ borderRadius: "50%", border: "2px solid #FFFFFF" }} src={custom_data[i]?.service_user?.profile?.avatar} />  {custom_data[i]?.service_user?.profile?.name}
    //             </div>
    //           ) : (
    //             <></>
    //           )}
    //         </div>
    //       </div>
    //     )}

    //     {/* {custom_data[i]?.event_type?.id == 3 ? (
    //       <div></div>
    //     ) : (
    //       <div
    //         style={{
    //           // border: "1px solid",
    //           padding: "3px",
    //           // borderRadius: "7px",
    //           margin: "3px",
    //           color: "#000",
    //           display: "flex",
    //           justifyContent: "flex-end"
    //         }}
    //       >
    //         Attendies: {custom_data[i]?.attendies || 0}

    //         {custom_data[i]?.attendies || 0} students
    //       </div>
    //     )} */}

    //     {
    //       custom_data[i]?.event_type?.id === 3 ? "" : (<div style={{ display: "flex", justifyContent:"space-between", alignItems:'center' }}>
    //         {
    //             custom_data[i]?.event_type?.id === 1 ? (<>
    //             <div style={{backgroundColor:"#A44CFC", padding:"2px 5px" , borderRadius:"10px"}}>
    //             Online Class
    //             </div>
    //             </>) : custom_data[i]?.event_type?.id === 2 ? <><div style={{backgroundColor:"#FF5454" , padding:"2px 5px" , borderRadius:"10px"}}>Flying Slot</div></> : ""
    //         }
    //         <div
    //           style={{
    //             // border: "1px solid",
    //             padding: "3px",
    //             // borderRadius: "7px",
    //             margin: "3px",
    //             color: "#000",
    //             display: "flex",
    //             justifyContent: "flex-end"
    //           }}
    //         >
    //           {/* Attendies: {custom_data[i]?.attendies || 0} */}

    //           {custom_data[i]?.attendies || 0} students
    //         </div>
    //       </div>)
    //     }
    //   </div>
    // );

    custom_data[i].title =(
      <div style={{fontSize: "12px", fontWeight: "500", lineHeight: 1.3}}>
        {
          custom_data[i]?.event_type?.id === 3 ? (
            
              <div style={{ color: "#476800", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
              {custom_data[i].title} {custom_data[i].service?.title}
              </div>
          
          ) : custom_data[i]?.event_type?.id === 2 ? (
            
              <div style={{ color: "#983500", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
              {custom_data[i].title} {custom_data[i]?.course?.name}
              </div>
           
          ) : custom_data[i]?.event_type?.id === 1 ? (
           
              <div style={{ color: "#000000", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
              {custom_data[i].title} {custom_data[i]?.course?.name}
              </div>
            
          ) : ""
        }
      </div>
    )
    custom_data[i].start = new Date(year, month - 1, date, s_hr, s_min, s_sec);
    custom_data[i].end = new Date(year, month - 1, date, e_hr, e_min, e_sec);
  }
  return custom_data;
}

export function getDate(_date) {
  var myDate = _date.split("-");
  const year = Number(myDate[0]);
  const month = Number(myDate[1]);
  const date = Number(myDate[2]);
  return { year, month, date };
}

export function getTime(_time) {
  var myTime = _time.split(":");
  const hr = Number(myTime[0]);
  const min = Number(myTime[1]);
  const sec = Number(myTime[2].split(".")[0]);
  // return { hr: myTime[0], min: myTime[1], sec: myTime[2] };
  return { hr: hr, min: min, sec: sec };
}

export function getCurrentDateTime() {
  const _date = new Date();
  const year = _date.getFullYear();
  const month = _date.getMonth();
  const date = _date.getDate();
  return { year, month, date };
}

export function helperdayMonthYearFull(date_) {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date(date_);
  let day = d.getDate();
  let name = month[d.getMonth()];
  let year = d.getFullYear();

  return day + " " + name + " " + year;
}

export const formatDate = (inputDate) => {
  const formattedDate = moment(inputDate).format("Do MMMM YYYY");
  return formattedDate;
};

export const getStatusStyles = (status) => {
  switch (status) {
    case 1:
      return {
        color: "rgba(3, 98, 2, 1)",
        backgroundColor: "rgba(198, 239, 205, 1)",
      };
    case 2:
      return { color: "white", backgroundColor: "rgba(38, 38, 38, 1)" };
    case 3:
      return {
        color: "rgba(118, 0, 0, 1)",
        backgroundColor: "rgba(255, 170, 170, 1)",
      };
    case 4:
      return {
        color: "rgba(119, 78, 0, 1)",
        backgroundColor: "rgba(255, 233, 192, 1)",
      };
    default:
      return { color: "black", backgroundColor: "lightgray" };
  }
};

export const getStatusStylesforDrones = (status) => {
  switch (status) {
    case "Working":
      return { backgroundColor: "rgba(222, 255, 228, 1)" };
    case "Sold":
      return { backgroundColor: "rgba(242, 242, 242, 1)" };
    case "Broken":
      // return { backgroundColor: "rgba(222, 255, 228, 1)" };
      return { backgroundColor: "lightgray" };

    case "Under Repair":
      return { backgroundColor: "rgba(222, 255, 228, 1)" };
    default:
      return { backgroundColor: "lightgray" };
  }
};

