import { open_api, token_api } from "../../Utils/network";
import {
  apiFailed,
  cropStageServiceList,
  cropTypeList,
  cropTypeServiceList,
  getAllServicesSuccess,
  getCropStageSuccess,
  pesticideList,
  pesticideServiceList,
  serviceCategories,
} from "../reducers/serviceSlice";

const getServicesApi = async (filters) => {
  try {
    const response = await open_api.get(`service` , {params:filters});
    if (response) {
      const { data } = response;
      return data;
    }
    throw new Error("Error");
  } catch (error) {
    throw error;
  }
};

const cropStageApi = async () => {
  try {
    const response = await open_api.get(`service/crop_stage`);
    if (response.status === 200) {
      const { data } = response;
      return data;
    }
    throw new Error("Error");
  } catch (error) {
    throw error;
  }
};

const serviceBase = async (filters, endPoint) => {
  try {
    const response = await open_api.get(`service/${endPoint}`);
    if (response.status !== 200) {
      throw new Error("Error in fetching data.");
    }

    const { data } = response;
    return data;
  } catch (error) {
    // throw error;
    throw error;
  }
};

// All Actions

export const getServices = (filters) => async (dispatch) => {
  try {
    const data = await getServicesApi(filters);
    dispatch(getAllServicesSuccess(data));
  } catch (error) {
    throw error;
  }
};

export const getCropStage = () => async (dispatch) => {
  try {
    const data = await cropStageApi();
    // console.log("ExecutedData", data);
    //   dispatch(loginSuccess(data));
  } catch (error) {
    throw error;
  }
};

export const getServiceBaseApi = (filters, endPoint) => async (dispatch) => {
  try {
    const data = await serviceBase(filters, endPoint);

    if (endPoint === "crop_stage") {
      dispatch(getCropStageSuccess(data));
    }
    if (endPoint === "category") {
      dispatch(serviceCategories(data));
    }
    if (endPoint === "crop_type") {
      dispatch(cropTypeList(data));
    }
    if (endPoint === "crop_stage/service") {
      dispatch(cropStageServiceList(data));
    }
    if (endPoint === "crop_type/service") {
      dispatch(cropTypeServiceList(data));
    }
    if (endPoint === "pesticide") {
      dispatch(pesticideList(data));
    }
    if (endPoint === "pesticide/service") {
      dispatch(pesticideServiceList(data));
    }
    //   dispatch(loginSuccess(data));
  } catch (error) {
    dispatch(apiFailed(error));

    // return error;
  }
};
