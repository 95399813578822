import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  useTheme,
} from "@mui/material";
import { getStatusStylesforDrones } from "../../Utils/Utils";
import downSort from "../../Assets/downSort.png";

const CustomTableForDrones = ({
  data = [],
  columns = [],
  handleClick,
  tableFor,
}) => {
  const theme = useTheme();
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead
            sx={{ backgroundColor: theme.palette.primary[500], color: "#fff" }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{ color: "#fff", fontSize: "16px", textAlign: "center" }}
                  key={column.id}
                >
                  {column.label}
                  {/* <img src={downSort} alt="sort" /> */}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow
                key={index}
                style={{  ...getStatusStylesforDrones(row?.drone_status?.props?.children)}}
              >
                {columns?.map((column) => (
                  <TableCell key={column.id} style={{ textAlign: "center" }}>
                    {row[column.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomTableForDrones;
