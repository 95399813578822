export const Validate = (value, accForm, id) => {
  return new Promise((resolve, reject) => {
    const {
      hotelName,
      pincode,
      line_1,
      line_2,
      district,
      state,
      city,
      country,
      check_in,
      check_out,
      about,
      single_occupancy_no,
      double_occupancy_no,
      price,
    } = value;

    const errors = { error: false };

    if (!id) {
      if (!single_occupancy_no) {
        errors.single_occupancy_no = "Please enter number of single occupancy";
        errors.error = true;
      }
    }

    if (!id) {
      if (!double_occupancy_no) {
        errors.double_occupancy_no = "Please enter number of double occupancy";
        errors.error = true;
      }
    }

    if (!accForm?.location) {
      errors.location = "Please select location for this accomodation";
      errors.location = true;
    }

    if (!hotelName) {
      errors.hotelName = "Please enter hotel name";
      errors.error = true;
    }
    // if (!pincode) {
    //   errors.pincode = "Please enter pincode";
    //   errors.error = true;
    // }
    // if (!line_1) {
    //   errors.line_1 = "Please enter line 1";
    //   errors.error = true;
    // }
    // if (!line_2) {
    //   errors.line_2 = "Please enter line 2";
    //   errors.error = true;
    // }

    // if (!district) {
    //   errors.district = "Please enter area";
    //   errors.error = true;
    // }

    // if (!state) {
    //   errors.state = "Please enter state";
    //   errors.error = true;
    // }
    // if (!city) {
    //   errors.city = "Please enter city";
    //   errors.error = true;
    // }

    // if (!country) {
    //   errors.country = "Please enter country";
    //   errors.error = true;
    // }
    if (!check_in) {
      errors.check_in = "Please enter check-in time";
      errors.error = true;
    }
    if (!check_out) {
      errors.check_out = "Please enter check-out time";
      errors.error = true;
    }
    if (!about) {
      errors.about = "Please enter about the hotel";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
