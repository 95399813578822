import React from "react";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import { Box } from "@mui/material";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import styles from "./services.module.css";
import "../../App.css";
import { serviceOrderKeys } from "../../Utils/constants";
import Divider from "../../Component/Divider/Divider";
import mapDummy from "../../Assets/addressMapImage.png";
import droneImg from "../../Assets/serviceOrdDrone.png";
import CustomButton from "../../Component/CustomButton/CustomButton";

const ServiceOrder = ({ handleCancelDrw, serviceOrderObj }) => {
  console.log("CACELDRWER2", serviceOrderObj);
  const s = {
    title1: "Service Order ID",
    title2: "#SP1200",
  };
  return (
    <DrawerContainer>
      <DrawerTitle
        text={`${s?.title1} : ${s?.title2 || ""}`}
        handleCancelDrw={handleCancelDrw}
      />
      <div className={`${styles.landDtlsBox}`}>
        {/* all keys */}
        <div style={{ flex: "2" }} className={`drawerLeftFontStyles `}>
          {serviceOrderKeys?.landDetails.map((e) => {
            let finalKey = e.split(" ").join("").toLowerCase().replace(".", "");
            return (
              <div className={`${styles.keyValueBox}`}>
                <p style={{ minWidth: "150px" }} key={e}>
                  {e}
                </p>
                <p className={`${styles.valuesContent}`}>
                  {":"} {serviceOrderObj[finalKey] || " N.A"}
                </p>
              </div>
            );
          })}
        </div>
        {/* this box shakl be dymic showing map results */}
        <div style={{ flex: "1" }}>
          <img src={mapDummy} alt="map" />
        </div>
      </div>

      <Divider />

      <div>
        <div>Drone Details</div>
        <div className={`${styles.landDtlsBox}`}>
          {/* all keys */}
          <div style={{ flex: "2" }} className={`drawerLeftFontStyles `}>
            {serviceOrderKeys?.droneDetails.map((e) => {
              let finalKey = e
                .split(" ")
                .join("")
                .toLowerCase()
                .replace(".", "");
              return (
                <div className={`${styles.keyValueBox}`}>
                  <p style={{ minWidth: "150px" }} key={e}>
                    {e}
                  </p>
                  <p className={`${styles.valuesContent}`}>
                    {":"} {serviceOrderObj[finalKey] || " N.A"}
                  </p>
                </div>
              );
            })}
          </div>
          {/* this box shall be dynamic  */}
          <div style={{ flex: "1" }}>
            <img src={droneImg} alt="map" />
          </div>
        </div>
      </div>
      <Divider />
      <div>
        <div>Pilot details</div>

        <div className={`${styles.landDtlsBox}`}>
          {/* all keys */}
          <div style={{ flex: "2" }} className={`drawerLeftFontStyles `}>
            {serviceOrderKeys?.pilotDetails.map((e) => {
              let finalKey = e
                .split(" ")
                .join("")
                .toLowerCase()
                .replace(".", "");
              return (
                <div className={`${styles.keyValueBox}`}>
                  <p style={{ minWidth: "150px" }} key={e}>
                    {e}
                  </p>
                  <p className={`${styles.valuesContent}`}>
                    {":"} {serviceOrderObj[finalKey] || " N.A"}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Divider />
      <div>
        <div>Other details</div>

        <div className={`${styles.landDtlsBox}`}>
          {/* all keys */}
          <div style={{ flex: "2" }} className={`drawerLeftFontStyles `}>
            {serviceOrderKeys?.otherDetails.map((e) => {
              let finalKey = e
                .split(" ")
                .join("")
                .toLowerCase()
                .replace(".", "");
              return (
                <div className={`${styles.keyValueBox}`}>
                  <p style={{ minWidth: "150px" }} key={e}>
                    {e}
                  </p>
                  <p className={`${styles.valuesContent}`}>
                    {":"} {serviceOrderObj[finalKey] || " N.A"}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div style={{ padding: "15px 0px" }}>
        <CustomButton text="Edit" />
      </div>
    </DrawerContainer>
  );
};

export default ServiceOrder;

const dummyServiceOrderValues = {
  landDetails: [{}],
  droneDetails: ["Drone", "License No.", "Insurance Coverage"],
  pilotDetails: ["Pilot", "Pilot License No."],
  otherDetails: ["Amount", "Coupon Code", "Discount", "Final Amount"],
};
