export const Validate = (value) => {
  return new Promise((resolve, reject) => {
    const {
      line_1,
      line_2,
      area,
      city,
      tehsil,
      district,
      state,
      pincode,
      country,
      city_new,
    } = value;

    const errors = { error: false };

    if (!line_1) {
      errors.line_1 = "Please enter address line1";
      errors.error = true;
    }

    if (!line_2) {
      errors.line_2 = "Please enter address line2";
      errors.error = true;
    }

    if (!area) {
      errors.area = "Please enter area";
      errors.error = true;
    }
    if (!city) {
      errors.city = "Please enter city/town name";
      errors.error = true;
    }
    if (!tehsil) {
      errors.tehsil = "Please enter tehsil";
      errors.error = true;
    }
    if (!district) {
      errors.district = "Please enter district";
      errors.error = true;
    }

    if (!state) {
      errors.state = "Please enter state";
      errors.error = true;
    }

    if (!pincode) {
      errors.pincode = "Please enter pincode";
      errors.error = true;
    }
    if (!country) {
      errors.country = "Please enter country";
      errors.error = true;
    }
    // if (!city_new) {
    //   errors.city_new = "Please select city_new";
    //   errors.error = true;
    // }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
