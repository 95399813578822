import React, { Component, createContext } from "react";
import { createTheme } from "@mui/material/styles";
import { showSideData } from "../../Utils/constants";
export const UserCredsContext = createContext();

// dd_panel
const localstorage_state = localStorage.getItem("dd_panel");

function getLocalData(keyname) {
  // Function to return values from local storage
  let object = null;
  try {
    object = JSON.parse(localstorage_state);
  } catch {
    console.error("There was error while parsing data from localstorage.");
  }

  if (object) {
    if (object[keyname]) {
      return object[keyname];
    }
  }

  if (keyname === "themeMode") return "light";

  if (keyname === "user_state") return {};
  if (keyname === "token") return { access: "", refresh: "" };
  if (keyname === "user_permissions") return {};
  return "";
}

class UserCredsContextProvider extends Component {
  constructor(props) {

    super(props);
    this.state = {
      theme: createTheme({
        palette: {
          primary: { 500: "#E95E33" },
          secondary: { A400: "#D78312" },
          white: { 500: "rgba(255,255,255)" },
          mode: "light",
        },
      }),
      col: {
        primary: "#FB9B5F",
        secondary: "#143F6B",
        ternary: "#006080",
      },
      user_permissions: getLocalData("user_permissions"),
      themeMode: getLocalData("themeMode"),
      user_state: getLocalData("user_state"),
      token: getLocalData("token"),
      checkMapData: showSideData,
    };
    this.setThemeMode = this.setThemeMode.bind(this);
    this.setUserState = this.setUserState.bind(this);
    this.setUserPermissions = this.setUserPermissions.bind(this);
    this.setToken = this.setToken.bind(this);
    this.setCheckMapData = this.setCheckMapData.bind(this);
  }

  componentDidMount() {
    return () => {
      localStorage.setItem("dd_panel", JSON.stringify(this.state));
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.user_state !== this.state.user_state) {
      localStorage.setItem("dd_panel", JSON.stringify(this.state));
    }
  }

  componentWillUnmount() {}

  updateLocal = () => {
    localStorage.setItem("dd_panel", JSON.stringify(this.state));
  };

  setCheckMapData = (_data) => {
    this.setState({ checkMapData: _data });
  };

  setThemeMode = (_boolean_value) => {};

  setUserState = (user_data, token_data) => {
    this.setState({ user_state: user_data, token: token_data }, () => {
      this.updateLocal();
    });
  };

  setUserPermissions = (_permissionobject) => {
    this.setState(
      { user_permissions: _permissionobject },

      () => {
        this.updateLocal();
      }
    );
  };

  setToken = (_token) => {
    this.setState({ token: _token.token });
    this.updateLocal();
  };

  logout = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.href = "/login";
    }, 100);
  };

  render() {
    return (
      <UserCredsContext.Provider
        value={{
          theme: this.state.theme,
          themeMode: this.state.themeMode,
          setUserState: this.setUserState,
          user_permissions: this.state.user_permissions,
          setUserPermissions: this.setUserPermissions,
          logout: this.logout,
          userState: this.state.user_state,
          token_data: this.state.token,
          setToken: this.setToken,
          setCheckMapData: this.setCheckMapData,
          checkMapData: this.state.checkMapData,
        }}
      >
        {this.props.children}
      </UserCredsContext.Provider>
    );
  }
}

export default UserCredsContextProvider;
