import React, { useEffect, useState, useContext } from "react";
import styles from "./PilotSlotList.module.css";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import Container from "../../Component/Container/Container";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import dayjs from "dayjs";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import { Link, Router, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { token_api, base_url } from "../../Utils/network";
import uploadSlot from "../../Assets/uploadSlot.svg";
import DronePilot from "../../Component/DronePilot/DronePilot";
import DurationPicker from "../../Component/DurationPicker/DurationPicker";
import { Button } from "@mui/material";
import { Validate } from "./Utility";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import {
  formatDate,
  helperdayMonthYearFull,
  convertTo12HourFormat,
} from "../../Utils/helper";
import weekSelected from "../../Assets/slotweekSelected.svg";
import weekNotSelected from "../../Assets/weekNotSelected.svg";
import Switch from "@mui/material/Switch";
import CustomModal from "../../Component/CustomModal/CustomModal";
import successGif from "../../Assets/deletedSuccessfully.gif";
import failedGif from "../../Assets/failedPayment.gif";
import InputFields from "../../Component/InputFields/InputFields";
import axios from "axios";
import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import AddressBlock from "../../Component/AddressBlock/AddressBlock";
import CustomButton from "../../Component/CustomButton/CustomButton";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

const PilotSlotList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user_permissions } = useContext(UserCredsContext);
  console.log(user_permissions, "pilotSlotsUserPermissions");

  const [pilotSlotsData, setPilotSlotsData] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;

  const [loading, setLoading] = useState(false);
  const [pilotSelected, setPilotSelected] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState([]);
  const [formData, setFormData] = useState({
    // pincode: "",
    // district: "",
    // state: "",
    start_date: null,
    end_date: null,
    start_time: null,
    end_time: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link to="/pilots/pilotslist" className="orange-1 bread breadElement">
      Pilots
    </Link>,

    <div className="orange-1 bread">Pilot Slots</div>,
  ];
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalState, setModalState] = useState("");
  const [filters, setFilters] = useState({
    pilot: [],
    start_date: null,
    end_date: null,
  });
  const [blockList, setBlockList] = useState([]);
  const [deleteSlotId, setDeleteSlotId] = useState(null);

  const handleEditSlot = (item) => {};

  const handleDeleteSlotDialog = (slotId) => {
    setDeleteSlotId(slotId);
    setModalState("3");
    setIsModalOpen(true);
  };

  const handleRouteToServiceBooking = (serviceBookingId) => {
    navigate(`/bookings/service-booking-details/${serviceBookingId}`);
  };
  const handleDeleteSlot = () => {
    token_api
      .delete(`service/pilot/slots/${deleteSlotId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setDeleteSlotId(null);
          setIsModalOpen(false);
          setModalState("");
          getPilotSlotsData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getPilotSlotsData = async (filterobject) => {
    try {
      const fetchList = await token_api.get(`service/pilot/slots`, {
        params: { ...filterobject },
      });
      if (fetchList?.status !== 200) {
        throw new Error("Network Error");
      }
      setPaginationData(fetchList?.data);

      let modifiedArray = fetchList?.data?.data?.map((item) => {
        return {
          id: item?.id,
          pilot: `${item?.pilot?.first_name} ${item?.pilot?.last_name}`,
          date: helperdayMonthYearFull(item?.slot_date),
          slot: convertTo12HourFormat(item?.slot_time),
          service_booking: item?.is_available ? (
            <p
              style={{
                padding: "10px 0px",
                boxSizing: "border-box",
                borderRadius: "20px",
                fontWeight: "400",
                fontSize: "18px",
                textAlign: "center",
                margin: "0 auto",
                width: "150px",
                backgroundColor: "rgba(198, 239, 205, 1)",
                color: "rgba(3, 98, 2, 1)",
              }}
            >
              Available
            </p>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleRouteToServiceBooking(item?.service_booking?.id);
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                  textAlign: "center",
                  color: "rgba(233, 94, 51, 1)",
                  textDecoration: "underline",
                  textDecorationColor: "rgba(233, 94, 51, 0.25)",
                }}
              >
                {item?.service_booking?.full_name}
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                  textAlign: "center",
                  color: "rgba(233, 94, 51, 1)",
                  textDecoration: "underline",
                  textDecorationColor: "rgba(233, 94, 51, 0.25)",
                }}
              >
                Booking ID : {item?.service_booking?.id}
              </p>
            </div>
          ),
          block_name: item?.block_name ? item?.block_name : "N.A",
          // pincode: item?.pincode,
          state: item?.state || "N.A",
          district: item?.district || "N.A",
          action: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleEditSlot(item);
                  }}
                >
                  <img src={editIcon} />
                </div>
              )}

              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleDeleteSlotDialog(item?.id);
                  }}
                >
                  <img src={deleteIcon} />
                </div>
              )}
            </div>
          ),
        };
      });

      setPilotSlotsData([...modifiedArray]);
    } catch (error) {
      console.log("FFFFFFsSS", error);
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      let filtersObject = {};
      if (pageState) {
        filtersObject.page = pageState;
      }
      if (filters?.q) {
        filtersObject.q = filters?.q;
      }
      if (filters?.start_date) {
        filtersObject.start_date = formatDate(filters?.start_date);
      }
      if (filters?.end_date) {
        filtersObject.end_date = formatDate(filters?.end_date);
      }
      if (filters?.pilot[0]) {
        const ids = filters?.pilot?.map((info) => info?.id);
        filtersObject.pilot = ids[0];
      }

      getPilotSlotsData(filtersObject);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [
    pageState,
    filters?.q,
    filters?.pilot,
    filters?.start_date,
    filters?.end_date,
  ]);

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setFormData({
        ...formData,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setFormData({
        ...formData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      if (name === "pincode") {
        if (value.length > 6) {
          return;
        }
      }
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  const handleAutocompleteChangeforPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setPilotSelected(selectedValues);
    }
  };

  const handleAutoCompleteChangeForBlock = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Block already included");
    } else {
      setSelectedBlock(selectedValues);
    }
  };

  const handleSearchPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        pilot: selectedValues,
      }));
    }
  };

  const handleAddSlot = () => {
    setOpenDrawer(true);
    setFormData({
      ...formData,
      // pincode: "",
      // district: "",
      // state: "",
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
    });
    setPilotSelected([]);
    setSelectedBlock([]);
    setFormErrors({});
    setSelectAll(false);
    setSelectedWeeks([]);
  };

  const handleCreateSlot = async () => {
    let data = {
      ...(pilotSelected.length > 0 && {
        pilot_id: pilotSelected[0]?.id,
      }),
      start_date: formatDate(formData?.start_date),
      end_date: formatDate(formData?.end_date),
      start_time: formData?.start_time,
      end_time: formData?.end_time,
      days: selectedWeeks.map((selectedWeek) =>
        weeksList[weeksList.indexOf(selectedWeek)].toLowerCase()
      ),
      // pincode: formData?.pincode,
      ...(selectedBlock.length > 0 && {
        block_id: selectedBlock[0]?.id,
      }), // district: formData?.district,
      // state: formData?.state,
    };

    try {
      Validate(formData, pilotSelected, selectedBlock)
        .then(async (response) => {
          if (response === "success") {
            token_api
              .post(`service/pilot/slots/`, data)
              .then((response) => {
                if (response?.status == 200 || response?.status == 201) {
                  setModalState("1");
                  setIsModalOpen(true);
                  setOpenDrawer(false);
                  setFormData({
                    ...formData,
                    // pincode: "",
                    // district: "",
                    // state: "",
                    start_date: null,
                    end_date: null,
                    start_time: null,
                    end_time: null,
                  });
                  setPilotSelected([]);
                  setSelectedBlock([]);
                  setFormErrors({});
                  setSelectAll(false);
                  setSelectedWeeks([]);
                  getPilotSlotsData();
                }
              })
              .catch((error) => {
                console.log(error);
                setModalState("2");
                setIsModalOpen(true);
                setOpenDrawer(false);
              });
          }
        })
        .catch((error) => {
          setFormErrors(error);
        });
    } catch (error) {}
  };

  const handleWeekClick = (week) => {
    if (selectedWeeks.includes(week)) {
      setSelectedWeeks(
        selectedWeeks.filter((selectedWeek) => selectedWeek !== week)
      );
      setSelectAll(false);
    } else {
      setSelectedWeeks([...selectedWeeks, week]);
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allWeeks = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      setSelectedWeeks(allWeeks);
    } else {
      setSelectedWeeks([]);
    }
    setSelectAll(!selectAll);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setFormData({});
    setSelectAll(false);
    setSelectedWeeks([]);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalState("");
  };

  const handleDeleteChip = (id, selectName) => {
    setFormData({
      ...formData,
      [selectName]: formData?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  //Export Logic....

  const handleExportData = async () => {
    try {
      const response = await axios.get(`${base_url}service/pilot/slots_export`);
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };
  return (
    <div>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>
        <div style={{ margin: "20px 0px" }}>
          <FilterContainer>
            <CustomSearchFilter
              placeholder="Search by slot id, block name, pilot name..."
              onChange={(e) => {
                setFilters({ ...filters, q: e });
              }}
            />

            <DronePilot value={filters?.pilot} onChange={handleSearchPilot} />
            <CustomDatePicker
              label="Start Date"
              value={filters?.start_date}
              onChange={(newValue) =>
                setFilters({
                  ...filters,
                  start_date: newValue,
                })
              }
            />
            <CustomDatePicker
              label="End Date"
              value={filters?.end_date}
              onChange={(newValue) =>
                setFilters({
                  ...filters,
                  end_date: newValue,
                })
              }
            />

            <CustomButton
              text={"Export"}
              maxWidth="110px"
              onClick={handleExportData}
            />

            {user_permissions?.write && (
              <div className={styles.uploadMainCon}>
                <div className={styles.uploadCon}>
                  <img src={uploadSlot} />
                  <h3>Upload</h3>{" "}
                </div>
                <div className={styles.uploadCon}>
                  <h3 onClick={handleAddSlot}> + Add Slot</h3>{" "}
                </div>
              </div>
            )}
          </FilterContainer>
        </div>

        <div className={styles.tableContainer}>
          <CustomTable
            columns={tableColumns}
            data={pilotSlotsData}
            tableFor="pilotslotslist"
          />
        </div>
        <div className={styles.paginationContainer}>
          <CustomPagination max_pages={max_pages} setPageState={setPageState} />
        </div>

        <DrawerComp
          open={openDrawer}
          onClose={() => handleCloseDrawer()}
          anchor="right"
        >
          <DrawerContainer>
            <DrawerTitle
              text={"Add Slot"}
              handleCancelDrw={() => handleCloseDrawer()}
            />

            <div style={{ marginTop: "10px" }}>
              <DronePilot
                value={pilotSelected}
                onChange={handleAutocompleteChangeforPilot}
              />
              {formErrors?.pilotSelected && (
                <p style={{ color: "red" }}>{formErrors?.pilotSelected}</p>
              )}
            </div>

            <div style={{ marginTop: "10px" }}>
              <AddressBlock
                value={selectedBlock}
                onChange={handleAutoCompleteChangeForBlock}
              />
              {formErrors?.selectedBlock && (
                <p style={{ color: "red" }}>{formErrors?.selectedBlock}</p>
              )}
            </div>
            {/* <div style={{ marginTop: "10px" }}>
              <InputFields
                label="Pincode"
                name="pincode"
                value={formData?.pincode || ""}
                onChange={handleChange}
                error={formErrors?.pincode}
                helperText={formErrors?.pincode}
                maxLength={6}
                type="number"
              />
            </div> */}

            {/* <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div style={{ marginTop: "10px", width: "45%" }}>
                <InputFields
                  label="District"
                  name="district"
                  value={formData?.district || ""}
                  onChange={handleChange}
                  error={formErrors?.district}
                  helperText={formErrors?.district}
                />
              </div>{" "}
              <div style={{ marginTop: "10px", width: "45%" }}>
                <InputFields
                  label="State"
                  name="state"
                  value={formData?.state || ""}
                  onChange={handleChange}
                  error={formErrors?.state}
                  helperText={formErrors?.state}
                />
              </div>
            </div> */}

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div style={{ width: "45%" }}>
                <CustomDatePicker
                  label="Start Date"
                  value={formData?.start_date}
                  onChange={(newValue) =>
                    setFormData({
                      ...formData,
                      start_date: newValue,
                    })
                  }
                  error={formErrors?.start_date}
                />
              </div>
              <div style={{ width: "45%" }}>
                <CustomDatePicker
                  label="End Date"
                  value={formData?.end_date}
                  onChange={(newValue) =>
                    setFormData({
                      ...formData,
                      end_date: newValue,
                    })
                  }
                  error={formErrors?.end_date}
                />
              </div>
            </div>

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div style={{ marginTop: "10px", width: "45%" }}>
                <DurationPicker
                  style={{ width: "100%" }}
                  label="Start Time"
                  size="small"
                  name="start_time"
                  onChange={handleChange}
                  value={formData?.start_time}
                  error={formErrors?.start_time}
                  // required
                  // error={startTimeCheck}
                  helperText={formErrors?.start_time ? "Enter start time" : ""}
                />
              </div>
              <div style={{ marginTop: "10px", width: "45%" }}>
                <DurationPicker
                  style={{ width: "100%" }}
                  label="End Time"
                  size="small"
                  name="end_time"
                  onChange={handleChange}
                  value={formData?.end_time}
                  error={formErrors?.end_time}
                  helperText={formErrors?.end_time ? "Enter End time" : ""}
                />
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <h3>Select All</h3>
                <div>
                  <Switch
                    checked={selectAll}
                    onChange={handleSelectAll}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </div>
              <div className={styles.weeksListCon}>
                {weeksList.map((week, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        selectedWeeks.includes(week)
                          ? styles.selectedWeek
                          : styles.unselectedWeek
                      }
                      onClick={() => handleWeekClick(week)}
                    >
                      <h3>{week}</h3>
                      <img
                        src={
                          selectedWeeks.includes(week)
                            ? weekSelected
                            : weekNotSelected
                        }
                        alt="week_selected_or_not"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div style={{ margin: "10px auto" }}>
              <Button variant="contained" onClick={handleCreateSlot}>
                Create Slots
              </Button>
            </div>
          </DrawerContainer>
        </DrawerComp>

        <CustomModal open={isModalOpen} onClose={handleCloseModal}>
          {modalState === "1" && (
            <div className={styles.deleteConfirmCon}>
              <img src={successGif} />
              <h1>Congratulations!</h1>
              <p className={styles.reallyText}>
                Slot Has Been Added <span>Successfully</span> <br />
                {/* 7th May 2024 - 8:30 AM */}
              </p>
              <div className={styles.bottomCon}>
                <h1 className={styles.yesText} onClick={handleCloseModal}>
                  OK
                </h1>
              </div>
            </div>
          )}

          {modalState === "2" && (
            <div className={styles.deleteConfirmCon}>
              <img src={failedGif} />
              <h1>Oooops!</h1>
              <p className={styles.reallyText}>
                Slot Has <span>Not Been Added</span> <br />
                Please try again.{" "}
              </p>

              <div className={styles.bottomCon}>
                <h1 className={styles.yesText} onClick={handleCloseModal}>
                  Try Again
                </h1>
              </div>
            </div>
          )}

          {modalState === "3" && (
            <div className={styles.deleteConfirmCon}>
              <p className={styles.reallyText}>
                Do you really want to delete this pilot slot?
              </p>
              <div className={styles.bottomCon}>
                <h1 className={styles.yesText} onClick={handleDeleteSlot}>
                  Yes
                </h1>
                <h1 className={styles.noText} onClick={handleCloseModal}>
                  No
                </h1>
              </div>
            </div>
          )}
        </CustomModal>
      </Container>
    </div>
  );
};

export default PilotSlotList;

const tableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "block_name",
    label: "Block Name",
  },
  {
    id: "pilot",
    label: "Pilot",
  },
  {
    id: "date",
    label: "Date",
  },
  {
    id: "slot",
    label: "Slot",
  },

  {
    id: "service_booking",
    label: "Service Booking",
  },

  // {
  //   id: "pincode",
  //   label: "Pincode",
  // },
  {
    id: "state",
    label: "State",
  },
  {
    id: "district",
    label: "District",
  },
  {
    id: "action",
    label: "Action",
  },
];

const tableBodyData = [
  {
    id: 1,
    pilot: "Premchand",
    date: "07/05/2024",
    slot: "06:00 AM",
    service_booking: "135",
    pincode: "532409",
    state: "Maharastra",
    district: "Thane",
  },
  {
    id: 2,
    pilot: "Premchand",
    date: "07/05/2024",
    slot: "06:00 AM",
    service_booking: "135",
    pincode: "532409",
    state: "Maharastra",
    district: "Thane",
  },
  {
    id: 3,
    pilot: "Premchand",
    date: "07/05/2024",
    slot: "06:00 AM",
    service_booking: "135",
    pincode: "532409",
    state: "Maharastra",
    district: "Thane",
  },
  {
    id: 4,
    pilot: "Premchand",
    date: "07/05/2024",
    slot: "06:00 AM",
    service_booking: "",
    pincode: "532409",
    state: "Maharastra",
    district: "Thane",
  },
  {
    id: 5,
    pilot: "Premchand",
    date: "07/05/2024",
    slot: "06:00 AM",
    service_booking: "135",
    pincode: "532409",
    state: "Maharastra",
    district: "Thane",
  },
];

const weeksList = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
