export const ValidateProduct = (value) => {
  return new Promise((resolve, reject) => {
    const { product_name, brand } = value;

    const errors = { error: false };

    if (!product_name) {
      errors.product_name = "Please enter product name";
      errors.error = true;
    }

    if (!brand[0] && !Object.keys(brand).length === 0) {
      errors.brand = "Please select brand";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};

export const ValidateBrand = (value) => {
  return new Promise((resolve, reject) => {
    const { brand_name } = value;

    const errors = { error: false };

    if (!brand_name) {
      errors.brand_name = "Please enter brand name";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
