import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({
  variant = "outlined",
  btnIcon,
  text,
  width = "100%",
  maxWidth,
  maxHeight,

  onClick,
}) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        textAlign: "center",
        maxWidth: maxWidth,
        height: "55px",
        fontWeight: "",
        // padding: "0px",
        // maxHeight: maxHeight,
        width: { width },
      }}
      variant={variant}
      startIcon={btnIcon || ""}
    >
      {text || ""}
    </Button>
  );
};

export default CustomButton;
