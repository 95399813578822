import React from "react";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { helperdayMonthYearFull } from "../../Utils/helper";

const CustomBatchListingChip = ({
  label,
  onChange,
  onDelete,
  name,
  start_date,
  end_date,
  type = "select",
  value,
  listArray,
  disabled,
  error,
}) => {

  return (
    <FormControl sx={{ width: "100%" }} size="small" error={error}>
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        label={label}
        disabled={disabled}
        size="small"
        value={value || []}
        onChange={(e) => onChange(e.target.value, type, name)}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected?.map((value) => (
              <Chip
                label={`${value?.name} (${helperdayMonthYearFull(
                  value?.start_date
                )} - ${helperdayMonthYearFull(value?.end_date)})`}
                key={value}
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                onDelete={() => onDelete(value?.id, name)}
              />
            ))}
          </Box>
        )}
      >
        {listArray?.map((item, key) => (
          <MenuItem key={key} value={item}>
            {`${item?.name} (${
              item?.start_date && helperdayMonthYearFull(item?.start_date)
            } - ${item?.end_date && helperdayMonthYearFull(item?.end_date)})`}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default CustomBatchListingChip;
