import React, { useState, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon, divIcon, point } from "leaflet";
import "./styles.css";
import { token_api } from "../../Utils/network";

const workingDroneIcon = new Icon({
  iconUrl: require("../../Assets/icons/working_drone.png"),
  iconSize: [25, 30],
});

const brokenDroneIcon = new Icon({
  iconUrl: require("../../Assets/icons/broken_drone.png"),
  iconSize: [25, 30],
});

const pilotIcon = new Icon({
  iconUrl: require("../../Assets/icons/pilot.png"),
  iconSize: [25, 30],
});

const batteryIcon = new Icon({
  iconUrl: require("../../Assets/icons/battery.png"),
  iconSize: [25, 30],
});

const createClusterCustomIcon = function (cluster) {
  return new divIcon({
    html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: point(33, 33, true),
  });
};

const markers = [
  {
    geocode: [20.5937, 78.9629],
    popUp: "Working Drone",
    icon: workingDroneIcon,
  },
  {
    geocode: [28.6139, 77.209],
    popUp: "Broken Drone",
    icon: brokenDroneIcon,
  },
  {
    geocode: [19.076, 72.8777],
    popUp: "Pilot",
    icon: pilotIcon,
  },
  {
    geocode: [22.5726, 88.3639],
    popUp: "Battery",
    icon: batteryIcon,
  },
];

const MapforDashboard = () => {
  const [markerdata, setMarkerData] = useState([]);


  function getBatteryInventory() {
    token_api
      .get(`/drone/inventory_geomap_data`)
      .then((response) => {
  
        let workingDroneRes = response?.data?.data?.data?.working_drones?.map((info) => {
          if (info?.stationed_at__lat !== null && info?.stationed_at__long !== null) {
            return {
              geocode: [info.stationed_at__lat, info.stationed_at__long],
              popUp: `${info.drone__title} (${info.serial_no})`,
              icon: workingDroneIcon,
            };
          }
          return null;
        });
  
        let brokenDrone = response?.data?.data?.data?.broken_drones?.map((info) => {
          if (info?.stationed_at__lat !== null && info?.stationed_at__long !== null) {
            return {
              geocode: [info.stationed_at__lat, info.stationed_at__long],
              popUp: `${info.drone__title} (${info.serial_no})`,
              icon: brokenDroneIcon,
            };
          }
          return null;
        });
  
        let batteryResponse = response?.data?.data?.data?.batteries?.map((info) => {
          if (info?.stationed_at__lat !== null && info?.stationed_at__long !== null) {
            return {
              geocode: [info.stationed_at__lat, info.stationed_at__long],
              popUp: `${info.battery__name} (${info.serial_no}) `,
              icon: batteryIcon,
            };
          }
          return null;
        });
  
        let pilotResponse = response?.data?.data?.data?.pilot_profile?.map((info) => {
          if (info?.address__lat !== null && info?.address__long !== null) {
            return {
              geocode: [info.address__lat, info.address__long],
              popUp: "Pilot",
              icon: pilotIcon,
            };
          }
          return null;
        });
  
        const filteredMarkerData = [...(workingDroneRes || []), ...(brokenDrone || []), ...(batteryResponse || []), ...(pilotResponse || [])];
        setMarkerData(filteredMarkerData.filter(Boolean));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  useEffect(() => {
    // getDronelatlong();
    getBatteryInventory();
  }, []);

  return (
    <MapContainer center={[20.5937, 78.9629]} zoom={5.4}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <MarkerClusterGroup
        chunkedLoading
        iconCreateFunction={createClusterCustomIcon}
      >
        {markerdata.map((marker, index) => (
          <Marker key={index} position={marker.geocode} icon={marker.icon}>
            <Popup>{marker.popUp}</Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default MapforDashboard;
