import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { formatDates } from "../../Utils/helper";

export default function DateComponent({
  size = "small",
  handleDate = () => {},
  title,
  dateErr,
  disablePast = true,
  label = "",
  date,
  disableFuture = true,
  question = {},
}) {
  const [value, setValue] = React.useState(dayjs(formatDates(new Date())));

  React.useEffect(() => {
    setValue(dayjs(date));
  }, [date]);

  const handleChange = (_value) => {
    var newDate = formatDates(_value);

    setValue(_value);
    if (title === "From date") {
      handleDate(newDate, "from_date", question);
    } else if (title === "To date") {
      handleDate(newDate, "to_date", question);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        onChange={(newValue) => handleChange(newValue)}
        slotProps={{
          textField: {
            fullWidth: true,
            size: "small",
            title: title,
            label: label,
            style: { color: "red" },
          },
        }}
        disableFuture={disableFuture}
        format="DD/MM/YYYY" // Customize the date format here
      />
    </LocalizationProvider>
  );
}
