import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export default function DynamicForm({
  value,
  onChange,
  listing,
  error,
  typedValue,

  setTypedValue,
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      const filteredOptions = listing.filter((option) => {
        const searchTerm = typedValue.toLowerCase();
        return (
          option?.name?.toLowerCase().includes(searchTerm) ||
          option?.creator?.email?.toLowerCase().includes(searchTerm) ||
          option?.creator?.mobile?.includes(searchTerm)
        );
      });
      setOptions(filteredOptions);
    }
  }, [open, typedValue, listing]);


  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: "100%" }}
      size="small"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, selectedValue) =>
        option?.name === selectedValue?.name ||
        option?.creator?.email === selectedValue?.creator?.email ||
        option?.creator?.mobile === selectedValue?.creator?.mobile
      }
      getOptionLabel={(option) => {
        const name = option?.name || "";
        const email = option?.creator?.email || "";
        const mobile = option?.creator?.mobile || "";

        return `${name} ${email} ${mobile}`.trim();
      }}
      options={options}
      loading={loading}
      value={value}
      inputValue={typedValue}
      onInputChange={(event, newInputValue) => setTypedValue(newInputValue)}
      onChange={(event, newValue) => {
        onChange && onChange(event, newValue);
        setTypedValue(newValue?.name || "");
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="DD User*"
          error={Boolean(error)}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <div {...props}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography>{option.name}</Typography>
            <Typography variant="body2" style={{ color: "gray" }}>
              Email: {option?.creator?.email}
              <br />
              Phone: {option?.creator?.mobile}
            </Typography>
          </div>
        </div>
      )}
    />
  );
}
