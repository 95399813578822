import React, { useEffect } from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import L from "leaflet";


import userIcon from "./constants";

function Test({ location, search, onUpdateLocation }) {
  const map = useMap();

  const handleMarkerDrag = (e) => {
    onUpdateLocation(e.target.getLatLng());
  };

  if (location) map.flyTo(location, 12);

  return location ? (
    <Marker
      position={location}
      draggable={true}
      icon={userIcon}
      eventHandlers={{
        dragend: handleMarkerDrag,
      }}
    >
      <Popup>You are here: {search}</Popup>
    </Marker>
  ) : null;
}

export default function App({ width, height, search, loc, updLoc }) {
  useEffect(() => {
    if (search && search.length === 6) {
      const geocoder = L.Control.Geocoder.nominatim({
        geocodingQueryParams: {
          countrycodes: 'IN'
        }
      });
      if (search)
        geocoder.geocode(search, (results) => {
          var r = results[0];
          if (r) {
            const { lat, lng } = r?.center || {};
            updLoc({ lat, lng });
          }
        });
    }
  }, [search]);

  const handleUpdateLocation = (newLocation) => {
    updLoc(newLocation);
  };
  // 24.66364471139861,77.16952729836406
  return (
    <>
      <MapContainer
       center={loc && [loc?.lat || 20.5937, loc?.lng || 78.9629] }  // Ensure loc is defined
        zoom={loc ? 12 : 3}
        zoomControl={false}
        style={{ height: height || "80vh", width: width || "50vw", borderRadius:'50px' }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Test location={loc} search={search} onUpdateLocation={handleUpdateLocation} />
      </MapContainer>
      {/* {loc && (
        <div>
          {loc?.lat},{loc?.lng}
        </div>
      )} */}
    </>
  );
}
