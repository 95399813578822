import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import { token_api } from "../../Utils/network";
import { helperdayMonthYearFull } from "../../Utils/Utils";
import { convertDaterange } from "../../Utils/helper";
import { colors } from "@mui/material";

// const data = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

const gradientStyle = {
  // background:
  //   "linear-gradient(180.82deg, #0C7888 0.37%, rgba(12, 121, 137, 0.4) 42.38%, rgba(211, 236, 240, 0.18) 99.29%)",
  background:
    "linear-gradient(to left, rgba(246, 133, 100, 1), rgba(245, 199, 99, 1))",

  padding: "10px",
  borderRadius: "5px",
  // fontWeight: "semi-bold",
  marginTop: "12px",
  color: "white",
  fontWeight: "600",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
const gradientStyletwo = {
  // background: "linear-gradient(180deg, #BD5B4D 0%, #FBD3CE 100%)",
  background:
    "linear-gradient(to left, rgba(132, 98, 251, 1), rgba(235, 137, 235, 1))",

  padding: "10px",
  borderRadius: "5px",
  // fontWeight: "semi-bold",
  marginTop: "12px",
  color: "white",
  fontWeight: "600",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const gradientStylethree = {
  // background: "linear-gradient(180deg, #603796 0%, #C9B4E4 100%)",
  background:
    "linear-gradient(to right, rgba(87, 182, 122, 1), rgba(11, 119, 84, 1))",

  padding: "10px",
  borderRadius: "5px",
  // fontWeight: "semi-bold",
  marginTop: "12px",
  color: "white",
  fontWeight: "600",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export default function StackedAreaChart({ startDate, endDate }) {
  const [data, setdata] = useState([]);

  const getGraphdata = () => {
    const apiParams = {};

    if (startDate && endDate) {
      const { start, end } = convertDaterange(startDate, endDate);

      apiParams.start_date = start;
      apiParams.end_date = end;
    }

    token_api
      .get(`/base/date_wise_booking`, {
        params: apiParams,
      })
      .then((response) => {
        const newres = response?.data?.data?.data;

        const changemap = newres?.map((info) => ({
          name: helperdayMonthYearFull(info?.date),
          servce_booking_count: info?.servce_booking_count,
          course_booking_count: info?.course_booking_count,
        }));
        setdata(changemap);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGraphdata();
  }, [startDate, endDate]);

  return (
    <div
      style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}
    >
      <div style={{ width: "80%", height: "350px" }}>
        <div style={{ width: "100%", height: "100%" }}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 10,
                bottom: 30,
              }}
            >
              <defs>
                <linearGradient id="gradientOne" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="0%"
                    style={{
                      stopColor: "rgba(246, 133, 100, 1)",
                      stopOpacity: 1,
                    }}
                  />
                  <stop
                    offset="42.38%"
                    style={{
                      stopColor: "rgba(246, 133, 100, 1)",
                      stopOpacity: 1,
                    }}
                  />
                  <stop
                    offset="99.29%"
                    style={{
                      stopColor: "rgba(246, 133, 100, 1)",
                      stopOpacity: 1,
                    }}
                  />
                </linearGradient>

                <linearGradient id="gradientTwo" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="0%"
                    style={{
                      stopColor: "rgba(132, 98, 251, 1)",
                      stopOpacity: 1,
                    }}
                  />
                  <stop
                    offset="100%"
                    style={{
                      stopColor: "rgba(132, 98, 251, 1)",
                      stopOpacity: 1,
                    }}
                  />
                </linearGradient>

                <linearGradient id="gradientThree" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="0%"
                    style={{
                      stopColor: "rgba(83, 178, 120, 1)",
                      stopOpacity: 1,
                    }}
                  />
                  <stop
                    offset="100%"
                    style={{
                      stopColor: "rgba(83, 178, 120, 1)",
                      stopOpacity: 1,
                    }}
                  />
                </linearGradient>
              </defs>

              <CartesianGrid strokeDasharray="3 3" />
              {/* <XAxis dataKey="name" /> */}
              <XAxis dataKey="name">
                <Label
                  value="Time"
                  position="insideBottom"
                  style={{ textAnchor: "middle" }}
                  offset={-20}
                  // dy={100}
                />
              </XAxis>
              <YAxis type="number">
                <Label
                  value="No. Of Bookings"
                  position="insideLeft"
                  angle={-90}
                  style={{ textAnchor: "middle" }}
                />
              </YAxis>
              {/* <YAxis /> */}
              <Tooltip />
              {/* <Area
                type="monotone"
                dataKey="servce_booking_count"
                stackId="1"
                stroke="#8884d8"
                fill="url(#gradientThree)"
              /> */}
              <Area
                type="monotone"
                dataKey="course_booking_count"
                stackId="1"
                stroke="rgba(132, 98, 251, 1)"
                fill="url(#gradientTwo)"
              />
              <Area
                type="monotone"
                dataKey="servce_booking_count"
                stackId="1"
                stroke="rgba(246, 133, 100, 1)"
                fill="url(#gradientOne)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        <div>
          <p style={gradientStyle}>Service Booking</p>
          <p style={gradientStyletwo}>Training Bookings</p>
          <p style={gradientStylethree}>Drone Shop Bookings</p>
        </div>
      </div>
    </div>
  );
}
