import React, { useContext, useEffect, useState } from "react";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import styles from "./Location.module.css";
import { Button } from "@mui/material";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { token_api } from "../../Utils/network";
import editIcon from "../../Assets/editIcon.png";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import InputFields from "../../Component/InputFields/InputFields";
import { Validate } from "./Utility";
import axios from "axios";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import MyMap from "../../Component/MyMap/MyMap";
import delete_icon from "../../Assets/delete_icon.svg";
import { useNavigate, Link } from "react-router-dom";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

const Locations = () => {
  let navigate = useNavigate();
  const [filters, setfilters] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [cityNewData, setCityNewData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("");
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [loc, updLoc] = useState({ lat: 20.5937, lng: 78.9629 });
  const [loading, setLoading] = useState(false);
  const { user_permissions } = useContext(UserCredsContext);

  console.log(user_permissions, "locationsUserPermissions");

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Location</div>,
  ];
  const [locationId, setLocationId] = useState(null);
  const [formData, setFormData] = useState({
    line_1: "",
    line_2: "",
    area: "",
    city: "",
    tehsil: "",
    district: "",
    pincode: "",
    state: "",
    country: "",
    // city_new: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const getLocationList = async (filters) => {
    try {
      const fetchedLocationData = await token_api.get(`course/location`, {
        params: { ...filters },
      });
      if (fetchedLocationData?.status !== 200) {
        throw new Error("Error while fetching locations");
      }
      setPaginationData(fetchedLocationData?.data);
      let modifiedArray = fetchedLocationData?.data?.data.map((item, index) => {
        return {
          locationId: item?.id,
          add1: item?.line_1,
          add2: item?.line_2,
          area: item?.area,
          city: item?.city_new,
          pincode: item?.pincode,
          state: item?.state,
          country: item?.country,
          action: (
            <div className={styles.languageCon}>
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleEditDrawer(e, item)}
                >
                  <img src={editIcon} />
                </div>
              )}

              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteorg(item?.id)}
                >
                  <img src={delete_icon} />
                </div>
              )}
            </div>
          ),
        };
      });
      setLocationList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      let filterObject = {};
      if (pageState) {
        filterObject.page = pageState;
      }
      if (filters?.q) {
        filterObject.q = filters?.q;
      }
      getLocationList(filterObject);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pageState, filters?.q]);

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && !multiple) {
      setFormData({
        ...formData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      if (name === "pincode") {
        if (value.length > 6) {
          return;
        }
      }
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setFormData({
      ...formData,
      [selectName]: formData?.[selectName]?.filter((chip) => chip?.id !== id),
    });
    // (chips) => chips.filter((chip) => chip?.id !== id)
  };

  const handleEditDrawer = (e, info) => {
    console.log(info, "hariInfoooo");
    const {
      id,
      line_1,
      line_2,
      area,
      city,
      pincode,
      state,
      country,
      lat,
      long,
    } = info;
    setFormData((prev) => ({
      ...prev,
      line_1,
      line_2,
      area,
      city,
      pincode,
      state,
      country,
    }));
    setFormErrors({});
    setOpenDrawer(true);
    setDrawerState("edit");
    setLocationId(id);
    updLoc({
      ...loc,
      lat: lat,
      lng: long,
    });
  };

  console.log(formData, loc, "uploccccc");
  const handleAddDrawer = () => {
    const newAddLocation = {
      line_1: "",
      line_2: "",
      area: "",
      city: "",
      pincode: "",
      state: "",
      country: "",
    };
    setFormErrors({});
    setFormData(newAddLocation);
    setOpenDrawer(true);
    setDrawerState("add");
    setLocationId(null);
  };

  const handleAddLocation = (e) => {
    e.preventDefault();

    const data = {
      line_1: formData?.line_1,
      line_2: formData?.line_2,
      area: formData?.area,
      city: formData?.city,
      state: formData?.state,
      pincode: formData?.pincode,
      country: formData?.country,
      tehsil: formData?.tehsil,
      district: formData?.district,
      lat: loc?.lat,
      long: loc?.lng,
      // city_new: formData?.city_new[0]?.id,
    };

    Validate(formData)
      .then((response) => {
        if (response == "success") {
          if (locationId) {
            console.log(formErrors, formData, "editFormdata");
            //If Editing a location, then patch api.....locationId is available while clicking on edit icon.......
            token_api
              .patch(`/course/location/${locationId}/`, data)
              .then((response) => {
                if (response?.status == 200 || response?.status == 201) {
                  alert("Location Details Updated Successfully!");
                  setOpenDrawer(false);
                  getLocationList();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            console.log(formErrors, formData, "addFormdata");

            //If adding new location, then post api.....locationId is null
            token_api
              .post(`/course/location/`, data)
              .then((response) => {
                if (response?.status == 200 || response?.status == 201) {
                  alert("New Location Created Successfully!");
                  setOpenDrawer(false);
                  getLocationList();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      })
      .catch((error) => {
        setFormErrors(error);
      });
  };

  const getCityNewData = () => {
    token_api
      .get(`course/city`)
      .then((response) => {
        if (response?.status === 200) {
          setCityNewData(response?.data?.data);
          console.log(response, "divyaResponsee");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCityNewData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (formData?.pincode && formData?.pincode?.length === 6) {
        try {
          setLoading(true);

          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${formData?.pincode}`
          );

          if (
            response.data.length !== 0 &&
            response.data[0].PostOffice.length !== 0
          ) {
            const pinData = response.data[0].PostOffice[0];
            const { District, Division, State, Name, Region, Country } =
              pinData;

            setFormData({
              ...formData,
              tehsil: Division,
              state: State,
              district: District,
              city: District,
              country: Country,
            });

            // setFormData({
            //   ...formData,
            //   district: Name,
            //   city: Region,
            //   state: State,
            //   country: Country,
            //   pincode: pin,
            // });
            // setShowOther(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [formData.pincode]);

  const handleDeleteorg = (deleteid) => {
    token_api
      .patch(`/course/location/${deleteid}/`, { is_deleted: true })
      .then((response) => {
        if (
          response?.status == 204 ||
          response?.status == 201 ||
          response?.status === 200
        ) {
          getLocationList();
        }
      })
      .catch((error) => {});
  };
  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  console.log(loc, "hariupdLoc");
  return (
    <div>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>
        {user_permissions?.write && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button variant="contained" onClick={handleAddDrawer}>
              {"Add Location"}
            </Button>
          </div>
        )}
        <FilterContainer
          className={styles.filterContainer}
          // className={`${styles.marginTopBox}`}
        >
          <CustomSearchFilter
            onChange={(e) => {
              setfilters({ ...filters, q: e });
            }}
            placeholder={"Search by city..."}
          />

          {/* <CustomSelect
            label={"Filter by Location"}
            listArray={[]}
            minWidth={"175px"}
          /> */}

          {/* <CustomButton
            text={"ADVANCE FILTERS"}
            btnIcon={<img src={filterIcon} alt="adv-filter" />}
            // maxWidth="170px"
          /> */}
          {/* <CustomButton
            text={"Export"}
            maxWidth="110px"
            onClick={() => setOpenDrawer(true)}
          /> */}
        </FilterContainer>

        <div className={styles.tableContainer}>
          <CustomTable
            columns={tableColumns}
            data={locationList}
            // handleClick={handleRedirect}
            tableFor="locationList"
          />
        </div>
        <div className={styles.paginationContainer}>
          <CustomPagination max_pages={max_pages} setPageState={setPageState} />
        </div>
      </Container>

      <DrawerComp
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
      >
        <DrawerContainer>
          <DrawerTitle
            text={drawerState === "add" ? "Add Location" : "Edit Location"}
            handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <MyMap
                width="30vw"
                height="40vh"
                search={formData?.pincode}
                loc={loc}
                updLoc={updLoc}
              />
            </div>
            <div>
              <InputFields
                label="Pincode"
                name="pincode"
                value={formData?.pincode || ""}
                onChange={handleChange}
                error={formErrors?.pincode}
                helperText={formErrors?.pincode}
                type="number"
              />
            </div>
            <div>
              <InputFields
                label="Address Line 1"
                name="line_1"
                style={{ width: "100%" }}
                value={formData?.line_1}
                onChange={handleChange}
                error={formErrors?.line_1}
                helperText={formErrors?.line_1}
              />
            </div>
            <div>
              <InputFields
                label="Address Line 2"
                name="line_2"
                style={{ width: "100%" }}
                value={formData?.line_2}
                onChange={handleChange}
                error={formErrors?.line_2}
                helperText={formErrors?.line_2}
              />
            </div>
            <div>
              <InputFields
                label="Area"
                name="area"
                style={{ width: "100%" }}
                value={formData?.area}
                onChange={handleChange}
                error={formErrors?.area}
                helperText={formErrors?.area}
              />
            </div>
            <div>
              <InputFields
                label="City"
                name="city"
                style={{ width: "100%" }}
                value={formData?.city}
                onChange={handleChange}
                error={formErrors?.city}
                helperText={formErrors?.city}
              />
            </div>
            <div>
              <InputFields
                label="Tehsil"
                name="tehsil"
                value={formData?.tehsil}
                onChange={handleChange}
                error={formErrors?.tehsil}
                helperText={formErrors?.tehsil}
              />
            </div>{" "}
            <div>
              <InputFields
                label="District"
                name="district"
                value={formData?.district}
                onChange={handleChange}
                error={formErrors?.district}
                helperText={formErrors?.district}
              />
            </div>
            <div>
              <InputFields
                // disabled={loctnFieldDisable}
                label="State"
                name="state"
                style={{ width: "100%" }}
                value={formData?.state}
                onChange={handleChange}
                error={formErrors?.state}
                helperText={formErrors?.state}
              />
            </div>
            <div>
              <InputFields
                // disabled={loctnFieldDisable}
                label="Country"
                name="country"
                style={{ width: "100%" }}
                value={formData?.country}
                onChange={handleChange}
                error={formErrors?.country}
                helperText={formErrors?.country}
              />
            </div>
            {/* <div>
              <CustomSelectChip
                multiple={false}
                label="New City"
                name="city_new"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={formData?.city_new}
                error={formErrors?.city_new}
                helperText={formErrors?.city_new}
                listArray={cityNewData}
                // error={formErrors?.mode}
              />
            </div> */}
            <div>
              <Button variant="contained" onClick={handleAddLocation}>
                {drawerState === "add" ? "ADD" : "UPDATE"}{" "}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>
    </div>
  );
};

export default Locations;

const tableColumns = [
  {
    id: "locationId",
    label: "Location ID",
  },

  {
    id: "add1",
    label: "Address 1",
  },
  {
    id: "add2",
    label: "Address 2",
  },
  {
    id: "area",
    label: "Area",
  },
  {
    id: "city",
    label: "City",
  },
  {
    id: "pincode",
    label: "Pincode",
  },

  {
    id: "state",
    label: "State",
  },
  {
    id: "country",
    label: "Country",
  },

  {
    id: "action",
    label: "Action",
  },
];
