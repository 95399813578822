export const Validate = (value) => {
    return new Promise((resolve, reject) => {
      const { serial_no, laptop_id, manufacturer_serial_no, status } =
        value;
  
      const errors = { error: false };
  
      if (!serial_no) {
        errors.serial_no = "Enter serial number";
        errors.error = true;
      }
      if (!manufacturer_serial_no) {
        errors.manufacturer_serial_no = "Enter manufacturer serial number";
        errors.error = true;
      }
  
      if (!laptop_id || Object.keys(laptop_id).length === 0) {
        errors.laptop_id = "Select laptop";
        errors.error = true;
      }
  
      if (!status || Object.keys(status).length === 0) {
        errors.status = "Status is required";
        errors.error = true;
      }
  
      if (errors.error) {
        reject(errors);
      } else {
        resolve("success");
      }
    });
  };
  