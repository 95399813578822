import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import L from "leaflet";
import Pilot from "../../Assets/Pilot.svg";
import userIcon from "./constants";
import tracking from "./tracking";
import styles from "./trackingmap.module.css";
import "./custommap.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { checkColor, checkIconType } from "../../Utils/helper";
import { token_api1 } from "../../Utils/network";
import drone from "./drone";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
function Test({
  location,
  search,
  onUpdateLocation,
  position,
  track,
  vehicle,
  droneLocation,
  currentLive,
  showObject,
}) {
  const navigate = useNavigate();
  const map = useMap();
  // {
  //     lat: 12.92415,
  //     lng: 77.67229
  // }
  const handleMarkerDrag = (e) => {
    // onUpdateLocation(e.target.getLatLng());
  };
  var _position = [];
  if (position && position.hasOwnProperty("Service Bookings")) {
    for (var i = 0; i < position["Service Bookings"].length; i++) {
      _position.push({
        lat: position["Service Bookings"][i].lat,
        lng: position["Service Bookings"][i].long,
        ...position["Service Bookings"][i],
      });
    }
  }
  useEffect(() => {
    if (currentLive.lat !== null) {
      map.flyTo(currentLive, 12);
    }
  }, [currentLive]);
  useEffect(() => {
    if (location) map.flyTo(location, 12);
  }, [location]);
  // console.log(track, _position, "track");
  // const newArrayOfObj = track.map(({ long: lng, ...rest }) => ({
  //   lng,
  //   ...rest,
  // }));
  const newArrayOfObj = track.map((item) => ({
    ...item,
    message: (({ long, ...rest }) => ({ ...rest, lng: long }))(item.message),
  }));

  const droneLocationObject = droneLocation.map(
    ({ longitude, latitude, status, name, ...rest }) => ({
      lat: Number(latitude),
      lng: Number(longitude),
      status,
      name,
      ...rest,
    })
  );
  const vehicleObjt = vehicle.map(
    ({
      longitude,
      latitude,
      vehicleNo,
      vehicle_status,
      vehicle_no,
      ...rest
    }) => ({
      lng: Number(longitude),
      lat: Number(latitude),
      vehicleNo: vehicle_no,
      vehicleStatus: vehicle_status,
      ...rest,
    })
  );

  return location ? (
    <div>
      {/* <Marker
                position={location}
                draggable={true}
                icon={userIcon}
                eventHandlers={{
                    dragend: handleMarkerDrag,
                }}
            >
                <Popup>You are here: {search}</Popup>
            </Marker> */}
      {showObject.drone &&
        droneLocationObject.length > 0 &&
        droneLocationObject.map((item, index) => {
          return (
            <Marker
              position={item}
              draggable={false}
              icon={drone}
              eventHandlers={{
                dragend: handleMarkerDrag,
              }}
            >
              <Popup>
                <div
                  style={{
                    minWidth: "200px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "8px 0px",
                  }}
                >
                  <span>
                    <b>Name</b>
                  </span>{" "}
                  <span>{item.name}</span>
                </div>
                <div
                  style={{
                    minWidth: "200px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "8px 0px",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <b> Status</b>
                  </span>{" "}
                  <span
                    style={{
                      background: checkColor(item.status),
                      padding: "5px",
                      color: "white",
                      borderRadius: "5px",
                    }}
                  >
                    {item.status}
                  </span>
                </div>
              </Popup>
            </Marker>
          );
        })}
      {showObject.vehicle &&
        vehicleObjt.length > 0 &&
        vehicleObjt.map((item, index) => {
          return (
            <Marker
              position={item}
              draggable={false}
              icon={checkIconType(item.vehicleStatus)}
              eventHandlers={{
                dragend: handleMarkerDrag,
              }}
            >
              <Popup>
                <div
                  style={{
                    minWidth: "200px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "8px 0px",
                  }}
                >
                  <span>
                    <b>Vehicle Number</b>
                  </span>{" "}
                  <span>{item.vehicleNo}</span>
                </div>
                <div
                  style={{
                    minWidth: "200px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "8px 0px",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <b>Running Status</b>
                  </span>{" "}
                  <span
                    style={{
                      background: checkColor(item.vehicleStatus),
                      padding: "5px",
                      color: "white",
                      borderRadius: "5px",
                    }}
                  >
                    {item.vehicleStatus}
                  </span>
                </div>
              </Popup>
            </Marker>
          );
        })}
      {_position.map((item, index) => {
        return (
          <Marker
            position={item}
            draggable={false}
            icon={userIcon}
            eventHandlers={{
              dragend: handleMarkerDrag,
            }}
          >
            <Popup>
              <div
                style={{ fontWeight: "600", width: "300px", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/bookings/service-booking-details/${item?.id}`);
                }}
              >
                <div className={styles.statusC}>
                  <p style={{ margin: "5px 0px" }}>Service ID: #{item?.id}</p>
                  <p style={{ margin: "5px 0px" }} className={styles.statusCI}>
                    {item?.status_str}
                  </p>
                </div>
                <div>
                  {item?.full_name} | {item?.contact_no}
                </div>
                <div>
                  Area in acres:{" "}
                  {item?.area_in_acres ? item?.area_in_acres : "-"}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {item.bookingsservicedrone.length > 0 &&
                      item?.bookingsservicedrone[0]?.drone_inventory?.map(
                        (drone, idx) => {
                          return (
                            <div>
                              <span>{drone?.drone?.title}</span> |{" "}
                              <span>{drone?.serial_no}</span>
                            </div>
                          );
                        }
                      )}
                  </div>
                  <div>
                    {item.bookingsservicepilot.length > 0 &&
                      item?.bookingsservicepilot[0]?.pilot?.map(
                        (pilot, idx) => {
                          return (
                            <div>
                              <img
                                src={
                                  pilot.default_avatar
                                    ? pilot.default_avatar
                                    : pilot.avatar
                                }
                                style={{ width: "20px", borderRadius: "50px" }}
                              />
                              <p
                                style={{ margin: "3px 0px", fontSize: "10px" }}
                              >
                                {pilot.first_name}
                              </p>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            </Popup>
          </Marker>
        );
      })}
      {showObject.pilot &&
        newArrayOfObj.map((item, index) => {
          return (
            <Marker
              position={item?.message}
              draggable={false}
              icon={tracking}
              eventHandlers={{
                dragend: handleMarkerDrag,
              }}
            >
              <Popup>
                <p>
                  <b>
                    Pilot : {item?.first_name} {item?.last_name}
                  </b>
                </p>
              </Popup>
            </Marker>
          );
        })}
    </div>
  ) : null;
}

export default function App({
  width,
  height,
  search,
  loc,
  updLoc,
  listingData,
  pilotSelected,
  pilots,
  droneLocation,
  vehicleTracking,
  setPilotLocation,
  currentLive,
  showVehicle
}) {
  const [polyLine, setPolyLine] = useState({
    lat: 12.92415,
    lng: 77.67229,
    zoom: 11,
    data: [
      {
        from_lat: "12.92415",
        from_long: "77.67229",
        id: "132512",
        to_lat: "12.92732",
        to_long: "77.63575",
      },
      {
        from_lat: "12.96691",
        from_long: "77.74935",
        id: "132513",
        to_lat: "12.92768",
        to_long: "77.62664",
      },
    ],
  });
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState("");
  const [track, setTrack] = useState([]);
  const [line, setLine] = useState([]);
  const [wsList, setWsList] = useState([]);

  // useEffect(() => {
  //   // Create a WebSocket connection
  //   if (pilotSelected) {
  //     const token = JSON.parse(localStorage.getItem("dd_admin_token"));

  //     // const ws = new WebSocket(
  //     //   `ws://13.233.39.247:8000/ws/live_tracking?user=227&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI0NDE2NzI0LCJpYXQiOjE3MTU3NzY3MjQsImp0aSI6IjMwYTc4OGJlMGJkMDQ1NzM4ODBmOTQ3ODBiOGM4YTRhIiwidXNlcl9pZCI6M30._Pdr0SxRMTkS-CnHar4cpOVAKpb1-x53iqaR04a1P1w`
  //     // );
  //     const url = window.location.href;
  //     let baseUrl = "";
  //     if (url.includes("thedronedestination")) {
  //       baseUrl = "wss://livetrack.thedronedestination.com/";
  //     } else {
  //       baseUrl = "ws://13.233.39.247:8000/";
  //     }
  //     const ws = new WebSocket(
  //       `${baseUrl}ws/db_live_tracking/${pilotSelected}/`
  //     );

  //     // Set up event listeners
  //     ws.onopen = () => {
  //       console.log("WebSocket connected");
  //     };

  //     ws.onmessage = (event) => {
  //       setMessage(JSON.parse(event.data));
  //     };

  //     ws.onclose = () => {
  //       console.log("WebSocket disconnected");
  //     };

  //     // Save the WebSocket instance in state
  //     setSocket(ws);

  //     // Clean up function
  //     return () => {
  //       // Close the WebSocket connection when component unmounts
  //       if (ws) {
  //         if (!pilotSelected) return;
  //         ws.close();
  //         // setTrack([]);
  //       }
  //     };
  //   }
  // }, [pilotSelected]); // Only run this effect once, on component mount

  useEffect(() => {
    try {
      // apiCall();
    } catch (error) {}
    // const myInterval = setInterval(async () => {
    //   const api = await axios({
    //     method: "get",
    //     url: `https://www.tbtrack.in/gps/public/api/v1/vehicles/location/data`,
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //       username: "droneapi",
    //     },
    //   });
    //   if (api.status === 200) {
    //     console.log(api, "api data");
    //   }
    // }, 90000);
    // clearInterval(myInterval);
  }, []);

  const apiCall = async () => {
    const url =
      "https://www.tbtrack.in/gps/public/api/v1/vehicles/location/data"; // Replace with your API endpoint
    const headers = {
      "Content-Type": "application/json",
      username: "droneapi",
    };

    fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the data received from the API
      })
      .catch((error) => {
        // Handle any errors that occur during the fetch
        console.error("Fetch Error:", error);
      });
    // if (api.status === 200) {
    //   console.log(api, "api data");
    // }
  };

  useEffect(() => {
    if (pilots) {
      const url = window.location.href;
      let baseUrl = "";
      if (url.includes("thedronedestination")) {
        baseUrl = "wss://livetrack.thedronedestination.com/";
      } else {
        baseUrl = "ws://13.233.39.247:8000/";
      }
      const webSockets = pilots.map((data) => ({
        socket: new WebSocket(
          `${baseUrl}ws/db_live_tracking/${data?.user?.id}/`
        ),
        id: data?.user?.id,
        first_name: data?.user?.first_name,
        last_name: data?.user?.last_name,
      }));
      webSockets?.forEach((ms, index) => {
        const ws = ms.socket;
        ws.onopen = () => {
          // console.log("WebSocket connected");
        };

        ws.onmessage = (event) => {
          const message = JSON.parse(event.data);
          var myIndex = pilots.findIndex((i) => i.id === event.data.id);
          setWsList((prevData) => {
            const existingIndex = prevData.findIndex(
              (item) => item.id === index
            );
            if (existingIndex !== -1) {
              // Replace the existing item
              const updatedData = [...prevData];
              updatedData[existingIndex] = {
                id: index,
                message,
                first_name: ms.first_name,
                last_name: ms.last_name,
              };
              return updatedData;
            } else {
              // Append the new item
              return [
                ...prevData,
                {
                  id: index,
                  message,
                  first_name: ms.first_name,
                  last_name: ms.last_name,
                },
              ];
            }
          });
        };
      });
      return () => {
        webSockets.forEach(({ socket }) => socket.close());
      };
    }
  }, [pilots]);

  useEffect(() => {
    if (wsList.length > 0) {
      const data = [...wsList];
      // console.log(wsList, data, "data from use");
      setTrack(data);
      const _data = [...pilots];

      _data.forEach((user) => {
        const matchingMessage = wsList.find(
          (message) => message.message.pilot === user.user.id
        );
        if (matchingMessage) {
          user.message = matchingMessage.message;
        } else {
          user.message = null;
        }
      });
      setPilotLocation(_data);
    }
  }, [wsList]);

  useEffect(() => {
    if (search && search.length === 6) {
      const geocoder = L.Control.Geocoder.nominatim({
        geocodingQueryParams: {
          countrycodes: "IN",
        },
      });
      if (search)
        geocoder.geocode(search, (results) => {
          var r = results[0];
          if (r) {
            const { lat, lng } = r?.center || {};
            updLoc({ lat, lng });
          }
        });
    }
  }, [search]);

  const handleUpdateLocation = (newLocation) => {
    updLoc(newLocation);
  };

  useEffect(() => {
    if (listingData.hasOwnProperty("Service Bookings")) {
      const myArray = listingData["Service Bookings"];
      const connectingArray = [];
      for (var i = 0; i < myArray.length - 1; i++) {
        // {
        //   from_lat: "12.92415",
        //   from_long: "77.67229",
        //   id: "132512",
        //   to_lat: "12.92732",
        //   to_long: "77.63575",
        // }
        if (i + 1 <= myArray.length) {
          connectingArray.push({
            from_lat: myArray[i].lat ? myArray[i].lat : 0,
            from_long: myArray[i].long ? myArray[i].long : 0,
            to_lat: myArray[i + 1].lat ? myArray[i + 1].lat : 0,
            to_long: myArray[i + 1].long ? myArray[i + 1].long : 0,
            id: i + 1,
          });
        }
      }
      setLine(connectingArray);
    }
  }, [listingData]);

  // 24.66364471139861,77.16952729836406
  const position = [polyLine.lat, polyLine.lng];

  const from_lat = polyLine.data.map((start) => start.from_lat);
  const to_lat = polyLine.data.map((to) => to.to_lat);

  const from_long = polyLine.data.map((start) => start.from_long);
  const to_long = polyLine.data.map((to) => to.to_long);
  return (
    <>
      <MapContainer
        center={loc && [loc?.lat || 28.7041, loc?.lng || 77.1025]} // Ensure loc is defined
        zoom={loc ? 12 : 3}
        zoomControl={false}
        style={{
          height: height || "100%",
          width: width || "100%",
        }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" /> */}

        {line.map(({ id, from_lat, from_long, to_lat, to_long }) => {
          return (
            <Polyline
              key={id}
              positions={[
                [from_lat, from_long],
                [to_lat, to_long],
              ]}
              color={"red"}
            />
          );
        })}

        <Test
          location={loc}
          search={search}
          onUpdateLocation={handleUpdateLocation}
          position={listingData}
          icon={Pilot}
          track={track}
          vehicle={vehicleTracking}
          droneLocation={droneLocation}
          currentLive={currentLive}
          showObject={{ ...showVehicle }}
        />
      </MapContainer>
      {/* {loc && (
        <div>
          {loc?.lat},{loc?.lng}
        </div>
      )} */}
    </>
  );
}
