import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Dealers.module.css";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import { useNavigate, Link } from "react-router-dom";
import { Button, useTheme } from "@mui/material";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import dummyDealer from "../../Assets/dummyimg.png";
import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import InputFields from "../../Component/InputFields/InputFields";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import dayjs from "dayjs";
import dragDrop from "../../Assets/drag_drop.svg";
import { formatDate } from "../../Utils/helper";
import { Validate } from "./Utility";
import { token_api, base_url } from "../../Utils/network";
import axios from "axios";
import { useSelector } from "react-redux";
import { helperdayMonthYearFull } from "../../Utils/Utils";
import AddressBlock from "../../Component/AddressBlock/AddressBlock";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

const Dealers = () => {
  const theme = useTheme();
  const { access_token } = useSelector((state) => state.auth);
  const { user_permissions } = useContext(UserCredsContext);

  console.log(user_permissions, "dealersUserPermissions");

  const { palette } = theme;

  const navigate = useNavigate();
  const [dealersList, setDealersList] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [filters, setfilters] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    name: "",
    establishment_name: "",
    total_target_acres: "",
    number: "",
    code: "",
    joining_date: null,
    line_1: "",
    line_2: "",
    area: "",
    city: "",
    district: "",
    state: "",
    pincode: "",
    country: "",
    gender: [],
    status: [],
    // location: [],
  });
  const [selectedBlock, setSelectedBlock] = useState([]);
  console.log(formData, selectedBlock, "hariGadiFormData");
  const [isDisable, setIsDisable] = useState(true);
  const bannerInput = useRef(null);
  const [bannerObj, setBannerObj] = useState("");
  const [bannerPreview, setBannerPreview] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Dealers</div>,
  ];

  console.log(formData, "hariFormData");

  //Profile Pic Upload...

  function handleDrop(e, type) {
    e.preventDefault();
    if (e?.target?.files) {
      const imageItem = e?.target?.files[0];
      setBannerObj(imageItem);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setBannerPreview(imageUrl);
    } else if (e?.dataTransfer?.files) {
      const imageItem = e?.dataTransfer?.files[0];
      setBannerObj(imageItem);
      const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
      setBannerPreview(imageUrl);
    }
  }

  const handleClick = (type) => {
    bannerInput.current.click();
  };
  function handleDragOver(e) {
    e.preventDefault();
  }
  const getStatusStyles = (status) => {
    switch (status) {
      case true:
        return {
          color: "green",
          backgroundColor: "rgb(212, 236, 195)",
        };
      case false:
        return {
          color: "red",
          backgroundColor: "rgba(255, 145, 111, 0.5)",
        };
      default:
        return {
          color: "white",
          backgroundColor: "black",
        };
    }
  };

  const handleNavigateDetails = (dealerId) => {
    navigate(`/users/dealers/profile/${dealerId}`);
  };
  const getDealerList = async (filters) => {
    try {
      const fetchDealers = await token_api.get(`users/profile/dealers`, {
        params: { ...filters },
        paramsSerializer: {
          indexes: null,
        },
      });
      if (fetchDealers.status !== 200) {
        throw new Error("Error while fetching dealers");
      }
      setPaginationData(fetchDealers?.data);

      console.log(fetchDealers?.data?.data, "hariDatatata");

      const modifiedArray = fetchDealers?.data?.data?.map((item) => {
        return {
          id: <div>{item?.id}</div>,
          image: (
            <div className={styles.profileImg}>
              <img src={item?.avatar ? item?.avatar : item?.default_avatar} />
            </div>
          ),
          dealer_name:
            (
              <p
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => handleNavigateDetails(item?.id)}
              >
                {item?.full_name}
              </p>
            ) || "N.A",
          establishemnt_name: item?.establishment_name || "N.A",
          dealer_number: item?.mobile || "N.A",
          dealer_code: item?.dealer_code || "N.A",
          gender: item?.gender_str || "N.A",

          joined_date: item?.joining_date
            ? helperdayMonthYearFull(item?.joining_date)
            : "N.A",

          //   location: item?.location,
          status: (
            <div
              style={{ ...getStatusStyles(item?.active) }}
              className={styles.statusContainer}
            >
              {item?.active === true ? "Active" : "Inactive"}
            </div>
          ),

          actions: (
            <div className={styles.actionsContainer}>
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEditDealer(item)}
                >
                  <img src={editIcon} />
                </div>
              )}
              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteDealer(item?.id)}
                >
                  <img src={deleteIcon} />
                </div>
              )}
            </div>
          ),
        };
      });

      setDealersList(modifiedArray);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      let filterObject = {};
      if (pageState) {
        filterObject.page = pageState;
      }
      if (filters?.q) {
        filterObject.q = filters?.q;
      }

      getDealerList(filterObject);
    }, 500);

    return () => clearTimeout(timeout);
  }, [pageState, filters?.q]);
  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  //Changing Data...

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setFormData({
        ...formData,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setFormData({
        ...formData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      if (name === "number") {
        if (value.length > 10) {
          return;
        }
      }
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDeleteChipforDrawer = (id, selectName) => {
    setFormData({
      ...formData,
      [selectName]: formData?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  //Add Dealer...

  const handleAddDealer = () => {
    setFormData({
      ...formData,
      id: null,
      name: "",
      establishment_name: "",
      total_target_acres: "",
      number: "",
      code: "",
      joining_date: null,
      line_1: "",
      line_2: "",
      area: "",
      city: "",
      district: "",
      state: "",
      pincode: "",
      country: "",
      gender: [],
      status: [],
      //   location: [],
    });
    setSelectedBlock([]);
    setIsDisable(true);
    setBannerObj("");
    setBannerPreview("");
    setFormErrors({});
    setOpenDrawer(true);
  };

  //Edit Dealer....
  const handleEditDealer = (dealer) => {
    const {
      id,
      full_name,
      establishment_name,
      dealer_code,
      mobile,
      joining_date,
      gender,
      active,
      avatar,
      residence_address,
      total_target_acres,
    } = dealer;
    let genderItem = genderList.filter((item) => item?.id == gender);
    let statusItem = StatusList.filter((item) => item?.id === active);
    console.log(dealer, genderItem, statusItem, "gender-status");
    setFormData({
      ...formData,
      id: id,
      establishment_name: establishment_name,
      total_target_acres: total_target_acres,
      name: full_name,
      code: dealer_code,
      number: mobile,
      joining_date: joining_date ? dayjs(joining_date) : "",
      gender: genderItem,
      status: statusItem,
      line_1: residence_address?.line_1,
      line_2: residence_address?.line_2,
      area: residence_address?.area,
      city: residence_address?.city,
      pincode: residence_address?.pincode,
      state: residence_address?.state,
      district: residence_address?.district,
      country: residence_address?.country,
    });
    {
      residence_address?.block && setSelectedBlock([residence_address?.block]);
    }
    setFormErrors({});
    setBannerPreview(avatar);
    setOpenDrawer(true);
    setIsDisable(false);
  };

  //Delete Dealer....
  const handleDeleteDealer = (dealerId) => {
    token_api
      .delete(`users/profile/admin_dealer/${dealerId}/`)
      .then((response) => {
        if (
          response?.status === 201 ||
          response?.status === 200 ||
          response?.status === 204
        ) {
          alert("Dealer Account Deleted Successfully!");
          getDealerList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (formData?.pincode && formData?.pincode?.length === 6) {
        try {
          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${formData?.pincode}`
          );

          if (
            response.data.length !== 0 &&
            response.data[0].PostOffice.length !== 0
          ) {
            setIsDisable(false);
            const pinData = response.data[0].PostOffice[0];
            const { District, Division, State, Name, Region, Country } =
              pinData;

            setFormData({
              ...formData,
              state: State,
              district: District,
              // city: District,
              country: Country,
            });

            // setFormData({
            //   ...formData,
            //   district: Name,
            //   city: Region,
            //   state: State,
            //   country: Country,
            //   pincode: pin,
            // });
            // setShowOther(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
        }
      }
    };

    fetchData();
  }, [formData.pincode]);
  //Craeting & Updating Dealer Data...

  const handleCreateDealer = async () => {
    const data = {
      full_name: formData?.name,
      mobile: formData?.number,
      establishment_name: formData?.establishment_name,
      total_target_acres: formData?.total_target_acres,
      dealer_code: formData?.code,
      joining_date: formatDate(formData?.joining_date),
      active: formData?.status[0]?.id,
      gender: formData?.gender[0]?.id,
      residence_address_detail: {
        line_1: formData?.line_1,
        line_2: formData?.line_2,
        area: formData?.area,
        city: formData?.city,
        district: formData?.district,
        state: formData?.state,
        pincode: formData?.pincode,
        country: formData?.country,
        block_code: selectedBlock[0]?.block_code,
      },
      // establishment_address_detail: {
      //   line_1: "Kosta",
      //   line_2: "Kosta",
      //   area: "Maddilapalem",
      //   city: "Isukathota",
      //   district: "Maddilapalem",
      //   state: "Andhra Pradesh",
      //   pincode: "532409",
      //   country: "India",
      // },
    };

    try {
      Validate(formData, selectedBlock)
        .then(async (response) => {
          if (response === "success") {
            if (formData?.id) {
              const response = await token_api.patch(
                `users/profile/admin_dealer/${formData?.id}/`,
                data
              );
              if (response?.status === 403) {
                alert(response?.data?.error?.message);
                // setOpenDrawer(false);
              }
              if (response?.status !== 200) {
                throw new Error("Dealer details not updated!");
              }
              if (bannerObj) {
                let dealerMedia = new FormData();
                dealerMedia.append("avatar", bannerObj);
                await axios({
                  method: "patch",
                  url: `${base_url}users/profile/admin_dealer/${formData?.id}/`,
                  data: dealerMedia,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${access_token}`,
                  },
                });
              }
              alert("Dealer Details Updated Successfully!");
              setFormErrors({});
              setOpenDrawer(false);
              getDealerList();
              setBannerObj("");
            } else {
              const response = await token_api.post(
                `users/profile/admin_dealer/`,
                data
              );
              console.log(response?.data?.error?.message, "harResponse");

              if (response?.status === 403) {
                alert(response?.data?.error?.message);
                // setOpenDrawer(false);
              }
              if (response?.status !== 201) {
                throw new Error("Dealer not created");
              }
              if (bannerObj) {
                let dealerMedia = new FormData();
                dealerMedia.append("avatar", bannerObj);
                await axios({
                  method: "patch",
                  url: `${base_url}users/profile/admin_dealer/${response?.data?.data?.id}/`,
                  data: dealerMedia,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${access_token}`,
                  },
                });
              }
              alert("New Dealer Created Successfully!");
              setFormErrors({});
              setOpenDrawer(false);
              getDealerList();
              setBannerObj("");
            }
          }
        })
        .catch((error) => {
          setFormErrors(error);
        });
    } catch (error) {
      console.log(error, "divyaError");
    }
  };

  const handleAutoCompleteChangeForBlock = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Block already included");
    } else {
      setSelectedBlock(selectedValues);
    }
  };
  return (
    <Container>
      <div className={styles.breadcrumbsCon}>
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>{" "}
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>
      {user_permissions?.write && (
        <div className={styles.buttonContainer}>
          <Button variant="contained" onClick={handleAddDealer}>
            ADD Dealer
          </Button>
        </div>
      )}

      <FilterContainer className={styles.filterContainer}>
        <CustomSearchFilter
          placeholder="Search by name, dealer code..."
          onChange={(e) => {
            setPageState(1);
            setfilters({ ...filters, q: e });
          }}
        />
      </FilterContainer>

      <div className={styles.tableContainer}>
        <CustomTable
          columns={tableColumns}
          data={dealersList}
          tableFor="dealers"
        />
      </div>
      <div className={styles.paginationContainer}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>

      <DrawerComp
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
      >
        <DrawerContainer>
          <DrawerTitle
            text={formData?.id ? "Update Dealer" : "Add Dealer"}
            handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
          />
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Dealer Name"
              name="name"
              value={formData?.name || ""}
              onChange={handleChange}
              error={formErrors?.name}
              helperText={formErrors?.name}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Establishment Name"
              name="establishment_name"
              value={formData?.establishment_name || ""}
              onChange={handleChange}
              error={formErrors?.establishment_name}
              helperText={formErrors?.establishment_name}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Mobile Number"
              name="number"
              type="number"
              value={formData?.number || ""}
              onChange={handleChange}
              error={formErrors?.number}
              helperText={formErrors?.number}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Dealer Code"
              name="code"
              value={formData?.code || ""}
              onChange={handleChange}
              error={formErrors?.code}
              helperText={formErrors?.code}
            />
          </div>
          {/* <div className={styles.inputContainer} style={{ marginTop: "10px" }}>
            <CustomSelectChip
              multiple={false}
              label="Location"
              name="location"
              onChange={handleChange}
              onDelete={handleDeleteChipforDrawer}
              value={formData?.location}
              listArray={locationList}
              error={formErrors?.location}
              helperText={formErrors?.location}
            />
          </div> */}
          <div style={{ marginTop: "10px" }}>
            <CustomDatePicker
              label="Joining Date"
              value={formData?.joining_date}
              onChange={(newValue) =>
                setFormData({
                  ...formData,
                  joining_date: newValue,
                })
              }
              error={formErrors?.joining_date}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Pincode"
              name="pincode"
              type="number"
              value={formData?.pincode || ""}
              onChange={handleChange}
              error={formErrors?.pincode}
              helperText={formErrors?.pincode}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <AddressBlock
              value={selectedBlock}
              onChange={handleAutoCompleteChangeForBlock}
            />
            {formErrors?.selectedBlock && (
              <p style={{ color: "red" }}>{formErrors?.selectedBlock}</p>
            )}
          </div>
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Address Line 1"
              name="line_1"
              value={formData?.line_1 || ""}
              onChange={handleChange}
              error={formErrors?.line_1}
              helperText={formErrors?.line_1}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Address Line 2"
              name="line_2"
              value={formData?.line_2 || ""}
              onChange={handleChange}
              error={formErrors?.line_2}
              helperText={formErrors?.line_2}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Area"
              name="area"
              value={formData?.area || ""}
              onChange={handleChange}
              error={formErrors?.area}
              helperText={formErrors?.area}
            />
          </div>{" "}
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="City"
              name="city"
              disabled={isDisable}
              value={formData?.city || ""}
              onChange={handleChange}
              error={formErrors?.city}
              helperText={formErrors?.city}
            />
          </div>{" "}
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="District"
              name="district"
              disabled={isDisable}
              value={formData?.district || ""}
              onChange={handleChange}
              error={formErrors?.district}
              helperText={formErrors?.district}
            />
          </div>{" "}
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="State"
              name="state"
              disabled={isDisable}
              value={formData?.state || ""}
              onChange={handleChange}
              error={formErrors?.state}
              helperText={formErrors?.state}
            />
          </div>{" "}
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Country"
              name="country"
              disabled={isDisable}
              value={formData?.country || ""}
              onChange={handleChange}
              error={formErrors?.country}
              helperText={formErrors?.country}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Total Target Acres"
              name="total_target_acres"
              type="number"
              value={formData?.total_target_acres || ""}
              onChange={handleChange}
              error={formErrors?.total_target_acres}
              helperText={formErrors?.total_target_acres}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <CustomSelectChip
              multiple={false}
              label="Gender"
              name="gender"
              onChange={handleChange}
              onDelete={handleDeleteChipforDrawer}
              value={formData?.gender}
              listArray={genderList}
              error={formErrors?.gender}
              helperText={formErrors?.gender}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <CustomSelectChip
              multiple={false}
              label="Status"
              name="status"
              onChange={handleChange}
              onDelete={handleDeleteChipforDrawer}
              value={formData?.status}
              listArray={StatusList}
              error={formErrors?.status}
              helperText={formErrors?.status}
            />
          </div>
          <div className={`${styles.formContainer} mt-20`}>
            <div
              style={{ color: palette?.primary[500] }}
              className={`${styles.titleStyles}`}
            >
              Profile Image
            </div>

            <div style={{ marginTop: "20px" }}>
              <div
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "banner")}
                className={styles.dropBoxStyles}
              >
                <div
                  onClick={() => handleClick("banner")}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <p>
                    <img src={dragDrop} alt="dradanddrop" />
                  </p>
                  <p>Drag & Drop the File or click here</p>
                  <input
                    ref={bannerInput}
                    type="file"
                    // id="myfile"
                    // name="myfile"
                    // multiple
                    onChange={(e) => handleDrop(e, "banner")}
                    style={{ display: "none" }}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          {bannerPreview && (
            <div className={`${styles.formContainer} mt-20`}>
              <div className={styles.commonFieldsBox}>
                <div>
                  <p
                    style={{ color: palette?.primary[500] }}
                    className={`${styles.titleStyles}`}
                  >
                    Profile Preview
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div
                      className={`${styles.flexBetween} ${styles.imgCardBox} `}
                    >
                      {" "}
                      <img
                        src={bannerPreview}
                        alt="banner"
                        width={200}
                        height={140}
                      />
                      <div onClick={() => handleClick("banner")}>
                        <Button variant="contained">Update</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div style={{ marginTop: "10px" }}>
            <Button variant="contained" onClick={handleCreateDealer}>
              {formData?.id ? "Update" : "Add"}
            </Button>
          </div>
        </DrawerContainer>
      </DrawerComp>
    </Container>
  );
};

export default Dealers;

const tableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "image",
    label: "Image",
  },

  {
    id: "dealer_name",
    label: "Dealer Name",
  },
  {
    id: "establishemnt_name",
    label: "Establishment Name",
  },
  {
    id: "dealer_number",
    label: "Dealer Number",
  },
  {
    id: "dealer_code",
    label: "Dealer Code",
  },
  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "joined_date",
    label: "Joined Date",
  },
  //   {
  //     id: "location",
  //     label: "Location",
  //   },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

const tableBodyData = [
  {
    id: 1,
    image: dummyDealer,
    dealer_name: "Hari Gadi",
    dealer_number: "6304113067",
    dealer_code: "D123",
    joined_date: "02-06-2002",
    location: "Visakhapatnam",
    status: 1,
  },
  {
    id: 2,
    image: dummyDealer,
    dealer_name: "Hari Gadi",
    dealer_number: "6304113067",
    dealer_code: "D123",
    joined_date: "02-06-2002",
    location: "Visakhapatnam",
    status: 2,
  },
  {
    id: 3,
    image: dummyDealer,
    dealer_name: "Hari Gadi",
    dealer_number: "6304113067",
    dealer_code: "D123",
    joined_date: "02-06-2002",
    location: "Visakhapatnam",
    status: 1,
  },
  {
    id: 4,
    image: dummyDealer,
    dealer_name: "Hari Gadi",
    dealer_number: "6304113067",
    dealer_code: "D123",
    joined_date: "02-06-2002",
    location: "Visakhapatnam",
    status: 2,
  },
  {
    id: 5,
    image: dummyDealer,
    dealer_name: "Hari Gadi",
    dealer_number: "6304113067",
    dealer_code: "D123",
    joined_date: "02-06-2002",
    location: "Visakhapatnam",
    status: 1,
  },
  {
    id: 6,
    image: dummyDealer,
    dealer_name: "Hari Gadi",
    dealer_number: "6304113067",
    dealer_code: "D123",
    joined_date: "02-06-2002",
    location: "Visakhapatnam",
    status: 2,
  },
];

const StatusList = [
  {
    id: true,
    title: "Active",
  },
  {
    id: false,
    title: "Inactive",
  },
];

const genderList = [
  {
    id: 1,
    title: "Male",
  },
  {
    id: 2,
    title: "Female",
  },
];
