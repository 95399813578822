import React, { useEffect, useState } from "react";
import DateComponent from "../../Component/DateComponent/DateComponent";
import { formatDates } from "../../Utils/helper";
import { token_api } from "../../Utils/network";
import QuestionListing from "../../Component/QuestionListing/QuestionListing";

var now = new Date();

function DateCustom({ handleChange, props }) {
  const [date, setDate] = useState({
    to_date: formatDates(
      new Date(now.getFullYear(), now.getMonth(), now.getDate())
    ),
    from_date: formatDates(new Date()),
  });
  const [error, setError] = useState(false);
  const [questions, setQuestions] = useState([]);
  const handleDates = (_date, name, _question) => {
    setDate({ ...date, to_date: _date });
    handleChange(_date, name, _question);
  };
  useEffect(() => {
    cropListing();
  }, []);
  function cropListing() {
    token_api
      .get(`/service/agrispray-using-drones`)
      .then((response) => {
        setQuestions(response?.data?.data?.other_details);
      })
      .catch((error) => {});
  }

  return (
    <div style={{ padding: "20px" }}>
      <DateComponent
        handleDate={handleDates}
        title="From date"
        dateErr={error}
        label="Select Date"
        date={date.to_date}
        disableFuture={true}
      />
      {/* <QuestionListing questions={questions} /> */}
    </div>
  );
}

export default DateCustom;
