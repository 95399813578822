import React, { useState, useEffect } from "react";
import styles from "./SystemUsersList.module.css";
import Container from "../../../Component/Container/Container";
import CustomBreadCrumbs from "../../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import CustomTabs3 from "../../../Component/CustomTabsNew/CustomTabs3";
import CustomButton from "../../../Component/CustomButton/CustomButton";
import CustomSearchFilter from "../../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../../Component/CustomSelect/CustomSelect";
import CustomDate from "../../../Component/CustomDate/CustomDate";
import CustomDatePicker from "../../../Component/MaterialDate/MaterialDate";
import FilterContainer from "../../../Component/FitlerContainer/FilterContainer";
import CustomTable from "../../../Component/CustomTable/CustomTable";
import CustomPagination from "../../../Component/CustomPagination/CustomPagination";
import { Checkbox } from "@mui/material";
import filterIcon from "../../../Assets/filterIcon.png";
import pilotIcon from "../../../Assets/pilotIcon.svg";
import androidIcon from "../../../Assets/android.svg";
import appleIcon from "../../../Assets/apple.svg";
import windowsIcons from "../../../Assets/windows.svg";
import breadCrumbBack from "../../../Assets/breadCrumbBack.svg";
import { useNavigate, Link } from "react-router-dom";

const SystemUsersList = () => {
  let navigate = useNavigate();
  const [filters, setfilters] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [systemUserList, setSystemUserList] = useState([]);
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Services</div>,
  ];

  const handleRedirect = (id) => {
  };

  useEffect(() => {
    getSystemUserList();
  }, []);

  const getSystemUserList = () => {
    let modifiedArray = tableBodyData?.map((item) => {
      // const { bookingaddonservice } = item;

      return {
        checkBox: (
          <Checkbox
            checked={false}
            // onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        ),
        id: item?.userId,

        name: (
          <div className={styles.profileCon}>
            <img src={item?.name?.profile} />
            <p>{item?.name?.title}</p>
          </div>
        ),
        mobile: item?.mobile,
        email: item?.email,
        location: (
          <p style={{ textDecoration: "underline" }}>{item?.location}</p>
        ),
        gender: item?.gender,
        gst: item?.gst,
        status: (
          <p
            style={{
              color: `${item?.status?.color}`,
              background: `${item?.status?.bgColor}`,
            }}
            className={styles.statusText}
          >
            {item?.status?.title}
          </p>
        ),
        onboarding: item?.onboarding,
        device: (
          <div>
            <img src={item?.device} />
          </div>
        ),

        login: (
          <div className={styles.languageCon}>
            <p className={styles.loginDate}>{item?.login?.date}</p>
            <p className={styles.loginTime}>({item?.login?.time})</p>
          </div>
        ),
      };
    });
    setSystemUserList([...modifiedArray]);
  };
  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };
  return (
    <Container style={{ overflowX: "auto" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>
      <div className={styles.tabContainer}>
        <CustomTabs3 tabHead={tabData} currentTab={1} />
      </div>
      <FilterContainer
        className={styles.filterContainer}
        // className={`${styles.marginTopBox}`}
      >
        <CustomSearchFilter
          onChange={(e) => {
            setfilters({ ...filters, q: e });
          }}
        />

        <CustomSelect
          label={"Filter by Location"}
          listArray={[]}
          minWidth={"175px"}
        />

        {/* <CustomSelect label={"Date"} listArray={[]} minWidth={"150px"} /> */}
        {/* <CustomDate /> */}
        <CustomDatePicker />

        <CustomSelect
          label={"Filter by Service Type"}
          listArray={[]}
          minWidth={"195px"}
        />
        <CustomButton
          text={"ADVANCE FILTERS"}
          btnIcon={<img src={filterIcon} alt="adv-filter" />}
          // maxWidth="170px"
        />
        <CustomButton
          text={"Export"}
          maxWidth="110px"
          onClick={() => setOpenDrawer(true)}
        />
      </FilterContainer>
      <div className={styles.tableContainer}>
        <CustomTable
          columns={tableColumns}
          data={systemUserList}
          handleClick={handleRedirect}
          tableFor="systemUsers"
        />
      </div>
      <div className={styles.paginationContainer}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>
    </Container>
  );
};

export default SystemUsersList;

const tabData = [
  {
    id: 1,
    title: "Service User",
  },
  {
    id: 2,
    title: "Rental",
  },
  {
    id: 3,
    title: "Buyers",
  },
];

const tableColumns = [
  {
    id: "checkBox",
    label: "",
  },
  {
    id: "id",
    label: "User ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "mobile",
    label: "Mobile No.",
  },
  {
    id: "email",
    label: "Email",
  },
  // {
  //   id: "time",
  //   label: "Time",
  // },
  {
    id: "location",
    label: "Location",
  },

  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "gst",
    label: "GST",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "onboarding",
    label: "Onboarding Date",
  },
  {
    id: "device",
    label: "Device",
  },
  {
    id: "login",
    label: "Last Login",
  },
];

const tableBodyData = [
  {
    userId: "SP12000",
    name: {
      profile: pilotIcon,
      title: "Yogin Patil",
    },
    mobile: 6304113067,
    email: "yoginP@gmail.com",
    location: "Mumbai",
    gender: "Male",
    gst: "27UNRGU6491C2Z6",
    status: {
      color: "rgba(60, 147, 8, 1)",
      bgColor: "rgba(211, 251, 171, 1)",
      title: "Completed",
    },
    onboarding: "21st Sept 2023",
    device: androidIcon,
    login: {
      date: "21st Sept 2023",
      time: "07:32 AM",
    },
  },
  {
    userId: "SP12001",
    name: {
      profile: pilotIcon,
      title: "Hari Gadi",
    },
    mobile: 6304113068,
    email: "harig@gmail.com",
    location: "Mumbai",
    gender: "Male",
    gst: "27UNRGU6491C2Z6",
    status: {
      color: "rgba(188, 70, 7, 1)",
      bgColor: "rgba(252, 185, 176, 1)",
      title: "Pending",
    },
    onboarding: "21st Sept 2023",
    device: appleIcon,
    login: {
      date: "21st Sept 2023",
      time: "07:32 AM",
    },
  },
  {
    userId: "SP12002",
    name: {
      profile: pilotIcon,
      title: "Sandesh Sakhare",
    },
    mobile: 6304113067,
    email: "sandeshS@gmail.com",
    location: "Mumbai",
    gender: "Male",
    gst: "27UNRGU6491C2Z6",
    status: {
      color: "rgba(150, 118, 6, 1)",
      bgColor: "rgba(255, 215, 8, 0.45)",
      title: "In Progress",
    },
    onboarding: "21st Sept 2023",
    device: windowsIcons,
    login: {
      date: "21st Sept 2023",
      time: "07:32 AM",
    },
  },
];
