import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { helperdayMonthYearFull } from "../../Utils/helper";
import { token_api } from "../../Utils/network";

const DynamicTable = ({ data, getAttendanceList, batch, pageState }) => {
  const [localSwitchStates, setLocalSwitchStates] = useState(null);
  const [dates, setDates] = useState([]);
  const [slotChoices, setSlotChoices] = useState([]);

  useEffect(() => {
    const uniqueDates = data[0]?.attendance.map((item) => item.date);
    const uniqueSlotChoices = data[0]?.attendance.map(
      (item) => item.slot_choice_str
    );

    setDates(uniqueDates);
    setSlotChoices(uniqueSlotChoices);

    // Set initialSwitchStates when the component mounts
    const initialSwitchStates = data.reduce((acc, student) => {
      student.attendance.forEach((attendanceItem) => {
        acc[`${student.id}-${attendanceItem.date}`] = attendanceItem.present;
      });
      return acc;
    }, {});
    setLocalSwitchStates(initialSwitchStates);
  }, [data]);


  const handleSwitchChange = (studentId, date) => (event) => {
    const isChecked = event.target.checked;

    const newSwitchStates = {
      ...localSwitchStates,
      [`${studentId}-${date}`]: isChecked,
    };
    setLocalSwitchStates(newSwitchStates);


    const selectedStudent = data.find((student) => student.id === studentId);

    if (selectedStudent?.id) {
      const attendanceItem = selectedStudent?.attendance?.find(
        (info) => info?.id === date
      );
     

      token_api
        .patch(`/course/attendance/${attendanceItem?.id}/`, {
          present: isChecked,
        })
        .then((response) => {
          if (response?.status == 200 || response?.status == 201) {
            const finalFilters = {};
            finalFilters.page = pageState;

            if (batch) {
              finalFilters.batch = batch;

              getAttendanceList(finalFilters);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{ backgroundColor: "#E95E33" }}>
          <TableRow>
            <TableCell
              style={{ textAlign: "center", color: "white", fontWeight: "500" }}
            >
              Student ID
            </TableCell>
            <TableCell
              style={{ textAlign: "center", color: "white", fontWeight: "500" }}
            >
              User Name
            </TableCell>
            <TableCell
              style={{ textAlign: "center", color: "white", fontWeight: "500" }}
            >
              Average Present
            </TableCell>
            {dates?.map((date, index) => (
              <TableCell
                key={date}
                align="center"
                style={{
                  textAlign: "center",
                  color: "white",
                  fontWeight: "500",
                }}
              >
                {helperdayMonthYearFull(date)}
                <div style={{ textAlign: "center", margin: "0px" }}>
                  {/* <p style={{ textAlign: "center", margin: "0px" }}>
                    {" "}
                    (Attendance)
                  </p> */}
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    {slotChoices[index]}
                  </p>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((student) => (
            <TableRow key={student.id}>
              <TableCell style={{ textAlign: "center" }}>
                {student?.id}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {student?.user_name}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {student?.average_present}
              </TableCell>
              {student.attendance?.map((item, index) => (
                <TableCell key={item?.id} align="center">
                  <Switch
                    style={{ textAlign: "center" }}
                    checked={
                      // student.attendance[index].present
                      item?.present
                      // localSwitchStates[`${student?.id}-${date}`] || false
                    }
                    onChange={handleSwitchChange(student?.id, item?.id)}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DynamicTable;
