import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { token_api } from "../../Utils/network";

const OrganizationDropDown = ({ onChange, value }) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleChange = (event, newValue) => {
    onChange && onChange(newValue);
  };

  useEffect(() => {
    setLoading(true);
    let apiEndpoint = "/users/organizations";

    if (inputValue) {
      apiEndpoint += `?q=${encodeURIComponent(inputValue)}`;
    }

    token_api
      .get(apiEndpoint)
      .then((response) => {
        const data = response.data?.data;
        setOptions(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [inputValue]);

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      id="tags-outlined"
      options={options}
      getOptionLabel={(option) => option.name}
      value={value}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label="Organization" placeholder="Search by organization name" />
      )}
    />
  );
};

export default OrganizationDropDown;
