import React, { useEffect, useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import {
  Chip,
  Box,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
// import { createTheme } from "@mui/material/styles";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import styles from "./CreateOtherDetails.module.css";
import {
  getAMPM,
  formatDate,
  getMonthEndDates,
  formatDates,
} from "../../Utils/helper";
import { validateForm } from "./validate";
import CustomDate from "../CustomDate/CustomDate";
import { token_api } from "../../Utils/network";
import { convertTo12HourFormat } from "../../Utils/helper";
import { Link, useNavigate } from "react-router-dom";
import defaultImg from "../../Assets/defaultImg.svg";
import CustomDatePicker from "../MaterialDate/MaterialDate";
import productDecrease from "../../Assets/productDecrease.svg";
import productIncrease from "../../Assets/productIncrease.svg";
import morningSlot from "../../Assets/morningSlot.svg";
import afternoonSlot from "../../Assets/afternoonSlot.svg";
import eveningSlot from "../../Assets/eveningSlot.svg";
import CustomServiceCalendar from "../CustomServiceCalendar/CustomServiceCalendar";
import DateCustom from "../DateCustom/DateCustom";
import DateComponent from "../DateComponent/DateComponent";
var now = new Date();

const theme = createTheme();
const themeone = createTheme({
  palette: {
    primary: {
      main: "#E95E33",
    },
    secondary: {
      main: "#yourSecondaryColor",
    },
  },
});

const CreateOtherDeatils = ({
  questionsdata,
  availableSlots,
  activeStage,
  setActiveStage,
  activeaddressid,
  userDetails,
  dduser,
  setFormErrors,
  singleServiceSelect,
  formErrors,
  handleOpen,
  productsList,
  activeAddress,
}) => {
  const getStyles = (name, selected, theme) => ({
    fontWeight:
      selected.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  });
  // State to store user responses
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [dummyresponse, setDummyres] = useState([]);
  const [userResponses, setUserResponses] = useState({
    other_details_ids: dummyresponse,
  });
  const [goodtoGo, setgoodtoGo] = useState(false);
  const [filters, setfilters] = useState({
    start_date: null,
    end_date: null,
  });
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [slotsData, setSlotsData] = useState({});
  const [activeServiceSlot, setActiveServiceSlot] = useState({});

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [occupant, setOccupant] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [date, setDate] = useState({
    to_date: formatDates(
      new Date(now.getFullYear(), now.getMonth(), now.getDate())
    ),
    from_date: formatDates(new Date()),
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    cropListing();
  }, []);
  function cropListing() {
    token_api
      .get(`/service/agrispray-using-drones`)
      .then((response) => {
        setQuestions(response?.data?.data?.other_details);
      })
      .catch((error) => {});
  }
  useEffect(() => {
    if (questionsdata.length > 0) {
      setQuestions(questionsdata);
    } else {
      setQuestions([]);
    }
  }, [questionsdata]);

  useEffect(() => {
    const updatedUserResponses = questions.map((question) => ({
      question_id: question.id,
      selected_choices_ids: [],
      text_choices: [],
    }));

    setDummyres(updatedUserResponses);
  }, [questions]);

  useEffect(() => {
    setUserResponses({
      other_details_ids: dummyresponse,
    });
  }, [dummyresponse]);

  //   Function to handle user responses
  const handleResponse = (questionId, response, textChoice = null) => {
    setUserResponses((prevResponses) => {
      const updatedResponses = {
        ...prevResponses,
        other_details_ids: prevResponses.other_details_ids.map((detail) => {
          if (detail.question_id === questionId) {
            let updatedChoices = [];
            if (questions?.find((q) => q.id === questionId)?.is_multi) {
              // If is_multi is true, push multiple selected choices
              updatedChoices = response;
            } else {
              // If is_multi is false, allow only a single selected choice
              updatedChoices = response.slice(0, 1);
            }

            let updatedTextChoices = detail.text_choices;
            if (textChoice) {
              const selectedChoice = questions
                ?.find((q) => q.id === questionId)
                .choices?.find((choice) => choice.id === parseInt(textChoice));
              const textChoiceId = selectedChoice?.id || 1; // Use the selected choice's id or default to 1
              updatedTextChoices = [
                ...detail.text_choices,
                { text_choice_id: textChoiceId, text_choice_ans: textChoice },
              ];
            }

            return {
              ...detail,
              selected_choices_ids: updatedChoices,
              text_choices: updatedTextChoices,
            };
          }
          return detail;
        }),
      };

      return updatedResponses;
    });
  };

  const handleTextChoice = (questionId, textChoiceId, textChoiceAns) => {
    setUserResponses((prevResponses) => {
      const updatedResponses = {
        ...prevResponses,
        other_details_ids: prevResponses.other_details_ids.map((detail) => {
          if (detail.question_id === questionId) {
            const existingTextChoices = detail.text_choices.filter(
              (choice) => choice.text_choice_id !== textChoiceId
            );

            return {
              ...detail,
              text_choices: [
                ...existingTextChoices,
                {
                  text_choice_id: textChoiceId,
                  text_choice_ans: textChoiceAns,
                },
              ],
            };
          }
          return detail;
        }),
      };

      return updatedResponses;
    });
    // dispatch(actions.setUserResponses(userResponses));
  };

  const handleChange = (questionId, choiceId, question) => {
    setUserResponses((prevResponses) => {
      const updatedResponses = {
        ...prevResponses,
        other_details_ids: prevResponses.other_details_ids.map((detail) => {
          if (detail.question_id === questionId) {
            let updatedChoices = [];
            if (question.is_multi) {
              // If is_multi is true, toggle the selection
              if (detail.selected_choices_ids.includes(choiceId)) {
                // Remove the choice if it's already selected
                updatedChoices = detail.selected_choices_ids.filter(
                  (id) => id !== choiceId
                );
              } else {
                // Add the choice if it's not selected
                updatedChoices = [...detail.selected_choices_ids, choiceId];
              }
            } else {
              // If is_multi is false, allow only a single selected choice
              updatedChoices = [choiceId];
            }

            return {
              ...detail,
              selected_choices_ids: updatedChoices,
            };
          }
          return detail;
        }),
      };

      return updatedResponses;
    });
    // dispatch(actions.setUserResponses(userResponses));
  };

  const handleSecondform = () => {
    validateForm(
      userDetails,
      dduser,
      singleServiceSelect,
      // filters,
      activeaddressid,
      handleOpen
    )
      .then((response) => {
        if (response == "success") {
          setLoading(true);
          setFormErrors({});

          const data = {
            creator: dduser?.creator?.id,
            address_id: activeaddressid,
            services: [singleServiceSelect?.id],
            contact_no: userDetails?.phone,
            gst: userDetails?.gst,
            remark: userDetails?.remark,
            full_name: `${dduser?.creator?.first_name} ${dduser?.creator?.last_name}`,
            // slot_services: activeStage ? [activeStage] : [],
            pilot_slots_ids: activeServiceSlot?.id
              ? [activeServiceSlot?.id]
              : [],
            status: 4,
            start_date: formatDate(startDate),
            end_date: formatDate(getMonthEndDates(currentMonth).endOfMonth),
            other_details_ids: userResponses?.other_details_ids,
            products: selectedProducts.map((item) => ({
              product_id: item?.id,
              qty: item?.quantity,
            })),

            dealer_code: userDetails?.dealer_code,
          };
          token_api.post(`/service/booking/`, data).then((response) => {

            if (response?.status == 201) {
              alert("Service Booking Created Successfully!");
              navigate(`/bookings/service-bookings`);
              setLoading(false);
            }
          });
        } else {
          throw new Error("error occured");
        }
      })
      .catch((error) => {
        setFormErrors(error);
        setLoading(false);
      });
  };

  const getSlotsData = (serviceBlockName, serviceStartDate) => {
    token_api
      .get(
        `v2/service/pilot_available/slots?block_name=${serviceBlockName}&date=${formatDate(
          serviceStartDate
        )}`
      )
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setSlotsData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (activeAddress?.block_name && startDate) {
      getSlotsData(activeAddress?.block_name, startDate);
    }
  }, [activeAddress?.block_name, startDate]);

 

  const handleStageClick = (id) => {
    setActiveStage(id);
  };


  const handleFilter = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleSelectProduct = (product) => {
    const existingProduct = selectedProducts.find((p) => p.id === product.id);
    if (!existingProduct) {
      setSelectedProducts([...selectedProducts, { ...product, quantity: 1 }]);
    } else {
      setSelectedProducts(
        selectedProducts.filter((productItem) => productItem.id !== product.id)
      );
    }
  };

  const handleIncreaseQuantity = (product) => {
    const existingProduct = selectedProducts.find((p) => p.id === product.id);
    if (existingProduct) {
      setSelectedProducts(
        selectedProducts.map((p) =>
          p.id === product.id ? { ...p, quantity: p.quantity + 1 } : p
        )
      );
    } else {
      setSelectedProducts([...selectedProducts, { ...product, quantity: 1 }]);
    }
  };

  const handleDecreaseQuantity = (productItem) => {
    setSelectedProducts(
      selectedProducts.map((product) =>
        product.id === productItem.id && product.quantity > 1
          ? { ...product, quantity: product.quantity - 1 }
          : product
      )
    );
  };

  const getProductQuantity = (productItem) => {
    const product = selectedProducts.find((p) => p.id === productItem.id);
    return product ? product.quantity : 0;
  };

  const handleSelectSlot = (slotObj) => {
    setActiveServiceSlot(slotObj);
  };

  const handleDateChange = (_start_date, _end_date) => {
    if (_start_date) setStartDate(_start_date);
    if (_end_date) setEndDate(_start_date);
  };

  const getPricesCalendar = (filters) => {
    const { startOfMonth, endOfMonth } = getMonthEndDates(currentMonth);
    filters.start_date = startOfMonth;
    filters.end_date = endOfMonth;
    token_api
      .get(`service/price/date_wise`, { params: { ...filters } })
      .then((response) => {
        if (response) {
          setDateList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (
      currentMonth &&
      activeAddress?.district &&
      userResponses.other_details_ids.length > 0
    ) {
      let _id = null;
      const checkObject = userResponses.other_details_ids;
      if (checkObject.length > 0) {
        const data = checkObject[0];
        if (checkObject[0].selected_choices_ids.length > 0) {
          _id = checkObject[0].selected_choices_ids[0];
        }
      }
      const timeout = setTimeout(() => {
        let filterObject = {};

        if (activeAddress?.district) {
          filterObject.district = activeAddress?.district;
        }
        if (startDate) {
          // filterObject.start_date = formatDate(startDate);
          filterObject.start_date = "2024-05-01";
        }
        if (endDate) {
          // filterObject.end_date = formatDate(endDate);
          filterObject.end_date = "2024-05-10";
        }
        filterObject.service = 132;
        // filterObject.crop = _id;
        filterObject.crop = _id;

        getPricesCalendar(filterObject);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [userResponses, activeAddress?.district, currentMonth]);

  const handleDates = (_date, name, _question) => {
    setDate({ ...date, to_date: _date });
    const questionId = _question.id;
    setUserResponses((prevResponses) => {
      const updatedResponses = {
        ...prevResponses,
        other_details_ids: prevResponses.other_details_ids.map((detail) => {
          if (detail.question_id === questionId) {
            const existingTextChoices = detail.date_choices.filter(
              (choice) =>
                choice.date_selected_id !== _question.date_selected[0].id
            );

            return {
              ...detail,
              date_choices: [
                ...existingTextChoices,
                {
                  date_selected_id: _question.date_selected[0].id,
                  date: _date,
                },
              ],
              // selected_choices_ids: [_question.date_selected[0].date_choice],
              selected_choices_ids: [],
            };
          }
          return detail;
        }),
      };

      return updatedResponses;
    });
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "20px",
          marginTop: "20px",
          width: "40%",
        }}
      >
        {/* <CustomDate
          label="Service start date*"
          handleChange={(e) =>
            handleFilter(e.target.value, "select", "start_date", false)
          }
          name="start_date"
          value={filters?.start_date}
          error={formErrors?.start_date}
          helperText={formErrors?.start_date}
        /> */}

        {/* <CustomDatePicker
          label="Service start date*"
          value={filters?.start_date}
          onChange={(newValue) =>
            setfilters({
              ...filters,
              start_date: newValue,
            })
          }
          error={formErrors?.start_date}
        /> */}

        {/* <CustomDate
          label="Service end date*"
          handleChange={(e) =>
            handleFilter(e.target.value, "select", "end_date", false)
          }
          name="end_date"
          value={filters?.end_date}
          error={formErrors?.end_date}
          helperText={formErrors?.end_date}
        /> */}

        {/* <CustomDatePicker
          label="Service end date*"
          value={filters?.end_date}
          onChange={(newValue) =>
            setfilters({
              ...filters, const handleDates = (_date, name, _question) => {
    console.log(_question, "_question");
    setDate({ ...date, to_date: _date });
    const questionId = _question.id;
    setUserResponses((prevResponses) => {
      const updatedResponses = {
        ...prevResponses,
        other_details_ids: prevResponses.other_details_ids.map((detail) => {
          if (detail.question_id === questionId) {
            const existingTextChoices = detail.date_choices.filter(
              (choice) =>
                choice.date_selected_id !== _question.date_selected[0].id
            );

            return {
              ...detail,
              date_choices: [
                ...existingTextChoices,
                {
                  date_selected_id: _question.date_selected[0].id,
                  date: _date,
                },
              ],
              // selected_choices_ids: [_question.date_selected[0].date_choice],
              selected_choices_ids: [],
            };
          }
          return detail;
        }),
      };

      return updatedResponses;
    });
  };
              end_date: newValue,
            })
          }
          error={formErrors?.end_date}
        /> */}
      </div>

      {questions.map((question) => (
        <div
          className={`  ${styles.margintopfifty}`}
          key={question.id}
          id={`question-${question.id}`}
        >
          <p className={styles.questiontitle}>
            {question?.is_required && (
              <span style={{ color: "red", fontWeight: "bold" }}>* </span>
            )}
            {question.title}{" "}
            <span className={styles.moreInfotitle}>
              {`(${question?.more_info})`}
            </span>
          </p>

          {question.view_type === 1 /* Radio buttons */ && (
            <FormControl component="fieldset">
              <RadioGroup
                aria-label={`question_${question.id}`}
                name={`question_${question.id}`}
                value={
                  userResponses.other_details_ids?.find(
                    (detail) => detail.question_id === question.id
                  )?.selected_choices_ids[0] || ""
                }
                onChange={(e) =>
                  handleResponse(question.id, [parseInt(e.target.value)])
                }
              >
                {question.choices.map((choice) => (
                  <FormControlLabel
                    key={choice.id}
                    value={choice.id.toString()}
                    control={<Radio />}
                    label={choice.title}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}

          {question.view_type === 2 /* Dropdown */ && (
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id={`multiple-chip-label-${question.id}`}>
                {question.title}
              </InputLabel>
              <Select
                labelId={`multiple-chip-label-${question.id}`}
                id={`multiple-chip-${question.id}`}
                multiple={question.is_multi}
                value={
                  userResponses.other_details_ids?.find(
                    (detail) => detail.question_id === question.id
                  )?.selected_choices_ids || []
                }
                onChange={(e) =>
                  handleResponse(
                    question.id,
                    Array.isArray(e.target.value)
                      ? e.target.value
                      : [e.target.value]
                  )
                }
                input={
                  <OutlinedInput
                    id={`select-multiple-chip-${question.id}`}
                    label={question.title}
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          question.choices?.find(
                            (choice) => choice.id === value
                          )?.title
                        }
                      />
                    ))}
                  </Box>
                )}
              >
                {question.choices.map((choice) => (
                  <MenuItem
                    key={choice.id}
                    value={choice.id}
                    style={getStyles(
                      choice.title,
                      userResponses.other_details_ids?.find(
                        (detail) => detail.question_id === question.id
                      )?.selected_choices_ids || [],
                      theme
                    )}
                  >
                    {choice.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {question.view_type === 3 /* vertical Text Stack */ && (
            <div className={styles.textStack}>
              {question.text_choices.map((textChoice) => (
                <TextField
                  id="outlined-basic"
                  label={textChoice?.hint}
                  variant="outlined"
                  key={textChoice.id}
                  value={
                    userResponses.other_details_ids
                      ?.find((detail) => detail.question_id === question.id)
                      ?.text_choices?.find(
                        (choice) => choice.text_choice_id === textChoice.id
                      )?.text_choice_ans || ""
                  }
                  onChange={(e) =>
                    handleTextChoice(question.id, textChoice.id, e.target.value)
                  }
                />
              ))}
            </div>
          )}

          {question.view_type === 4 /* horizontal Text Stack */ && (
            <div className={styles.textStackhorizontal}>
              {question.text_choices.map((textChoice) => (
                <TextField
                  id="outlined-basic"
                  label={textChoice?.hint}
                  variant="outlined"
                  key={textChoice.id}
                  value={
                    userResponses.other_details_ids
                      ?.find((detail) => detail.question_id === question.id)
                      ?.text_choices?.find(
                        (choice) => choice.text_choice_id === textChoice.id
                      )?.text_choice_ans || ""
                  }
                  onChange={(e) =>
                    handleTextChoice(question.id, textChoice.id, e.target.value)
                  }
                />
              ))}
            </div>
          )}

          {question.view_type === 5 && question.choices.length > 10 ? (
            <div style={{ overflowX: "scroll" }}>
              <div className={styles.displayFlexfruit}>
                {question.choices
                  .slice(0, Math.floor(question.choices.length / 2))
                  .map((choice, index) => {
                    const isChoiceSelected =
                      userResponses.other_details_ids
                        ?.find((detail) => detail.question_id === question.id)
                        ?.selected_choices_ids.includes(choice.id) ?? false;

                    return (
                      <div
                        key={choice.id}
                        className={` ${styles.fruitsCon}  ${
                          isChoiceSelected ? styles.selectedFruit : ""
                        }`}
                        onClick={() =>
                          handleChange(question.id, choice.id, question)
                        }
                      >
                        <div className={`${styles.fruitrealimg}`}>
                          <img
                            src={choice.icon}
                            alt={choice.title}
                            style={{ width: "35px" }}
                          />
                        </div>

                        <p
                          className={`${styles.marginzero} ${
                            isChoiceSelected ? styles.selectedFruittext : ""
                          }`}
                        >
                          {choice.title}
                        </p>
                      </div>
                    );
                  })}
              </div>

              <div className={styles.displayFlexfruit}>
                {question.choices
                  .slice(
                    Math.floor(question.choices.length / 2),
                    question.choices.length
                  )
                  .map((choice, index) => {
                    const isChoiceSelected =
                      userResponses.other_details_ids
                        ?.find((detail) => detail.question_id === question.id)
                        ?.selected_choices_ids.includes(choice.id) ?? false;

                    return (
                      <div
                        key={choice.id}
                        className={` ${styles.fruitsCon}  ${
                          isChoiceSelected ? styles.selectedFruit : ""
                        }`}
                        onClick={() =>
                          handleChange(question.id, choice.id, question)
                        }
                      >
                        <div className={`${styles.fruitrealimg}`}>
                          <img
                            src={choice.icon}
                            alt={choice.title}
                            style={{ width: "35px" }}
                          />
                        </div>

                        <p
                          className={`${styles.marginzero} ${
                            isChoiceSelected ? styles.selectedFruittext : ""
                          }`}
                        >
                          {choice.title}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <div className={styles.displayFlexfruit}>
              {question.choices.map((choice, index) => {
                const isChoiceSelected =
                  userResponses.other_details_ids
                    ?.find((detail) => detail.question_id === question.id)
                    ?.selected_choices_ids.includes(choice.id) ?? false;

                return (
                  <div
                    key={choice.id}
                    className={` ${styles.fruitsCon}  ${
                      isChoiceSelected ? styles.selectedFruit : ""
                    }`}
                    onClick={() =>
                      handleChange(question.id, choice.id, question)
                    }
                  >
                    <div className={`${styles.fruitrealimg}`}>
                      <img
                        src={choice.icon}
                        alt={choice.title}
                        style={{ width: "35px" }}
                      />
                    </div>

                    <p
                      className={`${styles.marginzero} ${
                        isChoiceSelected ? styles.selectedFruittext : ""
                      }`}
                    >
                      {choice.title}
                    </p>
                  </div>
                );
              })}
            </div>
          )}

          {question.view_type === 6 && (
            <div className={styles}>
              <DateComponent
                handleDate={handleDates}
                title="From date"
                dateErr={error}
                label="Select Date"
                date={date.to_date}
                disableFuture={true}
              />{" "}
            </div>
          )}
          {/* ... other view types */}
        </div>
      ))}

      {singleServiceSelect?.slug === "agrispray-using-drones" &&
        productsList.length > 0 && (
          <div>
            <h3 style={{ margin: "20px 0px" }}>Products</h3>

            <div className={styles.produtListCon}>
              {productsList.map((product, index) => {
                return (
                  <div
                    key={index}
                    className={styles.productCon}
                    // style={{
                    //   border: selectedProducts.some(
                    //     (productItem) => productItem.id === product?.id
                    //   )
                    //     ? "2px solid #ff9908"
                    //     : "2px solid lightgray",
                    // }}
                    style={{ border: "2px solid lightgray" }}
                  >
                    <div
                      className={styles.topContainer}
                      // onClick={() => {
                      //   handleSelectProduct(product);
                      // }}
                    >
                      <img
                        src={product?.thumbnail}
                        style={{ fill: "red" }}
                        alt="product_image"
                      />
                    </div>
                    <div>
                      <p
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginBottom: "0px",
                          fontWeight: "600",
                        }}
                      >
                        {product?.product_name}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <p className={styles.actualPriceText}>
                          Rs. {product?.mrp}
                        </p>
                        <p className={styles.offerPriceText}>
                          Rs. {product?.selling_price}
                        </p>
                      </div>
                    </div>
                    <div className={styles.counterContainer}>
                      <div
                        className={styles.leftCount}
                        onClick={() => {
                          handleDecreaseQuantity(product);
                        }}
                      >
                        <img src={productDecrease} alt="product_decrease" />
                      </div>
                      <p style={{ margin: "0px" }}>
                        {getProductQuantity(product)}
                      </p>
                      <div
                        className={styles.rightCon}
                        onClick={() => {
                          handleIncreaseQuantity(product);
                        }}
                      >
                        <img src={productIncrease} alt="product_increase" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

      {singleServiceSelect?.slug === "agrispray-using-drones" && startDate && (
        <div>
          <h3>Preffered Date</h3>
          <div>
            <p
              style={{
                background: "#ffeacf",
                color: "#f06e1e",
                fontWeight: "600",
                padding: "10px",
                borderRadius: "10px",
                width: "max-content",
              }}
            >
              {formatDate(startDate)}
            </p>
            <p>Selected Date</p>
          </div>
        </div>
      )}

      {singleServiceSelect?.slug === "agrispray-using-drones" && (
        <div
          className={`${styles.dateContainer}  ${styles.customDatePickerWidth}   `}
          style={{ width: "80%", margin: "auto", marginTop: "50px" }}
        >
          <CustomServiceCalendar
            startDate={startDate}
            endDate={endDate}
            handleDate={handleDateChange}
            occupancy={occupant}
            showImage={false}
            showDoubleView={false}
            selectRange={false}
            setCurrentMonth={setCurrentMonth}
            dateList={dateList}
          />
        </div>
      )}

      {/* 
      {availableSlots.length > 0 && (
        <p style={{ fontWeight: "bold", marginTop: "10px" }}>
          Please select slot
        </p>
      )}

      <div className={`${styles.cropStageContainer} ${styles.marginTop}  `}>
        {availableSlots?.map((stage) => (
          <div
            key={stage.id}
            className={`${styles.cropStage} ${
              activeStage === stage.id ? styles.activeStage : ""
            }`}
            onClick={() => handleStageClick(stage.id)}
          >
            <div className={styles.cropstageCon}>
              <img
                className={styles.cropicon}
                src={stage?.icon}
                alt={stage.title}
              />
            </div>
            <p className={`${styles.marginzero}  ${styles.marginfour} `}>
              {stage?.title}
            </p>
            <p className={`${styles.marginzero}  ${styles.marginfour} `}>
              {" "}
              {stage?.from_hr} {getAMPM(stage?.from_hr)} to {stage?.to_hr}{" "}
              {getAMPM(stage?.to_hr)}{" "}
            </p>
          </div>
        ))}
      </div> */}

      {singleServiceSelect?.slug &&
        singleServiceSelect?.slug === "agrispray-using-drones" && (
          <div className={`${styles.marginTop}`}>
            {Object.keys(slotsData).length > 0 && <h3>Preferred Time</h3>}
            <div>
              {/* {prefferedTimeData.map((item, index) => {
         return (
           <div key={index}>
             <div
               style={{
                 display: "flex",
                 alignItems: "center",
                 gap: "10px",
               }}
             >
               <img src={item?.icon} />

               <h3 style={{ fontSize: "18px", fontWeight: "500" }}>
                 {item?.noonState}
               </h3>
             </div>
             <div className={styles.slotsListCon}>
               {item?.slotsList.map((slot, indx) => {
                 return (
                   <button
                     key={indx}
                     className={styles.slotButton}
                     style={{
                       border:
                         slot?.slotStatus === "Active"
                           ? "2px solid green"
                           : "2px solid grey",
                       cursor:
                         slot?.slotStatus === "Active"
                           ? "pointer"
                           : "default",
                       background:
                         activeServiceSlot?.id === slot?.id
                           ? "linear-gradient(to right, rgba(11, 209, 0, 1), rgba(33, 127, 28, 1))"
                           : "white",
                       color:
                         slot?.slotStatus === "Active"
                           ? activeServiceSlot?.id === slot?.id
                             ? "white"
                             : "green"
                           : slot?.slotStatus === "Inactive"
                           ? "gray"
                           : "white",
                     }}
                     disabled={slot?.slotStatus === "Inactive"}
                     onClick={() => {
                       handleSelectSlot(slot);
                     }}
                   >
                     {slot?.slotTime}
                   </button>
                 );
               })}
             </div>
           </div>
         );
       })} */}
              {slotsData?.morning && slotsData?.morning.length > 0 && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <img src={morningSlot} />

                    <h3 style={{ fontSize: "18px", fontWeight: "500" }}>
                      Morning
                    </h3>
                  </div>{" "}
                  <div className={styles.slotsListCon}>
                    {slotsData?.morning.map((item, index) => {
                      return (
                        <button
                          className={styles.slotButton}
                          style={{
                            border:
                              item?.is_available === true
                                ? "2px solid green"
                                : "2px solid grey",
                            cursor:
                              item?.is_available === true
                                ? "pointer"
                                : "default",
                            background:
                              activeServiceSlot?.id === item?.id
                                ? "linear-gradient(to right, rgba(11, 209, 0, 1), rgba(33, 127, 28, 1))"
                                : "white",
                            color:
                              item?.is_available === true
                                ? activeServiceSlot?.id === item?.id
                                  ? "white"
                                  : "green"
                                : item?.is_available === false
                                ? "gray"
                                : "white",
                          }}
                          disabled={item?.is_available === false}
                          onClick={() => {
                            handleSelectSlot(item);
                          }}
                        >
                          {convertTo12HourFormat(item?.slot_time)}
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
              {slotsData?.afternoon && slotsData?.afternoon.length > 0 && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <img src={afternoonSlot} />

                    <h3 style={{ fontSize: "18px", fontWeight: "500" }}>
                      Afternoon
                    </h3>
                  </div>{" "}
                  <div className={styles.slotsListCon}>
                    {slotsData?.afternoon.map((item, index) => {
                      return (
                        <button
                          className={styles.slotButton}
                          style={{
                            border:
                              item?.is_available === true
                                ? "2px solid green"
                                : "2px solid grey",
                            cursor:
                              item?.is_available === true
                                ? "pointer"
                                : "default",
                            background:
                              activeServiceSlot?.id === item?.id
                                ? "linear-gradient(to right, rgba(11, 209, 0, 1), rgba(33, 127, 28, 1))"
                                : "white",
                            color:
                              item?.is_available === true
                                ? activeServiceSlot?.id === item?.id
                                  ? "white"
                                  : "green"
                                : item?.is_available === false
                                ? "gray"
                                : "white",
                          }}
                          disabled={item?.is_available === false}
                          onClick={() => {
                            handleSelectSlot(item);
                          }}
                        >
                          {convertTo12HourFormat(item?.slot_time)}
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}{" "}
              {slotsData?.evening && slotsData?.evening.length > 0 && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <img src={eveningSlot} />

                    <h3 style={{ fontSize: "18px", fontWeight: "500" }}>
                      Evening
                    </h3>
                  </div>{" "}
                  <div className={styles.slotsListCon}>
                    {slotsData?.evening.map((item, index) => {
                      return (
                        <button
                          className={styles.slotButton}
                          style={{
                            border:
                              item?.is_available === true
                                ? "2px solid green"
                                : "2px solid grey",
                            cursor:
                              item?.is_available === true
                                ? "pointer"
                                : "default",
                            background:
                              activeServiceSlot?.id === item?.id
                                ? "linear-gradient(to right, rgba(11, 209, 0, 1), rgba(33, 127, 28, 1))"
                                : "white",
                            color:
                              item?.is_available === true
                                ? activeServiceSlot?.id === item?.id
                                  ? "white"
                                  : "green"
                                : item?.is_available === false
                                ? "gray"
                                : "white",
                          }}
                          disabled={item?.is_available === false}
                          onClick={() => {
                            handleSelectSlot(item);
                          }}
                        >
                          {convertTo12HourFormat(item?.slot_time)}
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
              {slotsData?.morning &&
                slotsData?.morning.length === 0 &&
                slotsData?.afternoon &&
                slotsData?.afternoon.length === 0 &&
                slotsData?.evening &&
                slotsData?.evening.length === 0 && (
                  <p>No slots present for this start date</p>
                )}
            </div>
          </div>
        )}

      <button
        className={` ${loading ? styles.loadingStyle : styles.saveandnextbtn}`}
        onClick={handleSecondform}
        disabled={loading}
        // style={{ backgroundColor: loading ? 'gray' : 'inherit' }}
      >
        Save and proceed
      </button>
    </div>
  );
};

export default CreateOtherDeatils;

const prefferedTimeData = [
  {
    id: 1,
    noonState: "Morning",
    icon: morningSlot,
    slotsList: [
      {
        id: 1,
        slotTime: "06:00 AM",
        slotStatus: "Active",
      },
      {
        id: 2,
        slotTime: "07:00 AM",
        slotStatus: "Active",
      },
      {
        id: 3,
        slotTime: "08:00 AM",
        slotStatus: "Active",
      },
      {
        id: 4,
        slotTime: "09:00 AM",
        slotStatus: "Active",
      },
      {
        id: 5,
        slotTime: "10:00 AM",
        slotStatus: "Inactive",
      },
      {
        id: 6,
        slotTime: "11:00 AM",
        slotStatus: "Active",
      },
    ],
  },
  {
    id: 2,
    noonState: "Afternoon",
    icon: afternoonSlot,
    slotsList: [
      {
        id: 7,
        slotTime: "12:00 PM",
        slotStatus: "Inactive",
      },
      {
        id: 8,
        slotTime: "01:00 PM",
        slotStatus: "Active",
      },
      {
        id: 9,
        slotTime: "02:00 PM",
        slotStatus: "Active",
      },
    ],
  },
  {
    id: 3,
    noonState: "Evening",
    icon: eveningSlot,
    slotsList: [
      {
        id: 10,
        slotTime: "04:00 PM",
        slotStatus: "Inactive",
      },
      {
        id: 11,
        slotTime: "05:00 PM",
        slotStatus: "Active",
      },
      {
        id: 12,
        slotTime: "06:00 PM",
        slotStatus: "Active",
      },
    ],
  },
];
