import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { token_api } from "../../Utils/network";
import { helperdayMonthYearFull } from "../../Utils/helper";

const DroneServiceBooking = ({ onChange, value }) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleChange = (event, newValue) => {
    onChange && onChange(newValue);
  };

  useEffect(() => {
    setLoading(true);

    let apiEndpoint = `service/booking`;

    if (inputValue) {
      apiEndpoint += `?q=${encodeURIComponent(inputValue)}`;
    }

    token_api
      .get(apiEndpoint)
      .then((response) => {
        const data = response?.data?.data?.map((info) => ({
          id: info?.id,
          full_name: info?.full_name,
          city: info?.city,
          contact_no: info?.contact_no,
          start_date: info?.start_date,
          end_date: info?.end_date,
          pincode: info?.pincode,
          address_line_1: info?.address_line_1,
          address_line_2 : info?.address_line_2,
          district : info?.district,
          lat : info?.lat,
          country : info?.country,
          long : info?.long,
          remark : info?.remark,
          state : info?.state,
          plot_no: info?.plot_no,
          state : info?.state,



        }));
        setOptions(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [inputValue]);

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      multiple
      id="tags-outlined"
      options={options}
      getOptionLabel={(option) => `${option?.full_name} `}
      value={value}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Service Booking"
          placeholder="Search for service booking"
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const label = `ID : ${option?.id} Person Name: ${option.full_name}`;
        const sublabel = `Location: ${option.city} | Contact No: ${
          option.contact_no
        } | Start Date: ${helperdayMonthYearFull(
          option.start_date
        )} | End Date : ${helperdayMonthYearFull(option.end_date)}`;

        return (
          <li {...props}>
            <div style={{ width: "100%" }}>
              <div>
                <div style={{ fontWeight: "bold" }}>{label}</div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>{sublabel}</div>
                </div>
              </div>
            </div>
          </li>
        );
      }}
    />
  );
};

export default DroneServiceBooking;
