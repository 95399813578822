import React, { useState, useEffect } from "react";
import styles from "./DealerServiceBookings.module.css";
import { token_api } from "../../../Utils/network";
import CustomPagination from "../../CustomPagination/CustomPagination";
import orderDrone from "../../../Assets/orangeDroneBooking.svg";
import { formatDate } from "../../../Utils/helper";
import { useNavigate } from "react-router-dom";

const DealerServiceBookings = ({dealerCode}) => {

  const navigate = useNavigate();
  const [serviceBookingList, setServiceBookingList] = useState([]);
  // let dealerCode = "D123";
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;

  const getDealerServiceBookingList = async (filters) => {
    try {
      const fetchDealerServiceBookingList = await token_api.get(
        `service/booking?dealer_code=${dealerCode}`,
        {
          params: { ...filters },
          paramsSerializer: {
            indexes: null,
          },
        }
      );
      if (fetchDealerServiceBookingList.status !== 200) {
        throw new Error("Error while fetching dealer service booking list");
      }
      setPaginationData(fetchDealerServiceBookingList?.data);

      setServiceBookingList(fetchDealerServiceBookingList?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (dealerCode) {
      const timeout = setTimeout(() => {
        let filterObject = {};
        if (pageState) {
          filterObject.page = pageState;
        }

        getDealerServiceBookingList(filterObject);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [dealerCode, pageState]);

  const handleClick = (bookingID) => {
    navigate(`/bookings/service-booking-details/${bookingID}`);
  };
  return (
    <>
      {serviceBookingList.length > 0 ? (
        <div>
          <div>
            {serviceBookingList.map((item, index) => {
              return (
                <div className={styles.mainContainer} key={index}>
                  <div className={styles.topContainer}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "20px",
                      }}
                    >
                      <img src={orderDrone} className={styles.droneImg} />
                      <div>
                        {item?.bookingaddonservice &&
                          item?.bookingaddonservice?.length > 0 && (
                            <h4 className={styles.title}>
                              {item?.bookingaddonservice[0]?.services?.title}
                            </h4>
                          )}
                        <div className={styles.bookingCon}>
                          {item?.status_str === "Scheduled" && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                padding: "10px",
                                borderRadius: "8px",
                                backgroundColor: "rgba(255, 215, 8, 0.45)",
                                marginRight: "8px",
                              }}
                            >
                              <img
                              //   src={item?.statusIcon.src}
                              //   style={{ width: "20px", height: "20px" }}
                              />
                              <p
                                style={{
                                  color: "rgba(150, 118, 6, 1)",
                                  margin: "0px",
                                }}
                              >
                                {item?.status_str}
                              </p>{" "}
                            </div>
                          )}
                          {item?.status_str === "New Request" && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                padding: "10px",
                                borderRadius: "8px",
                                backgroundColor: "rgba(252, 185, 176, 1)",
                                marginRight: "8px",
                              }}
                            >
                              <img
                              //   src={item?.statusIcon.src}
                              //   style={{ width: "20px", height: "20px" }}
                              />
                              <p
                                style={{
                                  color: "rgba(188, 70, 7, 1)",
                                  margin: "0px",
                                }}
                              >
                                {item?.status_str}
                              </p>{" "}
                            </div>
                          )}
                          {item?.status_str === "Completed" && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                padding: "10px",
                                borderRadius: "8px",
                                backgroundColor: "rgba(211, 251, 171, 1)",
                                marginRight: "8px",
                              }}
                            >
                              <img
                              //   src={item?.statusIcon.src}
                              //   style={{ width: "20px", height: "20px" }}
                              />
                              <p
                                style={{
                                  color: "rgba(60, 147, 8, 1)",
                                  margin: "0px",
                                }}
                              >
                                {item?.status_str}
                              </p>{" "}
                            </div>
                          )}
                          {/* |
                      <p className={styles.bookText} style={{ marginLeft: "8px" }}>
                        Booking ID: <span>{item?.bookId}</span>
                      </p> */}
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        handleClick(item?.id);
                      }}
                    >
                      <button className={styles.viewandmanagebtn}>
                        View & Manage Order
                      </button>
                    </div>
                  </div>
                  <div className={styles.bottomContainer}>
                    <div style={{ paddingLeft: "20px", width: "100%" }}>
                      <div className={styles.miniCon}>
                        <p className={styles.bookId}>Booking Id</p> :{" "}
                        <p className={styles.bookSpan}>{item?.id}</p>
                      </div>
                      <div className={styles.miniCon}>
                        <p className={styles.bookId}>Booking Date</p>:
                        <p className={styles.bookSpan}>
                          {formatDate(item?.created)}
                        </p>
                      </div>
                    </div>
                    <div className={styles.amountCon}>
                      <p className={styles.amountVal}>
                        {item?.total_amt || "N.A"}
                      </p>
                      <p className={styles.amountName}>Amount</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>{" "}
          <div className={styles.paginationContainer}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      ) : (
        <h3>No Service Bookings Available</h3>
      )}
    </>
  );
};

export default DealerServiceBookings;
