export const validateForm = (
  userDetails,
  dduser,
  singleServiceSelect,
  // filters,
  activeaddressid,
  handleOpen
) => {
  return new Promise((resolve, reject) => {
    const { phone, title } = userDetails;

    const errors = { error: false };

    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phone)) {
      errors.phone = "Enter a valid 10-digit phone number";
      errors.error = true;
    }

    if (!dduser?.id) {
      errors.dduser = "Enter user name";
      errors.error = true;
    }

    if (!singleServiceSelect?.id) {
      errors.title = "Select the service type";
      errors.error = true;
    }

    // if (!filters?.start_date) {
    //   errors.start_date = "Select the service start date ";
    //   errors.error = true;
    // }

    // if (!filters?.end_date) {
    //   errors.end_date = "Select the service end date";
    //   errors.error = true;
    // }

    if (dduser?.id && !activeaddressid) {
      handleOpen();
    }
    // if(isDescriptionsEmpty){
    //   errors.descriptions = "Enter description";
    //   errors.error = true;
    // }

    // if (!name) {
    //   errors.name = "Enter batch name";
    //   errors.error = true;
    // }

    // if (!start_date) {
    //   errors.start_date = "Enter start date";
    //   errors.error = true;
    // }

    // if (!end_date) {
    //   errors.end_date = "Enter end date";
    //   errors.error = true;
    // }

    // if (!start_time) {
    //   errors.start_time = "Enter start time";
    //   errors.error = true;
    // }

    // if (!end_time) {
    //   errors.end_time = "Enter end time";
    //   errors.error = true;
    // }

    // if (!mrp) {
    //   errors.mrp = "Enter MRP ";
    //   errors.error = true;
    // }

    // if (!selling_price) {
    //   errors.selling_price = "Enter selling price";
    //   errors.error = true;
    // }

    // if (!total_seats) {
    //   errors.total_seats = "Enter total seats";
    //   errors.error = true;
    // }

    // if (!location || !location[0]) {
    //   errors.location = "Enter location";
    //   errors.error = true;
    // }

    // if (!days_of_weeks || !days_of_weeks[0]) {
    //   errors.days_of_weeks = "Enter days of the week";
    //   errors.error = true;
    // }

    // if (!mode || !mode[0]) {
    //   errors.mode = "Enter mode";
    //   errors.error = true;
    // }
    // if (!language || !language[0]) {
    //   errors.language = "Enter mode of language";
    //   errors.error = true;
    // }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
