import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { token_api } from "../../Utils/network";
import { helperdayMonthYearFull } from "../../Utils/helper";

const PilotDropDown = ({
  onChange,
  value,
  start_date,
  end_date,
  location,
  state,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleChange = (event, newValue) => {
    onChange && onChange(newValue);
  };

  useEffect(() => {
    if (
      start_date === undefined ||
      end_date === undefined ||
      location === undefined ||
      state === undefined
    ) {
      return;
    }

    setLoading(true);

    let apiEndpoint = `/service/check_pilot_availability?start_date=${start_date}&end_date=${end_date}&location=${location}&state=${state}`;

    if (inputValue) {
      apiEndpoint += `&=${encodeURIComponent(inputValue)}`;
    }

    token_api
      .get(apiEndpoint)
      .then((response) => {
        const data = response.data.data?.available_pilots;

        const not_available_pilots  = response.data.data?.not_available_pilots

        const updatedAvailableDrones = data.map((pilot) => ({
          ...pilot,
          is_assigned: false,
        }));

        const updatedNotAvailableDrones = not_available_pilots.map((pilot) => ({
          ...pilot,
          is_assigned: true,
        }));

    
        const combinedArray = [...updatedAvailableDrones, ...updatedNotAvailableDrones];

        setOptions(combinedArray);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [inputValue, start_date, end_date, location, state]);

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      multiple
      id="tags-outlined"
      options={options}
      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
      value={value}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      onChange={handleChange}
      //   sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label="Pilot" placeholder="Search for pilots" />
      )}
      renderOption={(props, option, { inputValue }) => {
        const label = `Pilot name: ${option.user?.first_name} ${option.user?.last_name} `;
        const sublabel = `Location: ${option.location} | License No: ${
          option.license?.license_no
        } | Expiry Date: ${helperdayMonthYearFull(
          option.license?.expiry_date
        )}`;

        return (
          <li {...props}>
            <div style={{ width: "100%" }}>
              <div>
                <div style={{ fontWeight: "bold" }}>{label}</div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>{sublabel}</div>

                  <div>
                    {!option.is_assigned ? (
                      <div
                        style={{
                          padding: "7px 25px",
                          borderRadius: "25px",
                          backgroundColor: "#CAE6BD",
                          color: "#1E7026",
                          minWidth: "145px",
                          textAlign: "center",
                        }}
                      >
                        Available
                      </div>
                    ) : (
                      <div
                        style={{
                          padding: "7px 25px",
                          borderRadius: "25px",
                          color: "rgb(158, 40, 40)",
                          backgroundColor: "rgb(255, 164, 145)",
                        }}
                      >
                        Not available
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      }}
    />
  );
};

export default PilotDropDown;
