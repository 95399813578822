import React, { useState } from "react";
import styles from "./PilotProfileCard.module.css";
import Profileimg from "../../Assets/agmUserProfile/profile.png";
import editBtnIcon from "../../Assets/userProfile/editIcon.svg";
import details1 from "../../Assets/userProfile/fname.svg";
import details2 from "../../Assets/userProfile/mobNo.svg";
import details3 from "../../Assets/userProfile/location.svg";
import details4 from "../../Assets/userProfile/onBdate.svg";
import details5 from "../../Assets/userProfile/gender.svg";
import details6 from "../../Assets/userProfile/mail.svg";
import details7 from "../../Assets/userProfile/gst.svg";
import details8 from "../../Assets/userProfile/lastlogin.svg";
import verified from "../../Assets/userProfile/verified.svg";
import notverified from "../../Assets/userProfile/notVerified.svg";

const PilotProfileCard = ({ user, opneProfileDrawer, opneProfileModel }) => {

  const [statuscheck, setStatusCheck] = useState(true);
  return (
    <div className={styles.mainContainer}>
      {/* <div className={styles.leftProfileimgContainer}>
                <img src={user?.avatar ? user?.avatar : user?.default_avatar} className={styles.profilepic}  alt='profileimg' />
                <img onClick={opneProfileModel} src={editBtnIcon} className={styles.editprofilepic} alt='edit-icon' />
            </div> */}
      <div className={styles.rightProfiledetaile}>
        <div className={styles.topbar}>
          <div className={styles.cardheading}>
            <h3>Profile</h3>
            <p>Basic Info</p>
          </div>
          <div className={styles.editBtn} style={{ cursor: "pointer" }}>
            <img
              src={editBtnIcon}
              onClick={() => {
                opneProfileDrawer(user);
              }}
              alt="edit-Icon"
            />
          </div>
        </div>
        <div className={styles.besicDetailsWrap}>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={details1} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}>
                {" "}
                {`${user?.first_name}  ${user?.last_name}` || "N.A"}
              </h4>
              <p className={styles.detailsHeading}> Full name</p>
            </div>
          </div>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={details2} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}>
                {" "}
                {user?.user?.mobile || "N.A"}
                {user?.user?.mobile &&
                  (user?.user?.mobile_verified ? (
                    <span className={styles.Vstatus}>
                      {" "}
                      <img src={verified} /> verified{" "}
                    </span>
                  ) : (
                    <span className={styles.NVstatus}>
                      {" "}
                      <img src={notverified} /> Not verified{" "}
                    </span>
                  ))}
                {/* <span className={styles.Vstatus}> <img src={verified} /> verified </span> */}
              </h4>
              <p className={styles.detailsHeading}> mobile no.</p>
            </div>
          </div>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={details3} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}> {user?.location || "N.A"}</h4>
              <p className={styles.detailsHeading}> Location</p>
            </div>
          </div>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={details4} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}> {user?.joining_date || "N.A"}</h4>
              <p className={styles.detailsHeading}> Onboarding date</p>
            </div>
          </div>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={details5} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}> {user?.gender_str || "N.A"}</h4>
              <p className={styles.detailsHeading}> gender</p>
            </div>
          </div>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={details6} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detailemail}>
                {" "}
                {user?.user?.email || "N.A"}
                {user?.user?.email &&
                  (user?.user?.email_verified ? (
                    <span className={styles.Vstatus}>
                      {" "}
                      <img src={verified} /> verified{" "}
                    </span>
                  ) : (
                    <span className={styles.NVstatus}>
                      {" "}
                      <img src={notverified} /> Not verified{" "}
                    </span>
                  ))}
                {/* <span className={styles.NVstatus}> <img src={notverified} /> Not Verified </span> */}
              </h4>
              <p className={styles.detailsHeading}> email id</p>
            </div>
          </div>

          {/* <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={details7} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}> {user?.gst || "N.A"}</h4>
              <p className={styles.detailsHeading}> GST no.</p>
            </div>
          </div> */}
          {/* <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={details8} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}>
                {" "}
                {user?.user?.last_login || "N.A"}
              </h4>
              <p className={styles.detailsHeading}> Last login</p>
            </div>
          </div> */}
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              {/* <img src={details8} alt='' /> */}
            </div>
            <div className={styles.detailsText}>
              <h4
                className={`${styles.status} ${
                  user?.active ? styles.active : styles.inActive
                }`}
              >
                {user?.active ? "Active" : "Inactive"}{" "}
              </h4>
              <p className={styles.detailsHeading}> status</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PilotProfileCard;
