import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
  '&.Mui-checked': {
    '& .MuiSwitch-track::before': {
      backgroundColor: 'red', // Active color
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#8796A5', // Active color
    },
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
  },
}));

const ControllableSwitch = ({ checked, onChange, label }) => {
  const [internalChecked, setInternalChecked] = useState(false);

  const handleChange = (event) => {
    setInternalChecked(event.target.checked);

    // If an external onChange handler is provided, call it
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <FormControlLabel
      control={<Android12Switch checked={checked !== undefined ? checked : internalChecked} onChange={handleChange} />}
      label={label}
    />
  );
};

export default ControllableSwitch;
