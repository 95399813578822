import React, { useEffect, useState } from "react";
import styles from "./DealerDetails.module.css";
import { Link, useNavigate } from "react-router-dom";
import Container from "../../Component/Container/Container";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import dummyProfile from "../../Assets/DDuserIcons/dduserDummyProfile.svg";
import editIcon from "../../Assets/edit_icon.svg";
import ddPersonal from "../../Assets/DDuserIcons/ddPersonal.svg";
import ddAddresses from "../../Assets/DDuserIcons/ddAddresses.svg";
import orangeDocument from "../../Assets/orangeUserDocument.svg";
import orangeServiceBooking from "../../Assets/orangeServiceBooking.svg";
import DealerPersonalDetails from "../../Component/DealersComponents/PersonalDetails/PersonalDetails";
import DealerAddress from "../../Component/DealersComponents/DealerAddress/DealerAddress";
import DealerDocuments from "../../Component/DealersComponents/DealerDocuments/DealerDocuments";
import DealerServiceBookings from "../../Component/DealersComponents/DealerServiceBookings/DealerServiceBookings";
import { useParams } from "react-router";
import { token_api } from "../../Utils/network";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import AddressBlock from "../../Component/AddressBlock/AddressBlock";
import InputFields from "../../Component/InputFields/InputFields";
import dayjs from "dayjs";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import { Button } from "@mui/material";
import { Validate, ValidateAddress } from "./Utility";
import { formatDate } from "../../Utils/helper";
import axios from "axios";
import MyMap from "../../Component/MyMap/MyMap";
import EditDealerpicModel from "./EditDealerPic";
import CustomModal from "../../Component/CustomModal/CustomModal";

const DealerDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [userId, setUserId] = useState(null);
  console.log(id, "hariParams");
  let [tabValue, setTabValue] = useState(1);
  const [dealerDetails, setDealerDetails] = useState({});
  const [dealerAddressList, setDealerAddressList] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("");
  //Dealer Personal Details...
  const [personalForm, setPersonalForm] = useState({
    id: null,
    full_name: "",
    establishment_name: "",
    joining_date: null,
    gender: [],
    status: [],
    dealer_code: "",
    total_target_acres: "",
    mobile: "",
    mobile_verified: [],

    email: "",
    email_verified: [],
  });
  const [formErrors, setFormErrors] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModel = () => {
    setIsModalOpen(true);
  };
  //Dealer Address Data....
  const [selectedBlock, setSelectedBlock] = useState([]);
  const [loc, updLoc] = useState({ lat: 20.5937, lng: 78.9629 });

  const [addressData, setAddressData] = useState({
    id: null,
    area: "",
    area_in_acres: "",
    city: "",
    country: "",
    district: "",
    line_1: "",
    line_2: "",
    plot_no: "",
    line_kms: "",
    pincode: "",
    state: "",
    tehsil: "",
  });

  const [addressErrors, setAddressErrors] = useState({});
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link to="/users/dealers" className="orange-1 bread breadElement">
      Dealers
    </Link>,
    <div className="orange-1 bread">Dealer Profile</div>,
  ];

  const handleNavigateBack = () => {
    navigate(`/users/dealers`);
  };
  const handleChangeTab = (tabId) => {
    setTabValue(tabId);
  };

  const handleAutoCompleteChangeForBlock = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Block already included");
    } else {
      setSelectedBlock(selectedValues);
    }
  };

  const getDealerDetails = () => {
    token_api
      .get(`users/profile/admin_dealer/${id}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          const { user } = response?.data?.data;
          setDealerDetails(response?.data?.data);
          if (user?.id) {
            setUserId(user?.id);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDealerAddressList = () => {
    token_api
      .get(`users/address?creator=${dealerDetails?.user?.id}`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setDealerAddressList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (id) {
      getDealerDetails();
    }
  }, [id]);
  useEffect(() => {
    if (userId) {
      getDealerAddressList();
    }
  }, [userId]);

  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    if (name === "pincode") {
      if (value.length > 6) {
        return;
      }
    }
    setAddressData({
      ...addressData,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (addressData?.pincode && addressData?.pincode?.length === 6) {
        try {
          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${addressData?.pincode}`
          );

          if (
            response.data.length !== 0 &&
            response.data[0].PostOffice.length !== 0
          ) {
            const pinData = response.data[0].PostOffice[0];
            const { District, Division, State, Name, Region, Country } =
              pinData;

            setAddressData({
              ...addressData,
              tehsil: Division,
              state: State,
              district: District,
              city: District,
              country: Country,
            });

            // setFormData({
            //   ...formData,
            //   district: Name,
            //   city: Region,
            //   state: State,
            //   country: Country,
            //   pincode: pin,
            // });
            // setShowOther(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
        }
      }
    };

    fetchData();
  }, [addressData?.pincode]);

  //Drawer State....

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setDrawerState("");
  };

  const handleOpenDrawer = (data, type) => {
    setOpenDrawer(true);
    setDrawerState(type);
    if (type === "profile") {
      const {
        id,
        full_name,
        establishment_name,
        joining_date,
        gender,
        active,
        user,
        dealer_code,
        total_target_acres,
      } = data;
      let genderItem = genderList.filter((item) => item?.id == gender);
      let statusItem = statusList.filter((item) => item?.id == active);
      let mobile_verified = verifiedList.filter(
        (item) => item?.id == user?.mobile_verified
      );
      let email_verified = verifiedList.filter(
        (item) => item?.id == user?.email_verified
      );

      setPersonalForm({
        ...personalForm,
        id: id,
        full_name,
        establishment_name: establishment_name,
        joining_date: dayjs(joining_date),
        gender: genderItem,
        status: statusItem,
        dealer_code: dealer_code,
        total_target_acres: total_target_acres,
        mobile: user?.mobile,
        mobile_verified: mobile_verified,
        email: user?.email,
        email_verified: email_verified,
      });
    }

    setFormErrors({});
    setAddressErrors({});
  };

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setPersonalForm({
        ...personalForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setPersonalForm({
        ...personalForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      if (name === "mobile") {
        if (value.length > 10) {
          return;
        }
      }
      setPersonalForm({
        ...personalForm,
        [name]: value,
      });
    }
  };

  const handleDeleteChipforDrawer = (id, selectName) => {
    setPersonalForm({
      ...personalForm,
      [selectName]: personalForm?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
  };

  //Updating Dealer Personal Details...

  const handleUpdateDealer = async () => {
    const data = {
      full_name: personalForm?.full_name,
      establishment_name: personalForm?.establishment_name,
      total_target_acres: personalForm?.total_target_acres,
      dealer_code: personalForm?.dealer_code,
      joining_date: formatDate(personalForm?.joining_date),
      active: personalForm?.status[0]?.id,
      gender: personalForm?.gender[0]?.id,
      // user: {
      mobile: personalForm?.mobile,
      mobile_verified: personalForm?.mobile_verified[0]?.id,
      email: personalForm?.email,
      email_verified: personalForm?.email_verified[0]?.id,
      // },
    };

    try {
      Validate(personalForm)
        .then(async (response) => {
          if (response === "success") {
            const response = await token_api.patch(
              `users/profile/admin_dealer/${personalForm?.id}/`,
              data
            );
            if (response?.status === 403) {
              alert(response?.data?.error?.message);
              // setOpenDrawer(false);
            }
            if (response?.status !== 200) {
              throw new Error("Dealer details not updated!");
            }

            alert("Dealer Details Updated Successfully!");
            setFormErrors({});
            setOpenDrawer(false);
            setDrawerState("");
            getDealerDetails();
          }
        })
        .catch((error) => {
          setFormErrors(error);
        });
    } catch (error) {
      console.log(error, "divyaError");
    }
  };

  //Adding and Updating dealer address....

  const handleAddNewAddress = () => {
    setDrawerState("address");
    setOpenDrawer(true);
    setAddressData({
      ...addressData,
      id: null,
      area: "",
      area_in_acres: "",
      city: "",
      country: "",
      district: "",
      line_1: "",
      line_2: "",
      plot_no: "",
      line_kms: "",
      pincode: "",
      state: "",
      tehsil: "",
    });
    setSelectedBlock([]);
    setAddressErrors({});
    updLoc({ lat: 20.5937, lng: 78.9629 });
  };
  const handleEditAddress = (info, drawertype) => {
    setDrawerState(drawertype);
    setAddressData({
      ...addressData,
      id: info?.id,
      area: info?.area,
      area_in_acres: info?.area_in_acres,
      city: info?.city,
      country: info?.country,
      district: info?.district,
      line_1: info?.line_1,
      line_2: info?.line_2,
      plot_no: info?.plot_no,
      line_kms: info?.line_kms,
      pincode: info?.pincode,
      state: info?.state,
      tehsil: info?.tehsil,
    });
    updLoc((prev) => ({
      ...prev,
      lat: info?.lat,
      lng: info?.long,
    }));
    setSelectedBlock([info?.block]);
    setOpenDrawer(true);
    setAddressErrors({});
  };

  console.log(addressData, "hariAddressData");
  const handleDeleteAddress = (addressId) => {
    token_api
      .delete(`users/address/${addressId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Dealer address deleted successfully!");
          getDealerAddressList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePostPilotAddress = (e) => {
    e.preventDefault();
    const data = {
      user_id: userId,
      area: addressData?.area,
      area_in_acres: addressData?.area_in_acres,
      city: addressData?.city,
      country: addressData?.country,
      district: addressData?.district,
      line_1: addressData?.line_1,
      line_2: addressData?.line_2,
      plot_no: addressData?.plot_no,
      line_kms: addressData?.line_kms,
      pincode: addressData?.pincode,
      state: addressData?.state,
      tehsil: addressData?.tehsil,
      lat: loc?.lat,
      long: loc?.lng,
      ...(selectedBlock.length > 0 && {
        block_id: selectedBlock[0]?.id,
      }),
    };

    try {
      ValidateAddress(addressData, selectedBlock)
        .then(async (response) => {
          if (response === "success") {
            if (addressData?.id) {
              //PATCH API....
              token_api
                .patch(`users/address/${addressData?.id}/`, data)
                .then((response) => {
                  if (
                    response?.status === 200 ||
                    response?.status === 201 ||
                    response?.status === 204
                  ) {
                    alert("Dealer's Address Updated Successfully!");
                    setDrawerState("");
                    setOpenDrawer(false);
                    setAddressData({});
                    setAddressErrors({});
                    getDealerAddressList();
                    setSelectedBlock([]);
                  }
                });
            } else {
              //POST API....
              token_api.post(`users/admin/address/`, data).then((response) => {
                if (
                  response?.status === 200 ||
                  response?.status === 201 ||
                  response?.status === 204
                ) {
                  alert("Dealer's New Address Added Successfully!");
                  setDrawerState("");
                  setOpenDrawer(false);
                  setAddressData({});
                  setAddressErrors({});
                  getDealerAddressList();
                  setSelectedBlock([]);
                }
              });
            }
          }
        })
        .catch((error) => {
          setAddressErrors(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  console.log(dealerDetails, personalForm, "hariPersonalForm");
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>

        <CustomBreadCrumbs breadcrumbs={breadcrumbs} />
      </div>

      <div className={styles.topcards}>
        <div className={styles.leftCon}>
          <div>
            <div className={styles.userProfileIconCon}>
              <img
                src={
                  dealerDetails?.avatar
                    ? dealerDetails?.avatar
                    : dealerDetails?.default_avatar
                }
                style={{ width: "100%" }}
              />
              <img
                src={editIcon}
                className={styles.editIcon}
                onClick={handleOpenModel}
              />
            </div>
            <h3 style={{ textAlign: "center" }}>{dealerDetails?.full_name}</h3>
          </div>
          <div style={{ marginTop: "30px" }}>
            {tabData.map((tab, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    handleChangeTab(tab?.id);
                  }}
                  className={
                    tabValue === tab?.id ? styles.activeTab : styles.normalTab
                  }
                >
                  <img src={tab?.icon} />
                  <p>{tab?.title}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.rightCon}>
          {tabValue === 1 && (
            <DealerPersonalDetails
              dealer={dealerDetails}
              handleOpenDrawer={handleOpenDrawer}
            />
          )}
          {tabValue === 2 && (
            <DealerAddress
              dealerAddressList={dealerAddressList}
              handleDeleteAddress={handleDeleteAddress}
              handleEditAddress={handleEditAddress}
              handleAddNewAddress={handleAddNewAddress}
            />
          )}
          {tabValue === 3 && <DealerDocuments documents={dealerDetails} />}
          {tabValue === 4 && (
            <DealerServiceBookings dealerCode={dealerDetails?.dealer_code} />
          )}
        </div>
      </div>

      <DrawerComp
        // width="45%"
        open={openDrawer}
        onClose={handleCloseDrawer}
        anchor={"right"}
      >
        {drawerState === "profile" && (
          <DrawerContainer>
            <DrawerTitle
              text={"Update Dealer"}
              handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
            />
            <div style={{ marginTop: "10px" }}>
              <InputFields
                label="Dealer Name"
                name="full_name"
                value={personalForm?.full_name || ""}
                onChange={handleChange}
                error={formErrors?.full_name}
                helperText={formErrors?.full_name}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <InputFields
                label="Establishment Name"
                name="establishment_name"
                value={personalForm?.establishment_name || ""}
                onChange={handleChange}
                error={formErrors?.establishment_name}
                helperText={formErrors?.establishment_name}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <InputFields
                label="Mobile Number"
                name="mobile"
                type="number"
                value={personalForm?.mobile || ""}
                onChange={handleChange}
                error={formErrors?.mobile}
                helperText={formErrors?.mobile}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <CustomSelectChip
                multiple={false}
                label="Mobile Verification"
                name="mobile_verified"
                onChange={handleChange}
                onDelete={handleDeleteChipforDrawer}
                value={personalForm?.mobile_verified}
                listArray={verifiedList}
                error={formErrors?.mobile_verified}
                helperText={formErrors?.mobile_verified}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <InputFields
                label="Email"
                name="email"
                value={personalForm?.email || ""}
                onChange={handleChange}
                error={formErrors?.email}
                helperText={formErrors?.email}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <CustomSelectChip
                multiple={false}
                label="Email Verification"
                name="email_verified"
                onChange={handleChange}
                onDelete={handleDeleteChipforDrawer}
                value={personalForm?.email_verified}
                listArray={verifiedList}
                error={formErrors?.email_verified}
                helperText={formErrors?.email_verified}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <InputFields
                label="Dealer Code"
                name="dealer_code"
                value={personalForm?.dealer_code || ""}
                onChange={handleChange}
                error={formErrors?.dealer_code}
                helperText={formErrors?.dealer_code}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <CustomDatePicker
                label="Joining Date"
                value={personalForm?.joining_date}
                onChange={(newValue) =>
                  setPersonalForm({
                    ...personalForm,
                    joining_date: newValue,
                  })
                }
                error={formErrors?.joining_date}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <InputFields
                label="Total Target Acres"
                name="total_target_acres"
                type="number"
                value={personalForm?.total_target_acres || ""}
                onChange={handleChange}
                error={formErrors?.total_target_acres}
                helperText={formErrors?.total_target_acres}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <CustomSelectChip
                multiple={false}
                label="Gender"
                name="gender"
                onChange={handleChange}
                onDelete={handleDeleteChipforDrawer}
                value={personalForm?.gender}
                listArray={genderList}
                error={formErrors?.gender}
                helperText={formErrors?.gender}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <CustomSelectChip
                multiple={false}
                label="Status"
                name="status"
                onChange={handleChange}
                onDelete={handleDeleteChipforDrawer}
                value={personalForm?.status}
                listArray={statusList}
                error={formErrors?.status}
                helperText={formErrors?.status}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <Button variant="contained" onClick={handleUpdateDealer}>
                Update
              </Button>
            </div>
          </DrawerContainer>
        )}

        {drawerState === "address" && (
          <DrawerContainer style={{ marginTop: "0px", marginBottom: "10px" }}>
            <DrawerTitle
              text={addressData?.id ? "Edit Address" : "Add Address"}
              handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
            />

            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <MyMap
                  width="30vw"
                  height="40vh"
                  search={addressData?.pincode}
                  loc={loc}
                  updLoc={updLoc}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Pincode"
                name="pincode"
                type="number"
                value={addressData?.pincode}
                onChange={handleChangeAddress}
                error={addressErrors?.pincode}
                helperText={addressErrors?.pincode}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <AddressBlock
                value={selectedBlock}
                onChange={handleAutoCompleteChangeForBlock}
              />
              {addressErrors?.selectedBlock && (
                <p style={{ color: "red" }}>{addressErrors?.selectedBlock}</p>
              )}
            </div>

            <div className={styles.inputContainer}>
              <InputFields
                label="Line 1"
                name="line_1"
                value={addressData?.line_1}
                onChange={handleChangeAddress}
                error={addressErrors?.line_1}
                helperText={addressErrors?.line_1}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Line 2"
                name="line_2"
                value={addressData?.line_2}
                onChange={handleChangeAddress}
                error={addressErrors?.line_1}
                helperText={addressErrors?.line_1}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Area"
                name="area"
                value={addressData?.area}
                onChange={handleChangeAddress}
                error={addressErrors?.area}
                helperText={addressErrors?.area}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Area In Acres"
                name="area_in_acres"
                type="number"
                value={addressData?.area_in_acres}
                onChange={handleChangeAddress}
                error={addressErrors?.area_in_acres}
                helperText={addressErrors?.area_in_acres}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="City"
                name="city"
                value={addressData?.city}
                onChange={handleChangeAddress}
                error={addressErrors?.city}
                helperText={addressErrors?.city}
              />
            </div>

            <div className={styles.inputContainer}>
              <InputFields
                label="Plot No."
                name="plot_no"
                value={addressData?.plot_no}
                onChange={handleChangeAddress}
                error={addressErrors?.plot_no}
                helperText={addressErrors?.plot_no}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Line In Kms"
                name="line_kms"
                type="number"
                value={addressData?.line_kms}
                onChange={handleChangeAddress}
                error={addressErrors?.line_kms}
                helperText={addressErrors?.line_kms}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Tehsil"
                name="tehsil"
                value={addressData?.tehsil}
                onChange={handleChangeAddress}
                error={addressErrors?.tehsil}
                helperText={addressErrors?.tehsil}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="District"
                name="district"
                value={addressData?.district}
                onChange={handleChangeAddress}
                error={addressErrors?.district}
                helperText={addressErrors?.district}
              />
            </div>

            <div className={styles.inputContainer}>
              <InputFields
                label="State"
                name="state"
                value={addressData?.state}
                onChange={handleChangeAddress}
                error={addressErrors?.state}
                helperText={addressErrors?.state}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Country"
                name="country"
                value={addressData?.country}
                onChange={handleChangeAddress}
                error={addressErrors?.country}
                helperText={addressErrors?.country}
              />
            </div>

            <div className={styles.inputContainer}>
              <Button
                variant="contained"
                // onClick={() => handleOpenModal(item)}
                onClick={handlePostPilotAddress}
                // style={{ maxWidth: "170px" }}
              >
                {addressData?.id ? "Update Address" : "Add Address"}
              </Button>
            </div>
          </DrawerContainer>
        )}
      </DrawerComp>

      <CustomModal open={isModalOpen} onClose={handleCloseModal}>
        <EditDealerpicModel
          user={dealerDetails}
          userFetchDatanew={getDealerDetails}
          handleCloseModal={handleCloseModal}
        />
      </CustomModal>
    </Container>
  );
};

export default DealerDetails;

const tabData = [
  {
    id: 1,
    title: "Personal Details",
    icon: ddPersonal,
  },

  {
    id: 2,
    title: "Addresses",
    icon: ddAddresses,
  },
  {
    id: 3,
    title: "Documents",
    icon: orangeDocument,
  },

  {
    id: 4,
    title: "Service Bookings",
    icon: orangeServiceBooking,
  },
];

const genderList = [
  {
    id: 1,
    title: "Male",
  },
  {
    id: 2,
    title: "Female",
  },
];

const statusList = [
  {
    id: true,
    title: "Active",
  },
  {
    id: false,
    title: "Inactive",
  },
];

const verifiedList = [
  {
    id: true,
    title: "Verified",
  },
  {
    id: false,
    title: "Not Verified",
  },
];
