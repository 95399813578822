import { Box, useTheme } from "@mui/material";
import React from "react";
import cancelIcon from "../../Assets/cancel.png";

const DrawerTitle = ({ handleCancelDrw, text }) => {
  const theme = useTheme();
  const {
    palette: { primary },
  } = theme;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        color: primary[500],
      }}
    >
      <div
        style={{ fontSize: "22px", fontWeight: "500", lineHeight: "26.4px" }}
      >
        {text}
      </div>
      <div onClick={handleCancelDrw} style={{ cursor: "pointer" }}>
        <img src={cancelIcon} alt="cancel" />
      </div>
    </Box>
  );
};

export default DrawerTitle;
