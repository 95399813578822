import React from "react";

const ServiceBookingCountCard = ({
  cardTitle = "Service Request",
  cardIcon,
  primaryGardient,
  secondaryGradient,
  value,
  hint,
  amount_hint,
  acres_hint,
  countvalue,
  eclipseIcon,
  present = false,
}) => {
  return (
    <div
      className=""
      style={{
        borderRadius: "10px",
        flex: "1",
        background: primaryGardient,
        position: "relative",
      }}
    >
      <div
        style={{
          borderRadius: "0px 30px 10px 10px",
          background: secondaryGradient,
          marginTop: "25px",
          padding: "0px 15px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // paddingLeft: "12px",
            width: "100%",
          }}
        >
          {/* <div>
            <img src={cardIcon} alt="icon" />
          </div> */}
          <div
            style={{
              //   marginLeft: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                borderBottom: "5px solid white",
                paddingBottom: "10px",
                gap: "20px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderRight: "5px solid white",
                  paddingRight: "40px",
                  boxSizing: "border-box",
                  //   alignItems: "center",
                }}
              >
                {present && (
                  <h1
                    style={{
                      //   marginBottom: "8px",
                      color: "white",
                      fontSize: "26px",
                      fontWeight: "700",
                    }}
                  >
                    {countvalue[hint]}
                  </h1>
                )}
                <p
                  style={{
                    marginTop: "0px",
                    color: "white",
                    fontWeight: "700",
                    fontSize: "19px",
                  }}
                >
                  {cardTitle}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  //   alignItems: "center",
                }}
              >
                {present && (
                  <h1
                    style={{
                      //   marginBottom: "8px",
                      color: "white",
                      fontSize: "26px",
                      fontWeight: "700",
                    }}
                  >
                    {countvalue[acres_hint]}
                  </h1>
                )}
                <p
                  style={{
                    marginTop: "0px",
                    color: "white",
                    fontWeight: "700",
                    fontSize: "19px",
                  }}
                >
                  Acres{" "}
                </p>
              </div>
            </div>
            <div
              style={{ color: "white", fontSize: "30px", fontWeight: "700" }}
            >
              ₹ {countvalue[amount_hint]}
            </div>
          </div>
        </div>
      </div>
      <img
        src={eclipseIcon}
        style={{ position: "absolute", bottom: "0px", right: "0px" }}
      />
    </div>
  );
};

export default ServiceBookingCountCard;
