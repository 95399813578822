import { Drawer } from "@mui/material";
import React from "react";

function DrawerComp({
  children,
  width = "40%",
  anchor,
  open,
  onClose = () => {},
}) {
  return (
    <div>
      <Drawer
        anchor={anchor}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: width },
        }}
      >
        {children}
      </Drawer>
    </div>
  );
}

export default DrawerComp;
