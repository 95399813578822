import styled from "@emotion/styled";
import { Box } from "@mui/material";

const DrawerContainer = styled(Box)({
  padding: "30px 35px",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  rowGap: "5px",
  // border: "1px solid red",
  marginTop: "64px",
});

export default DrawerContainer;
