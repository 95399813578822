import React, { useEffect, useRef, useState } from "react";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import styles from "./CourseList.module.css";
import InputFields from "../../Component/InputFields/InputFields";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import TextEditor from "../../Component/TextEditor/TextEditor";
import { Button } from "@mui/material";
import CustomDate from "../../Component/CustomDate/CustomDate";
import DurationPicker from "../../Component/DurationPicker/DurationPicker";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { formatDate, handleKeyPress } from "../../Utils/helper";
import { token_api } from "../../Utils/network";
import { validateBatch } from "./validate";
import CustomizedSwitches from "../../Component/CustomSwitch/CustomSwitch";
import StartAndEndDateDropdown from "../../Component/StartAndEndDateDropdown/StartAndEndDateDropdown";
import delete_icon from "../../Assets/delete_icon.svg";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import dayjs from "dayjs";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";
import SearchInstructorAndDropDown from "../../Component/SearchInstructorAndDropDown/SearchInstructorAndDropDown";

const AddBatchForm = ({
  handleCancelDrw,
  setCourseForm,
  courseForm,
  batchObject,
  languageList,
  // locationList,
  editBatchIndex,
}) => {
  const editorRef = useRef(null);

  const [values, setValues] = useState([{ emiAmount: "", dueDate: "" }]);

  const [batchForm, setbatchForm] = useState({
    name: "",
    start_date: null,
    end_date: "",
    start_time: "",
    end_time: null,
    mrp: "",
    selling_price: "",
    total_seats: "",
    location: [],
    discount: "",
    days_of_weeks: [],
    mode: [],
    language: [],
    instructors: [],
    descriptions: "",
    flying_start_date: null,
    flying_end_date: null,
    flying_slot_id: [],
    zoom_link: "",
  });
  const [typedValue, setTypedValue] = useState("");
  const [locationList, setLocationList] = useState([]);

  const [isOfflineMode, setIsOfflineMode] = useState(false);
  const [switchValue, setSwitchValue] = useState(false);
  const [flyingSlotList, setFlyingSlotList] = useState([]);
  const [partPaymentSwitch, setPartPaymentSwitch] = useState(false);

  const [selectedInstructors, setSelectedInstructors] = useState([]);

  const handleSwitchChange = (newValue) => {
    setSwitchValue(newValue);
  };

  const handlePartPaymentSwitchChange = (newValue) => {
    setPartPaymentSwitch(newValue);
  };

  const [formErrors, setFormErrors] = useState({});
  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setbatchForm({
        ...batchForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setbatchForm({
        ...batchForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setbatchForm({
        ...batchForm,
        [name]: value,
      });
    }

    // const { name, target } = e.target;
  };
  const handleDeleteChip = (id, selectName) => {
    setbatchForm({
      ...batchForm,
      [selectName]: batchForm?.[selectName]?.filter((chip) => chip?.id !== id),
    });
    // (chips) => chips.filter((chip) => chip?.id !== id)
  };
  const [instructorList, setInstructorList] = useState([]);

  const handleAddBatch = () => {
    let isDescriptionsEmpty;

    if (editorRef.current) {
      const content = editorRef.current.getContent();
      batchForm.descriptions = content;

      // Set a boolean value based on whether the content is empty or not
      isDescriptionsEmpty = !content.trim();
    }

    // validate before adding batch

    // const copyBatch = batchForm;

    // copyBatch.mode = batchForm?.mode[0]?.id;
    // copyBatch.language = batchForm?.language?.map((item) => item?.id);
    // copyBatch.instructors = batchForm?.instructors?.map((item) => item?.id);
    // copyBatch.days = batchForm?.days?.map((item) => item?.id);
    // console.log("BATCHEDITORLOG", copyBatch, batchForm);

    validateBatch(batchForm, isOfflineMode, switchValue, isDescriptionsEmpty)
      .then((res) => {
        if (res === "success") {
          // after validations success , 3 situations
          // 1-new courses new batches - no api call
          // 2-present course  new batch - add batch api
          // 3-presnt course batch edit - batch patch api
          if (batchObject?.id && courseForm?.id) {
            // update by batch patch api callss
            // hit patch api , 3 situation
            const language_id = batchForm?.language[0]?.id;
            const location_id = batchForm?.location[0]?.id;
            const instructor = batchForm?.instructors?.map((item) => item?.id);
            const days_of_weeks_process = batchForm?.days_of_weeks?.map(
              (item) => item?.id
            );
            const con_start_date = formatDate(batchForm?.start_date);
            const con_end_date = formatDate(batchForm?.end_date);
            const fly_start_date = formatDate(batchForm?.flying_start_date);
            const fly_end_date = formatDate(batchForm?.flying_end_date);

            let updatedBatchForm;

            if (isOfflineMode) {
              if (switchValue) {
                const {
                  start_date,
                  end_date,
                  flying_start_date,
                  flying_end_date,
                  flying_slot_id,
                  instructors,
                  days_of_weeks,
                  ...rest
                } = batchForm;
                updatedBatchForm = {
                  ...rest,
                  flying_start_date: fly_start_date,
                  flying_end_date: fly_end_date,
                  flying_slot_id: batchForm?.flying_slot_id[0]?.id,
                };
              } else {
                const {
                  start_date,
                  end_date,
                  flying_slot_id,
                  flying_start_date,
                  flying_end_date,
                  instructors,
                  days_of_weeks,
                  ...rest
                } = batchForm;
                updatedBatchForm = {
                  ...rest,
                  flying_slot_id: batchForm?.flying_slot_id[0]?.id,
                };
              }
            } else {
              const {
                start_date,
                end_date,
                flying_start_date,
                flying_end_date,
                flying_slot_id,
                instructors,
                days_of_weeks,
                ...restBatchForm
              } = batchForm;
              updatedBatchForm = restBatchForm;
            }

            token_api
              .patch(`course/batches/${batchObject?.id}/`, {
                ...updatedBatchForm,
                days_of_week: days_of_weeks_process,
                mode: updatedBatchForm?.mode[0]?.id,
                course: courseForm?.id,
                language_id,
                instructor_id: instructor,
                start_date: con_start_date,
                end_date: con_end_date,
                ...(updatedBatchForm?.mode?.[0]?.id !== 1 && {
                  location_id: location_id,
                }),
                ...(updatedBatchForm?.mode?.[0]?.id !== 2 && {
                  zoom_link: batchForm.zoom_link,
                }),
              })
              .then((response) => {
                setCourseForm((prev) => {
                  let batchCopy = prev?.batches;
                  batchCopy[editBatchIndex] = response?.data?.data;


                  let newState = { ...prev, batches: batchCopy };
                  return newState;
                });
                handleCancelDrw();
              })
              .catch((err) => {});
          } else if (courseForm?.id && !batchObject?.id) {
            // batch add logic
            // 2- situation - add batch api
            const language_id = batchForm?.language[0]?.id;
            const location_id = batchForm?.location[0]?.id;
            const instructor =
              batchForm?.instructors.length > 0
                ? batchForm?.instructors?.map((item) => item?.id)
                : [];
            const days_of_weeks_process = batchForm?.days_of_weeks?.map(
              (item) => item?.id
            );

            const con_start_date = formatDate(batchForm?.start_date);
            const con_end_date = formatDate(batchForm?.end_date);
            const fly_start_date = formatDate(batchForm?.flying_start_date);
            const fly_end_date = formatDate(batchForm?.flying_end_date);

            let updatedBatchForm;

            if (isOfflineMode) {
              if (switchValue) {
                const {
                  start_date,
                  end_date,
                  flying_start_date,
                  flying_end_date,
                  flying_slot_id,
                  instructors,
                  days_of_weeks,
                  ...rest
                } = batchForm;
                updatedBatchForm = {
                  ...rest,
                  flying_start_date: fly_start_date,
                  flying_end_date: fly_end_date,
                };
              } else {
                const {
                  start_date,
                  end_date,
                  flying_slot_id,
                  flying_start_date,
                  flying_end_date,
                  instructors,
                  days_of_weeks,
                  ...rest
                } = batchForm;
                updatedBatchForm = {
                  ...rest,
                  flying_slot_id: batchForm?.flying_slot_id[0]?.id,
                };
              }
            } else {
              const {
                start_date,
                end_date,
                flying_start_date,
                flying_end_date,
                flying_slot_id,
                instructors,
                days_of_weeks,
                ...restBatchForm
              } = batchForm;
              updatedBatchForm = restBatchForm;
            }


            token_api
              .post(`course/batches/`, {
                ...updatedBatchForm,
                course: courseForm?.id,
                mode: updatedBatchForm?.mode?.[0]?.id,
                language_id: language_id,
                instructor_id: instructor,
                days_of_week: days_of_weeks_process,
                start_date: con_start_date,
                end_date: con_end_date,
                ...(updatedBatchForm?.mode?.[0]?.id !== 1 && {
                  location_id: location_id,
                }),
                ...(updatedBatchForm?.mode?.[0]?.id !== 2 && {
                  zoom_link: batchForm.zoom_link,
                }),
              })
              .then((response) => {
                courseForm.batches.push(response?.data?.data);

                handleCancelDrw();
              })
              .catch((err) => {});
          } else if (!editBatchIndex && editBatchIndex !== 0) {
            courseForm.batches.push(batchForm);
            setCourseForm(courseForm);
            handleCancelDrw();
          } else {
            if (editBatchIndex || editBatchIndex === 0) {
              setCourseForm((prev) => {
                let batchCopy = prev?.batches;
                batchCopy[editBatchIndex] = batchForm;

                let newState = { ...prev, batches: batchCopy };
                return newState;
              });
              handleCancelDrw();
            }
          }

          // courseForm.batches.push(batchForm);
          // setCourseForm(courseForm);
        }
      })
      .catch((err) => {
        console.log(err, "iamurerror");
        setFormErrors(err);
      });
  };


  // const getInstructors = () => {
  //   token_api
  //     .get(`course/instructor?page_size=100`)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         const modifiedList = response?.data?.data?.map((item) => {
  //           return {
  //             id: item?.id,
  //             title: item?.name,
  //           };
  //         });
  //         setInstructorList(modifiedList);
  //       }
  //     })
  //     .catch((err) => {});
  // };

  const [courseDaysList, setCourseDaysList] = useState([]);
  const getCourseDays = () => {
    token_api
      .get(`course/days`)
      .then((response) => {
        if (response.status === 200) {
          const modifiedList = response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              title: item?.weekday,
            };
          });
          setCourseDaysList(modifiedList);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    // getInstructors();
    getCourseDays();
  }, []);

  useEffect(() => {
    if (batchObject) {
      const {
        name,
        start_date,
        start_time,
        end_time,
        end_date,
        descriptions,
        mrp,
        selling_price,
        discount,
        days_of_weeks,
        language,
        mode,
        instructors,
        total_seats,
        location,
        slots,
        instructor,
        zoom_link,
      } = batchObject;
      let modifiedMode = "";
      let modifiedLanguage = "";
      let modifiedInstructors = [];
      let modifiedLocation = "";
      if (mode && mode[0]) {
        modifiedMode = [mode[0]];
      } else {
        modifiedMode = modeArray.filter((item) => item?.id === mode);
      }

      if (language && language[0]) {
        modifiedLanguage = [language[0]];
      } else {
        modifiedLanguage = languageList.filter(
          (item) => item?.id === language?.id
        );
      }
      // if (location && location[0]) {
      //   modifiedLocation = [location[0]];
      // }
      if (location) {
        modifiedLocation = {
          id: location?.id,
          title: `${location?.line_1}, ${location?.line_2}, ${location?.area}, ${location?.city_new}, ${location?.state} ,${location?.country}`,
        };
      } else {
        modifiedLocation = locationList.filter(
          (item) => item?.id === location?.id
        );
      }

      if (instructors && instructors[0]) {
        modifiedInstructors = instructors?.map((item) => {
          return {
            id: item?.id,
            name: item?.name,
            qualification: item?.qualification,
            experience: item?.experience,
          };
        });
      }


      setbatchForm({
        ...batchForm,
        name,
        start_date: dayjs(start_date),
        end_date: dayjs(end_date),
        start_time,
        end_time,
        descriptions,
        mrp,
        selling_price,
        discount,
        days_of_weeks: days_of_weeks?.map((item) => {
          return { id: item?.id, title: item?.weekday || item?.title };
        }),
        language: modifiedLanguage,
        mode: modifiedMode,
        instructors: modifiedInstructors,
        total_seats: total_seats,
        location: [modifiedLocation],
        flying_slot_id: slots || [],
        zoom_link,
      });
    }
  }, [batchObject]);

  useEffect(() => {
    const isOffline = batchForm.mode.length === 1 && batchForm.mode[0].id === 2;
    setIsOfflineMode(isOffline);
  }, [batchForm.mode]);

  useEffect(() => {
    // Ensure flying_start_date is not before start_date
    if (batchForm.flying_start_date && batchForm.start_date) {
      const startDate = formatDate(batchForm.start_date);
      const flyingStartDate = formatDate(batchForm.flying_start_date);

      if (flyingStartDate < startDate) {
        setbatchForm((prevBatchForm) => ({
          ...prevBatchForm,
          flying_start_date: dayjs(batchForm.start_date),
        }));
      }
    }

    // Ensure flying_end_date is not after end_date
    if (batchForm.flying_end_date && batchForm.end_date) {
      const endDate = formatDate(batchForm.end_date);
      const flyingEndDate = formatDate(batchForm.flying_end_date);

      if (flyingEndDate > endDate) {
        setbatchForm((prevBatchForm) => ({
          ...prevBatchForm,
          flying_end_date: dayjs(batchForm.end_date),
        }));
      }
    }
  }, [
    batchForm.start_date,
    batchForm.end_date,
    batchForm.flying_start_date,
    batchForm.flying_end_date,
  ]);


  function getFlyingSlotsforOffiline() {

    const startdate = formatDate(batchForm?.start_date);
    const enddate = formatDate(batchForm?.end_date);

    token_api
      .get(`/course/flying_slots?start_date=${startdate}&end_date=${enddate}`)
      .then((response) => {
        setFlyingSlotList(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (isOfflineMode && batchForm?.start_date && batchForm?.end_date) {
      getFlyingSlotsforOffiline();
    }
  }, [isOfflineMode, batchForm?.start_date, batchForm?.end_date]);


  // const handleAdd = () => {
  //   // Initialize the new input with empty values
  //   const updatedValues = [...values, { emiAmount: "", dueDate: "" }];
  //   setValues(updatedValues);
  // };

  const handleChangeInput = (onChangeValue, index, field) => {
    const updatedValues = [...values];
    updatedValues[index][field] = onChangeValue.target.value;
    setValues(updatedValues);
  };

  const handleDelete = (index) => {
    const updatedValues = [...values];
    updatedValues.splice(index, 1);
    setValues(updatedValues);
  };

  const handleAddNewSet = () => {
    setValues([...values, { emiAmount: "", dueDate: "" }]);
  };


  const handleChangeforSearch = (event, newValue) => {
    setTypedValue(newValue);
    setbatchForm((prev) => ({
      ...prev,

      location: [newValue],
    }));
  };

  const getLocationList = (value) => {
    let apiEndpoint = "/course/location";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          let userloc = res.data.data?.map((info) => ({
            id: info?.id,
            title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
          }));
          setLocationList(userloc);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getLocationList(typedValue);
      }, 500);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);


  const handleAutocompleteChangeforInstructor = (selectedValues) => {
    const newItemId = selectedValues[selectedValues?.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Instructor already included");
    } else {
      setbatchForm({
        ...batchForm,
        instructors: selectedValues,
      });
    }
  };

  return (
    <DrawerContainer style={{ marginTop: "0px", marginBottom: "10px" }}>
      <DrawerTitle text={`Add Batch`} handleCancelDrw={handleCancelDrw} />

      <div
        style={{ marginTop: "10px", rowGap: "15px" }}
        className={styles.commonFieldsBox}
      >
        <InputFields
          label="Batch Name"
          required
          name="name"
          value={batchForm?.name}
          onChange={handleChange}
          error={formErrors?.name}
          helperText={formErrors?.name}
        />
        <CustomSelectChip
          multiple="true"
          label="Language"
          name="language"
          onChange={handleChange}
          onDelete={handleDeleteChip}
          value={batchForm?.language}
          listArray={languageList}
          error={formErrors?.language}
        />
        <CustomSelectChip
          multiple={false}
          label="Mode"
          name="mode"
          onChange={handleChange}
          onDelete={handleDeleteChip}
          value={batchForm?.mode}
          listArray={modeArray}
          error={formErrors?.mode}
        />
        <CustomSelectChip
          multiple="true"
          label="Days"
          name="days_of_weeks"
          onChange={handleChange}
          onDelete={handleDeleteChip}
          value={batchForm?.days_of_weeks}
          listArray={courseDaysList}
          error={formErrors?.days_of_weeks}
        />

        {/* <CustomDate
          label="Start Date"
          handleChange={handleChange}
          name="start_date"
          value={batchForm?.start_date}
          error={formErrors?.start_date}
          helperText={formErrors?.start_date ? "Enter batch start date" : ""}
        />
        <CustomDate
          label="End Date"
          handleChange={handleChange}
          name="end_date"
          value={batchForm?.end_date}
          error={formErrors?.end_date}
          helperText={formErrors?.end_date ? "Enter batch end date" : ""}
        /> */}

        <CustomDatePicker
          label="Start date"
          value={batchForm?.start_date}
          onChange={(newValue) =>
            setbatchForm({
              ...batchForm,
              start_date: newValue,
            })
          }
          error={formErrors?.start_date}
        />

        <CustomDatePicker
          label="End date"
          value={batchForm?.end_date}
          onChange={(newValue) =>
            setbatchForm({
              ...batchForm,
              end_date: newValue,
            })
          }
          error={formErrors?.end_date}
        />

        <DurationPicker
          style={{ width: "100%" }}
          label="Start Time"
          size="small"
          name="start_time"
          onChange={handleChange}
          value={batchForm?.start_time}
          error={formErrors?.start_time}
          // required
          // error={startTimeCheck}
          helperText={formErrors?.start_time ? "Enter start time" : ""}
        />
        <DurationPicker
          style={{ width: "100%" }}
          label="End Time"
          size="small"
          name="end_time"
          onChange={handleChange}
          value={batchForm?.end_time}
          error={formErrors?.end_time}
          // required
          // error={startTimeCheck}
          helperText={formErrors?.end_time ? "Enter end time" : ""}
        />
        {/* <CustomSelectChip
          multiple="true"
          label="Instructors"
          name="instructors"
          onChange={handleChange}
          onDelete={handleDeleteChip}
          value={batchForm?.instructors}
          listArray={instructorList}
        /> */}
        <SearchInstructorAndDropDown
          value={batchForm?.instructors}
          onChange={handleAutocompleteChangeforInstructor}
          error={formErrors?.instructors}
        />
        {/* {formErrors?.instructors && (
          <span style={{ color: "red" }}>Please select instructor.</span>
        )} */}
        <InputFields
          label="Total Seats"
          required
          name="total_seats"
          value={batchForm?.total_seats}
          onChange={handleChange}
          error={formErrors?.total_seats}
          helperText={formErrors?.total_seats}
        />
        {batchForm?.mode[0]?.id != 1 && (
          // <CustomSelectChip
          //   multiple={false}
          //   label="Location"
          //   name="location"
          //   onChange={handleChange}
          //   onDelete={handleDeleteChip}
          //   value={batchForm?.location}
          //   listArray={locationList}
          //   error={formErrors?.location}
          // />
          <SearchAndDropdown
            label="Filter by Location"
            value={batchForm?.location[0]}
            onChange={handleChangeforSearch}
            listing={locationList}
            typedValue={typedValue}
            setTypedValue={setTypedValue}
          />
        )}

        {isOfflineMode && (
          <CustomizedSwitches
            initialValue={switchValue}
            label="Create New Flying Slot for this Batch."
            onChange={handleSwitchChange}
          />
        )}

        {isOfflineMode && switchValue && (
          <>
            {/* <CustomDate
              label="Start Date"
              handleChange={handleChange}
              name="flying_start_date"
              value={batchForm?.flying_start_date}
            />
            <CustomDate
              label="End Date"
              handleChange={handleChange}
              name="flying_end_date"
              value={batchForm?.flying_end_date}
            /> */}

            <CustomDatePicker
              label="Flying start date"
              value={batchForm?.flying_start_date}
              onChange={(newValue) =>
                setbatchForm({
                  ...batchForm,
                  flying_start_date: newValue,
                })
              }
              error={formErrors?.flying_start_date}
            />
            <CustomDatePicker
              label="Flying end date"
              value={batchForm?.flying_end_date}
              onChange={(newValue) =>
                setbatchForm({
                  ...batchForm,
                  flying_end_date: newValue,
                })
              }
              error={formErrors?.flying_end_date}
            />
          </>
        )}

        {isOfflineMode &&
          !switchValue &&
          batchForm?.start_date &&
          batchForm?.end_date && (
            <StartAndEndDateDropdown
              multiple={false}
              label="Select Flying Class Slot"
              name="flying_slot_id"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={batchForm?.flying_slot_id}
              listArray={flyingSlotList}
              error={formErrors?.flying_slot_id}
            />
          )}
        {/* <InputFields
          label=""
          required
          name="name"
          value={batchForm?.name}
          onChange={handleChange}
        /> */}
        {/* <InputFields
          label="Batch Name"
          required
          name="name"
          value={batchForm?.name}
          onChange={handleChange}
        /> */}
      </div>

      <div style={{ marginTop: "10px" }}>
        <TextEditor
          editorRef={editorRef}
          initialValue={batchForm?.descriptions}
          height={300}
        />

        <small style={{ marginTop: "5px", color: "red" }}>
          {formErrors?.descriptions &&
            "Please fill the descriptions for the batch"}
        </small>
      </div>

      <div style={{ marginTop: "10px" }} className={styles.commonFieldsBox}>
        <InputFields
          onKeyPress={handleKeyPress}
          label="Selling Price"
          required
          name="selling_price"
          value={batchForm?.selling_price}
          onChange={handleChange}
          error={formErrors?.selling_price}
          helperText={formErrors?.selling_price}
        />
        <InputFields
          onKeyPress={handleKeyPress}
          label="MRP"
          required
          name="mrp"
          value={batchForm?.mrp}
          onChange={handleChange}
          error={formErrors?.mrp}
          helperText={formErrors?.mrp}
        />
        <InputFields
          onKeyPress={handleKeyPress}
          label="Discount"
          name="discount"
          value={batchForm?.discount}
          onChange={handleChange}
        />
        {batchForm?.mode[0]?.id != 2 && (
          <InputFields
            label="Zoom link"
            required
            name="zoom_link"
            value={batchForm?.zoom_link}
            onChange={handleChange}
            error={formErrors?.zoom_link}
            helperText={formErrors?.zoom_link}
          />
        )}
      </div>

      {/* paste here the commented code  */}
      <div style={{ marginTop: "10px" }}>
        <Button
          variant="contained"
          className={`${styles.gradientColor}`}
          onClick={handleAddBatch}
        >
          Save
        </Button>
      </div>
    </DrawerContainer>
  );
};

export default AddBatchForm;

const createCourse = {
  course_id: "1",
  languages_id: [], //multiple
  status: "boolean",
  duration: "text",
  seats: 3,
  location_id: [], // multiple
  description: "<p>Description<p/>", //rich text editor field
  course_includes: [{}],
  course_excludes: [{}],
  pre_requisite: "<p>pre_requi<p/>", //rich text editor field
  batches: [],
  course_banners: [], //multiple
  course_videos: [], //multiple
};

const batches = [
  {
    title: "",
    languages_id: [], //multiple
    mode: "boolean",
    days_id: [], //multiple
    start_date: "Date",
    end_date: "Date",
    start_time: "time",
    end_time: "time",
    instructor_id: [],
    description: "", // rich text field
    selling_price: 4000,
    mrp: 4000,
    discount_type: 1,
    discount: 4000,
  },
];

const chipData = [
  {
    id: 1,
    title: "Data 1",
  },
  {
    id: 2,
    title: "Data 2",
  },
  {
    id: 3,
    title: "Data 3",
  },
];

const modeArray = [
  {
    id: 1,
    title: "Online",
  },
  {
    id: 2,
    title: "Offline",
  },
  {
    id: 3,
    title: "Hybrid",
  },
];
