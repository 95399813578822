import { Pagination } from "@mui/material";
import React from "react";

const CustomPagination = ({ setPageState, max_pages = 1 }) => {
  //   const [page, setPage] = React.useState(1);
  return (
    <div>
      <Pagination
        count={max_pages}
        shape="rounded"
        onChange={(e, value) => setPageState(value)}
      />
    </div>
  );
};

export default CustomPagination;
