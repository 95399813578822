import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";

const CustomDatePicker = ({ label, value, onChange, name, error }) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      style={{ width: "100%", height: "60px" }}
    >
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        format="DD/MM/YYYY" //Initially MM//DD//YYYY
        name={name}
        // slotProps={{ textField: { fullWidth: true } }}
        slotProps={{
          textField: {
            helperText: error,
            error: error,
            fullWidth: true,
          },
        }}
        style={{ width: "100%" }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
