export const Validate = (value) => {
  return new Promise((resolve, reject) => {
    const { charger_id, serial_no, drone_inventory_id, status } = value;

    const errors = { error: false };

    if (!serial_no) {
      errors.serial_no = "Enter serial number";
      errors.error = true;
    }

    if (!charger_id || Object.keys(charger_id).length === 0) {
      errors.charger_id = "Select charger";
      errors.error = true;
    }
    if (!drone_inventory_id || Object.keys(drone_inventory_id).length === 0) {
      errors.drone_inventory_id = "Select drone inventory";
      errors.error = true;
    }

    if (!status || Object.keys(status).length === 0) {
      errors.status = "status is required";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
