import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  access_token: JSON.parse(localStorage.getItem("dd_admin_token")) || null,
  refresh_token: null,
  isLoading: false,
  isError: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state, action) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      console.log("FInalValue", action);
      state.access_token = action?.payload?.access;
      state.refresh_token = action?.payload?.refresh;
      state.user = action?.payload?.user;
      state.isLoading = false;
      localStorage.setItem(
        "dd_admin_token",
        JSON.stringify(action?.payload?.access) || null
      );
    },
    loginFailed: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { loginStart, loginSuccess, loginFailed } = authSlice.actions;
export default authSlice.reducer;
