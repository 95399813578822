import React, { useContext, useState } from "react";
import styles from "./SlideOutPanel.module.css";
import ControlledCheckbox from "../ControlledCheckBox/ControlledCheckBox";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { showSideData } from "../../Utils/constants";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

const SlideOutPanel = () => {
  const { setCheckMapData } = useContext(UserCredsContext);
  const [data, setData] = useState(showSideData);
  const handleCheck = (_boolean, index) => {
    let spreadData = [...data];
    spreadData[index].checked = _boolean;
    setData(spreadData);
    setCheckMapData(spreadData);
  };
  return (
    <div className={styles.containerClass}>
      {data.map((item, index) => {
        return (
          <div className={styles.rowListing} key={index}>
            <div>
              <ControlledCheckbox
                isChecked={item.checked}
                index={index}
                handleCheck={handleCheck}
              />
            </div>
            <div>
              {item.id === "calendar" ? (
                <CalendarMonthIcon />
              ) : (
                <img
                  src={item.icon}
                  className={styles.iconClass}
                  style={item.style}
                />
              )}
            </div>
            <div className={styles.titleClass}>{item.title}</div>
          </div>
        );
      })}
    </div>
  );
};

export default SlideOutPanel;
