import React, { useEffect, useState } from "react";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import BookingDetailsCard from "../../Component/BookingDetailsCard/BookingDetailsCard";
import TitleSubTitleVertical from "../../Component/TitleSubTitleVertical/TitleSubTitleVertical";
import servBookMap from "../../Assets/servBookMap.png";
import ServiceHistory from "../../Component/ServiceHistory/ServiceHistory";
import OtherServiceDetails from "./OtherServiceDetails";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  convertTo12HourFormat,
  extractDateTime,
  getEstimateDataForView,
  helperdayMonthYearFull,
  setServiceLocationHelper,
} from "../../Utils/helper";
import AddServiceCard from "../../Component/AddServiceCard/AddServiceCard";
import CustomAutoComplete from "../../Component/CustomAutoComplete/CustomAutoComplete";
import styles from "./servicebooking.module.css";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setOtherServiceDetailsForView } from "../../state/reducers/serviceBookingSlice";
import { token_api } from "../../Utils/network";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import useFetchData from "./useFetchData";
import ServiceDropdown from "../../Component/ServiceDropdown/ServiceDropdown";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import CustomerDetails from "../../Component/CustomerDetails/CustomerDetails";
import UserOtherServiceDetails from "../../Component/UserOtherServiceDetails/UserOtherServiceDetails";
import CustomModal from "../../Component/CustomModal/CustomModal";
import DronesDropDown from "../../Component/ServiceDropdown/DronesDropDown";
import PilotDropDown from "../../Component/ServiceDropdown/PilotDropDown";
import CustomButton from "../../Component/CustomButton/CustomButton";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import paymentIcon from "../../Assets/paymentIcon.svg";
import MyMap from "../../Component/MyMap/MyMap";
import noTransaction from "../../Assets/noTransactions.svg";
const ServiceBookingDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [serviceData, setServiceData] = useState({});
  const [open, setOpen] = useState(false);
  const servBookReduxDetails = useSelector((state) => state?.serviceBooking);
  const drones = useSelector((state) => state?.drone);
  const { otherServiceView } = servBookReduxDetails;
  console.log("REduxDetails", drones);
  const locationState = useLocation();
  const [bookingDetails, setBookingDetails] = useState({});
  const [selectedDrones, setSelectedDrones] = useState([[]]);
  const [ifestimateGenerated, setIfestimateGenerated] = useState(false);
  const [estimateDetails, setEstimateDetails] = useState([]);
  const [droneListing, setDroneListing] = useState([]);
  const [typedValue, setTypedValue] = useState("");
  const [pilottypedValue, setPilotTypedValue] = useState("");

  const [pilotListing, setPilotListing] = useState([]);
  const [openTabNo, setopenTabNo] = useState(null);
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelData, setModelData] = useState(null);
  const [amountData, setamountData] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [pilotSelected, setPilotSelected] = useState([]);
  const [filters, setfilters] = useState({});
  const [firstIndexId, setFirstIndex] = useState(null);
  const [paymentData, setPaymentData] = useState([]);
  const [transactionData, setTransactiondData] = useState({});
  const [loc, updLoc] = useState({ lat: 20.5937, lng: 78.9629 });
  const [bookingStatus, setBookingStatus] = useState({
    status: [],
  });

  console.log(bookingDetails, otherServiceView, "hariOtherServiceNow");
  console.log("LOcationState", useParams());
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link
      to="/bookings/service-bookings"
      className="orange-1 bread breadElement"
    >
      Services
    </Link>,
    <div className="orange-1 bread">Service Booking Details</div>,
  ];

  const handleEstimate = (type) => {
    console.log("Redirect", type, bookingDetails);
    if (type === "create") {
      token_api
        .get(`service/booking/${id}`)
        .then((response) => {
          navigate(`/services/create-quotation`, {
            state: { serviceBookingDetails: response?.data?.data },
          });
        })
        .catch((err) => {});
    } else if ((type = "edit")) {
      navigate(`/services/edit-quotation`);
    }
  };

  const handleChangeSelect = (e, index) => {
    // const { name, value } = e.target;
    console.log("INININI", e, index, selectedDrones);

    // hit drone selection api, if api success then set the values

    setSelectedDrones((prev) => {
      let newState = [...prev];
      console.log("NEWSTATE", newState);

      if (index >= 0 && index < newState.length) {
        // Replace the value at the target index with the new value
        newState[index] = e;
      } else {
        // If the index doesn't exist, add the new value at the end
        newState.push(e);
      }

      return newState;
    });
  };

  const getEstimateDetails = async (bookingId) => {
    try {
      const fetchEstimate = await token_api.get(
        `/service/quotation/${bookingId}`
      );
      if (fetchEstimate?.status !== 200) {
        // throw new Exception("")
        return;
      }
      setEstimateDetails(fetchEstimate?.data?.data);

      setFirstIndex(fetchEstimate?.data?.data[0]?.id);
    } catch (error) {}
  };

  const getServiceBookingDetails = () => {
    token_api
      .get(`service/booking/${id}`)
      .then((response) => {
        const data = response.data.data;
        if (data) {
          console.log("DFDFFDFFDFD", data);

          const { address } = data;

          dispatch(setOtherServiceDetailsForView(data?.other_details));
          getEstimateDetails(data?.id);

          console.log(data?.start_date, "newdata");

          updLoc((prev) => ({
            ...prev,
            lat: address?.lat,
            lng: address?.long,
          }));
          setBookingDetails((prev) => ({
            ...prev,
            created_on: data?.created,
            booking_service: data?.id,
            full_name: data?.full_name || "N.A",
            contact_no: data?.contact_no || "N.A",
            // email: data?.user?.email || "N.A",
            email: data?.user_detail?.creator?.email || "N.A",
            gst: data?.gst,
            service_location: setServiceLocationHelper(address),
            pincode: address?.pincode,
            lat: address?.lat || "N.A",
            long: address?.long || "N.A",
            kml_file: "N.A",
            other_details: data?.other_details,
            start_date: data?.start_date,
            end_date: data?.end_date,
            status_str: data?.status_str,
            location: data?.address?.city,
            fulladdress: address,
            bookingaddonservice: data?.bookingaddonservice || [],
            bookingsservicedrone: data?.bookingsservicedrone || [],
            bookingsservicepilot: data?.bookingsservicepilot || [],
            bookingserviceproduct: data?.bookingserviceproduct || [],
            area_in_acres: data?.area_in_acres,
            line_kms: data?.line_kms,
            dealer_code: data?.dealer_code,
          }));

          let isverified = bookingStatusList?.filter(
            (info) => info?.id == data?.status
          );

          setBookingStatus({
            status: isverified,
          });

          setServiceData(data);

          if (data?.bookingsservicedrone.length > 0) {
            const combinedDroneInventory = [];

            data?.bookingsservicedrone.forEach((item) => {
              const { drone_inventory } = item;
              combinedDroneInventory.push(...drone_inventory);
            });

            setSelectedValues(combinedDroneInventory);
          }

          if (data?.bookingsservicepilot?.length > 0) {
            const combinedPilotInventory = [];

            data?.bookingsservicepilot.forEach((item) => {
              const { pilot } = item;
              combinedPilotInventory.push(...pilot);
            });

            setPilotSelected(combinedPilotInventory);
          }
        }
      })
      .catch((err) => {});
  };

  console.log(bookingStatus, "durga");

  const getPaymentData = () => {
    token_api
      .get(`payment/pay/?booking_service=${id}`)
      // .get(`payment/pay/?booking_service=216`)

      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setPaymentData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTransactionData = () => {
    token_api
      .get(`service/booking/transaction_history?booking_service=${id}`)
      // .get(`service/booking/transaction_history?booking_service=216`)

      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setTransactiondData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getServiceBookingDetails();
    getPaymentData();
    getTransactionData();
  }, []);

  const getList = () => {};

  const handleEditClick = (editdata, value) => {
    setOpen(editdata);
    setopenTabNo(value);
    getServiceBookingDetails();
  };

  const handleOpenModal = (item) => {
    setIsModalOpen(true);
    setModelData(item);

    const { amount, final_amount, flat_discount } = getEstimateDataForView(
      item?.breakdown_sequence
    );

    console.log(final_amount, "jshdfjsdhjs");

    setamountData({
      amount,
      final_amount,
      flat_discount,
    });
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePDFView = (item) => {
    console.log(item, "useritem");
    const { quotation_pdfs } = item;
    const quotefile = quotation_pdfs[0]?.quote_file;

    if (quotefile) {
      const pdfURL = `${quotefile}`;
      window.open(pdfURL, "_blank");
    }
  };

  const handleNotify = (item) => {
    const { id } = item;
    token_api
      .get(`/service/quotation/admin_estimate_notify?quotation=${id}`)
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAutocompleteChange = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("drone already included");
    } else {
      setSelectedValues(selectedValues);
    }
  };
  const handleAutocompleteChangeforPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setPilotSelected(selectedValues);
    }
  };

  const handleFilter = (e, type, selectName, multiple) => {
    console.log("HANDLERSS", e, type);
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleSubmitStatus = (item) => {
    console.log(item, "filterstats");

    let { id: estimateid } = item;
    let id =
      filters && filters.status && filters.status[0]
        ? filters.status[0].id
        : undefined;

    // Check if id is defined before proceeding
    if (id !== undefined) {
      const data = {
        quotation_id: estimateid,
        ...(id === 4 && { reason: "Changes made by admin" }),
        status: id,
      };

      token_api
        .post(`/service/quotation/estimate_approval/`, data)
        .then((response) => {
          if (
            response?.status === 201 ||
            response?.status === 200 ||
            response?.status === 204
          ) {
            setIsModalOpen(false);
            getServiceBookingDetails();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // console.error("ID is not defined. Unable to proceed.");
      alert("please select the status");
    }
  };

  console.log(bookingDetails?.other_details, "amountData");

  const handleAssign = async (e) => {
    e.preventDefault();

    const drones = selectedValues?.map((info) => info?.id);
    const pilot = pilotSelected?.map((info) => info?.id);

    const dronesdata = {
      booking_service: id,
      drone_inventory_id: drones,
    };

    const pilotdata = {
      booking_service: id,
      pilot_id: pilot,
    };

    if (drones?.length === 0 || pilot?.length === 0) {
      alert("Please select the drones and pilot");
      return;
    }

    try {
      // Use Promise.all to execute both requests concurrently
      const [pilotResponse, droneResponse] = await Promise.all([
        token_api.post(`/service/booking/pilot/`, pilotdata),
        token_api.post(`/service/booking/drone/`, dronesdata),
      ]);

      if (pilotResponse?.status === 201 && droneResponse?.status === 201) {
        alert("Successfully assigned!");
        setSelectedDrones([]);
        setSelectedDrones([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChangeBookingStats = (e, type, selectName, multiple) => {
    console.log("HANDLERSS", e, type);
    if (type === "select" && selectName && multiple) {
      setBookingStatus({
        ...bookingStatus,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setBookingStatus({
        ...bookingStatus,
        [selectName]: [e],
      });
    }
  };

  const handleDeleteBookingStatus = (id, selectName) => {
    setBookingStatus({
      ...bookingStatus,
      [selectName]: bookingStatus?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
  };

  const handleNavigateBack = () => {
    navigate(`/bookings/service-bookings`);
  };

  console.log(serviceData, bookingDetails, "divyabookingDetails");

  const handleChangeBookingStatus = () => {
    let data = {
      status: bookingStatus?.status[0]?.id,
    };

    token_api
      .patch(`service/admin_booking/${id}/`, data)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Service Booking Status Updated Successfully!");
          getServiceBookingDetails();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(bookingStatus, "hariFafafaffa");

  return (
    <Container>
      <div className={styles.breadCrumbsCon}>
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>
      <div className={styles.topContainer}>
        <div className={styles.topContainerLeft}>
          <div className={styles.leftFirstCon}>
            <h3>
              {" "}
              Service Booking ID :{" "}
              <span>
                {bookingDetails?.booking_service
                  ? bookingDetails?.booking_service
                  : "N.A"}
              </span>
            </h3>
            <p>
              Created On:{" "}
              {bookingDetails?.created_on
                ? helperdayMonthYearFull(bookingDetails?.created_on)
                : "N.A"}
            </p>
          </div>
          <h3>
            {bookingDetails?.bookingaddonservice
              ? bookingDetails?.bookingaddonservice[0]?.services?.title
              : "N.A"}
          </h3>
        </div>
        <div className={styles.topContainerRight}>
          {/* Service Booking Status :{" "} */}
          <CustomSelectChip
            multiple={false}
            label="Service Booking Status"
            name="status"
            listArray={bookingStatusList}
            onChange={handleChangeBookingStats}
            onDelete={handleDeleteBookingStatus}
            value={bookingStatus?.status}
          />
          <Button
            variant="contained"
            onClick={handleChangeBookingStatus}
            disabled={!bookingStatus?.status[0]?.id}
            style={{
              backgroundColor: bookingStatus?.status[0]?.id
                ? "linear-gradient( 90deg, rgb(255, 153, 8) 0%, rgb(237, 103, 31) 100.02% )"
                : "light-grey",
              color: "white",
            }}
          >
            Submit
          </Button>
          {/* <span style={{ cursor: "pointer" }}>
            {bookingDetails?.status_str}{" "}
          </span> */}
        </div>
      </div>
      {/* main details layout */}
      <div style={{ display: "flex" }}>
        <div
          style={{
            flex: 2,
            padding: "5px",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            rowGap: "25px",
          }}
        >
          {/* booking details card layout */}
          {/* customer details */}
          <div
            className={styles.topContainer}
            // style={{ alignItems: "flex-start" }}
          >
            <div
              className={styles.topContainerLeft}
              style={{ background: "none" }}
            >
              <BookingDetailsCard
                cardTitle="Customer Details & Service Location"
                handleEditClick={handleEditClick}
                tabValue={1}
              >
                <div style={{ display: "flex", columnGap: "10px" }}>
                  <div style={{ flex: "1" }}>
                    <TitleSubTitleVertical
                      title={"Full Name"}
                      subtitle={bookingDetails?.full_name}
                    />
                  </div>
                  <div style={{ display: "flex", flex: 2, columnGap: "20px" }}>
                    <TitleSubTitleVertical
                      title={"Mobile"}
                      subtitle={bookingDetails?.contact_no}
                    />
                    <TitleSubTitleVertical
                      title={"Email"}
                      subtitle={bookingDetails?.email}
                    />
                  </div>
                  <div></div>
                </div>
                <div style={{ display: "flex", columnGap: "10px" }}>
                  <div style={{ flex: "1" }}>
                    <TitleSubTitleVertical
                      title={"Service Location"}
                      subtitle={bookingDetails?.service_location}
                    />
                  </div>
                  {/* <div style={{ display: "flex", flex: 2, columnGap: "20px" }}>
                    <div>
                      <img src={servBookMap} alt="map" />
                    </div>
                  </div> */}
                  <div></div>
                </div>

                <div style={{ display: "flex", columnGap: "10px" }}>
                  <div style={{ flex: "1" }}>
                    <TitleSubTitleVertical
                      title={"Latitute & Longitude"}
                      subtitle={`${bookingDetails?.lat || ""}, ${
                        bookingDetails?.long
                      } `}
                    />
                  </div>
                  <div style={{ display: "flex", flex: 2, columnGap: "80px" }}>
                    <TitleSubTitleVertical
                      title={"Land Type"}
                      subtitle={bookingDetails?.fulladdress?.line_2}
                    />
                    <TitleSubTitleVertical
                      title={"Dealer Code"}
                      subtitle={bookingDetails?.dealer_code}
                    />
                    {/* <TitleSubTitleVertical
                  title={"Service Area in Hectares"}
                  subtitle={"223.5"}
                />
                <TitleSubTitleVertical title={"Time"} subtitle={"Day"} /> */}
                  </div>
                </div>
                <div style={{ display: "flex", columnGap: "10px" }}>
                  <div style={{ flex: "1" }}>
                    <TitleSubTitleVertical
                      title={"KML File"}
                      subtitle={bookingDetails?.kml_file}
                    />
                  </div>
                  <div style={{ display: "flex", flex: 2, columnGap: "40px" }}>
                    {/* <TitleSubTitleVertical
                  title={"Line Kms"}
                  subtitle={"2.6 Kms"}
                /> */}
                    {serviceData.pilotslot &&
                      serviceData.pilotslot.map((item, index) => {
                        return (
                          <TitleSubTitleVertical
                            title={"Service Date"}
                            subtitle={helperdayMonthYearFull(item?.slot_date)}
                          />
                        );
                      })}

                    {serviceData.pilotslot &&
                      serviceData.pilotslot.map((item, index) => {
                        return (
                          <TitleSubTitleVertical
                            title={"Service Time"}
                            subtitle={convertTo12HourFormat(item?.slot_time)}
                          />
                        );
                      })}

                    <TitleSubTitleVertical
                      title={"Area In Acres"}
                      subtitle={bookingDetails?.area_in_acres || "N.A"}
                    />
                    <TitleSubTitleVertical
                      title={"Line In KMs"}
                      subtitle={bookingDetails?.line_kms || "N.A"}
                    />
                    {/* <TitleSubTitleVertical
                      title={"End Date"}
                      subtitle={helperdayMonthYearFull(
                        bookingDetails?.end_date
                      )}
                    /> */}
                    {/* <TitleSubTitleVertical
                  title={"Rate/Acre"}
                  subtitle={"Rs 200"}
                /> */}
                  </div>
                </div>
              </BookingDetailsCard>
            </div>
            <div className={styles.topContainerRight}>
              {/* <img
                src={servBookMap}
                alt="map"
                style={{ width: "100%", height: "250px" }}
              /> */}

              <MyMap
                width="100%"
                height="40vh"
                search={bookingDetails?.pincode}
                loc={loc}
                updLoc={updLoc}
              />
            </div>
          </div>
          <div
            className={styles.topContainer}
            style={{ alignItems: "flex-start" }}
          >
            <div
              className={styles.middleContainerLeft}
              style={{ background: "none" }}
            >
              <BookingDetailsCard
                childrenStyle={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "15px",
                }}
                handleEditClick={handleEditClick}
                tabValue={2}
                cardTitle="Other Service Details"
              >
                {bookingDetails?.other_details?.length > 0
                  ? otherServiceView?.map((item) => {
                      console.log(item, "useritem");
                      const { title, type, data } = item;
                      console.log(type, data, "typeanddata");

                      return (
                        <OtherServiceDetails
                          title={title}
                          type={type}
                          data={data}
                        />
                      );
                    })
                  : "N.A"}
                {bookingDetails?.bookingserviceproduct &&
                  bookingDetails?.bookingserviceproduct.length > 0 && (
                    <div>
                      <p>
                        Product To Be Used?(Please select the desired
                        formulation)
                      </p>
                      <div>
                        {bookingDetails?.bookingserviceproduct.map(
                          (item, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  background: "rgba(248, 248, 248, 1)",
                                  padding: "10px 20px",
                                  boxSizing: "border-box",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  margin: "10px 0px",
                                  borderRadius: "10px",
                                }}
                              >
                                <img
                                  src={item?.product?.thumbnail}
                                  style={{ width: "80px", height: "80px" }}
                                />

                                <h4>{item?.product?.product_name}</h4>

                                <p>{item?.quantity} Qty</p>
                                <h5>Rs. {item?.amount}</h5>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
              </BookingDetailsCard>

              <BookingDetailsCard cardTitle="Drone Details" isEditable={false}>
                {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
              }}
            >
              {droneDetails.map((item, index) => {
                return (
                  <AddServiceCard
                    key={item?.id}
                    textOne={item?.title}
                    textTwo={item?.subTitle}
                    listArray={item?.droneTypes}
                    changeHandler={handleChangeSelect}
                    name="drone"
                    selectedValue={selectedDrones[index]}
                    index={index}
                  />
                );
              })}
            </div> */}

                <DronesDropDown
                  value={selectedValues}
                  onChange={handleAutocompleteChange}
                  start_date={bookingDetails?.start_date}
                  end_date={bookingDetails?.end_date}
                  location={bookingDetails?.location}
                  state={bookingDetails?.fulladdress?.state}
                />
              </BookingDetailsCard>

              <BookingDetailsCard cardTitle="Pilot Details" isEditable={false}>
                {/* <CustomAutoComplete label={"Pilot"} /> */}

                <PilotDropDown
                  value={pilotSelected}
                  onChange={handleAutocompleteChangeforPilot}
                  start_date={bookingDetails?.start_date}
                  end_date={bookingDetails?.end_date}
                  location={bookingDetails?.location}
                  state={bookingDetails?.fulladdress?.state}
                />
              </BookingDetailsCard>

              {estimateDetails[0]?.estimate_approval[0]?.status == 3 && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button variant="contained" onClick={handleAssign}>
                    Save Drones and Pilots Assignment
                  </Button>
                </div>
              )}

              <BookingDetailsCard
                cardTitle="GST & Billing Address"
                isEditable={false}
              >
                <div style={{ display: "flex", columnGap: "25px" }}>
                  <TitleSubTitleVertical
                    title={"Billing Address"}
                    subtitle={bookingDetails?.service_location}
                  />
                  <div>
                    <TitleSubTitleVertical
                      title={"GST"}
                      subtitle={bookingDetails?.gst}
                    />
                  </div>
                </div>
              </BookingDetailsCard>

              {(estimateDetails?.length === 0 ||
                estimateDetails[0]?.estimate_approval[0]?.status == 4) && (
                <BookingDetailsCard
                  cardTitle="Generate Estimate"
                  isEditable={false}
                >
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="contained"
                      onClick={() => handleEstimate("create")}
                    >
                      Generate Estimate
                    </Button>
                  </div>
                </BookingDetailsCard>
              )}

              {estimateDetails?.length > 0 && (
                <div style={{ width: "100%" }}>
                  <p style={{ marginLeft: "10px", marginBottom: "10px" }}>
                    Estimate
                  </p>
                  {estimateDetails.map((item, index) => {
                    console.log("GHGHGHGHGHWWWW", item);
                    const {
                      final_amt,
                      services,
                      quote_no,
                      discount,
                      modified,
                    } = item;
                    const {
                      amount,
                      final_amount,
                      flat_discount,
                      advanced_amt,
                    } = getEstimateDataForView(item?.breakdown_sequence);

                    // const {}
                    return (
                      <div
                        style={{
                          border: "1px solid #c4c4c4",
                          padding: "10px",
                          boxSizing: "border-box",
                          marginBottom: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div
                            style={{
                              minWidth: "160px",
                              maxWidth: "160px",
                              marginBottom: "10px",
                            }}
                          >
                            <TitleSubTitleVertical
                              title={"Amount"}
                              subtitle={amount || "N.A"}
                            />
                          </div>
                          <div
                            style={{
                              minWidth: "160px",
                              maxWidth: "160px",
                              marginBottom: "10px",
                            }}
                          >
                            <TitleSubTitleVertical
                              title={"Coupon Code"}
                              subtitle={"N.A"}
                            />
                          </div>
                          <div
                            style={{
                              minWidth: "160px",
                              maxWidth: "160px",
                              marginBottom: "10px",
                            }}
                          >
                            <TitleSubTitleVertical
                              title={"Discount"}
                              subtitle={`${discount || "N.A"}`}
                            />
                          </div>
                          <div
                            style={{
                              minWidth: "160px",
                              maxWidth: "160px",
                              marginBottom: "10px",
                            }}
                          >
                            <TitleSubTitleVertical
                              title={"Final Amount"}
                              subtitle={`₹${final_amount}` || "N.A"}
                            />
                          </div>
                          <div
                            style={{
                              minWidth: "160px",
                              maxWidth: "160px",
                              marginBottom: "10px",
                            }}
                          >
                            <TitleSubTitleVertical
                              title={"Advance Amount"}
                              subtitle={`₹${advanced_amt}` || "N.A"}
                            />
                          </div>

                          <div
                            style={{
                              minWidth: "160px",
                              maxWidth: "160px",
                              marginBottom: "10px",
                            }}
                          >
                            <TitleSubTitleVertical
                              title={"Estimate Number"}
                              subtitle={`${quote_no || "N.A"}`}
                            />
                          </div>
                          <div
                            style={{
                              minWidth: "160px",
                              maxWidth: "160px",
                              marginBottom: "10px",
                            }}
                          >
                            <TitleSubTitleVertical
                              title={"Estimate Date"}
                              subtitle={`${
                                helperdayMonthYearFull(modified) || "N.A"
                              }`}
                            />
                          </div>
                          <div
                            style={{
                              minWidth: "160px",
                              maxWidth: "160px",
                              marginBottom: "10px",
                            }}
                          >
                            <TitleSubTitleVertical
                              title={"Total Services"}
                              subtitle={`${services?.length || "N.A"}`}
                            />
                          </div>

                          <div
                            style={{
                              minWidth: "160px",
                              maxWidth: "160px",
                              marginBottom: "10px",
                            }}
                          >
                            <p
                              className={`grayTitleFont`}
                              style={{ marginBottom: "5px" }}
                            >
                              Status
                            </p>
                            <p
                              style={{
                                color:
                                  item?.estimate_approval[0]?.status === 1
                                    ? "#29604F" // White for "Created"
                                    : item?.estimate_approval[0]?.status === 2
                                    ? "#A68941" // Black for "Sent to Client"
                                    : item?.estimate_approval[0]?.status === 3
                                    ? "#1E7026" // White for "Approved"
                                    : item?.estimate_approval[0]?.status === 4
                                    ? "#9E2828" // White for "Rejected"
                                    : item?.estimate_approval[0]?.status === 5
                                    ? "#DC3455" // Black for "On Hold"
                                    : item?.estimate_approval[0]?.status === 6
                                    ? "#511E70" // White for "Discarded"
                                    : "rgba(147, 100, 66, 1)", // Default color (original color)
                                backgroundColor:
                                  item?.estimate_approval[0]?.status === 1
                                    ? "#BAD6FF" // Blue for "Created"
                                    : item?.estimate_approval[0]?.status === 2
                                    ? "#FCE7B1" // Orange for "Sent to Client"
                                    : item?.estimate_approval[0]?.status === 3
                                    ? "#CAE6BD" // Green for "Approved"
                                    : item?.estimate_approval[0]?.status === 4
                                    ? "#FFA491" // Red for "Rejected"
                                    : item?.estimate_approval[0]?.status === 5
                                    ? "#FFE5E5" // Some color for "On Hold"
                                    : item?.estimate_approval[0]?.status === 6
                                    ? "#D4BDE6" // Gray for "Discarded"
                                    : "rgba(255, 255, 255, 1)", // Default color (white)
                                width: "maxContent",
                                padding: "6px 8px  ",
                                borderRadius: "19px",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              {item?.estimate_approval[0]?.status_str}
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            columnGap: "10px",
                            // marginTop: "20px",
                          }}
                        >
                          {index == 0 &&
                            estimateDetails[index]?.estimate_approval[0]
                              ?.status != 4 && (
                              <Button
                                variant="outlined"
                                onClick={() => handleNotify(item)}
                                style={{ maxWidth: "170px" }}
                              >
                                Notify Again
                              </Button>
                            )}
                          <Button
                            variant="outlined"
                            onClick={() => handlePDFView(item)}
                            style={{ maxWidth: "170px" }}
                          >
                            View Estimate
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => handleOpenModal(item)}
                            style={{ maxWidth: "170px" }}
                          >
                            Status History
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div className={styles.paymentDetailsMainCon}>
              <h3 className={styles.paymentTitle}>Payment Details</h3>
              <div className={styles.paymentDetailsCon}>
                <div className={styles.transactionListCon}>
                  <div
                    style={{
                      background: "rgba(255, 183, 77, 1)",
                    }}
                    className={styles.transactionCon}
                  >
                    <h4>Total Service Amount</h4>
                    <p>Incl. GST</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <img src={paymentIcon} alt="payment_icon" />
                      <h3>{`₹  ${transactionData?.total_service_amt}`}</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      background: "rgba(139, 195, 74, 1)",
                    }}
                    className={styles.transactionCon}
                  >
                    <h4>Total Paid Amount</h4>
                    <p>Incl. GST</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <img src={paymentIcon} alt="payment_icon" />
                      <h3>{`₹  ${transactionData?.total_paid_amt}`}</h3>
                    </div>{" "}
                  </div>{" "}
                  <div
                    style={{
                      background: "rgba(255, 115, 102, 1)",
                    }}
                    className={styles.transactionCon}
                  >
                    <h4>Total Pending Amount</h4>
                    <p>Incl. GST</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <img src={paymentIcon} alt="payment_icon" />
                      <h3>{`₹  ${transactionData?.total_pending_amt}`}</h3>
                    </div>{" "}
                  </div>
                </div>
                {paymentData && paymentData.length > 0 ? (
                  <div>
                    <h3>Transaction History</h3>

                    <div className={styles.withdrawListCon}>
                      {paymentData.map((withdraw, indx) => {
                        return (
                          <div key={indx} className={styles.withdrawCon}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginBottom: "15px",
                                width: "100%",
                              }}
                            >
                              <div>
                                <h3 className={styles.withdrawBlack}>
                                  {withdraw?.name}
                                </h3>
                                <h3 className={styles.withdrawGrey}>
                                  {" "}
                                  {helperdayMonthYearFull(
                                    withdraw?.created
                                  )}, {extractDateTime(withdraw?.created)}
                                </h3>
                              </div>
                              <div>
                                <h3 className={styles.withdrawBlack}>
                                  ₹ {withdraw?.amount}
                                </h3>
                                <button
                                  className={styles.downloadBtn}
                                  style={{
                                    background: `${
                                      withdraw?.payment_status === 4
                                        ? "linear-gradient(to right, rgba(9, 154, 6, 1), rgba(20, 219, 16, 1))"
                                        : "linear-gradient(to right, rgba(200, 8, 8, 1), rgba(255, 124, 116, 1))"
                                    }`,
                                  }}
                                >
                                  {withdraw?.payment_status_str}
                                </button>
                              </div>
                            </div>

                            {withdraw?.transaction_id && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "10px",
                                  width: "100%",
                                }}
                              >
                                <h3 className={styles.withdrawRed}>
                                  {withdraw?.payment_method_str}
                                </h3>
                                <h3 className={styles.withdrawRed}>
                                  {withdraw?.transaction_id || "N.A"}
                                </h3>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div style={{ marginTop: "20px" }}>
                    <h3>Transaction History</h3>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px",
                        boxSizing: "border-box",
                      }}
                    >
                      <img src={noTransaction} />
                      <h1
                        style={{
                          color: "grey",
                          fontSize: "24px",
                          fontWeight: "800",
                          marginBottom: "10px",
                        }}
                      >
                        NO TRANSACTIONS{" "}
                      </h1>
                      <p>You haven't made any transactions yet.</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ flex: 1, padding: "5px" }}>
          <ServiceHistory />
        </div> */}
      </div>

      <DrawerComp open={open} onClose={() => setOpen(false)} anchor="right">
        <DrawerContainer>
          <DrawerTitle
            text={
              openTabNo == 1
                ? "Update user details"
                : "Update service related details"
            }
            handleCancelDrw={() => setOpen((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            {openTabNo == 1 && (
              <CustomerDetails
                handleEditClick={handleEditClick}
                serviceData={serviceData}
                getServiceBookingDetails={getServiceBookingDetails}
              />
            )}

            {openTabNo == 2 && (
              <UserOtherServiceDetails
                handleEditClick={handleEditClick}
                serviceData={serviceData}
              />
            )}
          </div>
        </DrawerContainer>
      </DrawerComp>

      <CustomModal open={isModalOpen} onClose={handleCloseModal}>
        {/* Content inside the modal */}

        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", gap: "20px" }}>
              <div>
                <p style={{ color: "gray", margin: "3px", fontSize: "17px" }}>
                  Final Amount
                </p>
                <p style={{ textAlign: "center", margin: "3px" }}>
                  {amountData?.final_amount}
                </p>
              </div>

              <div>
                <p style={{ color: "gray", margin: "3px", fontSize: "17px" }}>
                  Estimate Number
                </p>
                <p style={{ textAlign: "center", margin: "3px" }}>
                  {modelData?.quote_no}
                </p>
              </div>
            </div>

            {modelData?.id == estimateDetails[0]?.id && (
              <div style={{ width: "200px" }}>
                <CustomSelectChip
                  multiple={false}
                  label="Change status"
                  name="status"
                  listArray={statuslist}
                  onChange={handleFilter}
                  onDelete={handleDeleteChip}
                  value={filters?.status}
                />
              </div>
            )}
          </div>

          {modelData?.id == estimateDetails[0]?.id && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <CustomButton
                text={"Submit"}
                maxWidth="200px"
                maxHeight="100px"
                onClick={() => handleSubmitStatus(modelData)}
              />
            </div>
          )}
        </div>
        {modelData?.estimate_approval.map((info) => {
          return (
            <>
              <div
                style={{
                  border: "1px solid lightgray",
                  display: "flex",
                  gap: "20px",
                  boxSizing: "border-box",
                  padding: "10px",
                  marginTop: "30px ",
                }}
              >
                <div style={{ minWidth: "120px" }}>
                  <p
                    style={{
                      color:
                        info?.status === 1
                          ? "#29604F" // White for "Created"
                          : info?.status === 2
                          ? "#A68941" // Black for "Sent to Client"
                          : info?.status === 3
                          ? "#1E7026" // White for "Approved"
                          : info?.status === 4
                          ? "#9E2828" // White for "Rejected"
                          : info?.status === 5
                          ? "#DC3455" // Black for "On Hold"
                          : info?.status === 6
                          ? "#511E70" // White for "Discarded"
                          : "rgba(147, 100, 66, 1)", // Default color (original color)
                      backgroundColor:
                        info?.status === 1
                          ? "#BAD6FF" // Blue for "Created"
                          : info?.status === 2
                          ? "#FCE7B1" // Orange for "Sent to Client"
                          : info?.status === 3
                          ? "#CAE6BD" // Green for "Approved"
                          : info?.status === 4
                          ? "#FFA491" // Red for "Rejected"
                          : info?.status === 5
                          ? "#FFE5E5" // Some color for "On Hold"
                          : info?.status === 6
                          ? "#D4BDE6" // Gray for "Discarded"
                          : "rgba(255, 255, 255, 1)", // Default color (white)
                      width: "maxContent",
                      padding: "6px 10px  ",
                      borderRadius: "19px",
                      textAlign: "center",
                    }}
                  >
                    {info?.status_str}
                  </p>
                </div>

                <div>
                  <p style={{ color: "gray", margin: "3px", fontSize: "17px" }}>
                    Created By
                  </p>
                  <p
                    style={{ textAlign: "center", margin: "3px" }}
                  >{`${info?.created_by?.first_name} ${info?.created_by?.last_name}`}</p>
                </div>

                <div>
                  <p style={{ color: "gray", margin: "3px", fontSize: "17px" }}>
                    Created On
                  </p>
                  <p style={{ textAlign: "center", margin: "3px" }}>
                    {helperdayMonthYearFull(info?.modified)}
                  </p>
                </div>

                <div>
                  <p style={{ color: "gray", margin: "3px", fontSize: "17px" }}>
                    Modified By
                  </p>
                  <p
                    style={{ textAlign: "center", margin: "3px" }}
                  >{`${info?.modified_by?.first_name} ${info?.modified_by?.last_name}`}</p>
                </div>

                {info?.reason && (
                  <div>
                    <p
                      style={{ color: "gray", margin: "3px", fontSize: "17px" }}
                    >
                      Reason
                    </p>
                    <p style={{ textAlign: "center", margin: "3px" }}>
                      {info?.reason}
                    </p>
                  </div>
                )}
              </div>
            </>
          );
        })}

        {/* <Button variant="contained" onClick={handleCloseModal}>
          Close Modal
        </Button> */}
      </CustomModal>
    </Container>
  );
};

export default ServiceBookingDetails;

const droneDetails = [
  {
    id: 1,
    title: "Agristar",
    subTitle: "Agricultural drone",
    image: "",
    droneTypes: [
      {
        id: 1,
        title: "B1227P",
        sub_topic: "Agristar | Mumbai",
      },
      {
        id: 2,
        title: "B72434M",
        sub_topic: "Agristar | Pune",
      },
    ],
  },
  {
    id: 1,
    title: "Agrimapper",
    subTitle: "Agricultural drone",
    image: "",
    droneTypes: [
      {
        id: 1,
        title: "B1227PThane",
        sub_topic: "Agristar | Thane",
      },
      {
        id: 2,
        title: "B72434MPanjim",
        sub_topic: "Agristar | Panjim",
      },
    ],
  },
];

const statuslist = [
  { id: 1, title: "Created" },
  { id: 2, title: "Sent to Client" },
  { id: 3, title: "Approved" },
  { id: 4, title: "Rejected" },
  { id: 5, title: "On Hold" },
  { id: 6, title: "Discarded" },
];

const bookingStatusList = [
  {
    id: 1,
    title: "Completed",
  },
  {
    id: 2,
    title: "Cancelled",
  },
  {
    id: 3,
    title: "Scheduled",
  },
  {
    id: 4,
    title: "New Request",
  },
];
