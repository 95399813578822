import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  otherServiceView: [],
};

const serviceBookingSlice = createSlice({
  name: "serviceBooking",
  initialState,
  reducers: {
    setOtherServiceDetailsForView: (state, action) => {
      let othDetsModified = action?.payload?.map((item) => {
        const { question, selected_choices, text_answers } = item;

        let currentType = "single";
        let currentData;
        if (question?.view_type === 3 || question?.view_type === 4) {
          // text block
          if (text_answers.length > 0) {
            currentType = "multiple";
            currentData = getStructuredData(text_answers);
          }
        } else if (question?.view_type === 1) {
          if (question?.is_multi === true && selected_choices.length > 0) {
            currentType = "multiple";
            currentData = getStructuredDataNonText(selected_choices);
          }else{
            currentType = "single";
            currentData = getStructuredDataNonText(selected_choices);
          }
        } else if (question?.view_type === 2) {
          if (question?.is_multi === true && selected_choices.length > 0) {
            currentType = "multiple";
            currentData = getStructuredDataNonText(selected_choices);
          }else{
            currentType = "single";
            currentData = getStructuredDataNonText(selected_choices);
          }
        } else if (question?.view_type === 5) {
          if (question?.is_multi === true && selected_choices.length > 0) {
            currentType = "multiple";
            currentData = getStructuredDataNonText(selected_choices);
          }else{
            currentType = "single";
            currentData = getStructuredDataNonText(selected_choices);
          }
        } else if (question?.view_type === 6) {
          if (question?.is_multi === true && selected_choices.length > 0) {
            currentType = "multiple";
            currentData = getStructuredDataNonText(selected_choices);
          }
        }

        return {
          id: question?.id || null,
          title:
            `${question?.title} (${
              (question?.more_info && question?.more_info) || ""
            })` || "",
          type: currentType,
          data: currentData,
        };
      });
      state.otherServiceView = othDetsModified;
    },
  },
});

export const { setOtherServiceDetailsForView } = serviceBookingSlice.actions;

export default serviceBookingSlice.reducer;

const getStructuredData = (data) => {
  const modifiedData = data?.map((item) => {
    return {
      firstLine: item?.text_choice?.hint,
      secondLine: item?.text_choice_ans,
    };
  });

  return modifiedData;
};

const getStructuredDataNonText = (data) => {
  const modifiedData = data?.map((item) => {
    return {
      firstLine: item?.title,
      secondLine: item?.id,
    };
  });

  return modifiedData;
};
