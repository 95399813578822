export const Validate = (value) => {
  return new Promise((resolve, reject) => {
    const {
      first_name,
      last_name,
      email,
      mobile,
      about,
      experience,
      qualification,
      gender,
      language,
    } = value;

    const errors = { error: false };
    const mobileRegex = /^\d{10}$/; 
    if (!first_name) {
      errors.first_name = "Please enter first name";
      errors.error = true;
    }
    if (!last_name) {
      errors.last_name = "Please enter last name";
      errors.error = true;
    }

    if (!email) {
      errors.email = "Please enter email";
      errors.error = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Please enter a valid email address";
      errors.error = true;
    }

    
    if (!mobile || !mobileRegex.test(mobile)) {
      errors.mobile = "Please enter a valid 10-digit mobile number";
      errors.error = true;
    }



    if (!about) {
      errors.about = "Please enter about instructor";
      errors.error = true;
    }

    if (!experience) {
      errors.experience = "Please enter experience";
      errors.error = true;
    }
    if (!qualification) {
      errors.qualification = "Please enter qualification";
      errors.error = true;
    }

    if (!gender[0]) {
      errors.gender = "Please enter gender details";
      errors.error = true;
    }
    if (!language[0]) {
      errors.language = "Please enter language details";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
