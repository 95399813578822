import React from "react";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./CustomSearch.module.css";

const CustomSearchFilter = ({
  onChange,
  width = "100%",
  size = "small",
  value,
  placeholder = "Search",
}) => {
  return (
    <TextField
      className={styles.searchCon}
      sx={{ width: width }}
      variant="outlined"
      placeholder={placeholder} // Use placeholder prop to set the placeholder
      value={value}
      size={size}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      InputProps={{
        startAdornment: <SearchIcon />,
      }}
    />
  );
};

export default CustomSearchFilter;
