import React, { useState, useRef, useEffect } from "react";
import styles from "./DealerDocuments.module.css";
import uploaded from "../../../Assets/greenUploaded.svg";
import notUploaded from "../../../Assets/greyUploaded.svg";
import orangeSelect from "../../../Assets/orangeUploaded.svg";
import uploadVector from "../../../Assets/uploadVector.svg";
import uploadFile from "../../../Assets/uploadFile.svg";
import crossIcon from "../../../Assets/orangeCross.svg";
import { useSelector } from "react-redux";
import { base_url, token_api } from "../../../Utils/network";
import { useParams } from "react-router-dom";
import downloadIcon from "../../../Assets/pdfDownload.svg";
import axios from "axios";
import Switch from "@mui/material/Switch";
import verified from "../../../Assets/userProfile/verified.svg";
import notverified from "../../../Assets/userProfile/notVerified.svg";
import InputFields from "../../InputFields/InputFields";
import { Validate } from "./Utility";

const DealerDocuments = ({ documents }) => {
  const { id } = useParams();
  const { access_token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  //useRef Variables....
  const aadharInput = useRef(null);
  const cinInput = useRef(null);
  const gstInput = useRef(null);
  const msmeInput = useRef(null);
  const pancardInput = useRef(null);

  const [aadharFile, setAadharFile] = useState("");
  const [cinFile, setCinFile] = useState("");
  const [gstFile, setGstFile] = useState("");
  const [msmeFile, setMsmeFile] = useState("");
  const [panFile, setPanFile] = useState("");

  const [aadharPreview, setAadharPreview] = useState("");
  const [cinPreview, setCinPreview] = useState("");
  const [gstPreview, setGstPreview] = useState("");
  const [msmePreview, setMsmePreview] = useState("");
  const [panPreview, setPanPreview] = useState("");

  const [isAadharVerified, setIsAadharVerified] = useState(false);
  const [isCinVerified, setIsCinVerified] = useState(false);
  const [isGstVerified, setIsGstVerified] = useState(false);
  const [isMsmeVerified, setIsMsmeVerified] = useState(false);
  const [isPanVerified, setIsPanVerified] = useState(false);

  const [userRegistrationId, setUserRegId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [formData, setFormData] = useState({
    id: "",
    aadhar_number: "",
    cin_number: "",
    gst_register_number: "",
    msme_register_number: "",
    pan_card_number: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleClick = (type) => {
    if (type === "aadhar") {
      aadharInput.current.click();
    } else if (type === "cin") {
      cinInput.current.click();
    } else if (type === "gst") {
      gstInput.current.click();
    } else if (type === "msme") {
      msmeInput.current.click();
    } else if (type === "pan") {
      pancardInput.current.click();
    }
  };

  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleDrop(e, type) {
    e.preventDefault();
    if (e?.target?.files) {
      if (type === "aadhar") {
        setAadharFile(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setAadharPreview(imageUrl);
      } else if (type === "cin") {
        setCinFile(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setCinPreview(imageUrl);
      } else if (type === "gst") {
        setGstFile(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setGstPreview(imageUrl);
      } else if (type === "msme") {
        setMsmeFile(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setMsmePreview(imageUrl);
      } else if (type === "pan") {
        setPanFile(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setPanPreview(imageUrl);
      }
    }
  }


  //User Individual Registration Detail Deleting ---> DELETE API Not Implemented.....
  const handleRemoveImage = async (type) => {
    if (type === "aadhar") {
      setAadharPreview("");
    } else if (type === "cin") {
      setCinPreview("");
    } else if (type === "gst") {
      setGstPreview("");
    } else if (type === "msme") {
      setMsmePreview("");
    } else if (type === "pan") {
      setPanPreview("");
    }

    if (formData?.id) {
      try {
        let registrationData = new FormData();

        if (type === "aadhar") registrationData.append("aadhar_img", "");
        if (type === "cin") registrationData.append("cin_img", "");
        if (type === "gst") registrationData.append("gst_register_img", "");
        if (type === "msme") registrationData.append("msme_register_img", "");
        if (type === "pan") registrationData.append("pan_card_img", "");

        let responseData = await axios({
          method: "patch",
          url: `${base_url}users/profile/admin_dealer/${formData?.id}/`,
          data: registrationData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
        });
        alert(`${type} Document Updated Successfully!`);
        setLoading(false);
        setAadharFile("");
        setCinFile("");
        setGstFile("");
        setMsmeFile("");
        setPanFile("");
        // setAadharPreview(responseData?.data?.data?.aadhar_img);
        // setCinPreview(responseData?.data?.data?.cin_img);
        // setGstPreview(responseData?.data?.data?.gst_register_img);
        // setMsmePreview(responseData?.data?.data?.msme_register_img);
        // setPanPreview(responseData?.data?.data?.pan_card_img);
        getRegistrationFiles();
      } catch (error) {
        console.log(error);
      }
    }
  };

  //User Registration Details  ---> GET API

  const getRegistrationFiles = () => {
    token_api
      .get(`users/profile/admin_dealer/${id}/`)
      .then((response) => {
        // setUserRegProofs(response?.data?.data[0]);
        if (response?.status === 200 || response?.status === 201) {
          setUploadedFiles(response?.data?.data);
          const {
            id,
            aadhar_img,
            aadhar_number,
            cin_img,
            cin_number,
            gst_register_img,
            gst_register_number,

            msme_register_img,
            msme_register_number,
            pan_card_img,
            pan_card_number,
            // is_aadhar_verified,
            // is_cin_verified,
            // is_gst_verified,
            // is_msme_verified,
            // is_pan_card_verified,
          } = response?.data?.data;
          setAadharPreview(aadhar_img);
          setCinPreview(cin_img);
          setGstPreview(gst_register_img);
          setMsmePreview(msme_register_img);
          setPanPreview(pan_card_img);
          setAadharFile("");
          setCinFile("");
          setGstFile("");
          setMsmeFile("");
          setPanFile("");
          // setIsAadharVerified(is_aadhar_verified);
          // setIsCinVerified(is_cin_verified);
          // setIsGstVerified(is_gst_verified);
          // setIsMsmeVerified(is_msme_verified);
          // setIsPanVerified(is_pan_card_verified);
          setFormData({
            ...formData,
            id: id,
            aadhar_number: aadhar_number,
            cin_number: cin_number,
            gst_register_number: gst_register_number,
            msme_register_number: msme_register_number,
            pan_card_number: pan_card_number,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (id) {
      getRegistrationFiles();
    }
  }, [id]);

  const uploadRegistrationDetail = async () => {
    try {
      Validate(formData)
        .then(async (response) => {
          if (response === "success") {
            setLoading(true);
            if (formData?.id) {
              let registrationData = new FormData();

              if (aadharFile) registrationData.append("aadhar_img", aadharFile);
              if (cinFile) registrationData.append("cin_img", cinFile);
              if (gstFile) registrationData.append("gst_register_img", gstFile);
              if (msmeFile)
                registrationData.append("msme_register_img", msmeFile);
              if (panFile) registrationData.append("pan_card_img", panFile);
              if (formData?.aadhar_number)
                registrationData.append(
                  "aadhar_number",
                  formData?.aadhar_number
                );
              if (formData?.cin_number)
                registrationData.append("cin_number", formData?.cin_number);
              if (formData?.gst_register_number)
                registrationData.append(
                  "gst_register_number",
                  formData?.gst_register_number
                );
              if (formData?.msme_register_number)
                registrationData.append(
                  "msme_register_number",
                  formData?.msme_register_number
                );
              if (formData?.pan_card_number)
                registrationData.append(
                  "pan_card_number",
                  formData?.pan_card_number
                );

              // if (adharVerified)
              // registrationData.append("is_aadhar_verified", isAadharVerified);
              // if (passportphotoVerified)
              // registrationData.append("is_cin_verified", isCinVerified);
              // if (marksheet10thVerified)
              // registrationData.append("is_gst_verified", isGstVerified);
              // if (medicalfitnessVerified)
              // registrationData.append("is_msme_verified", isMsmeVerified);
              // if (otherPhotoProofVerified)
              // registrationData.append("is_pan_card_verified", isPanVerified);
              // if (registrationformVerified)

              let responseData = await axios({
                method: "patch",
                url: `${base_url}users/profile/admin_dealer/${formData?.id}/`,
                data: registrationData,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
              alert("Dealer Documents Uploaded Successfully!");

              setAadharPreview(responseData?.data?.data?.aadhar_img);
              setCinPreview(responseData?.data?.data?.cin_img);
              setGstPreview(responseData?.data?.data?.gst_register_img);
              setMsmePreview(responseData?.data?.data?.msme_register_img);
              setPanPreview(responseData?.data?.data?.pan_card_img);
              setAadharFile("");
              setCinFile("");
              setGstFile("");
              setMsmeFile("");
              setPanFile("");
              setLoading(false);

              setFormErrors({});

              // setIsAadharVerified(false);
              // setIsCinVerified(false);
              // setIsGstVerified(false);
              // setIsMsmeVerified(false);
              // setIsPanVerified(false);

              getRegistrationFiles();
            }
          }
        })
        .catch((error) => {
          setFormErrors(error);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadDocument = (docValue, docType) => {
    if (docValue) {
      const link = document.createElement("a");
      link.href = docValue;
      link.target = "_blank";
      link.download = "document.jpeg";

      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    }
  };

  const handleVerifyDocument = (type, checked) => {

    if (type === "aadhar") {
      setIsAadharVerified(!isAadharVerified);
    } else if (type === "cin") {
      setIsCinVerified(!isCinVerified);
    } else if (type === "gst") {
      setIsGstVerified(!isGstVerified);
    } else if (type === "msme") {
      setIsMsmeVerified(!isMsmeVerified);
    } else if (type === "pan") {
      setIsPanVerified(!isPanVerified);
    }
  };

  const isAllDocumentsUploaded = () =>
    uploadedFiles?.aadhar_img &&
    uploadedFiles?.cin_img &&
    uploadedFiles?.gst_register_img &&
    uploadedFiles?.msme_register_img &&
    uploadedFiles?.pan_card_img;

  const areAllDocumentsVerified = () =>
    uploadedFiles?.aadhar_number &&
    uploadedFiles?.cin_number &&
    uploadedFiles?.gst_register_number &&
    uploadedFiles?.msme_register_number &&
    uploadedFiles?.pan_card_number;

  
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.leftContainer}>
          {/* Aadhar Card Details Card.... */}
          <div className={styles.leftDetailsCard}>
            <h1 className={styles.mainHeading}>Aadhar Card</h1>
            <p className={styles.greyText}>
              Aadhar number must be linked with your Email and Mobile Number
            </p>
            <div style={{ margin: "15px 0px" }}>
              <InputFields
                label="Enter Aadhar No"
                name="aadhar_number"
                value={formData?.aadhar_number || ""}
                onChange={handleChange}
                error={formErrors?.aadhar_number}
                helperText={formErrors?.aadhar_number}
              />
            </div>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "40px",
              }}
            >
              {aadharPreview && (
                <div className={styles.selectedImage}>
                  <img
                    src={aadharPreview}
                    // style={{ width: "100%", height: "100%" }}
                    alt="Aadhar Proof"
                  />
                  <div
                    className={styles.crossIcon}
                    onClick={() => handleRemoveImage("aadhar")}
                  >
                    <img src={crossIcon} />
                  </div>
                  {aadharPreview.startsWith("https") && (
                    <div
                      className={styles.downloadIcon}
                      onClick={() =>
                        handleDownloadDocument(aadharPreview, "aadhar")
                      }
                    >
                      <img src={downloadIcon} />
                    </div>
                  )}
                </div>
              )}

              {/* {aadharPreview && (
                <Switch
                  checked={isAadharVerified}
                  onChange={() => {
                    handleVerifyDocument("aadhar", isAadharVerified);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )} */}

              {/* {aadharPreview &&
                (uploadedFiles?.is_aadhar_verified ? (
                  <span className={styles.Vstatus}>
                    {" "}
                    <img src={verified} /> verified{" "}
                  </span>
                ) : (
                  <span className={styles.NVstatus}>
                    {" "}
                    <img src={notverified} /> Not verified{" "}
                  </span>
                ))} */}
            </div>
            <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "aadhar")}
            >
              <div
                className={styles.chooseCon}
                onClick={(e) => handleClick("aadhar")}
              >
                <img src={uploadVector} />
                <p className={styles.maxText}>Choose Files</p>
                <input
                  ref={aadharInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "aadhar")}
                  style={{ display: "none" }}
                />
                <button
                  className={styles.browseFilesText}
                  onChange={(e) => handleDrop(e, "aadhar")}
                >
                  Browse Files
                </button>
              </div>
            </div>
            {/* <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "aadhar")}
            >
              <div
                className={styles.dragDropCon}
                onClick={() => handleClick("aadhar")}
              >
                <img src={uploadFile} />
                <h3 className={styles.dropText}>Drag and drop files here</h3>
                <p className={styles.maxText}>(Maximum file size is 100MB)</p>
                <input
                  ref={aadharInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"

                  onChange={(e) => handleDrop(e, "aadhar")}
                  style={{ display: "none" }}
                />
              </div>
            </div> */}
          </div>

          {/* Pan Card... */}
          <div className={styles.leftDetailsCard}>
            <h1 className={styles.mainHeading}>Pan Card</h1>
            <div style={{ margin: "15px 0px" }}>
              <InputFields
                label="Enter PAN No"
                name="pan_card_number"
                value={formData?.pan_card_number || ""}
                onChange={handleChange}
                error={formErrors?.pan_card_number}
                helperText={formErrors?.pan_card_number}
              />
            </div>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "40px",
              }}
            >
              {panPreview && (
                <div className={styles.selectedImage}>
                  <img
                    src={panPreview}
                    // style={{ width: "100%", height: "auto" }}
                  />
                  <div
                    className={styles.crossIcon}
                    onClick={() => handleRemoveImage("pan")}
                  >
                    <img src={crossIcon} />
                  </div>
                  {panPreview.startsWith("https") && (
                    <div
                      className={styles.downloadIcon}
                      onClick={() => handleDownloadDocument(panPreview, "pan")}
                    >
                      <img src={downloadIcon} />
                    </div>
                  )}
                </div>
              )}
              {/* {panPreview && (
                <Switch
                  checked={isPanVerified}
                  onChange={() => {
                    handleVerifyDocument("pan");
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              {panPreview &&
                (uploadedFiles?.is_pan_card_verified ? (
                  <span className={styles.Vstatus}>
                    {" "}
                    <img src={verified} /> verified{" "}
                  </span>
                ) : (
                  <span className={styles.NVstatus}>
                    {" "}
                    <img src={notverified} /> Not verified{" "}
                  </span>
                ))} */}
            </div>
            <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "pan")}
            >
              <div
                className={styles.chooseCon}
                onClick={(e) => handleClick("pan")}
              >
                <img src={uploadVector} />
                <p className={styles.maxText}>Choose Files</p>
                <input
                  ref={pancardInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "pan")}
                  style={{ display: "none" }}
                />
                <button
                  className={styles.browseFilesText}
                  onChange={(e) => handleDrop(e, "pan")}
                >
                  Browse Files
                </button>
              </div>
            </div>
            {/* <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "other_photo_proof")}
            >
              <div
                className={styles.dragDropCon}
                onClick={(e) => handleClick("other_photo_proof")}
              >
                <img src={uploadFile} />
                <h3 className={styles.dropText}>Drag and drop files here</h3>
                <p className={styles.maxText}>(Maximum file size is 100MB)</p>
                <input
                  ref={otherPhotoProofInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "other_photo_proof")}
                  style={{ display: "none" }}
                />
              </div>
            </div> */}
          </div>

          {/* GST Registration.... */}

          <div className={styles.leftDetailsCard}>
            <h1 className={styles.mainHeading}>GST Registration </h1>
            <div style={{ margin: "15px 0px" }}>
              <InputFields
                label="Enter GST No"
                name="gst_register_number"
                value={formData?.gst_register_number || ""}
                onChange={handleChange}
                error={formErrors?.gst_register_number}
                helperText={formErrors?.gst_register_number}
              />
            </div>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "40px",
              }}
            >
              {gstPreview && (
                <div className={styles.selectedImage}>
                  <img
                    src={gstPreview}
                    // style={{ width: "100%", height: "auto" }}
                  />
                  <div
                    className={styles.crossIcon}
                    onClick={() => handleRemoveImage("gst")}
                  >
                    <img src={crossIcon} />
                  </div>
                  {gstPreview.startsWith("https") && (
                    <div
                      className={styles.downloadIcon}
                      onClick={() => handleDownloadDocument(gstPreview, "gst")}
                    >
                      <img src={downloadIcon} />
                    </div>
                  )}
                </div>
              )}

              {/* {gstPreview && (
                <Switch
                  checked={isGstVerified}
                  onChange={() => {
                    handleVerifyDocument("gst");
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              {gstPreview &&
                (uploadedFiles?.is_gst_verified ? (
                  <span className={styles.Vstatus}>
                    {" "}
                    <img src={verified} /> verified{" "}
                  </span>
                ) : (
                  <span className={styles.NVstatus}>
                    {" "}
                    <img src={notverified} /> Not verified{" "}
                  </span>
                ))} */}
            </div>
            <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "gst")}
            >
              <div
                className={styles.chooseCon}
                onClick={(e) => handleClick("gst")}
              >
                <img src={uploadVector} />
                <p className={styles.maxText}>Choose Files</p>
                <input
                  ref={gstInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "gst")}
                  style={{ display: "none" }}
                />
                <button
                  className={styles.browseFilesText}
                  onChange={(e) => handleDrop(e, "gst")}
                >
                  Browse Files
                </button>
              </div>
            </div>
            {/* <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "marksheet_10th")}
            >
              <div
                className={styles.dragDropCon}
                onClick={(e) => handleClick("marksheet_10th")}
              >
                <img src={uploadFile} />
                <h3 className={styles.dropText}>Drag and drop files here</h3>
                <p className={styles.maxText}>(Maximum file size is 100MB)</p>
                <input
                  ref={marksheet10thInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"

                  onChange={(e) => handleDrop(e, "marksheet_10th")}
                  style={{ display: "none" }}
                />
              </div>
            </div> */}
          </div>

          {/* CIN Card */}
          <div className={styles.leftDetailsCard}>
            <h1 className={styles.mainHeading}>CIN No. </h1>
            <div style={{ margin: "15px 0px" }}>
              <InputFields
                label="Enter CIN No"
                name="cin_number"
                value={formData?.cin_number || ""}
                onChange={handleChange}
                error={formErrors?.cin_number}
                helperText={formErrors?.cin_number}
              />
            </div>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "40px",
              }}
            >
              {cinPreview && (
                <div className={styles.selectedImage}>
                  <img
                    src={cinPreview}
                    // style={{ width: "100%", height: "auto" }}
                  />
                  <div
                    className={styles.crossIcon}
                    onClick={() => handleRemoveImage("cin")}
                  >
                    <img src={crossIcon} />
                  </div>
                  {cinPreview.startsWith("https") && (
                    <div
                      className={styles.downloadIcon}
                      onClick={() => handleDownloadDocument(cinPreview, "cin")}
                    >
                      <img src={downloadIcon} />
                    </div>
                  )}
                </div>
              )}

              {/* {cinPreview && (
                <Switch
                  checked={isCinVerified}
                  onChange={() => {
                    handleVerifyDocument("cin");
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              {cinPreview &&
                (uploadedFiles?.is_cin_verified ? (
                  <span className={styles.Vstatus}>
                    {" "}
                    <img src={verified} /> verified{" "}
                  </span>
                ) : (
                  <span className={styles.NVstatus}>
                    {" "}
                    <img src={notverified} /> Not verified{" "}
                  </span>
                ))} */}
            </div>
            <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "cin")}
            >
              <div
                className={styles.chooseCon}
                onClick={(e) => handleClick("cin")}
              >
                <img src={uploadVector} />
                <p className={styles.maxText}>Choose Files</p>
                <input
                  ref={cinInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "cin")}
                  style={{ display: "none" }}
                />
                <button
                  className={styles.browseFilesText}
                  onChange={(e) => handleDrop(e, "cin")}
                >
                  Browse Files
                </button>
              </div>
            </div>
            {/* <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "passport_photo")}
            >
              <div
                className={styles.dragDropCon}
                onClick={(e) => handleClick("passport_photo")}
              >
                <img src={uploadFile} />
                <h3 className={styles.dropText}>Drag and drop files here</h3>
                <p className={styles.maxText}>(Maximum file size is 100MB)</p>
                <input
                  ref={passportPhotoInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"

                  onChange={(e) => handleDrop(e, "passport_photo")}
                  style={{ display: "none" }}
                />
              </div>
            </div> */}
          </div>

          {/* MSME Registration.... */}
          <div className={styles.leftDetailsCard}>
            <h1 className={styles.mainHeading}>MSME Registration </h1>
            <div style={{ margin: "15px 0px" }}>
              <InputFields
                label="Enter MSME No"
                name="msme_register_number"
                value={formData?.msme_register_number || ""}
                onChange={handleChange}
                error={formErrors?.msme_register_number}
                helperText={formErrors?.msme_register_number}
              />
            </div>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "40px",
              }}
            >
              {" "}
              {msmePreview && (
                <div className={styles.selectedImage}>
                  <img
                    src={msmePreview}
                    // style={{ width: "100%", height: "auto" }}
                  />
                  <div
                    className={styles.crossIcon}
                    onClick={() => handleRemoveImage("msme")}
                  >
                    <img src={crossIcon} />
                  </div>
                  {msmePreview.startsWith("https") && (
                    <div
                      className={styles.downloadIcon}
                      onClick={() =>
                        handleDownloadDocument(msmePreview, "msme")
                      }
                    >
                      <img src={downloadIcon} />
                    </div>
                  )}
                </div>
              )}
              {/* {msmePreview && (
                <Switch
                  checked={isMsmeVerified}
                  onChange={() => {
                    handleVerifyDocument("msme");
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              {msmePreview &&
                (uploadedFiles?.is_msme_verified ? (
                  <span className={styles.Vstatus}>
                    {" "}
                    <img src={verified} /> verified{" "}
                  </span>
                ) : (
                  <span className={styles.NVstatus}>
                    {" "}
                    <img src={notverified} /> Not verified{" "}
                  </span>
                ))} */}
            </div>
            <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "msme")}
            >
              <div
                className={styles.chooseCon}
                onClick={(e) => handleClick("msme")}
              >
                <img src={uploadVector} />
                <p className={styles.maxText}>Choose Files</p>
                <input
                  ref={msmeInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "msme")}
                  style={{ display: "none" }}
                />
                <button
                  className={styles.browseFilesText}
                  onChange={(e) => handleDrop(e, "msme")}
                >
                  Browse Files
                </button>
              </div>
            </div>
            {/* <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "medical_fitness")}
            >
              <div
                className={styles.dragDropCon}
                onClick={(e) => handleClick("medical_fitness")}
              >
                <img src={uploadFile} />
                <h3 className={styles.dropText}>Drag and drop files here</h3>
                <p className={styles.maxText}>(Maximum file size is 100MB)</p>
                <input
                  ref={medicalFitnessInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"

                  onChange={(e) => handleDrop(e, "medical_fitness")}
                  style={{ display: "none" }}
                />
              </div>
            </div> */}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <h1 className={styles.documentTitle}>Documents</h1>
          <div className={styles.documentList}>
            {/* //Aadhar... */}
            <div className={styles.documentCard}>
              <div>
                <img
                  src={
                    (uploadedFiles?.aadhar_img &&
                      !uploadedFiles?.aadhar_number) ||
                    (!uploadedFiles?.aadhar_img && uploadedFiles?.aadhar_number)
                      ? orangeSelect
                      : uploadedFiles?.aadhar_img &&
                        uploadedFiles?.aadhar_number
                      ? uploaded
                      : notUploaded
                  }
                  // style={{ width: "30px", height: "30px" }}
                />
              </div>
              <p className={styles.documentName}>Aadhar Card</p>
            </div>
            {/* pan... */}
            <div className={styles.documentCard}>
              <div>
                <img
                  src={
                    (uploadedFiles?.pan_card_img &&
                      !uploadedFiles?.pan_card_number) ||
                    (!uploadedFiles?.pan_card_img &&
                      uploadedFiles?.pan_card_number)
                      ? orangeSelect
                      : uploadedFiles?.pan_card_img &&
                        uploadedFiles?.pan_card_number
                      ? uploaded
                      : notUploaded
                  }
                  // style={{ width: "30px", height: "30px" }}
                />
              </div>
              <p className={styles.documentName}>Pancard </p>
            </div>{" "}
            {/* gst... */}
            <div className={styles.documentCard}>
              <div>
                <img
                  src={
                    (uploadedFiles?.gst_register_img &&
                      !uploadedFiles?.gst_register_number) ||
                    (!uploadedFiles?.gst_register_img &&
                      uploadedFiles?.gst_register_number)
                      ? orangeSelect
                      : uploadedFiles?.gst_register_img &&
                        uploadedFiles?.gst_register_number
                      ? uploaded
                      : notUploaded
                  }
                  // style={{ width: "30px", height: "30px" }}
                />
              </div>
              <p className={styles.documentName}>GST Registration </p>
            </div>{" "}
            {/* Cin... */}
            <div className={styles.documentCard}>
              <div>
                <img
                  src={
                    (uploadedFiles?.cin_img && !uploadedFiles?.cin_number) ||
                    (!uploadedFiles?.cin_img && uploadedFiles?.cin_number)
                      ? orangeSelect
                      : uploadedFiles?.cin_img && uploadedFiles?.cin_number
                      ? uploaded
                      : notUploaded
                  }
                  // style={{ width: "30px", height: "30px" }}
                />
              </div>
              <p className={styles.documentName}>CIN No. </p>
            </div>
            {/* msme.. */}
            <div className={styles.documentCard}>
              <div>
                <img
                  src={
                    (uploadedFiles?.msme_register_img &&
                      !uploadedFiles?.msme_register_number) ||
                    (!uploadedFiles?.msme_register_img &&
                      uploadedFiles?.msme_register_number)
                      ? orangeSelect
                      : uploadedFiles?.msme_register_img &&
                        uploadedFiles?.msme_register_number
                      ? uploaded
                      : notUploaded
                  }
                  // style={{ width: "30px", height: "30px" }}
                />
              </div>
              <p className={styles.documentName}>MSME Registration </p>
            </div>{" "}
          </div>
          {!isAllDocumentsUploaded() && (
            <button
              disabled={loading}
              className={
                loading
                  ? styles.loadingStyles
                  : !areAllDocumentsVerified()
                  ? styles.submitBtn
                  : styles.pendingBtn
              }
              onClick={uploadRegistrationDetail}
            >
              Submit
            </button>
          )}

          {isAllDocumentsUploaded() && (
            <button
              className={loading ? styles.loadingStyles : styles.uploadedBtn}
              disabled={loading}
              onClick={uploadRegistrationDetail}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default DealerDocuments;
