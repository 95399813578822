import React, { useEffect, useState } from "react";
import styles from "./DDUserAreaAlloted.module.css";
import CustomTable from "../CustomTable/CustomTable";
import CustomPagination from "../CustomPagination/CustomPagination";
import {
  helperdayMonthYearFull,
  convertTo12HourFormat,
} from "../../Utils/helper";
import { token_api } from "../../Utils/network";
import { useNavigate, useParams } from "react-router-dom";

const DDUserAreaAlloted = ({ user }: props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [areaAllotedData, setAreaAllotedData] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;

  const handleRouteToServiceBooking = (serviceBookingId) => {
    navigate(`/bookings/service-booking-details/${serviceBookingId}`);
  };

  const getPilotAreaAllotedData = async (filterobject) => {
    try {
      const fetchList = await token_api.get(`service/pilot/slots?pilot=${id}`, {
        params: { ...filterobject },
      });
      if (fetchList?.status !== 200) {
        throw new Error("Network Error");
      }
      setPaginationData(fetchList?.data);

      let modifiedArray = fetchList?.data?.data?.map((item) => {
        return {
          id: item?.id,
          pilot: `${item?.pilot?.first_name} ${item?.pilot?.last_name}`,
          date: helperdayMonthYearFull(item?.slot_date),
          slot: convertTo12HourFormat(item?.slot_time),
          service_booking: item?.is_available ? (
            <p
              style={{
                padding: "10px 0px",
                boxSizing: "border-box",
                borderRadius: "20px",
                fontWeight: "400",
                fontSize: "18px",
                textAlign: "center",
                margin: "0 auto",
                width: "150px",
                backgroundColor: "rgba(198, 239, 205, 1)",
                color: "rgba(3, 98, 2, 1)",
              }}
            >
              Available
            </p>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleRouteToServiceBooking(item?.service_booking?.id);
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                  textAlign: "center",
                  color: "rgba(233, 94, 51, 1)",
                  textDecoration: "underline",
                  textDecorationColor: "rgba(233, 94, 51, 0.25)",
                }}
              >
                {item?.service_booking?.full_name}
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                  textAlign: "center",
                  color: "rgba(233, 94, 51, 1)",
                  textDecoration: "underline",
                  textDecorationColor: "rgba(233, 94, 51, 0.25)",
                }}
              >
                Booking ID : {item?.service_booking?.id}
              </p>
            </div>
          ),
          // pincode: item?.pincode,
          block_name: item?.block_name ? item?.block_name : "N.A",

          state: item?.state || "N.A",
          district: item?.district || "N.A",
          // action: (
          //   <div
          //     style={{
          //       display: "flex",
          //       flexDirection: "row",
          //       alignItems: "center",
          //       justifyContent: "center",
          //       gap: "20px",
          //     }}
          //   >
          //     <div
          //       style={{ cursor: "pointer" }}
          //       onClick={() => {
          //         handleEditSlot(item);
          //       }}
          //     >
          //       <img src={editIcon} />
          //     </div>
          //     <div
          //       style={{ cursor: "pointer" }}
          //       onClick={() => {
          //         handleDeleteSlotDialog(item?.id);
          //       }}
          //     >
          //       <img src={deleteIcon} />
          //     </div>
          //   </div>
          // ),
        };
      });

      setAreaAllotedData([...modifiedArray]);
    } catch (error) {
      console.log("FFFFFFsSS", error);
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      let filtersObject = {};
      if (pageState) {
        filtersObject.page = pageState;
      }

      getPilotAreaAllotedData(filtersObject);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [pageState]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topContainer}>
        <h1>Area Alloted</h1>
        {/* <button>Add Location</button> */}
      </div>
      <div className={styles.tableContainer}>
        <CustomTable
          columns={tableColumns}
          data={areaAllotedData}
          //   handleClick={handleRedirect}
          tableFor="Area Alloted"
        />
      </div>

      <div className={styles.paginationContainer}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>
    </div>
  );
};

export default DDUserAreaAlloted;

// const tableColumns = [
//   {
//     id: "start_date",
//     label: "Start Date",
//   },
//   {
//     id: "end_date",
//     label: "End Date",
//   },
//   {
//     id: "pincode",
//     label: "Pincode",
//   },
//   {
//     id: "district",
//     label: "District",
//   },
//   {
//     id: "state",
//     label: "State",
//   },
//   {
//     id: "status",
//     label: "Status",
//   },
// ];

const tableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "block_name",
    label: "Block Name",
  },
  {
    id: "pilot",
    label: "Pilot",
  },
  {
    id: "date",
    label: "Date",
  },
  {
    id: "slot",
    label: "Slot",
  },

  {
    id: "service_booking",
    label: "Service Booking",
  },

  // {
  //   id: "pincode",
  //   label: "Pincode",
  // },
  {
    id: "state",
    label: "State",
  },
  {
    id: "district",
    label: "District",
  },
  // {
  //   id: "action",
  //   label: "Action",
  // },
];

const tableData = [
  {
    id: 1,
    start_date: "01-05-2024",
    end_date: "31-05-2024",
    pincode: "532409",
    district: "Srikakulam",
    state: "Andhra Pradesh",
    status: "Active",
  },
  {
    id: 2,
    start_date: "01-05-2024",
    end_date: "31-05-2024",
    pincode: "532409",
    district: "Srikakulam",
    state: "Andhra Pradesh",
    status: "Active",
  },
  {
    id: 3,
    start_date: "01-05-2024",
    end_date: "31-05-2024",
    pincode: "532409",
    district: "Srikakulam",
    state: "Andhra Pradesh",
    status: "Inactive",
  },
  {
    id: 4,
    start_date: "01-05-2024",
    end_date: "31-05-2024",
    pincode: "532409",
    district: "Srikakulam",
    state: "Andhra Pradesh",
    status: "Active",
  },
  {
    id: 5,
    start_date: "01-05-2024",
    end_date: "31-05-2024",
    pincode: "532409",
    district: "Srikakulam",
    state: "Andhra Pradesh",
    status: "Inactive",
  },
];
