import { TextField } from "@mui/material";
import React from "react";

const DurationPicker = ({
  style = { width: "100%" },
  label,
  size = "small",
  onChange,
  name,
  error,
  value,
  helperText,
  ...props
}) => {
  return (
    <>
      <TextField
        id="time"
        label={label}
        type="time"
        name={name}
        value={value}
        defaultValue="00:00:00"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        onChange={onChange}
        style={style}
        size={size}
        error={error}
        helperText={helperText}
      />
    </>
  );
};

export default DurationPicker;
