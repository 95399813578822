import React from "react";

const TitleSubTitleVertical = ({ title, subtitle, children }) => {
  return (
    <div>
      <p className={`grayTitleFont`} style={{ marginBottom: "5px" }}>
        {title}
      </p>
      <p style={{ marginTop: "0px" }}>{subtitle}</p>
    </div>
  );
};

export default TitleSubTitleVertical;
