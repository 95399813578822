import React, { useState, useRef, useEffect } from "react";
import styles from "./Registration.module.css";
import uploaded from "../../Assets/greenUploaded.svg";
import notUploaded from "../../Assets/greyUploaded.svg";
import orangeSelect from "../../Assets/orangeUploaded.svg";
import uploadVector from "../../Assets/uploadVector.svg";
import uploadFile from "../../Assets/uploadFile.svg";
import crossIcon from "../../Assets/orangeCross.svg";
import { useSelector } from "react-redux";
import { base_url, token_api } from "../../Utils/network";
import { useParams } from "react-router-dom";
import downloadIcon from "../../Assets/pdfDownload.svg";
import axios from "axios";
import Switch from "@mui/material/Switch";
import verified from "../../Assets/userProfile/verified.svg";
import notverified from "../../Assets/userProfile/notVerified.svg";

import {
  BannerAndMediaService,
  mediaDataForApi,
} from "../../Pages/Courses/courseUtility";

const Registration = () => {
  const { id } = useParams();
  const { access_token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const aadharInput = useRef(null);
  const passportPhotoInput = useRef(null);
  const marksheet10thInput = useRef(null);
  const medicalFitnessInput = useRef(null);
  const otherPhotoProofInput = useRef(null);
  const registrationFormInput = useRef(null);
  const selfAttestedInput = useRef(null);
  const [courseMedia, setCourseMedia] = useState([]);
  const [crsMediaPreview, setCrsMediaPreview] = useState([]);

  const [aadharObj, setAadharObj] = useState("");
  const [passportPhotoObj, setPassportPhotoObj] = useState("");
  const [marksheet10thObj, setMarksheet10thObj] = useState("");
  const [medicalFitnessObj, setMedicalFitnessObj] = useState("");
  const [otherPhotoProofObj, setOtherPhotoProofObj] = useState("");
  const [registrationFormObj, setRegistartionFormObj] = useState("");
  const [selfAttestedFirstObj, setSelfAttestedFirstObj] = useState("");
  const [selfAttestedSecondObj, setSelfAttestedSecondObj] = useState("");

  const [aadharPreview, setAadharPreview] = useState("");
  const [passportPhotoPreview, setPassprtPhotoPreview] = useState("");
  const [marksheet10thPreview, setMarksheet10thPreview] = useState("");
  const [medicalFitnessPreview, setMedicalFitnessPreview] = useState("");
  const [otherPhotoProofPreview, setOtherPhotoProofPreview] = useState("");
  const [registrationFormPreview, setRegistrationFormPreview] = useState("");
  const [selfAttestedPreviewFirst, setSelfAttestedPreviewFirst] = useState("");
  const [selfAttestedPreviewSecond, setSelfAttestedPreviewSecond] =
    useState("");

  const [adharVerified, setAdharVerified] = useState(false);
  const [passportphotoVerified, setPassportphotoVerified] = useState(false);
  const [marksheet10thVerified, setMarksheet10thVerified] = useState(false);
  const [medicalfitnessVerified, setMedicalfitnessVerified] = useState(false);
  const [otherPhotoProofVerified, setOtherphotoproofVerified] = useState(false);
  const [registrationformVerified, setRegistrationFormVerified] =
    useState(false);
  const [selfAttestedFirstVerified, setSelfAttestedFirstVerified] =
    useState(false);
  const [selfAttestedSecondVerified, setSelfAttestedSecondVerified] =
    useState(false);
  const [updatedAdhar, setUpdatedAdhar] = useState(false);
  const [updatedPassport, setUpdatedPassport] = useState(false);
  const [updatedMarksheet, setUpdatedMarksheet] = useState(false);
  const [updatedMedical, setUpdatedMedical] = useState(false);
  const [updatedOtherPhotoProof, setUpdatedOtherPhotoProof] = useState(false);
  const [updatedRegistrationForm, setUpdatedRegistrationForm] = useState(false);
  const [updatedSelfAttestedFirst, setUpdatedSelfAttestedFirst] =
    useState(false);
  const [updatedSelfAttestedSecond, setUpdatedSelfAttestedSecond] =
    useState(false);

  const [documentCount, setDocumentCount] = useState(0);
  const [userRegistrationId, setUserRegId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [updatedDocuments, setUpdatedDocuments] = useState({});

  const handleClick = (type) => {
    if (type === "aadhar") {
      aadharInput.current.click();
    } else if (type === "passport_photo") {
      passportPhotoInput.current.click();
    } else if (type === "marksheet_10th") {
      marksheet10thInput.current.click();
    } else if (type === "medical_fitness") {
      medicalFitnessInput.current.click();
    } else if (type === "other_photo_proof") {
      otherPhotoProofInput.current.click();
    } else if (type === "registration_form") {
      registrationFormInput.current.click();
    } else if (type === "attest_media") {
      selfAttestedInput.current.click();
    }
  };

  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleDrop(e, type) {
    e.preventDefault();
    if (e?.target?.files) {
      setDocumentCount((prevCount) => prevCount + 1);
      if (type === "attest_media") {
        // mediaService
        const mediaService = new BannerAndMediaService(
          e.target.files,
          ""
        ).addCourseMedia();
        setCrsMediaPreview((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgsPReview);
          return prevCopy;
        });
        setCourseMedia((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgFiles);
          return prevCopy;
        });
      } else if (type === "aadhar") {
        setAadharObj(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setAadharPreview(imageUrl);
      } else if (type === "passport_photo") {
        setPassportPhotoObj(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setPassprtPhotoPreview(imageUrl);
      } else if (type === "marksheet_10th") {
        setMarksheet10thObj(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setMarksheet10thPreview(imageUrl);
      } else if (type === "medical_fitness") {
        setMedicalFitnessObj(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setMedicalFitnessPreview(imageUrl);
      } else if (type === "other_photo_proof") {
        setOtherPhotoProofObj(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setOtherPhotoProofPreview(imageUrl);
      } else if (type === "registration_form") {
        setRegistartionFormObj(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setRegistrationFormPreview(imageUrl);
        // } else if (type === "attested_proof_1") {
        //   setSelfAttestedObj(e?.target?.files[0]);
        //   const imageUrl = URL.createObjectURL(e.target.files[0]);
        //   setSelfAttestedPreview(imageUrl);
        // }
      }
    } else if (e?.dataTransfer?.files) {
      // dragged
      // type=c_media or banner
      if (type === "attest_media") {
        const mediaService = new BannerAndMediaService(
          e?.dataTransfer?.files,
          ""
        ).addCourseMedia();
        setCrsMediaPreview((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgsPReview);
          return prevCopy;
        });
        setCourseMedia((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgFiles);
          return prevCopy;
        });
      }
    } else if (type === "aadhar") {
      setAadharObj(e?.target?.files[0]);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setAadharPreview(imageUrl);
    } else if (type === "passport_photo") {
      setPassportPhotoObj(e?.target?.files[0]);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setPassprtPhotoPreview(imageUrl);
    } else if (type === "marksheet_10th") {
      setMarksheet10thObj(e?.target?.files[0]);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setMarksheet10thPreview(imageUrl);
    } else if (type === "medical_fitness") {
      setMedicalFitnessObj(e?.target?.files[0]);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setMedicalFitnessPreview(imageUrl);
    } else if (type === "other_photo_proof") {
      setOtherPhotoProofObj(e?.target?.files[0]);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setOtherPhotoProofPreview(imageUrl);
    } else if (type === "registration_form") {
      setRegistartionFormObj(e?.target?.files[0]);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setRegistrationFormPreview(imageUrl);
      // } else if (type === "attested_proof_1") {
      //   setSelfAttestedObj(e?.target?.files[0]);
      //   const imageUrl = URL.createObjectURL(e.target.files[0]);
      //   setSelfAttestedPreview(imageUrl);
      // }
    }
  }

  //User Individual Registration Detail Deleting ---> DELETE API Not Implemented.....
  const handleRemoveImage = async (type) => {
    setDocumentCount((prevCount) => prevCount - 1);

    if (type === "aadhar") {
      setAadharPreview("");
    } else if (type === "passport_photo") {
      setPassprtPhotoPreview("");
    } else if (type === "marksheet_10th") {
      setMarksheet10thPreview("");
    } else if (type === "medical_fitness") {
      setMedicalFitnessPreview("");
    } else if (type === "other_photo_proof") {
      setOtherPhotoProofPreview("");
    } else if (type === "registration_form") {
      setRegistrationFormPreview("");
    } else if (type === "attested_proof_1") {
      setSelfAttestedPreviewFirst("");
    } else if (type === "attested_proof_2") {
      setSelfAttestedPreviewSecond("");
    }
    if (userRegistrationId) {
      try {
        let registrationData = new FormData();

        if (type === "aadhar") registrationData.append("aadhar", "");
        if (type === "passport_photo")
          registrationData.append("passport_photo", "");
        if (type === "marksheet_10th")
          registrationData.append("marksheet_10th", "");
        if (type === "medical_fitness")
          registrationData.append("medical_fitness", "");
        if (type === "other_photo_proof")
          registrationData.append("other_photo_proof", "");
        if (type === "registration_form")
          registrationData.append("registration_form", "");
        if (type === "attested_proof_1")
          registrationData.append("attested_proof_1", "");
        if (type === "attested_proof_2")
          registrationData.append("attested_proof_2", "");

        await axios({
          method: "patch",
          url: `${base_url}users/registration/documents/${userRegistrationId}/`,
          data: registrationData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
        });
        alert(`${type} Document Updated Successfully!`);
        setAadharObj("");
        setPassportPhotoObj("");
        setMarksheet10thObj("");
        setMedicalFitnessObj("");
        setOtherPhotoProofObj("");
        setRegistartionFormObj("");
        setSelfAttestedFirstObj("");
        setSelfAttestedSecondObj("");
        getRegistrationFiles();
      } catch (error) {
        console.log(error);
      }
    }
  };

  //User Registration Details  ---> GET API

  const getRegistrationFiles = () => {
    token_api
      .get(`users/registration/profile_documents?user=${id}`)
      .then((response) => {
        // setUserRegProofs(response?.data?.data[0]);
        if (response?.status === 200 || response?.status === 201) {
          setUploadedFiles(response?.data?.data);
          const {
            id,
            aadhar,
            passport_photo,
            marksheet_10th,
            medical_fitness,
            other_photo_proof,
            registration_form,
            attested_proof_1,
            attested_proof_2,
            is_aadhar_verified,
            is_passport_photo_verified,
            is_marksheet_10th_verified,
            is_medical_fitness_verified,
            is_other_photo_proof_verified,
            is_registration_form_verified,
            is_attested_proof_1_verified,
            is_attested_proof_2_verified,
          } = response?.data?.data;
          setUserRegId(id);
          setAadharPreview(aadhar);
          setPassprtPhotoPreview(passport_photo);
          setMarksheet10thPreview(marksheet_10th);
          setMedicalFitnessPreview(medical_fitness);
          setOtherPhotoProofPreview(other_photo_proof);
          setRegistrationFormPreview(registration_form);
          setSelfAttestedPreviewFirst(attested_proof_1);
          setSelfAttestedPreviewSecond(attested_proof_2);
          setAadharObj("");
          setPassportPhotoObj("");
          setMarksheet10thObj("");
          setMedicalFitnessObj("");
          setOtherPhotoProofObj("");
          setSelfAttestedFirstObj("");
          setSelfAttestedSecondObj("");
          setRegistartionFormObj("");
          setAdharVerified(is_aadhar_verified);
          setPassportphotoVerified(is_passport_photo_verified);
          setMarksheet10thVerified(is_marksheet_10th_verified);
          setMedicalfitnessVerified(is_medical_fitness_verified);
          setOtherphotoproofVerified(is_other_photo_proof_verified);
          setRegistrationFormVerified(is_registration_form_verified);
          setSelfAttestedFirstVerified(is_attested_proof_1_verified);
          setSelfAttestedSecondVerified(is_attested_proof_2_verified);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRegistrationFiles();
  }, []);

  useEffect(() => {
    if (!courseMedia?.length === 0 && courseMedia?.length === 1) {
      setSelfAttestedFirstObj(courseMedia[0]?.media);
      setSelfAttestedPreviewFirst(courseMedia[0]?.media_preview);
    } else {
      setSelfAttestedFirstObj(courseMedia[courseMedia?.length - 2]?.media);
      setSelfAttestedPreviewFirst(
        courseMedia[courseMedia?.length - 2]?.media_preview
      );
      setSelfAttestedSecondObj(courseMedia[courseMedia?.length - 1]?.media);
      setSelfAttestedPreviewSecond(
        courseMedia[courseMedia?.length - 1]?.media_preview
      );
    }
  }, [courseMedia]);

  const uploadRegistrationDetail = async () => {
    try {
      setLoading(true);
      if (userRegistrationId) {
        let registrationData = new FormData();

        if (aadharObj) registrationData.append("aadhar", aadharObj);
        if (passportPhotoObj)
          registrationData.append("passport_photo", passportPhotoObj);
        if (marksheet10thObj)
          registrationData.append("marksheet_10th", marksheet10thObj);
        if (medicalFitnessObj)
          registrationData.append("medical_fitness", medicalFitnessObj);
        if (otherPhotoProofObj)
          registrationData.append("other_photo_proof", otherPhotoProofObj);
        if (registrationFormObj)
          registrationData.append("registration_form", registrationFormObj);
        if (selfAttestedFirstObj)
          registrationData.append("attested_proof_1", selfAttestedFirstObj);
        if (selfAttestedSecondObj)
          registrationData.append("attested_proof_2", selfAttestedSecondObj);
        // if (adharVerified)
        registrationData.append("is_aadhar_verified", adharVerified);
        // if (passportphotoVerified)
        registrationData.append(
          "is_passport_photo_verified",
          passportphotoVerified
        );
        // if (marksheet10thVerified)
        registrationData.append(
          "is_marksheet_10th_verified",
          marksheet10thVerified
        );
        // if (medicalfitnessVerified)
        registrationData.append(
          "is_medical_fitness_verified",
          medicalfitnessVerified
        );
        // if (otherPhotoProofVerified)
        registrationData.append(
          "is_other_photo_proof_verified",
          otherPhotoProofVerified
        );
        // if (registrationformVerified)
        registrationData.append(
          "is_registration_form_verified",
          registrationformVerified
        );
        // if (selfAttestedFirstVerified)
        registrationData.append(
          "is_attested_proof_1_verified",
          selfAttestedFirstVerified
        );
        // if (selfAttestedSecondVerified)
        registrationData.append(
          "is_attested_proof_2_verified",
          selfAttestedSecondVerified
        );

        await axios({
          method: "patch",
          url: `${base_url}users/registration/documents/${userRegistrationId}/`,
          data: registrationData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
        });
        alert("Registration Details Updated Successfully!");
        setAadharObj("");
        setPassportPhotoObj("");
        setMarksheet10thObj("");
        setMedicalFitnessObj("");
        setOtherPhotoProofObj("");
        setRegistartionFormObj("");
        setSelfAttestedFirstObj("");
        setSelfAttestedSecondObj("");
        setAdharVerified(false);
        setPassportphotoVerified(false);
        setMarksheet10thVerified(false);
        setMedicalfitnessVerified(false);
        setOtherphotoproofVerified(false);
        setRegistrationFormVerified(false);
        setSelfAttestedFirstVerified(false);
        setSelfAttestedSecondVerified(false);

        getRegistrationFiles();
      } else {
        if (
          aadharObj ||
          passportPhotoObj ||
          marksheet10thObj ||
          medicalFitnessObj ||
          otherPhotoProofObj ||
          registrationFormObj ||
          selfAttestedFirstObj ||
          selfAttestedSecondObj
        ) {
          let registrationData = new FormData();
          registrationData.append("user", id);

          if (aadharObj) registrationData.append("aadhar", aadharObj);
          if (passportPhotoObj)
            registrationData.append("passport_photo", passportPhotoObj);
          if (marksheet10thObj)
            registrationData.append("marksheet_10th", marksheet10thObj);
          if (medicalFitnessObj)
            registrationData.append("medical_fitness", medicalFitnessObj);
          if (otherPhotoProofObj)
            registrationData.append("other_photo_proof", otherPhotoProofObj);
          if (registrationFormObj)
            registrationData.append("registration_form", registrationFormObj);
          if (selfAttestedFirstObj)
            registrationData.append("attested_proof_1", selfAttestedFirstObj);
          if (selfAttestedSecondObj)
            registrationData.append("attested_proof_2", selfAttestedSecondObj);
          if (adharVerified)
            registrationData.append("is_aadhar_verified", adharVerified);
          if (passportphotoVerified)
            registrationData.append(
              "is_passport_photo_verified",
              passportphotoVerified
            );
          if (marksheet10thVerified)
            registrationData.append(
              "is_marksheet_10th_verified",
              marksheet10thVerified
            );
          if (medicalfitnessVerified)
            registrationData.append(
              "is_medical_fitness_verified",
              medicalfitnessVerified
            );
          if (otherPhotoProofVerified)
            registrationData.append(
              "is_registration_form_verified",
              otherPhotoProofVerified
            );
          if (registrationformVerified)
            registrationData.append(
              "is_registration_form_verified",
              registrationformVerified
            );
          if (selfAttestedFirstVerified)
            registrationData.append(
              "is_attested_proof_1_verified",
              selfAttestedFirstVerified
            );
          if (selfAttestedSecondVerified)
            registrationData.append(
              "is_attested_proof_2_verified",
              selfAttestedSecondVerified
            );

          await axios({
            method: "post",
            url: `${base_url}users/registration/documents/`,
            data: registrationData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access_token}`,
            },
          });
          alert("Registration Details Uploaded Successfully!");
          setAadharObj("");
          setPassportPhotoObj("");
          setMarksheet10thObj("");
          setMedicalFitnessObj("");
          setOtherPhotoProofObj("");
          setRegistartionFormObj("");
          setSelfAttestedFirstObj("");
          setSelfAttestedSecondObj("");
          setAdharVerified(false);
          setPassportphotoVerified(false);
          setMarksheet10thVerified(false);
          setMedicalfitnessVerified(false);
          setOtherphotoproofVerified(false);
          setRegistrationFormVerified(false);
          setSelfAttestedFirstVerified(false);
          setSelfAttestedSecondVerified(false);

          getRegistrationFiles();
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadDocument = (docValue, docType) => {
    if (docValue) {
      const link = document.createElement("a");
      link.href = docValue;
      link.target = "_blank";
      link.download = "document.jpeg";

      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    }
  };

  const handleVerifyDocument = (type, checked) => {

    if (type === "adharVerified") {
      setAdharVerified(!adharVerified);
      setUpdatedAdhar(checked);
    } else if (type === "passportphotoVerified") {
      setPassportphotoVerified(!passportphotoVerified);
      setUpdatedPassport(checked);
    } else if (type === "marksheet10thVerified") {
      setMarksheet10thVerified(!marksheet10thVerified);
      setUpdatedMarksheet(checked);
    } else if (type === "medicalfitnessVerified") {
      setMedicalfitnessVerified(!medicalfitnessVerified);
      setUpdatedMedical(checked);
    } else if (type === "otherPhotoProofVerified") {
      setOtherphotoproofVerified(!otherPhotoProofVerified);
      setUpdatedOtherPhotoProof(checked);
    } else if (type === "registrationformVerified") {
      setRegistrationFormVerified(!registrationformVerified);
      setUpdatedRegistrationForm(checked);
    } else if (type === "selfAttestedFirstVerified") {
      setSelfAttestedFirstVerified(!selfAttestedFirstVerified);
      setUpdatedSelfAttestedFirst(checked);
    } else if (type === "selfAttestedSecondVerified") {
      setSelfAttestedSecondVerified(!selfAttestedSecondVerified);
      setUpdatedSelfAttestedSecond(checked);
    }
  };

  const isAllDocumentsUploaded = () =>
    uploadedFiles?.aadhar &&
    uploadedFiles?.attested_proof_1 &&
    uploadedFiles?.attested_proof_2 &&
    uploadedFiles?.passport_photo &&
    uploadedFiles?.marksheet_10th &&
    uploadedFiles?.medical_fitness &&
    uploadedFiles?.other_photo_proof &&
    uploadedFiles?.registration_form;

  const areAllDocumentsVerified = () =>
    uploadedFiles?.is_aadhar_verified &&
    uploadedFiles?.is_passport_photo_verified &&
    uploadedFiles?.is_attested_proof_1_verified &&
    uploadedFiles?.is_attested_proof_2_verified &&
    uploadedFiles?.is_marksheet_10th_verified &&
    uploadedFiles?.is_medical_fitness_verified &&
    uploadedFiles?.is_other_photo_proof_verified &&
    uploadedFiles?.is_registration_form_verified;

  return (
    <>
      <div className={styles.topContainer}>
        <h3>Registration Details</h3>
        <div className={styles.noOfCon}>
          <p className={styles.ountText}>
            No of Documents Uploaded : <span>{documentCount}</span> / 7
          </p>
        </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.leftContainer}>
          {/* Aadhar Card Details Card.... */}
          <div className={styles.leftDetailsCard}>
            <h1 className={styles.mainHeading}>Aadhar Card</h1>
            <p className={styles.greyText}>
              Aadhar number must be linked with your Email and Mobile Number
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "40px",
              }}
            >
              {aadharPreview && (
                <div className={styles.selectedImage}>
                  <img
                    src={aadharPreview}
                    style={{ width: "100%", height: "auto" }}
                    alt="Aadhar Proof"
                  />
                  <div
                    className={styles.crossIcon}
                    onClick={() => handleRemoveImage("aadhar")}
                  >
                    <img src={crossIcon} />
                  </div>
                  {aadharPreview.startsWith("https") && (
                    <div
                      className={styles.downloadIcon}
                      onClick={() =>
                        handleDownloadDocument(aadharPreview, "aadhar")
                      }
                    >
                      <img src={downloadIcon} />
                    </div>
                  )}
                </div>
              )}

              {aadharPreview && (
                <Switch
                  checked={adharVerified}
                  onChange={() => {
                    handleVerifyDocument("adharVerified", adharVerified);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}

              {aadharPreview &&
                (uploadedFiles?.is_aadhar_verified ? (
                  <span className={styles.Vstatus}>
                    {" "}
                    <img src={verified} /> verified{" "}
                  </span>
                ) : (
                  <span className={styles.NVstatus}>
                    {" "}
                    <img src={notverified} /> Not verified{" "}
                  </span>
                ))}
            </div>

            <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "aadhar")}
            >
              <div
                className={styles.chooseCon}
                onClick={(e) => handleClick("aadhar")}
              >
                <img src={uploadVector} />
                <p className={styles.maxText}>Choose Files</p>
                <input
                  ref={aadharInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "aadhar")}
                  style={{ display: "none" }}
                />
                <button
                  className={styles.browseFilesText}
                  onChange={(e) => handleDrop(e, "aadhar")}
                >
                  Browse Files
                </button>
              </div>
            </div>
            {/* <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "aadhar")}
            >
              <div
                className={styles.dragDropCon}
                onClick={() => handleClick("aadhar")}
              >
                <img src={uploadFile} />
                <h3 className={styles.dropText}>Drag and drop files here</h3>
                <p className={styles.maxText}>(Maximum file size is 100MB)</p>
                <input
                  ref={aadharInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"

                  onChange={(e) => handleDrop(e, "aadhar")}
                  style={{ display: "none" }}
                />
              </div>
            </div> */}
          </div>

          {/* Passport Details Card */}
          <div className={styles.leftDetailsCard}>
            <h1 className={styles.mainHeading}>
              Passport/Voter ID/Driver License/Ration Card
            </h1>
            <p className={styles.greyText}>Any 1</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "40px",
              }}
            >
              {passportPhotoPreview && (
                <div className={styles.selectedImage}>
                  <img
                    src={passportPhotoPreview}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <div
                    className={styles.crossIcon}
                    onClick={() => handleRemoveImage("passport_photo")}
                  >
                    <img src={crossIcon} />
                  </div>
                  {passportPhotoPreview.startsWith("https") && (
                    <div
                      className={styles.downloadIcon}
                      onClick={() =>
                        handleDownloadDocument(
                          passportPhotoPreview,
                          "passport_photo"
                        )
                      }
                    >
                      <img src={downloadIcon} />
                    </div>
                  )}
                </div>
              )}

              {passportPhotoPreview && (
                <Switch
                  checked={passportphotoVerified}
                  onChange={() => {
                    handleVerifyDocument("passportphotoVerified");
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              {passportPhotoPreview &&
                (uploadedFiles?.is_passport_photo_verified ? (
                  <span className={styles.Vstatus}>
                    {" "}
                    <img src={verified} /> verified{" "}
                  </span>
                ) : (
                  <span className={styles.NVstatus}>
                    {" "}
                    <img src={notverified} /> Not verified{" "}
                  </span>
                ))}
            </div>
            <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "passport_photo")}
            >
              <div
                className={styles.chooseCon}
                onClick={(e) => handleClick("passport_photo")}
              >
                <img src={uploadVector} />
                <p className={styles.maxText}>Choose Files</p>
                <input
                  ref={passportPhotoInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "passport_photo")}
                  style={{ display: "none" }}
                />
                <button
                  className={styles.browseFilesText}
                  onChange={(e) => handleDrop(e, "passport_photo")}
                >
                  Browse Files
                </button>
              </div>
            </div>

            {/* <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "passport_photo")}
            >
              <div
                className={styles.dragDropCon}
                onClick={(e) => handleClick("passport_photo")}
              >
                <img src={uploadFile} />
                <h3 className={styles.dropText}>Drag and drop files here</h3>
                <p className={styles.maxText}>(Maximum file size is 100MB)</p>
                <input
                  ref={passportPhotoInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"

                  onChange={(e) => handleDrop(e, "passport_photo")}
                  style={{ display: "none" }}
                />
              </div>
            </div> */}
          </div>

          {/* 10th details Card.... */}

          <div className={styles.leftDetailsCard}>
            <h1 className={styles.mainHeading}>
              Copy Of 10th Certificate/Marksheet
            </h1>
            <p className={styles.greyText}>Any 1</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "40px",
              }}
            >
              {marksheet10thPreview && (
                <div className={styles.selectedImage}>
                  <img
                    src={marksheet10thPreview}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <div
                    className={styles.crossIcon}
                    onClick={() => handleRemoveImage("marksheet_10th")}
                  >
                    <img src={crossIcon} />
                  </div>
                  {marksheet10thPreview.startsWith("https") && (
                    <div
                      className={styles.downloadIcon}
                      onClick={() =>
                        handleDownloadDocument(
                          marksheet10thPreview,
                          "marksheet_10th"
                        )
                      }
                    >
                      <img src={downloadIcon} />
                    </div>
                  )}
                </div>
              )}

              {marksheet10thPreview && (
                <Switch
                  checked={marksheet10thVerified}
                  onChange={() => {
                    handleVerifyDocument("marksheet10thVerified");
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              {marksheet10thPreview &&
                (uploadedFiles?.is_marksheet_10th_verified ? (
                  <span className={styles.Vstatus}>
                    {" "}
                    <img src={verified} /> verified{" "}
                  </span>
                ) : (
                  <span className={styles.NVstatus}>
                    {" "}
                    <img src={notverified} /> Not verified{" "}
                  </span>
                ))}
            </div>

            <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "marksheet_10th")}
            >
              <div
                className={styles.chooseCon}
                onClick={(e) => handleClick("marksheet_10th")}
              >
                <img src={uploadVector} />
                <p className={styles.maxText}>Choose Files</p>
                <input
                  ref={marksheet10thInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "marksheet_10th")}
                  style={{ display: "none" }}
                />
                <button
                  className={styles.browseFilesText}
                  onChange={(e) => handleDrop(e, "marksheet_10th")}
                >
                  Browse Files
                </button>
              </div>
            </div>
            {/* <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "marksheet_10th")}
            >
              <div
                className={styles.dragDropCon}
                onClick={(e) => handleClick("marksheet_10th")}
              >
                <img src={uploadFile} />
                <h3 className={styles.dropText}>Drag and drop files here</h3>
                <p className={styles.maxText}>(Maximum file size is 100MB)</p>
                <input
                  ref={marksheet10thInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"

                  onChange={(e) => handleDrop(e, "marksheet_10th")}
                  style={{ display: "none" }}
                />
              </div>
            </div> */}
          </div>

          {/* Medical Certificate Card.... */}
          <div className={styles.leftDetailsCard}>
            <h1 className={styles.mainHeading}>
              Medical Certificate Of Fitness
            </h1>
            <p className={styles.greyText}>From a Certified MBBS Doctor</p>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "40px",
              }}
            >
              {" "}
              {medicalFitnessPreview && (
                <div className={styles.selectedImage}>
                  <img
                    src={medicalFitnessPreview}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <div
                    className={styles.crossIcon}
                    onClick={() => handleRemoveImage("medical_fitness")}
                  >
                    <img src={crossIcon} />
                  </div>
                  {medicalFitnessPreview.startsWith("https") && (
                    <div
                      className={styles.downloadIcon}
                      onClick={() =>
                        handleDownloadDocument(
                          medicalFitnessPreview,
                          "medical_fitness"
                        )
                      }
                    >
                      <img src={downloadIcon} />
                    </div>
                  )}
                </div>
              )}
              {medicalFitnessPreview && (
                <Switch
                  checked={medicalfitnessVerified}
                  onChange={() => {
                    handleVerifyDocument("medicalfitnessVerified");
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              {medicalFitnessPreview &&
                (uploadedFiles?.is_medical_fitness_verified ? (
                  <span className={styles.Vstatus}>
                    {" "}
                    <img src={verified} /> verified{" "}
                  </span>
                ) : (
                  <span className={styles.NVstatus}>
                    {" "}
                    <img src={notverified} /> Not verified{" "}
                  </span>
                ))}
            </div>
            <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "medical_fitness")}
            >
              <div
                className={styles.chooseCon}
                onClick={(e) => handleClick("medical_fitness")}
              >
                <img src={uploadVector} />
                <p className={styles.maxText}>Choose Files</p>
                <input
                  ref={medicalFitnessInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "medical_fitness")}
                  style={{ display: "none" }}
                />
                <button
                  className={styles.browseFilesText}
                  onChange={(e) => handleDrop(e, "medical_fitness")}
                >
                  Browse Files
                </button>
              </div>
            </div>

            {/* <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "medical_fitness")}
            >
              <div
                className={styles.dragDropCon}
                onClick={(e) => handleClick("medical_fitness")}
              >
                <img src={uploadFile} />
                <h3 className={styles.dropText}>Drag and drop files here</h3>
                <p className={styles.maxText}>(Maximum file size is 100MB)</p>
                <input
                  ref={medicalFitnessInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"

                  onChange={(e) => handleDrop(e, "medical_fitness")}
                  style={{ display: "none" }}
                />
              </div>
            </div> */}
          </div>

          {/* Photographs Card... */}
          <div className={styles.leftDetailsCard}>
            <h1 className={styles.mainHeading}>Photographs (Passport Sized)</h1>
            <p className={styles.greyText}>Photographs with White Background</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "40px",
              }}
            >
              {otherPhotoProofPreview && (
                <div className={styles.selectedImage}>
                  <img
                    src={otherPhotoProofPreview}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <div
                    className={styles.crossIcon}
                    onClick={() => handleRemoveImage("other_photo_proof")}
                  >
                    <img src={crossIcon} />
                  </div>
                  {otherPhotoProofPreview.startsWith("https") && (
                    <div
                      className={styles.downloadIcon}
                      onClick={() =>
                        handleDownloadDocument(
                          otherPhotoProofPreview,
                          "other_photo_proof"
                        )
                      }
                    >
                      <img src={downloadIcon} />
                    </div>
                  )}
                </div>
              )}
              {otherPhotoProofPreview && (
                <Switch
                  checked={otherPhotoProofVerified}
                  onChange={() => {
                    handleVerifyDocument("otherPhotoProofVerified");
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              {otherPhotoProofPreview &&
                (uploadedFiles?.is_other_photo_proof_verified ? (
                  <span className={styles.Vstatus}>
                    {" "}
                    <img src={verified} /> verified{" "}
                  </span>
                ) : (
                  <span className={styles.NVstatus}>
                    {" "}
                    <img src={notverified} /> Not verified{" "}
                  </span>
                ))}
            </div>
            <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "other_photo_proof")}
            >
              <div
                className={styles.chooseCon}
                onClick={(e) => handleClick("other_photo_proof")}
              >
                <img src={uploadVector} />
                <p className={styles.maxText}>Choose Files</p>
                <input
                  ref={otherPhotoProofInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "other_photo_proof")}
                  style={{ display: "none" }}
                />
                <button
                  className={styles.browseFilesText}
                  onChange={(e) => handleDrop(e, "other_photo_proof")}
                >
                  Browse Files
                </button>
              </div>
            </div>
            {/* <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "other_photo_proof")}
            >
              <div
                className={styles.dragDropCon}
                onClick={(e) => handleClick("other_photo_proof")}
              >
                <img src={uploadFile} />
                <h3 className={styles.dropText}>Drag and drop files here</h3>
                <p className={styles.maxText}>(Maximum file size is 100MB)</p>
                <input
                  ref={otherPhotoProofInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "other_photo_proof")}
                  style={{ display: "none" }}
                />
              </div>
            </div> */}
          </div>
          {/* Registration Form Card.... */}
          <div className={styles.leftDetailsCard}>
            <h1 className={styles.mainHeading}>Registration Form</h1>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "40px",
              }}
            >
              {registrationFormPreview && (
                <div className={styles.selectedImage}>
                  <img
                    src={registrationFormPreview}
                    style={{ width: "100%", height: "100%" }}
                  />
                  <div
                    className={styles.crossIcon}
                    onClick={() => handleRemoveImage("registration_form")}
                  >
                    <img src={crossIcon} />
                  </div>
                  {registrationFormPreview.startsWith("https") && (
                    <div
                      className={styles.downloadIcon}
                      onClick={() =>
                        handleDownloadDocument(
                          registrationFormPreview,
                          "registration_form"
                        )
                      }
                    >
                      <img src={downloadIcon} />
                    </div>
                  )}
                </div>
              )}
              {registrationFormPreview && (
                <Switch
                  checked={registrationformVerified}
                  onChange={() => {
                    handleVerifyDocument("registrationformVerified");
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              {registrationFormPreview &&
                (uploadedFiles?.is_registration_form_verified ? (
                  <span className={styles.Vstatus}>
                    {" "}
                    <img src={verified} /> verified{" "}
                  </span>
                ) : (
                  <span className={styles.NVstatus}>
                    {" "}
                    <img src={notverified} /> Not verified{" "}
                  </span>
                ))}
            </div>
            <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "registration_form")}
            >
              <div
                className={styles.chooseCon}
                onClick={(e) => handleClick("registration_form")}
              >
                <img src={uploadVector} />
                <p className={styles.maxText}>Choose Files</p>
                <input
                  ref={registrationFormInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "registration_form")}
                  style={{ display: "none" }}
                />
                <button
                  className={styles.browseFilesText}
                  onChange={(e) => handleDrop(e, "registration_form")}
                >
                  Browse Files
                </button>
              </div>
            </div>

            {/* <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "registration_form")}
            >
              <div
                className={styles.dragDropCon}
                onClick={(e) => handleClick("registration_form")}
              >
                <img src={uploadFile} />
                <h3 className={styles.dropText}>Drag and drop files here</h3>
                <p className={styles.maxText}>(Maximum file size is 100MB)</p>
                <input
                  ref={registrationFormInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  // multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "registration_form")}
                  style={{ display: "none" }}
                />
              </div>
            </div> */}
          </div>
          {/* Self-Attested Card.... */}
          <div className={styles.leftDetailsCard}>
            <h1 className={styles.mainHeading}>
              Self-Attested Copies Of ID Proofs
            </h1>
            <p className={styles.greyText}>Any-2</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {selfAttestedPreviewFirst && (
                  <div className={styles.selectedImage}>
                    <img
                      src={selfAttestedPreviewFirst}
                      style={{ width: "100%", height: "100%" }}
                    />
                    <div
                      className={styles.crossIcon}
                      onClick={() => handleRemoveImage("attested_proof_1")}
                    >
                      <img src={crossIcon} />
                    </div>
                    {selfAttestedPreviewFirst.startsWith("https") && (
                      <div
                        className={styles.downloadIcon}
                        onClick={() =>
                          handleDownloadDocument(
                            selfAttestedPreviewFirst,
                            "attested_proof_1"
                          )
                        }
                      >
                        <img src={downloadIcon} />
                      </div>
                    )}
                  </div>
                )}
                {selfAttestedPreviewFirst && (
                  <Switch
                    checked={selfAttestedFirstVerified}
                    onChange={() => {
                      handleVerifyDocument("selfAttestedFirstVerified");
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                )}
                {selfAttestedPreviewFirst &&
                  (uploadedFiles?.is_attested_proof_1_verified ? (
                    <span className={styles.Vstatus}>
                      {" "}
                      <img src={verified} /> verified{" "}
                    </span>
                  ) : (
                    <span className={styles.NVstatus}>
                      {" "}
                      <img src={notverified} /> Not verified{" "}
                    </span>
                  ))}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {selfAttestedPreviewSecond && (
                  <div className={styles.selectedImage}>
                    <img
                      src={selfAttestedPreviewSecond}
                      style={{ width: "100%", height: "100%" }}
                    />
                    <div
                      className={styles.crossIcon}
                      onClick={() => handleRemoveImage("attested_proof_2")}
                    >
                      <img src={crossIcon} />
                    </div>
                    {selfAttestedPreviewSecond.startsWith("https") && (
                      <div
                        className={styles.downloadIcon}
                        onClick={() =>
                          handleDownloadDocument(
                            selfAttestedPreviewSecond,
                            "attested_proof_2"
                          )
                        }
                      >
                        <img src={downloadIcon} />
                      </div>
                    )}
                  </div>
                )}
                {selfAttestedPreviewSecond && (
                  <Switch
                    checked={selfAttestedSecondVerified}
                    onChange={() => {
                      handleVerifyDocument("selfAttestedSecondVerified");
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                )}
                {selfAttestedPreviewSecond &&
                  (uploadedFiles?.is_attested_proof_2_verified ? (
                    <span className={styles.Vstatus}>
                      {" "}
                      <img src={verified} /> verified{" "}
                    </span>
                  ) : (
                    <span className={styles.NVstatus}>
                      {" "}
                      <img src={notverified} /> Not verified{" "}
                    </span>
                  ))}
              </div>
            </div>

            <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "attest_media")}
            >
              <div
                className={styles.chooseCon}
                onClick={(e) => handleClick("attest_media")}
              >
                <img src={uploadVector} />
                <p className={styles.maxText}>Choose Files</p>
                <input
                  ref={selfAttestedInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  multiple
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  onChange={(e) => handleDrop(e, "attest_media")}
                  style={{ display: "none" }}
                />
                <button
                  className={styles.browseFilesText}
                  onChange={(e) => handleDrop(e, "attest_media")}
                >
                  Browse Files
                </button>
              </div>
            </div>
            {/* <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, "attest_media")}
            >
              <div
                className={styles.dragDropCon}
                onClick={(e) => handleClick("attest_media")}
              >
                <img src={uploadFile} />
                <h3 className={styles.dropText}>Drag and drop files here</h3>
                <p className={styles.maxText}>(Maximum file size is 100MB)</p>
                <input
                  ref={selfAttestedInput}
                  type="file"
                  // id="myfile"
                  // name="myfile"
                  accept="image/jpeg,image/jpg,image/svg,image/png"
                  multiple
                  onChange={(e) => handleDrop(e, "attest_media")}
                  style={{ display: "none" }}
                />
              </div>
            </div> */}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <h1 className={styles.documentTitle}>Documents</h1>
          <div className={styles.documentList}>
            <div className={styles.documentCard}>
              <div>
                <img
                  src={
                    uploadedFiles?.aadhar && !uploadedFiles?.is_aadhar_verified
                      ? orangeSelect
                      : uploadedFiles?.aadhar &&
                        uploadedFiles?.is_aadhar_verified
                      ? uploaded
                      : notUploaded
                  }
                  // style={{ width: "30px", height: "30px" }}
                />
              </div>
              <p className={styles.documentName}>Aadhar Card</p>
            </div>
            <div className={styles.documentCard}>
              <div>
                <img
                  src={
                    uploadedFiles?.passport_photo &&
                    !uploadedFiles?.is_passport_photo_verified
                      ? orangeSelect
                      : uploadedFiles?.passport_photo &&
                        uploadedFiles?.is_passport_photo_verified
                      ? uploaded
                      : notUploaded
                  }
                  // style={{ width: "30px", height: "30px" }}
                />
              </div>
              <p className={styles.documentName}>
                Passport / Voter Id / Driver License / Ration Card
              </p>
            </div>
            <div className={styles.documentCard}>
              <div>
                <img
                  src={
                    uploadedFiles?.marksheet_10th &&
                    !uploadedFiles?.is_marksheet_10th_verified
                      ? orangeSelect
                      : uploadedFiles?.marksheet_10th &&
                        uploadedFiles?.is_marksheet_10th_verified
                      ? uploaded
                      : notUploaded
                  }
                  // style={{ width: "30px", height: "30px" }}
                />
              </div>
              <p className={styles.documentName}>
                Copy Of 10th Certificate/Marksheet
              </p>
            </div>{" "}
            <div className={styles.documentCard}>
              <div>
                <img
                  src={
                    uploadedFiles?.medical_fitness &&
                    !uploadedFiles?.is_medical_fitness_verified
                      ? orangeSelect
                      : uploadedFiles?.medical_fitness &&
                        uploadedFiles?.is_medical_fitness_verified
                      ? uploaded
                      : notUploaded
                  }
                  // style={{ width: "30px", height: "30px" }}
                />
              </div>
              <p className={styles.documentName}>
                Medial Certificate Of Fitness
              </p>
            </div>{" "}
            <div className={styles.documentCard}>
              <div>
                <img
                  src={
                    uploadedFiles?.other_photo_proof &&
                    !uploadedFiles?.is_other_photo_proof_verified
                      ? orangeSelect
                      : uploadedFiles?.other_photo_proof &&
                        uploadedFiles?.is_other_photo_proof_verified
                      ? uploaded
                      : notUploaded
                  }
                  // style={{ width: "30px", height: "30px" }}
                />
              </div>
              <p className={styles.documentName}>
                Photographs (Passport Sized)
              </p>
            </div>{" "}
            <div className={styles.documentCard}>
              <div>
                <img
                  src={
                    uploadedFiles?.registration_form &&
                    !uploadedFiles?.is_registration_form_verified
                      ? orangeSelect
                      : uploadedFiles?.registration_form &&
                        uploadedFiles?.is_registration_form_verified
                      ? uploaded
                      : notUploaded
                  }
                  // style={{ width: "30px", height: "30px" }}
                />
              </div>
              <p className={styles.documentName}>Registration Form</p>
            </div>{" "}
            <div className={styles.documentCard}>
              <div>
                <img
                  src={
                    (uploadedFiles?.attested_proof_1 ||
                      uploadedFiles?.attested_proof_2) &&
                    !(
                      uploadedFiles?.is_attested_proof_1_verified ||
                      uploadedFiles?.is_attested_proof_2_verified
                    )
                      ? orangeSelect
                      : (uploadedFiles?.attested_proof_1 ||
                          uploadedFiles?.attested_proof_2) &&
                        (uploadedFiles?.is_attested_proof_1_verified ||
                          uploadedFiles?.is_attested_proof_2_verified)
                      ? uploaded
                      : notUploaded
                  }
                  // style={{ width: "30px", height: "30px" }}
                />
              </div>
              <p className={styles.documentName}>
                Self-Attested Copies Of 2 ID Proofs
              </p>
            </div>
          </div>
          {!areAllDocumentsVerified() && (
            <button
              disabled={loading}
              className={
                loading
                  ? styles.loadingStyles
                  : isAllDocumentsUploaded()
                  ? styles.submitBtn
                  : styles.pendingBtn
              }
              onClick={uploadRegistrationDetail}
            >
              Submit
            </button>
          )}

          {areAllDocumentsVerified() && (
            <button
              className={loading ? styles.loadingStyles : styles.uploadedBtn}
              disabled={loading}
              onClick={uploadRegistrationDetail}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Registration;

const documentList = [
  {
    id: 1,
    name: "Aadhar Card",
    icon: uploaded,
    icon_2: notUploaded,
  },
  {
    id: 2,
    name: "Passport / Voter Id / Driver License / Ration Card",
    icon: uploaded,
  },
  {
    id: 3,
    name: "Copy Of 10th Certificate/Marksheet",
    icon: notUploaded,
  },
  {
    id: 4,
    name: "Medial Certificate Of Fitness",
    icon: notUploaded,
  },
  {
    id: 5,
    name: "Photographs (Passport Sized)",
    icon: notUploaded,
  },
  {
    id: 6,
    name: "Registration Form",
    icon: notUploaded,
  },
  {
    id: 7,
    name: "Self-Attested Copies Of 2 ID Proofs",
    icon: notUploaded,
  },
];
