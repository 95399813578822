export const Validate = (value) => {
  return new Promise((resolve, reject) => {
    const {
      first_name,
      last_name,
      mobile,
      // email,
      // dob,
      gender,
      gst,
      blood_group,
      email_verified,
      mobile_verified,
      height,
      weight,
    } = value;

    const errors = { error: false };
    const nameRegex = /^[A-Za-z]+$/;

    if (!first_name) {
      errors.first_name = "Please enter first name";
      errors.error = true;
    }

    if (!last_name) {
      errors.last_name = "Please enter last name";
      errors.error = true;
    } else if (!nameRegex.test(last_name)) {
      errors.last_name = "Last name should contain only letters";
      errors.error = true;
    }

    if (!mobile) {
      errors.mobile = "Please fill mobile no";
      errors.error = true;
    } else if (!/^\d{10}$/.test(mobile)) {
      errors.mobile = "Mobile number must be 10 digits";
      errors.error = true;
    }

    // if (!email && !/^\S+@\S+\.\S+$/.test(email)) {
    //   errors.email = "Please enter a valid email address";
    //   errors.error = true;
    // }

    // if (!dob) {
    //   errors.dob = "Please fill date of birth";
    //   errors.error = true;
    // }

    if (!gender || !gender[0]) {
      errors.gender = "Please fill your gender details";
      errors.error = true;
    }
    if (!mobile_verified || !mobile_verified[0]) {
      errors.mobile_verified = "Please select mobile verification status";
      errors.error = true;
    }
    // if (!email_verified || !email_verified[0]) {
    //   errors.email_verified = "Please select email verification status";
    //   errors.error = true;
    // }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};

export const ValidateAddress = (addressData, selectedBlock) => {
  return new Promise((resolve, reject) => {
    const {
      area,
      area_in_acres,
      city,
      country,
      district,
      line_1,
      line_2,
      plot_no,
      line_kms,
      pincode,
      state,
      tehsil,
    } = addressData;

    const errors = { error: false };

    if (!area) {
      errors.area = "Please enter area";
      errors.error = true;
    }

    if (!area_in_acres) {
      errors.area_in_acres = "Please enter area in acres";
      errors.error = true;
    }

    if (!city) {
      errors.city = "Please enter city";
      errors.error = true;
    }

    if (!country) {
      errors.country = "Please enter country";
      errors.error = true;
    }

    if (!district) {
      errors.district = "Please enter district";
      errors.error = true;
    }

    if (!line_1) {
      errors.line_1 = "Please enter line_1";
      errors.error = true;
    }

    if (!line_2) {
      errors.line_2 = "Please enter line_2";
      errors.error = true;
    }
    if (!plot_no) {
      errors.plot_no = "Please enter plot number";
      errors.error = true;
    }
    if (!line_kms) {
      errors.line_kms = "Please enter line in kms";
      errors.error = true;
    }
    if (!pincode) {
      errors.pincode = "Please enter pincode";
      errors.error = true;
    }

    if (!state) {
      errors.state = "Please enter state";
      errors.error = true;
    }
    if (!tehsil) {
      errors.tehsil = "Please enter tehsil";
      errors.error = true;
    }

    if (!selectedBlock || !selectedBlock[0]) {
      errors.selectedBlock = "Please select block";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};

export const ValidateUserDetails = (userDetails) => {
  return new Promise((resolve, reject) => {
    const {
      name,
      mobile,
      mobile_verified,
      date_joined,
      // email,
      // email_verified,
      gender,
      gst,
      status,
    } = userDetails;

    const errors = { error: false };

    if (!name) {
      errors.name = "Please enter name";
      errors.error = true;
    }

    if (!mobile) {
      errors.mobile = "Please enter mobile number";
      errors.error = true;
    }

    // if (!email) {
    //   errors.email = "Please enter email";
    //   errors.error = true;
    // }

    // if (!gst) {
    //   errors.gst = "Please enter gst";
    //   errors.error = true;
    // }

    // if (!email_verified || !email_verified[0]) {
    //   errors.email_verified = "Please select email verification status";
    //   errors.error = true;
    // }

    if (!status || !status[0]) {
      errors.status = "Please select status";
      errors.error = true;
    }

    if (!mobile_verified || !mobile_verified[0]) {
      errors.mobile_verified = "Please select mobile verification status";
      errors.error = true;
    }

    if (!gender || !gender[0]) {
      errors.gender = "Please select gender";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
