import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./HotelDetails.module.css";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import { Button, useTheme } from "@mui/material";
import InputFields from "../../Component/InputFields/InputFields";
import Backdrop from "@mui/material/Backdrop";
import dragDrop from "../../Assets/drag_drop.svg";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import TextEditor from "../../Component/TextEditor/TextEditor";
import hotelLocation from "../../Assets/hotelLocation.svg";
import DurationPicker from "../../Component/DurationPicker/DurationPicker";
import {
  BannerAndMediaService,
  mediaDataForApi,
} from "../Courses/courseUtility";
import { base_url, token_api } from "../../Utils/network";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import MyMap from "../../Component/MyMap/MyMap";
import { Validate } from "./Utility";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";
import delete_icon from "../../Assets/delete_icon.svg";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Chip, OutlinedInput, Stack } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import Switch from "@mui/material/Switch";
import CustomModal from "../../Component/CustomModal/CustomModal";
import AddHotelModal from "../../Component/AddHotelModal/AddHotelModal";

const HotelDetails = () => {
  const { access_token } = useSelector((state) => state.auth);
  const { id } = useParams();
  const theme = useTheme();
  const { palette } = theme;
  const navigate = useNavigate();
  const fileInput = useRef(null);
  const [loc, updLoc] = useState({ lat: 20.5937, lng: 78.9629 });
  const [apiloading, setapiLoading] = useState(false);
  const [accForm, setaccForm] = useState({});
  const [typedValue, setTypedValue] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [mobileNumbers, setMobileNumbers] = useState([""]);

  const [hotelData, setHotelData] = useState({
    hotelName: "",
    pincode: "",
    line_1: "",
    line_2: "",
    district: "",
    state: "",
    city: "",
    country: "",
    check_in: "",
    check_out: "",
    double: "",
    single: "",
    price: "",
    ammenty: [],
    about: "",
    lat: "",
    long: "",
    single_occupancy_no: "",
    double_occupancy_no: "",
    rooms: [],
  });


  console.log(hotelData, "harihotelData")
  const [open, setOpen] = useState(false);
  const [amenitiesList, setaminitiesList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [hotelId, setHotelId] = useState(null);
  const bannerInput = useRef(null);
  const [pageLoading, setPageLoading] = useState(false);

  const [crsMediaPreview, setCrsMediaPreview] = useState([]);
  const [courseMedia, setCourseMedia] = useState([]);
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);

  const [bannersPreview, setBannersPreview] = useState([]);

  const descriptionRef = useRef(null);
  const preRequisiteRef = useRef(null);
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link to="/lms/hotel" className="orange-1 bread breadElement">
      Hotels
    </Link>,
    <div className="orange-1 bread">{id ? "Edit Hotel" : "Add Hotel"}</div>,
  ];

  async function bannerUpload(hoteliddata = null) {
    if (!id) {
      if (banners && banners.length > 0) {
        const allBanners = [...banners];

        let hotelMedia = new FormData();
        allBanners.forEach((banner) => {
          hotelMedia.append("images", banner);
        });
        hotelMedia.append("hotel", hoteliddata);

        try {
          const addBanners = await axios({
            method: "post",
            url: `${base_url}course/hotels/media/`,
            data: hotelMedia,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access_token}`,
            },
          });

          if (addBanners.status === 201) {
            getHotelDetailsforUpdate();
            setBanners([]);
            // alert("New Hotel successfully created!");
          }
          // Handle success
        } catch (error) {
          // Handle error
        }
      }
    } else {
      if (banners && banners.length > 0) {
        const allBanners = [...banners];

        let hotelMedia = new FormData();
        allBanners.forEach((banner) => {
          hotelMedia.append("images", banner);
        });
        hotelMedia.append("hotel", hoteliddata);

        try {
          const addBanners = await axios({
            method: "post",
            url: `${base_url}course/hotels/media/`,
            data: hotelMedia,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access_token}`,
            },
          });

          if (addBanners.status === 201) {
            getHotelDetailsforUpdate();
            setBanners([]);
            // alert("Image successfully uploaded!");
          }
          // Handle success
        } catch (error) {
          // Handle error
        }
      }
    }
  }

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && !multiple) {
      setHotelData({
        ...hotelData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      //   if (name === "pincode" && (!/^\d{6}$/.test(value))) {
      //     return;
      // }

      setHotelData({ ...hotelData, [name]: value });
    }
  };

  function handleDragOver(e) {
    e.preventDefault();
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setBanners([...banners, ...files]);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setBanners([...banners, ...files]);
  };

  const handleMediaDelete = async (type, itemIndex, id) => {
    if (type === "image") {
      if (id) {
        token_api
          .delete(`/course/hotels/media/${id}/`)
          .then((response) => {
            if (response?.status == 200 || response?.status == 204) {
              getHotelDetailsforUpdate();
            }
          })
          .catch((error) => {
            console.log(error);
          });

        setPageLoading(true);
      } else {
        const mediaFilter = courseMedia.filter(
          (item, index) => index !== itemIndex
        );
        setCourseMedia(mediaFilter);
      }
    }
  };

  const handleCreateHotelData = async () => {
    let isDescriptionsEmpty;

    if (descriptionRef.current) {
      const content = descriptionRef.current.getContent();
      hotelData.about = content;

      // Set a boolean value based on whether the content is empty or not
      isDescriptionsEmpty = !content.trim();
    }

    let data = {
      location_id: accForm?.location?.id,
      descriptions: hotelData?.about,
      hotel_name: hotelData?.hotelName,
      check_in_time: hotelData?.check_in,
      check_out_time: hotelData?.check_out,
      single_occupancy_no: hotelData?.single_occupancy_no,
      double_occupancy_no: hotelData?.double_occupancy_no,
      numbers: mobileNumbers,
    };

    try {
      Validate(hotelData, accForm, id)
        .then(async (response) => {
          if (response == "success") {
            setapiLoading(true);
            if (!id) {
              const newHotelResponse = await token_api.post(
                `course/hotels/`,
                data
              );
              setHotelId(newHotelResponse?.data?.data?.id);
              console.log(newHotelResponse?.data?.data?.id, "hariNewHiitetet");
              if (newHotelResponse?.status !== 201) {
                throw new Error("Hotel not created");
              }

              let imageUpload = await bannerUpload(
                newHotelResponse?.data?.data?.id
              );

              // alert("New Hotel Created Successfully!");
              // setHotelData({});
              setFormErrors({});
              setHotelData({});
              navigate(`/lms/hotel`);
            } else {
              console.log(id, "hariIdd");
              const newHotelResponse = await token_api.patch(
                `course/hotels/${id}/`,
                data
              );
              setHotelId(newHotelResponse?.data?.data?.id);
              console.log(newHotelResponse?.data?.data?.id, "hariNewHiitetet");
              if (newHotelResponse?.status !== 200) {
                throw new Error("Hotel not created");
              }

              let imageUpload = await bannerUpload(
                newHotelResponse?.data?.data?.id
              );

              // alert("Images Uploaded Successfully!");

              // setHotelData({});

              // navigate(`users/hotel`)
              setFormErrors({});
              // setHotelData({});
              navigate(`/lms/hotel`);
            }
          }
        })
        .catch((error) => {
          setFormErrors(error);
        })
        .finally(() => {
          setapiLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  console.log(formErrors, "formerror");

  const getLocationList = (value) => {
    let apiEndpoint = "/course/location";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data.data, "newresdata");
          let userloc = res.data.data?.map((info) => ({
            id: info?.id,
            title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
          }));
          setLocationList(userloc);
        }
      })
      .catch((err) => console.log(err));
  };

  function getHotelDetailsforUpdate() {
    if (id) {
      token_api
        .get(`/course/hotels/${id}`)
        .then((response) => {
          if (response?.status == 200 || response?.status == 201) {
            const data = response?.data;
            console.log(data?.location, "resdata");

            setHotelData((prev) => ({
              ...prev,
              hotelName: data?.hotel_name,
              check_in: data?.check_in_time,
              check_out: data?.check_out_time,
              about: data?.descriptions,
              single_occupancy_no: data?.single_occupancy,
              double_occupancy_no: data?.double_occupancy,
              rooms: data?.accommodation,
            }));

            const formattedData = data?.contact_numbers?.map(
              (obj) => obj.number
            );
            setMobileNumbers(formattedData);

            const dropdowndata = {
              id: data?.location?.id,
              title: `${data?.location?.line_1}, ${data?.location?.line_2} ${data?.location?.area} ${data?.location?.city_new} ${data?.location?.state} ${data?.location?.country}`,
            };

            setaccForm((prev) => ({
              ...prev,
              location: dropdowndata,
            }));

            if (data?.hotelmedia && data?.hotelmedia.length > 0) {
              const urls = data?.hotelmedia?.map((media) => media);
              setBannersPreview(urls);
            } else {
              setBannersPreview([]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getLocationList(typedValue);
      }, 500);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);

  const handleRemoveImage = (indexToRemove) => {
    setBanners((prevBanners) =>
      prevBanners.filter((_, index) => index !== indexToRemove)
    );
  };
  console.log(hotelData, "htoeldata");
  const handleClick = (type) => {
    console.log("refd", type);
    if (type === "c_media") {
      fileInput.current.click();
    } else {
      bannerInput.current.click();
    }
  };

  useEffect(() => {
    if (id) {
      getHotelDetailsforUpdate();
    }
  }, []);

  const handleHotelMedia = (receive) => {
    if (receive == "post") {
      bannerUpload(hotelId);
    }
  };

  const handleNavigateBack = () => {
    navigate(`/lms/hotel`);
  };

  const handleChangeforSearch = (event, newValue) => {
    setTypedValue(newValue);
    setaccForm((prev) => ({
      ...prev,
      location: newValue,
    }));
  };
  console.log(accForm?.location, "jdhfjd");

  const handleChangeMobile = (index, value) => {
    if (value.length > 10) {
      return;
    }

    const newMobileNumbers = [...mobileNumbers];
    newMobileNumbers[index] = value;
    setMobileNumbers(newMobileNumbers);
  };

  const handleAddMore = () => {
    setMobileNumbers([...mobileNumbers, ""]);
  };

  const handleDelete = (index) => {
    const newMobileNumbers = [...mobileNumbers];
    newMobileNumbers.splice(index, 1);
    setMobileNumbers(newMobileNumbers);
  };

  const handleRoomChange = (index, field, value) => {
    const updatedRooms = [...hotelData?.rooms];
    updatedRooms[index][field] = value;
    setHotelData({ ...hotelData, rooms: updatedRooms });
  };

  const handleAmenitiesChange = (index, event) => {
    const selectedAmenityIds = event.target.value;
    const updatedRooms = hotelData.rooms.map((room, i) => {
      if (i === index) {
        const selectedAmenities = selectedAmenityIds.map((amenityId) => {
          return amenitiesList.find((amenity) => amenity.id === amenityId);
        });
        return {
          ...room,
          amenities: selectedAmenities,
        };
      }
      return room;
    });
    setHotelData({ ...hotelData, rooms: updatedRooms });
  };

  function getamenities() {
    token_api
      .get(`course/amenities`)
      .then((response) => {
        setaminitiesList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    getamenities();
  }, []);

  console.log(hotelData?.rooms[0], "hoteldatarooms");

  const handleMorerooms = () => {
    setOpen(true);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDeleteClick = (e, id) => {
    alert(id);
    // console.log();
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>

      <div>
        <div
          style={{
            padding: "10px 100px",
            boxSizing: "border-box",
            borderRadius: "0px",
          }}
        >
          <div
            className={`${styles.titleStyles}`}
            style={{ color: palette?.primary[500] }}
          >
            {id ? "Edit Hotel" : "Add Hotel"}
          </div>
          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <InputFields
                label="Hotel Name *"
                name="hotelName"
                value={hotelData?.hotelName}
                error={formErrors?.hotelName}
                helperText={formErrors?.hotelName}
                onChange={handleChange}
              />

              <SearchAndDropdown
                label="Select Location *"
                value={accForm?.location}
                onChange={handleChangeforSearch}
                listing={locationList}
                typedValue={typedValue}
                setTypedValue={setTypedValue}
                error={formErrors?.location}
                helperText={formErrors?.location}
              />

              <DurationPicker
                style={{ width: "100%" }}
                label="Check-In Time *"
                size="small"
                name="check_in"
                onChange={handleChange}
                value={hotelData?.check_in}
                error={formErrors?.check_in}
                helperText={formErrors?.check_in}
                // error={formErrors?.start_time}
                // required
                // error={startTimeCheck}
                // helperText={formErrors?.start_time ? "Enter start time" : ""}
              />
              <DurationPicker
                style={{ width: "100%" }}
                label="Check-Out Time *"
                size="small"
                name="check_out"
                onChange={handleChange}
                value={hotelData?.check_out}
                error={formErrors?.check_out}
                helperText={formErrors?.check_out}
                // error={formErrors?.start_time}
                // required
                // error={startTimeCheck}
                // helperText={formErrors?.start_time ? "Enter start time" : ""}
              />

              <div>
                {mobileNumbers.map((number, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div style={{ marginBottom: "10px", width: "100%" }}>
                      <InputFields
                        label="Enter phone number"
                        type="number"
                        value={number}
                        onChange={(e) =>
                          handleChangeMobile(index, e.target.value)
                        }
                        placeholder="Mobile number"
                        style={{ marginRight: "10px" }}
                        maxLength={10}
                      />
                    </div>
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(index)}
                      src={delete_icon}
                    />
                  </div>
                ))}
                <Button onClick={handleAddMore}>
                  {mobileNumbers?.length > 1
                    ? "Add more phone numbers"
                    : "Add phone number"}
                </Button>
              </div>
              {!id && (
                <>
                  <InputFields
                    label="No. Of Double Occupancy"
                    name="double_occupancy_no"
                    type="number"
                    value={hotelData?.double_occupancy_no}
                    onChange={handleChange}
                    error={formErrors?.double_occupancy_no}
                    helperText={formErrors?.double_occupancy_no}
                  />
                  <InputFields
                    label="No. Of Single Occupancy"
                    name="single_occupancy_no"
                    type="number"
                    value={hotelData?.single_occupancy_no}
                    onChange={handleChange}
                    error={formErrors?.single_occupancy_no}
                    helperText={formErrors?.single_occupancy_no}
                  />
                </>
              )}
              {/* <InputFields
                label="Price (Per Night)"
                name="price"
                value={hotelData?.price}
                onChange={handleChange}
                type="number"
                error={formErrors?.price}
                helperText={formErrors?.price}
              /> */}
            </div>
          </div>

          <div>
            {hotelData.rooms.map((room, index) => (
              <div
                key={index}
                style={{ display: "flex", gap: "10px", marginTop: "20px" }}
              >
                <div style={{ width: "20%" }}>
                  <InputFields
                    label={`Room Number `}
                    name={`room_no_${index}`}
                    value={room.room_no}
                    onChange={(e) =>
                      handleRoomChange(index, "room_no", e.target.value)
                    }
                  />
                </div>

                <div style={{ width: "20%" }}>
                  <InputFields
                    label={`Floor Number`}
                    name={`floor_no_${index}`}
                    value={room.floor_no}
                    onChange={(e) =>
                      handleRoomChange(index, "floor_no", e.target.value)
                    }
                  />
                </div>

                <div style={{ width: "20%" }}>
                  <InputFields
                    label={`Price`}
                    name={`price_${index}`}
                    value={room.price}
                    onChange={(e) =>
                      handleRoomChange(index, "price", e.target.value)
                    }
                  />
                </div>

                <div>
                  {room.occupancy_type === 1 ? (
                    <div>Single Room</div>
                  ) : (
                    <div>Double Room</div>
                  )}
                  <Switch
                    checked={room.occupancy_type === 1}
                    onChange={(e) =>
                      handleRoomChange(
                        index,
                        "occupancy_type",
                        e.target.checked ? 1 : 2
                      )
                    }
                  />
                </div>

                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel id={`amenities-label-${index}`}>
                    Amenities
                  </InputLabel>
                  <Select
                    labelId={`amenities-label-${index}`}
                    id={`amenities-select-${index}`}
                    value={room?.amenities?.map((amenity) => amenity?.id)}
                    multiple
                    onChange={(e) => handleAmenitiesChange(index, e)}
                    input={<OutlinedInput label="Amenities" />}
                    renderValue={(selected) => (
                      <Stack gap={1} direction="row" flexWrap="wrap">
                        {selected.map((id) => {
                          const selectedAmenity = amenitiesList.find(
                            (amenity) => amenity.id === id
                          );
                          return (
                            <Chip
                              key={id}
                              label={selectedAmenity?.title}
                              onDelete={() => {
                                const updatedRooms = hotelData?.rooms.map(
                                  (room, i) => {
                                    if (i === index) {
                                      return {
                                        ...room,
                                        amenities: room.amenities.filter(
                                          (amenity) => amenity.id !== id
                                        ),
                                      };
                                    }
                                    return room;
                                  }
                                );
                                setHotelData({
                                  ...hotelData,
                                  rooms: updatedRooms,
                                });
                              }}
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                            />
                          );
                        })}
                      </Stack>
                    )}
                  >
                    {amenitiesList.map((amenity) => (
                      <MenuItem key={amenity.id} value={amenity.id}>
                        {amenity.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id={`status-label-${index}`}>Status</InputLabel>
                    <Select
                      labelId={`status-label-${index}`}
                      id={`status-select-${index}`}
                      value={room.status}
                      label="Status"
                      onChange={(e) =>
                        handleRoomChange(index, "status", e.target.value)
                      }
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleDeleteClick(e, room?.id)}
                >
                  <img src={delete_icon} />
                </div>
              </div>
            ))}

            <Button onClick={handleMorerooms}>+ Add more rooms</Button>
          </div>

          <div className={`${styles.formContainer}`}>
            <p>About Hotel</p>
            <TextEditor
              editorRef={descriptionRef}
              initialValue={hotelData?.about}
            />
            <small style={{ marginTop: "5px", color: "red" }}>
              {formErrors?.about && "Please enter about the hotel"}
            </small>
          </div>

          {/* <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <div>
                <div
                  className={`${styles.titleStyles}`}
                  style={{ color: palette?.primary[500] }}
                >
                  Add Ammenties
                </div>{" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  <CustomSelectChip
                    multiple={false}
                    label="Ammenties"
                    name="ammenty"
                    onChange={handleChange}
                    onDelete={handleDeleteChip}
                    value={hotelData?.ammenty}
                    listArray={ammentyArray}
                    // error={formErrors?.mode}
                  />
                </div>
              </div>
            </div>
          </div> */}

          <div className={`${styles.formContainer} mt-20`}>
            <div
              style={{ color: palette?.primary[500] }}
              className={`${styles.titleStyles}`}
            >
              Add Hotel Media
            </div>

            <div style={{ marginTop: "20px" }}>
              <div
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e)}
                className={styles.dropBoxStyles}
              >
                <div
                  onClick={handleClick}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <p>
                    <img src={dragDrop} alt="dradanddrop" />
                  </p>
                  <p>Drag & Drop the File or click here</p>
                  <input
                    ref={bannerInput}
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              {id && (
                <Button
                  // disabled={index }
                  variant="contained"
                  onClick={() => handleHotelMedia("post")}
                >
                  upload
                </Button>
              )}
            </div>
            <div
              style={{ width: "35%", marginTop: "10px", marginBottom: "10px" }}
            >
              {bannersPreview?.map((banner, index) => (
                <div
                  key={banner?.id}
                  className={`${styles.flexBetween} ${styles.imgCardBox} `}
                  style={{ gap: "10px", marginTop: "10px" }}
                >
                  <div style={{ width: "200px" }}>
                    <img
                      // style={{ width: "100px", height: "100px" }}
                      // width={200}
                      // height={140}
                      style={{ width: "100%" }}
                      src={banner?.image}
                      alt={`Uploaded image ${index}`}
                    />
                  </div>

                  <div>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleMediaDelete("image", index, banner?.id);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
  {banners.map((banner, index) => (
                <div
                  key={index}
                  className={`${styles.flexBetween} ${styles.imgCardBox} `}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <div>
                    <img
                      width={200}
                      height={140}
                      src={URL.createObjectURL(banner)}
                      alt={`Uploaded image ${index}`}
                    />
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      onClick={() => handleRemoveImage(index)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={`${styles.formContainer} mt-20`}>
            <Button
              disabled={apiloading}
              style={{ backgroundColor: apiloading && "gray" }}
              onClick={handleCreateHotelData}
              className={`${styles.gradientColor}`}
              variant="contained"
            >
              Save & Publish
            </Button>
          </div>
        </div>
      </div>

      <CustomModal open={open} onClose={handleClose}>
        <AddHotelModal
          amenitiesList={amenitiesList}
          handleClose={handleClose}
          id={id}
          getHotelDetailsforUpdate={getHotelDetailsforUpdate}
        />
      </CustomModal>
    </Container>
  );
};

export default HotelDetails;

const ammentyArray = [
  {
    id: 1,
    title: "Appartments",
  },
  {
    id: 2,
    title: "Kitchen",
  },
];
