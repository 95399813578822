import L from "leaflet";
import liveTracking from "../../Assets/liveTracking.svg";

export default L.icon({
  iconSize: [32, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: liveTracking,
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
});
