import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { helperdayMonthYearFull } from "../../Utils/helper";

const CustomSelectChip = ({
  multiple = "true",
  label,
  onChange,
  onDelete,
  name,
  type = "select",
  value,
  listArray,
  disabled,
  error,
}) => {
  //   const [value, setValue] = useState([]);
  //   const onChange = (value) => {
  //     setValue(typeof value === "string" ? value.split(",") : value);
  //   };

  console.log("VVLVLVLLV", value, error);

  //   const handleDeleteChips = (id) => {
  //     setValue((chips) => chips.filter((chip) => chip?.id !== id));
  //   };
  return (
    <FormControl sx={{ width: "100%" }} size="small" error={error}>
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        label={label}
        disabled={disabled}
        size="small"
        multiple={multiple}
        value={value || []}
        onChange={(e) => onChange(e.target.value, type, name, multiple)}
        name={name}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected?.map((value) => {
              return (
                // <Chip
                //   key={value.user.id}
                //   label={value?.user?.first_name}
                // />
                <Chip
                  label={
                    value?.hotel_name ||
                    value?.title ||
                    value?.name ||
                    value?.city ||
                    value?.room_no ||
                    helperdayMonthYearFull(value?.start_date)
                  }
                  key={value}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={() => onDelete(value?.id, name)}
                />
              );
            })}
          </Box>
        )}
        // MenuProps={MenuProps}
      >
        {listArray?.map((item, key) => (
          <MenuItem
            key={key}
            value={item}
            // style={getStyles(name, personName, theme)}
          >
            {item?.title ||
              item?.hotel_name ||
              item?.name ||
              item?.city ||
              item?.room_no ||
              helperdayMonthYearFull(item?.start_date)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default CustomSelectChip;

const dummyData = [
  {
    id: 1,
    title: "Data",
  },
  {
    id: 2,
    title: "Data2",
  },
  {
    id: 3,
    title: "Data3",
  },
];
