import React, { useEffect, useState } from "react";
import styles from "./DDUserAddress.module.css";
import profileLocation from "../../Assets/pilotMap.svg";
import profileEdit from "../../Assets/edit_icon.svg";
import profileDelete from "../../Assets/delete_icon.svg";
import { Button } from "@mui/material";

function DDUserAddress(props) {
  const {
    userAddresses,
    handleDeleteAddress,
    handleEditAddress,
    handleAddNewAddress,
  } = props;
  return (
    <div className={styles.mainContainer}>
      <div className={styles.topHead}>
        <h3 className={styles.addressHead}>Addresses</h3>
        <Button onClick={handleAddNewAddress} variant="contained">
          + New Address
        </Button>
      </div>
      <div style={{ width: "100%" }}>
        {userAddresses.map((addressData, index) => {
          return (
            <div className={styles.miniContainer} key={index}>
              {addressData?.land_type && (
                <p className={styles.addressType}>
                  <span style={{ fontWeight: "bold", color: "red" }}>. </span>
                  {addressData?.land_type}
                </p>
              )}
              <div className={styles.addressCon}>
                <div className={styles.textContainer}>
                  <h1 className={styles.addressHead}>
                    {addressData?.addressName}
                  </h1>
                  <p className={styles.addressLoc}>
                    {addressData?.city},{addressData?.tehsil},
                    {addressData?.district},{addressData?.state},
                    {addressData?.country}
                    {"           "}
                    {addressData?.pincode}
                  </p>
                </div>
                <div>
                  <img src={addressData?.addressMap} />
                </div>
                <div className={styles.actionCon}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleEditAddress(addressData, "address");
                    }}
                  >
                    <img src={profileEdit} className={styles.actionImg} />
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDeleteAddress(addressData?.id);
                    }}
                  >
                    <img src={profileDelete} className={styles.actionImg} />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DDUserAddress;

const addresses = [
  {
    id: 1,
    addressType: "Building",
    addressName: "Ram Mohan Rabi Field",
    adressLocation:
      "F 5-3, 5th Floor, Pinnacle Business Park, Mahakali Caves Road, Shanti Nagar, Andheri East, Mumbai 400056",
    addressMap: profileLocation,
  },
  {
    id: 2,
    addressType: "Farm",
    addressName: "Wheat Farm",
    adressLocation:
      "F 5-3, 5th Floor, Pinnacle Business Park, Mahakali Caves Road, Shanti Nagar, Andheri East, Mumbai 400056",
    addressMap: profileLocation,
  },
];
