export const Validate = (value) => {
  return new Promise((resolve, reject) => {
    const { vehicle_id, vehicle_no, registration_date, status } = value;

    const errors = { error: false };

    if (!registration_date) {
      errors.registration_date = "Enter registration_date";
      errors.error = true;
    }

    if (!vehicle_id || Object.keys(vehicle_id).length === 0) {
      errors.vehicle_id = "Vehicle is required";
      errors.error = true;
    }

    if (!vehicle_no) {
      errors.vehicle_no = "Enter Vehicle Number";
      errors.error = true;
    }

    if (!status || Object.keys(status).length === 0) {
      errors.status = "status is required";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
