import React, { useEffect } from "react";

const DashboardHeader = ({
  id,
  cardTitle = "Service Request",
  cardIcon,
  primaryGardient,
  secondaryGradient,
  value,
  hint,
  countvalue,
  present = false,
  item,
  eclipseIcon,
}) => {
  function helper(hint) {
    const valueToShow =
      countvalue[hint] !== undefined ? countvalue[hint] : "N.A";
    return valueToShow;
  }

  return (
    <div
      className=""
      style={{
        borderRadius: "10px",
        flex: "1",
        background: primaryGardient,
        position: "relative",
      }}
    >
      <div
        style={{
          borderRadius: "0px 30px 10px 10px",
          background: secondaryGradient,
          marginTop: "25px",
          padding: "15px 15px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: "12px",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <div style={{ marginLeft: "20px" }}>
            {present && (
              <h2
                style={{
                  marginBottom: "8px",
                  color: "white",
                  fontSize: "36px",
                }}
              >
                {}
                {/* {id == 1 && helper("users")?.total_new_users}
                {id == 2 && helper("service_booking")?.booking_count}
                {id == 3 && helper("course_booking")?.booking_count} */}

                {item?.total_revenue}
              </h2>
            )}
            <p style={{ marginTop: "0px", color: "white", fontSize: "22px" }}>
              {" "}
              {cardTitle}
            </p>
          </div>
          <div style={{ display: "flex", alignItems: "start" }}>
            <img src={cardIcon} alt="icon" />
          </div>
        </div>

        <div style={{ marginLeft: "20px", paddingLeft: "12px" }}>
          {/* {present && (
          
          )} */}
          {id != 1 && (
            <p style={{ margin: "0px", color: "rgba(28, 1, 33, 1)" }}>
              <h2 style={{ margin: "0px ", color: "white", fontSize: "36px" }}>
                {/* {id == 3 && helper("course_booking")?.total_revenue} 
                {id == 2  && (helper("service_booking")?.total_revenue || 0 )   }  */}
                {item?.booking_count}
              </h2>
              <span
                style={{ fontWeight: "26px", color: "white", fontSize: "22px" }}
              >
                Booking Count
              </span>
            </p>
          )}
        </div>
      </div>
      <img
        src={eclipseIcon}
        style={{ position: "absolute", right: "0px", bottom: "0px" }}
      />
    </div>
  );
};

export default DashboardHeader;
