import React, { useState, useRef, useEffect } from "react";
import styles from "./OrganizationDetails.module.css";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import { Button, useTheme } from "@mui/material";
import InputFields from "../../Component/InputFields/InputFields";
import Backdrop from "@mui/material/Backdrop";
import dragDrop from "../../Assets/drag_drop.svg";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import TextEditor from "../../Component/TextEditor/TextEditor";
import hotelLocation from "../../Assets/hotelLocation.svg";
import DurationPicker from "../../Component/DurationPicker/DurationPicker";
import CustomDate from "../../Component/CustomDate/CustomDate";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { base_url, token_api } from "../../Utils/network";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MyMap from "../../Component/MyMap/MyMap";
import { Validate } from "./Utility";
import { Navigate, Link } from "react-router-dom";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import LocationDropDown from "../../Component/LocationDropDown/LocationDropDown";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";
import { formatDate } from "../../Utils/helper";

import {
  BannerAndMediaService,
  mediaDataForApi,
} from "../Courses/courseUtility";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import dayjs from "dayjs";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";

const OrganizationDetails = () => {
  const { access_token } = useSelector((state) => state.auth);
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { palette } = theme;
  const [showAdress, setShowAddress] = useState(false);
  const [organizationData, setOrganizationData] = useState({
    organizationName: "",
    pincode: "",
    organizationName: "",
    boarding: null,
    about: "",
    city_new: "",
    line_1: "",
    line_2: "",
    area: "",
    city: "",
    district: "",
    tehsil: "",
    state: "",
    country: "",
  });
  const [formErrors, setFormErrors] = useState({});
  // const [cityNewData, setCityNewData] = useState([]);
  const fileInput = useRef(null);
  const bannerInput = useRef(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [crsMediaPreview, setCrsMediaPreview] = useState([]);
  const [courseMedia, setCourseMedia] = useState([]);
  const [banners, setBanners] = useState("");
  const [bannersPreview, setBannersPreview] = useState("");
  const descriptionRef = useRef(null);
  const preRequisiteRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [apiloading, setapiLoading] = useState(false);
  const [pilotSelected, setPilotSelected] = useState([]);
  const [accForm, setaccForm] = useState({});
  const [typedValue, setTypedValue] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);

  console.log(pilotSelected, "pilotSelected");

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link to="/users/organization" className="orange-1 bread breadElement">
      Organizations
    </Link>,
    <div className="orange-1 bread">
      {id ? "Edit Organization" : "Add Organization"}
    </div>,
  ];

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && !multiple) {
      setOrganizationData({
        ...organizationData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      // if ((name === "pincode" && isNaN(Number(value))) || value?.length > 6) {
      //   return;
      // }

      setOrganizationData({ ...organizationData, [name]: value });
    }
  };

  console.log(organizationData, courseMedia, "hariHotelDataaa");

  const handleDeleteChip = (id, selectName) => {
    setOrganizationData({
      ...organizationData,
      [selectName]: organizationData?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
    // (chips) => chips.filter((chip) => chip?.id !== id)
  };

  function handleDrop(e, type) {
    e.preventDefault();
    if (e?.target?.files) {
      // simple drop
      // type=c_media or banner
      if (type === "c_media") {
        // mediaService
        const mediaService = new BannerAndMediaService(
          e.target.files,
          ""
        ).addCourseMedia();
        setCrsMediaPreview((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgsPReview);
          return prevCopy;
        });
        setCourseMedia((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgFiles);
          return prevCopy;
        });
      } else {
        // banner
        setBanners(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setBannersPreview(imageUrl);
      }
    } else if (e?.dataTransfer?.files) {
      // dragged
      // type=c_media or banner
      if (type === "c_media") {
        const mediaService = new BannerAndMediaService(
          e?.dataTransfer?.files,
          ""
        ).addCourseMedia();
        setCrsMediaPreview((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgsPReview);
          return prevCopy;
        });
        setCourseMedia((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgFiles);
          return prevCopy;
        });
      } else {
        setBanners(e?.dataTransfer?.files[0]);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setBannersPreview(imageUrl);
      }
    }
  }

  const handleClick = (type) => {
    if (type === "c_media") {
      fileInput.current.click();
    } else {
      bannerInput.current.click();
    }
  };
  function handleDragOver(e) {
    e.preventDefault();
  }

  const handleMediaDelete = async (type, itemIndex, id) => {
    if (type === "image") {
      if (id) {
        setPageLoading(true);
      } else {
        const mediaFilter = courseMedia.filter(
          (item, index) => index !== itemIndex
        );
        setCourseMedia(mediaFilter);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        organizationData?.pincode &&
        organizationData?.pincode?.length === 6
      ) {
        try {
          setLoading(true);

          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${organizationData?.pincode}`
          );

          if (
            response.data.length !== 0 &&
            response.data[0].PostOffice.length !== 0
          ) {
            const pinData = response.data[0].PostOffice[0];
            const { District, Division, State, Name, Region, Country } =
              pinData;

            setOrganizationData({
              ...organizationData,
              tehsil: Division,
              state: State,
              district: District,
              city: District,
              country: Country,
            });

            // setFormData({
            //   ...formData,
            //   district: Name,
            //   city: Region,
            //   state: State,
            //   country: Country,
            //   pincode: pin,
            // });
            // setShowOther(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [organizationData?.pincode]);

  const handleCreateOrganizationData = async () => {
    let isDescriptionsEmpty;

    if (descriptionRef.current) {
      const content = descriptionRef.current.getContent();
      organizationData.about = content;
      isDescriptionsEmpty = !content.trim();
    }

    let data = {
      about: organizationData?.about,
      name: organizationData?.organizationName,
      since_year: organizationData?.since,
      on_boarding_date: formatDate(organizationData?.boarding),
      ...(organizationData?.line_1 && {
        line_1: organizationData?.line_1,
      }),
      ...(organizationData?.line_2 && {
        line_2: organizationData?.line_2,
      }),
      ...(organizationData?.area && {
        area: organizationData?.area,
      }),
      ...(organizationData?.city && {
        city: organizationData?.city,
      }),
      ...(organizationData?.pincode && {
        pincode: organizationData?.pincode,
      }),
      ...(organizationData?.state && {
        state: organizationData?.state,
      }),
      ...(organizationData?.tehsil && {
        tehsil: organizationData?.tehsil,
      }),
      ...(organizationData?.district && {
        district: organizationData?.district,
      }),
      ...(organizationData?.country && {
        country: organizationData?.country,
      }), // location_ids: [accForm?.location?.id],
    };

    try {
      Validate(organizationData)
        .then(async (response) => {
          if (response == "success") {
            setapiLoading(true);

            if (id) {
              const newOrganizationResponse = await token_api.patch(
                `users/organizations/${id}/`,
                data
              );

              if (banners) {
                let organizationMedia = new FormData();
                organizationMedia.append("logo", banners);

                await axios({
                  method: "patch",
                  url: `${base_url}users/organizations/${newOrganizationResponse?.data?.data?.id}/`,
                  data: organizationMedia,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${access_token}`,
                  },
                });
              }
              // alert("Organization Updated Successfully!");
              setFormErrors({});
              // setBanners("");
              navigate(`/users/organization`);
            } else {
              const newOrganizationResponse = await token_api.post(
                `users/organizations/`,
                data
              );
              // if (newOrganizationResponse?.statusText != "OK") {
              //   throw new Error("Organization not created");
              //   return;
              // }
              if (banners) {
                let organizationMedia = new FormData();
                organizationMedia.append("logo", banners);

                await axios({
                  method: "patch",
                  url: `${base_url}users/organizations/${newOrganizationResponse?.data?.data?.id}/`,
                  data: organizationMedia,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${access_token}`,
                  },
                });
              }
              // alert("Organization Created Successfully!");
              setFormErrors({});
              // setBanners("");
              navigate(`/users/organization`);
            }
          }
        })

        .catch((error) => {
          setFormErrors(error);
        })
        .finally(() => {
          setapiLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  function getdataforsingleOrgnization() {
    token_api
      .get(`/users/organizations/${id}`)
      .then((response) => {
        const patchdata = response?.data?.data;

        setOrganizationData((prev) => ({
          ...prev,
          organizationName: patchdata?.name,
          boarding: dayjs(patchdata?.on_boarding_date),
          about: patchdata?.about,
          since: patchdata?.since_year,
          line_1: patchdata?.line_1,
          line_2: patchdata?.line_2,
          area: patchdata?.area,
          city: patchdata?.city,
          pincode: patchdata?.pincode,
          state: patchdata?.state,
          tehsil: patchdata?.tehsil,
          district: patchdata?.district,
          country: patchdata?.country,
        }));

        // const dropdowndata = {
        //   id: patchdata?.location[0]?.id,
        //   title: `${patchdata?.location[0]?.line_1}, ${patchdata?.location[0]?.line_2}, ${patchdata?.location[0]?.area}, ${patchdata?.location[0]?.city_new} ,${patchdata?.location[0]?.state}, ${patchdata?.location[0]?.country}`,
        // };

        // setaccForm((prev) => ({
        //   ...prev,
        //   location: dropdowndata,
        // }));
        setShowAddress(true);
        setBannersPreview(patchdata?.logo);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getLocationList = (value) => {
    let apiEndpoint = "/course/location";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data.data, "newresdata");
          let userloc = res.data.data?.map((info) => ({
            id: info?.id,
            title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
          }));
          setLocationList(userloc);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getLocationList(typedValue);
      }, 500);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);

  useEffect(() => {
    if (id) {
      getdataforsingleOrgnization();
    }
  }, []);

  const handleChangeforSearch = (event, newValue) => {
    setTypedValue(newValue);
    setaccForm((prev) => ({
      ...prev,
      location: newValue,
    }));
  };

  const handleNavigateBack = () => {
    navigate(`/users/organization`);
  };

  console.log(organizationData, "hariOraggagagaa");

  const handleAutocompleteChangeforPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Location already included");
    } else {
      setPilotSelected(selectedValues);
    }
  };

  console.log(pilotSelected, "pilotSelected");

  const handleAddAddress = () => {
    setOpenDrawer(true);
    setShowAddress(false);
  };

  const handleCloseDrawer = () => {
    setShowAddress(true);
    setOpenDrawer(false);
    handleCreateOrganizationData();
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>
      <div>
        <div
          style={{
            padding: "10px 100px",
            boxSizing: "border-box",
            borderRadius: "0px",
          }}
        >
          <div
            className={`${styles.titleStyles}`}
            style={{ color: palette?.primary[500] }}
          >
            {id ? "Edit Organization" : "Add Organization"}
          </div>
          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <InputFields
                label="Organization Name *"
                name="organizationName"
                value={organizationData?.organizationName}
                error={formErrors?.organizationName}
                helperText={formErrors?.organizationName}
                onChange={handleChange}
              />
              <InputFields
                label="Since Year *"
                type="number"
                name="since"
                value={organizationData?.since}
                error={formErrors?.since}
                helperText={formErrors?.since}
                onChange={handleChange}
              />{" "}
              {/* <CustomDate
                label="On Boarding Date *"
                handleChange={handleChange}
                name="boarding"
                value={organizationData?.boarding}
                error={formErrors?.boarding}
                helperText={formErrors?.boarding}
                // error={formErrors?.start_date}
                // helperText={formErrors?.start_date ? "Enter batch start date" : ""}
              /> */}
              <CustomDatePicker
                label="On Boarding Date *"
                value={organizationData?.boarding}
                error={formErrors?.boarding}
                onChange={(newValue) => {
                  setOrganizationData({
                    ...organizationData,
                    boarding: newValue,
                  });
                }}
              />
              {/* <LocationDropDown
                value={pilotSelected}
                onChange={handleAutocompleteChangeforPilot}
                error={formErrors?.pilotSelected}
              /> */}
              {/* <SearchAndDropdown
                label="Select Location *"
                value={accForm?.location}
                onChange={handleChangeforSearch}
                listing={locationList}
                typedValue={typedValue}
                setTypedValue={setTypedValue}
                error={formErrors?.location}
                helperText={formErrors?.location}
              /> */}
              {/* <CustomSelectChip
                multiple={false}
                label="New City"
                name="city_new"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={organizationData?.city_new}
                listArray={cityNewData}
                // error={formErrors?.mode}
              /> */}
            </div>
          </div>
          <div
            style={{
              margin: "20px 0px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={styles.formContainer}
          >
            <h3
              style={{ color: palette?.primary[500] }}
              className={`${styles.titleStyles}`}
            >
              Organization Address
            </h3>
            <Button
              variant="contained"
              className="btnLinearGradient"
              onClick={handleAddAddress}
            >
              {id ? "EDIT ADDRESS" : "+ ADD ADDRESS"}
            </Button>
          </div>

          {showAdress &&
            (organizationData?.line_1 ||
              organizationData?.line_2 ||
              organizationData?.area ||
              organizationData?.city ||
              organizationData?.tehsil ||
              organizationData?.district ||
              organizationData?.state ||
              organizationData?.state ||
              organizationData?.country ||
              organizationData?.pincode) && (
              <div className={`${styles.formContainer} mt-20`}>
                <p className={styles.addressData}>
                  {organizationData?.line_1 && `${organizationData.line_1}, `}
                  {organizationData?.line_2 && `${organizationData.line_2}, `}
                  {organizationData?.area && `${organizationData.area}, `}
                  {organizationData?.city && `${organizationData.city}, `}
                  {organizationData?.tehsil && `${organizationData.tehsil}, `}
                  {organizationData?.district &&
                    `${organizationData.district}, `}
                  {organizationData?.state && `${organizationData.state}, `}
                  {organizationData?.country && `${organizationData.country}, `}
                  {organizationData?.pincode && `${organizationData.pincode}`}
                </p>
              </div>
            )}

          <div className={`${styles.formContainer} mt-20`}>
            <p
              style={{ color: palette?.primary[500] }}
              className={`${styles.titleStyles}`}
            >
              About Organization
            </p>
            <TextEditor
              editorRef={descriptionRef}
              initialValue={organizationData?.about}
              height={300}
            />
            {/* <small style={{ marginTop: "5px", color: "red" }}>
              {formErrors?.about && "Please enter about the organization"}
            </small> */}
          </div>

          <div className={`${styles.formContainer} mt-20`}>
            <div
              style={{ color: palette?.primary[500] }}
              className={`${styles.titleStyles}`}
            >
              Logo{" "}
            </div>

            <div style={{ marginTop: "20px" }}>
              <div
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "banner")}
                className={styles.dropBoxStyles}
              >
                <div
                  onClick={() => handleClick("banner")}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <p>
                    <img src={dragDrop} alt="dradanddrop" />
                  </p>
                  <p>Drag & Drop the File or click here</p>
                  <input
                    ref={bannerInput}
                    type="file"
                    // id="myfile"
                    // name="myfile"
                    // multiple
                    onChange={(e) => handleDrop(e, "banner")}
                    style={{ display: "none" }}
                  ></input>
                </div>
              </div>
            </div>
          </div>

          {bannersPreview && (
            <div className={`${styles.formContainer} mt-20`}>
              <div className={styles.commonFieldsBox}>
                <div>
                  <p
                    style={{ color: palette?.primary[500] }}
                    className={`${styles.titleStyles}`}
                  >
                    Logo Preview
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div
                      className={`${styles.flexBetween} ${styles.imgCardBox} `}
                    >
                      {" "}
                      <img
                        src={bannersPreview}
                        alt="banner"
                        width={200}
                        height={140}
                      />
                      <div onClick={() => handleClick("banner")}>
                        <Button
                          variant="contained"
                          // onClick={() => {
                          //   handleMediaDelete("image");
                          // }}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={`${styles.formContainer} mt-20`}>
            <Button
              onClick={handleCreateOrganizationData}
              className={`${styles.gradientColor}`}
              variant="contained"
            >
              Save & Publish
            </Button>
          </div>
        </div>
      </div>

      <DrawerComp
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
      >
        <DrawerContainer>
          <DrawerTitle
            text={id ? "Edit Organization Address" : "Add Organization Address"}
            handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <div>
              <InputFields
                label="Pincode"
                name="pincode"
                value={organizationData?.pincode || ""}
                onChange={handleChange}
                error={formErrors?.pincode}
                helperText={formErrors?.pincode}
                maxLength={6}
                type="number"
              />
            </div>

            <div>
              <InputFields
                label="Address Line 1"
                name="line_1"
                style={{ width: "100%" }}
                value={organizationData?.line_1}
                onChange={handleChange}
                // error={formErrors?.line_1}
                // helperText={formErrors?.line_1}
              />
            </div>
            <div>
              <InputFields
                label="Address Line 2"
                name="line_2"
                style={{ width: "100%" }}
                value={organizationData?.line_2}
                onChange={handleChange}
                // error={formErrors?.line_2}
                // helperText={formErrors?.line_2}
              />
            </div>

            <div>
              <InputFields
                label="Area"
                name="area"
                style={{ width: "100%" }}
                value={organizationData?.area}
                onChange={handleChange}
                // error={formErrors?.area}
                // helperText={formErrors?.area}
              />
            </div>

            <div>
              <InputFields
                label="City"
                name="city"
                style={{ width: "100%" }}
                value={organizationData?.city}
                onChange={handleChange}
                error={formErrors?.city}
                helperText={formErrors?.city}
              />
            </div>
            <div>
              <InputFields
                // disabled={loctnFieldDisable}
                label="State"
                name="state"
                style={{ width: "100%" }}
                value={organizationData?.state}
                onChange={handleChange}
                error={formErrors?.state}
                helperText={formErrors?.state}
              />
            </div>

            <div>
              <InputFields
                // disabled={loctnFieldDisable}
                label="Tehsil"
                name="tehsil"
                style={{ width: "100%" }}
                value={organizationData?.tehsil}
                onChange={handleChange}
                error={formErrors?.tehsil}
                helperText={formErrors?.tehsil}
              />
            </div>

            <div>
              <InputFields
                // disabled={loctnFieldDisable}
                label="District"
                name="district"
                style={{ width: "100%" }}
                value={organizationData?.district}
                onChange={handleChange}
                error={formErrors?.district}
                helperText={formErrors?.district}
              />
            </div>

            <div>
              <InputFields
                // disabled={loctnFieldDisable}
                label="Country"
                name="country"
                style={{ width: "100%" }}
                value={organizationData?.country}
                onChange={handleChange}
                error={formErrors?.country}
                helperText={formErrors?.country}
              />
            </div>

            <div>
              <Button variant="contained" onClick={handleCloseDrawer}>
                {id ? "UPDATE" : "ADD"}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>
    </Container>
  );
};

export default OrganizationDetails;

const ammentyArray = [
  {
    id: 1,
    title: "Appartments",
  },
  {
    id: 2,
    title: "Kitchen",
  },
];
