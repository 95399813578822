import React, { useState, useEffect } from "react";
import InputFields from "../InputFields/InputFields";
import axios from "axios";
import { token_api } from "../../Utils/network";
import styles from "./AddUserAddress.module.css";
import { Button } from "@mui/material";
import cancel from "../../Assets/cancel.png"


const AddUserAddress = ({addressId,handleCloseDrawer,getUserAddress}) => {
  const [addressObj, setAddressObj] = useState({
    line_1: "",
    line_2: "",
    pincode: "",
    area: "",
    streetName: "",
    tehsil: "",
    district: "",
    city: "",
    state: "",
    country: "",
  });
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (addressObj.pincode.length === 6) {
        try {
          setLoading(true);

          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${addressObj?.pincode}`
          );

          if (
            response.data.length !== 0 &&
            response.data[0].PostOffice.length !== 0
          ) {
            const pinData = response.data[0].PostOffice[0];
            const { District, Division, State, Name, Region, Country } =
              pinData;

            setAddressObj({
              ...addressObj,
              tehsil: Division,
              state: State,
              district: District,
              city: District,
              country: Country,
            });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [addressObj.pincode]);

  const handleaddsubmit = () => {
    const addressdata = {
      user_id: addressId,
      line_1: addressObj?.line_1,
      line_2: addressObj?.line_2,
      landmark: addressObj?.streetName,
      area: addressObj?.area,
      city: addressObj?.city,
      state: addressObj?.state,
      pincode: addressObj?.pincode,
      country: addressObj?.country,
      district: addressObj?.district,
      tehsil: addressObj?.tehsil,
    };

    // Remove fields with empty strings
    const filteredAddressData = {};
    for (const [key, value] of Object.entries(addressdata)) {
      if (value !== "") {
        filteredAddressData[key] = value;
      }
    }

      token_api
        .post(`/users/admin/address/`, filteredAddressData)
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
         
            getUserAddress(addressId);
            handleClose();
        

          } else {
            throw new Error("error in fields");
          }
          // Handle response
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddressObj((prevAddress) => ({
          ...prevAddress,
          [name]: value,
        }));
      };
  

      const handleClose = ()=>{

        handleCloseDrawer();
      }

  return (
    <div>
      <div className={`${styles.flexStContainer}`}>
        <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}}>
        <p className={styles.titletag}  >Add new address</p>
        <div style={{cursor:"pointer"}} onClick={handleClose}  >
            <img src={cancel}/>
        </div>
        </div>
   
        <div style={{marginBottom: "20px"}}>
          <InputFields
            label="Pincode"
            name="pincode"
            style={{ width: "100%" }}
            value={addressObj?.pincode}
            onChange={handleChange}
            error={formErrors?.pincode}
            helperText={formErrors?.pincode}
          />
        </div>

        <div  style={{marginBottom: "20px"}}>
          <InputFields
            label="Address Line 1"
            name="line_1"
            style={{ width: "100%" }}
            value={addressObj?.line_1}
            onChange={handleChange}
            error={formErrors?.line_1}
            helperText={formErrors?.line_1}
          />
        </div>
        <div  style={{marginBottom: "20px"}}>
          <InputFields
            label="Address Line 2"
            name="line_2"
            style={{ width: "100%" }}
            value={addressObj?.line_2}
            onChange={handleChange}
            error={formErrors?.line_2}
            helperText={formErrors?.line_2}
          />
        </div>

        <div  style={{marginBottom: "20px"}}> 
          <InputFields
            label="Area"
            name="area"
            style={{ width: "100%" }}
            value={addressObj?.area}
            onChange={handleChange}
            error={formErrors?.area}
            helperText={formErrors?.area}
          />
        </div>

        <div  style={{marginBottom: "20px"}}>
          <InputFields
            label="City"
            name="city"
            style={{ width: "100%" }}
            value={addressObj?.city}
            onChange={handleChange}
            error={formErrors?.city}
            helperText={formErrors?.city}
          />
        </div>
        <div  style={{marginBottom: "20px"}}>
          <InputFields
            // disabled={loctnFieldDisable}
            label="State"
            name="state"
            style={{ width: "100%" }}
            value={addressObj?.state}
            onChange={handleChange}
            error={formErrors?.state}
            helperText={formErrors?.state}
          />
        </div>

        <div  style={{marginBottom: "20px"}}>
          <InputFields
            // disabled={loctnFieldDisable}
            label="Tehsil"
            name="tehsil"
            style={{ width: "100%" }}
            value={addressObj?.tehsil}
            onChange={handleChange}
            error={formErrors?.tehsil}
            helperText={formErrors?.tehsil}
          />
        </div>

        <div  style={{marginBottom: "20px"}}>
          <InputFields
            // disabled={loctnFieldDisable}
            label="District"
            name="district"
            style={{ width: "100%" }}
            value={addressObj?.district}
            onChange={handleChange}
            error={formErrors?.district}
            helperText={formErrors?.district}
          />
        </div>

        <div  style={{marginBottom: "20px"}}>
          <InputFields
            // disabled={loctnFieldDisable}
            label="Country"
            name="country"
            style={{ width: "100%" }}
            value={addressObj?.country}
            onChange={handleChange}
            error={formErrors?.country}
            helperText={formErrors?.country}
          />
        </div>

        <div className={styles.stDrwBtnFlextwo}>
          {loading ? (
            <div>
              <Button variant="contained">Loading....</Button>
            </div>
          ) : (
            <div>
              <Button variant="contained" onClick={handleaddsubmit}>
                Add Address
              </Button>
            </div>
          )}
          <div>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserAddress;
