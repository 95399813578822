import React, { useEffect, useState } from "react";
import styles from "./LaptopInventory.module.css";
import FilterContainer from "../FitlerContainer/FilterContainer";
import CustomTable from "../CustomTable/CustomTable";
import CustomPagination from "../CustomPagination/CustomPagination";
import CustomSearchFilter from "../CustomSearch/CustomSearchFilter";
import { token_api, base_url } from "../../Utils/network";
import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import { Validate } from "./Utility";
import DrawerComp from "../DrawerComp/DrawerComp";
import DrawerContainer from "../DrawerContainer/DrawerContainer";
import DrawerTitle from "../DrawerTitle/DrawerTitle";
import { Button } from "@mui/material";
import CustomSelectChip from "../CustomSelectChip/CustomSelectChip";
import InputFields from "../InputFields/InputFields";
import DronePilot from "../../Component/DronePilot/DronePilot";
import CustomCard from "../../Component/CustomCard/CustomCard";
import pilotUser from "../../Assets/pilotUser.svg";
import dronePilot from "../../Assets/dronePilot.svg";
import laptopInv from "../../Assets/laptopInv.svg";
import droneInventoryBook from "../../Assets/droneInventoryBook.svg";
import CustomModal from "../CustomModal/CustomModal";
import { formatDate } from "../../Utils/helper";
import CustomDatePicker from "../MaterialDate/MaterialDate";
import { getStatusStyles } from "../../Utils/Utils";
import blackArrow from "../../Assets/breadCrumbBack.svg";
import dayjs from "dayjs";
import axios from "axios";
import CustomButton from "../CustomButton/CustomButton";

const LaptopInventory = () => {
  const [laptopInventoryList, setLaptopInventoryList] = useState([]);
  const [filters, setfilters] = useState({});
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;

  const [pilotSelected, setPilotSelected] = useState([]);

  const [assignPilot, setAssignPilot] = useState({
    laptop_inventory_id: null,
    pilot_id: null,
    assigned_from: null,
    assigned_to: null,
  });
  const [openDailog, setOpenDailog] = useState(false);
  const [open, setOpen] = useState(false);
  const [formErrors, setformErrors] = useState({});
  const [formData, setFormData] = useState({
    id: null,
    laptop_id: [],
    serial_no: "",
    manufacturer_serial_no: "",
    status: [],
  });
  const [laptopList, setLaptopList] = useState([]);

  const [cardData, setCardData] = useState({
    assign: { name: "Assigned", value: null },
    not_assign: { name: "Not Assigned", value: null },
    working: { name: "Working", value: null },
    damaged: { name: "Damaged", value: null },
    sold: { name: "Sold", value: null },
    repair: { name: "Under Repair", value: null },
    at_base: { name: "At Base", value: null },
    on_field: { name: "On Field", value: null },
    training: { name: "Training", value: null },
    survey_service: { name: "Survey Service", value: null },
    agri_service: { name: "Agri Service", value: null },
    no_category_assign: { name: "No Application Assigned", value: null },
  });
  const [assignInventoryObj, setAssignInventoryObj] = useState({});
  const [laptopAssignPilotHistory, setLaptopAssignPilotHistory] = useState([]);
  const [laptopAssetAssign, setLaptopAssetAssign] = useState({
    id: null,
    assigned_from: null,
    assigned_to: null,
    pilot: [],
  });
  const [assetAssignOpen, setAssetAssignOpen] = useState(false);

  const handleOpenAssignPilot = (inventoryId) => {
    setOpenDailog(true);
    setAssignPilot({
      ...assignPilot,
      laptop_inventory_id: inventoryId,
      assigned_from: null,
      assigned_to: null,
    });
    setPilotSelected([]);
  };

  const handleAdd = () => {
    setOpen(true);
    setFormData({
      ...formData,
      id: null,
      laptop_id: [],
      serial_no: "",
      manufacturer_serial_no: "",
      status: [],
    });
    setformErrors({});
    setPilotSelected([]);
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Working";
      case 2:
        return "Sold";
      case 3:
        return "Damaged";
      case 4:
        return "Under Repair";
      default:
        return "Unknown";
    }
  };

  const handleEditLaptopInventory = (info) => {
    const { id, laptop, inv_status, serial_no, manufacturer_serial_no } = info;

    setFormData({
      ...formData,
      id: id,

      laptop_id: [laptop]?.map((info) => ({
        title: info?.manufacturer,
        id: info?.id,
      })),

      status: statusOptions?.filter((info) => info?.id == inv_status),
      serial_no: serial_no,
      manufacturer_serial_no: manufacturer_serial_no,
    });

    setOpen(true);
    setformErrors({});
  };
  const handleDeleteLaptopInventory = (laptopId) => {
    token_api
      .delete(`/drone/laptop/inventory/${laptopId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Laptop Inventory is deleted successfully!");
          getLaptopInventoryList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleToShowAssignList = (inventoryobj) => {
    setAssignInventoryObj(inventoryobj);
  };

  const getLaptopInventoryList = async (filterobject) => {
    try {
      const fethLaptopInventoryList = await token_api.get(
        `drone/laptop/inventory`,
        {
          params: { ...filterobject },
        }
      );

      if (fethLaptopInventoryList.status !== 200) {
        throw new Error("Error in fetching");
      }
      setPaginationData(fethLaptopInventoryList?.data);
      let modifiedArray = fethLaptopInventoryList?.data?.data?.map((item) => {
        const droneStatusStyles = getStatusStyles(item?.inv_status);

        return {
          id: (
            <p
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                handleToShowAssignList(item);
              }}
            >
              {item?.id}
            </p>
          ),
          laptop_id: item?.laptop?.id,
          serial_no: item?.serial_no,
          manufacturer_serial_no: item?.manufacturer_serial_no,
          inv_status: (
            <span
              style={{
                padding: "5px",
                borderRadius: "50px",
                ...droneStatusStyles,
                minWidth: "60%",
                font: "bold",
                display: "inline-block",
                width: "100px",
              }}
            >
              {getStatusText(item?.inv_status)}
            </span>
          ),

          assign: (
            <Button
              variant="contained"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleOpenAssignPilot(item?.id);
              }}
            >
              Assign
            </Button>
          ),

          action: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => handleEditLaptopInventory(item)}
              >
                <img src={editIcon} />
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteLaptopInventory(item?.id)}
              >
                <img src={deleteIcon} />
              </div>
            </div>
          ),
        };
      });
      setLaptopInventoryList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      let filtersObject = {};
      if (pageState) {
        filtersObject.page = pageState;
      }
      if (filters?.q) {
        filtersObject.q = filters?.q;
      }

      getLaptopInventoryList(filtersObject);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [pageState, filters?.q]);

  const handleAutocompleteChangeforPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setPilotSelected(selectedValues);
    }
  };

  const getDashboardCount = () => {
    token_api
      .get(`drone/laptop_inventory/dashboard_count`)
      .then((res) => {
        if (res.data.data) {
          const {
            assign,
            not_assign,
            damaged,
            sold,
            working,
            under_repair,
            agri_service,
            no_category_assign,
            survey_service,
            training,
          } = res.data.data;
          const _data = {
            assign: { name: "Assigned", value: assign },
            not_assign: { name: "Not Assigned", value: not_assign },
            damaged: { name: "Damaged", value: damaged },
            working: { name: "Working", value: working },
            sold: { name: "Sold", value: sold },
            repair: { name: "Under Repair", value: under_repair },
            at_base: { name: "At Base", value: 0 },
            on_field: { name: "On Field", value: 0 },
            training: { name: "Training", value: training },
            survey_service: { name: "Survey Service", value: survey_service },
            agri_service: { name: "Agri Service", value: agri_service },
            no_category_assign: {
              name: "No Application Assigned",
              value: no_category_assign,
            },
          };
          setCardData(_data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCloseModal = () => {
    setOpenDailog(false);
    setAssignPilot({
      laptop_inventory_id: null,
      pilot_id: null,
      assigned_from: null,
      assigned_to: null,
    });
  };

  const getLaptopList = () => {
    token_api
      .get(`drone/laptops`)
      .then((res) => {
        if (res.data.data) {
          let newres = res?.data?.data.map((info) => ({
            title: info?.manufacturer,
            id: info?.id,
          }));
          setLaptopList(newres);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getDashboardCount();
    getLaptopList();
  }, []);

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setFormData({
        ...formData,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setFormData({
        ...formData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setFormData({
      ...formData,
      [selectName]: formData?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleText = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddLaptopInvenotory = () => {
    let data = {
      laptop_id: formData?.laptop_id[0]?.id,
      inv_status: formData?.status[0]?.id,
      serial_no: formData?.serial_no,
      manufacturer_serial_no: formData?.manufacturer_serial_no,
    };

    Validate(formData)
      .then((response) => {
        if (response == "success") {
          if (formData?.id) {
            token_api
              .patch(`/drone/laptop/inventory/${formData?.id}/`, data)
              .then((res) => {

                if (
                  res.status == 200 ||
                  res.status == 201 ||
                  res.status == 204
                ) {
                  alert("Laptop Inventory Updated Successfully!");
                  getLaptopInventoryList();
                  setFormData({
                    id: null,
                    laptop_id: [],
                    status: [],
                    serial_no: "",
                    manufacturer_serial_no: "",
                  });

                  setOpen(false);
                  setPilotSelected([]);
                }
              })
              .catch((err) => console.log(err));
          } else {
            token_api
              .post(`/drone/laptop/inventory/`, data)
              .then((res) => {
                if (res.status == 200 || res.status == 201) {
                  alert("Laptop Inventory Created Successfully!");
                  getLaptopInventoryList();
                  setFormData({
                    id: null,
                    laptop_id: [],
                    status: [],
                    serial_no: "",
                    manufacturer_serial_no: "",
                  });

                  setOpen(false);
                  setPilotSelected([]);
                }
              })
              .catch((err) => console.log(err));
          }
        }
      })
      .catch((error) => {
        setformErrors(error);
      });
  };

  const handleAssignPilot = () => {
    let data = {
      laptop_inventory_id: assignPilot?.laptop_inventory_id,
      pilot_id: pilotSelected[0]?.id,
      assigned_from: formatDate(assignPilot?.assigned_from),
      assigned_to: formatDate(assignPilot?.assigned_to),
    };

    token_api
      .post(`drone/pilot/laptop_assign/`, data)
      .then((res) => {

        if (res.status == 400) {
          alert(res?.data?.error?.fields[0]?.message[0]);
          setOpenDailog(false);
          // getLaptopInventoryList();
          setAssignPilot({
            laptop_inventory_id: null,
            pilot_id: null,
            assigned_from: null,
            assigned_to: null,
          });
          setPilotSelected([]);
        }
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          alert("Pilot Assigned Successfully!");
          setOpenDailog(false);
          // getLaptopInventoryList();
          setAssignPilot({
            laptop_inventory_id: null,
            pilot_id: null,
            assigned_from: null,
            assigned_to: null,
          });
          setPilotSelected([]);
          if (assignInventoryObj?.id) {
            setPageState(1);
            getInventoryAssignedList();
          } else {
            setPageState(1);
            getLaptopInventoryList();
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getInventoryAssignedList = async (filterobject) => {
    try {
      const fethLaptopInventoryAssignedList = await token_api.get(
        `drone/pilot/laptop_assign?laptop_inventory=${assignInventoryObj?.id}`,
        {
          params: { ...filterobject },
        }
      );

      if (fethLaptopInventoryAssignedList.status !== 200) {
        throw new Error("Error in fetching");
      }
      setPaginationData(fethLaptopInventoryAssignedList?.data);
      let modifiedArray = fethLaptopInventoryAssignedList?.data?.data?.map(
        (item) => {
          return {
            assigned_to: `${item?.pilot?.user?.first_name} ${item?.pilot?.user?.last_name}`,
            assigned_from: item?.assigned_from,
            assigned_till: item?.assigned_to,
            handover_accepted_on: "N.A",
            handover_accepted_by: "N.A",
            updated_on: "N.A",
            modified_by: item?.modified_by || "N.A",
            assign_by: item?.assign_by,
            created_on: item?.created_on || "N.A",
            // assign: (
            //   <Button
            //     variant="contained"
            //     style={{ cursor: "pointer" }}
            //     onClick={() => {
            //       handleOpenAssignPilot(assignInventoryObj?.id);
            //     }}
            //   >
            //     Assign
            //   </Button>
            // ),
            action: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleEdiLaptopAssetAssign(item)}
                >
                  <img src={editIcon} />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteLaptopAssetAssign(item?.id)}
                >
                  <img src={deleteIcon} />
                </div>
              </div>
            ),
          };
        }
      );
      setLaptopAssignPilotHistory([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdiLaptopAssetAssign = (item) => {
    setLaptopAssetAssign({
      ...laptopAssetAssign,
      id: item?.id,
      assigned_from: dayjs(item?.assigned_from),
      assigned_to: dayjs(item?.assigned_to),
      pilot: [item?.pilot],
    });
    setAssetAssignOpen(true);
  };

  const handleDeleteLaptopAssetAssign = (assignId) => {
    token_api
      .delete(`drone/pilot/laptop_assign/${assignId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Laptop Inventory Asset Assign Delete Successfully!");
          setAssetAssignOpen(false);
          getInventoryAssignedList();
        }
      });
  };
  useEffect(() => {
    if (assignInventoryObj?.id) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};

        if (pageState) {
          filtersObject.page = pageState;
        }

        getInventoryAssignedList(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [pageState, assignInventoryObj?.id]);

 

  const handleHideAssignHistory = () => {
    setAssignInventoryObj({});
    getLaptopInventoryList();
  };

  const handleUpdateLaptopAssetAssign = () => {
    let data = {
      pilot_id: laptopAssetAssign?.pilot[0]?.id,
      assigned_from: formatDate(laptopAssetAssign?.assigned_from),
      assigned_to: formatDate(laptopAssetAssign?.assigned_to),
    };
    token_api
      .patch(`drone/pilot/laptop_assign/${laptopAssetAssign?.id}/`, data)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Laptop Asset Assign Details updated");
          setLaptopAssetAssign({
            id: null,
            assigned_from: null,
            assigned_to: null,
            pilot: [],
          });
          setAssetAssignOpen(false);
          getInventoryAssignedList();
        }
      });
  };

  const handleAutocompleteChangeforLaptopPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setLaptopAssetAssign({
        ...laptopAssetAssign,
        ["pilot"]: selectedValues,
      });
    }
  };

  //Export Logic...
  const handleExportData = async () => {
    try {
      const response = await axios.get(
        `${base_url}drone/export/laptop_inventory`
      );
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };
  return (
    <div>
      <div
        className={styles.pilotDash}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          // flexWrap: "wrap",
        }}
      >
        <div
          style={{
            minWidth: "30%",
            minHeight: "150px",
            margin: "0px 10px 0px 0px",
            alignSelf: "stretch",
          }}
        >
          <CustomCard
            cardIcon={pilotUser}
            data={{
              assign: cardData.assign,
              not_assign: cardData.not_assign,
            }}
            background={`linear-gradient(to left, rgb(81, 79, 255), rgb(87, 194, 255))`}
          />
        </div>
        <div
          style={{
            minWidth: "30%",
            minHeight: "150px",
            margin: "0px 10px 0px 10px",
            alignSelf: "stretch",
          }}
        >
          <CustomCard
            cardIcon={laptopInv}
            data={{
              working: cardData.working,
              damaged: cardData.damaged,
              sold: cardData.sold,
              repair: cardData.repair,
            }}
            background={` linear-gradient(103.23deg, #57B67A -2.03%, #0B7754 96.35%)`}
          />
        </div>
        <div
          style={{
            minWidth: "30%",
            minHeight: "150px",
            margin: "0px 0px 0px 10px",
            alignSelf: "stretch",
          }}
        >
          <CustomCard
            cardIcon={droneInventoryBook}
            data={{
              at_base: cardData.at_base,
              on_field: cardData.on_field,
            }}
            background={`linear-gradient(to left, rgba(132, 98, 251, 1), rgba(235, 137, 235, 1))`}
          />
        </div>
      </div>

      {!assignInventoryObj?.id ? (
        <div style={{ marginTop: "20px" }}>
          <FilterContainer
            style={{ maxHeight: "40px", marginTop: "20px" }}
            // className={`${styles.marginTopBox}`}
          >
            <CustomSearchFilter
              placeholder="Search by serial number..."
              onChange={(e) => {
                setPageState(1);
                setfilters({ ...filters, q: e });
              }}
            />
            <CustomButton
              text={"Export"}
              maxWidth="110px"
              onClick={handleExportData}
            />

            <Button
              variant="contained"
              onClick={handleAdd}
              sx={{ height: "53px" }}
            >
              Add
            </Button>
          </FilterContainer>

          <div className={styles.tableContainer}>
            <CustomTable
              columns={tableColumns}
              data={laptopInventoryList}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <img
                src={blackArrow}
                onClick={handleHideAssignHistory}
                style={{ cursor: "pointer" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.id}</h4>
                <p>Laptop Inventory ID</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.laptop?.model || "N.A"}</h4>
                <p>Laptop Model</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.laptop?.manufacturer || "N.A"}</h4>
                <p>Laptop manufacturer</p>
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpenAssignPilot(assignInventoryObj?.id);
                }}
              >
                Assign
              </Button>
            </div>
          </div>

          <div className={styles.tableContainer}>
            <CustomTable
              columns={assignPilotColumns}
              data={laptopAssignPilotHistory}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      )}

      <DrawerComp open={open} onClose={() => setOpen(false)} anchor="right">
        <DrawerContainer>
          <DrawerTitle
            text={
              formData?.id ? "Update Laptop Inventory" : "Add Laptop Inventory"
            }
            handleCancelDrw={() => setOpen((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <CustomSelectChip
              multiple={false}
              label="Select Laptop"
              required
              name="laptop_id"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={formData?.laptop_id}
              listArray={laptopList}
              error={formErrors?.laptop_id}
              helperText={formErrors?.laptop_id}
            />
            <InputFields
              label="Serial Number"
              name="serial_no"
              value={formData?.serial_no || ""}
              onChange={handleText}
              error={formErrors?.serial_no}
              helperText={formErrors?.serial_no}
            />
            <InputFields
              label="Manufacturer Serial No."
              name="manufacturer_serial_no"
              value={formData?.manufacturer_serial_no || ""}
              onChange={handleText}
              error={formErrors?.manufacturer_serial_no}
              helperText={formErrors?.manufacturer_serial_no}
            />

            <CustomSelectChip
              multiple={false}
              label="Inventory Status"
              name="status"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={formData?.status}
              listArray={statusOptions}
              error={formErrors?.status}
              helperText={formErrors?.status}
            />

            <div>
              <Button variant="contained" onClick={handleAddLaptopInvenotory}>
                {formData?.id ? "Update" : "Add"}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>

      <DrawerComp
        open={assetAssignOpen}
        onClose={() => setAssetAssignOpen(false)}
        anchor="right"
      >
        <DrawerContainer>
          <DrawerTitle
            text={"Update Pilot Assign Details"}
            handleCancelDrw={() => setAssetAssignOpen((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <DronePilot
              value={laptopAssetAssign?.pilot}
              onChange={handleAutocompleteChangeforLaptopPilot}
            />

            <CustomDatePicker
              label="Assigned From"
              onChange={(newValue) => {
                setLaptopAssetAssign({
                  ...laptopAssetAssign,
                  assigned_from: newValue,
                });
              }}
              value={laptopAssetAssign?.assigned_from}
            />

            <CustomDatePicker
              label="Assigned Till"
              onChange={(newValue) => {
                setLaptopAssetAssign({
                  ...laptopAssetAssign,
                  assigned_to: newValue,
                });
              }}
              value={laptopAssetAssign?.assigned_to}
            />

            <div>
              <Button
                variant="contained"
                onClick={handleUpdateLaptopAssetAssign}
              >
                {"Update"}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>

      <CustomModal open={openDailog} onClose={handleCloseModal}>
        <div>
          <h3 style={{ marginBottom: "10px" }}>Assigned Pilot</h3>
          <div style={{ marginTop: "10px" }}>
            <CustomDatePicker
              label="Assigned From"
              value={assignPilot?.assigned_from}
              onChange={(newValue) =>
                setAssignPilot({
                  ...assignPilot,
                  assigned_from: newValue,
                })
              }
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <CustomDatePicker
              label="Assigned Till"
              value={assignPilot?.assigned_to}
              onChange={(newValue) =>
                setAssignPilot({
                  ...assignPilot,
                  assigned_to: newValue,
                })
              }
            />
          </div>{" "}
          <div style={{ margin: "10px 0px" }}>
            <DronePilot
              value={pilotSelected}
              onChange={handleAutocompleteChangeforPilot}
            />
          </div>
          <Button variant="contained" onClick={handleAssignPilot}>
            Submit
          </Button>
        </div>
      </CustomModal>
    </div>
  );
};

export default LaptopInventory;

const tableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "laptop_id",
    label: "Laptop ID",
  },
  {
    id: "serial_no",
    label: "Serial No",
  },
  {
    id: "manufacturer_serial_no",
    label: "Manufacturer Serial No",
  },

  {
    id: "inv_status",
    label: "Status",
  },

  {
    id: "assign",
    label: "Assign",
  },

  {
    id: "action",
    label: "Action",
  },
];

const tableBodyData = [
  {
    id: 1,
    drone_inventory: 101,
    charger_master: "AP31V3123",
    pilot_id: 201,
    status: "Active",
  },
  {
    id: 2,
    drone_inventory: 102,
    charger_master: "AP31V3123",
    pilot_id: 202,
    status: "Active",
  },
  {
    id: 3,
    drone_inventory: 103,
    charger_master: "AP31V3123",
    pilot_id: 203,
    status: "Active",
  },
  {
    id: 4,
    drone_inventory: 104,
    charger_master: "AP31V3123",
    pilot_id: 204,
    status: "Active",
  },
  {
    id: 5,
    drone_inventory: 105,
    charger_master: "AP31V3123",
    pilot_id: 205,
    status: "Inctive",
  },
];

const statusOptions = [
  { title: "Working", id: 1 },
  { title: "Sold", id: 2 },
  { title: "Damaged", id: 3 },
  { title: "Under Repair", id: 4 },
];

const assignPilotColumns = [
  {
    id: "assigned_to",
    label: "Assigned To",
  },
  {
    id: "assigned_from",
    label: "Assigned From",
  },
  {
    id: "assigned_till",
    label: "Assigned Till",
  },
  {
    id: "handover_accepted_on",
    label: "Handover Accepted On",
  },
  {
    id: "handover_accepted_by",
    label: "Handover Accepted By",
  },
  {
    id: "updated_on",
    label: "Updated On",
  },
  {
    id: "modified_by",
    label: "Updated By",
  },
  {
    id: "assign_by",
    label: "Created By",
  },
  {
    id: "created_on",
    label: "Created On",
  },
  // {
  //   id: "assign",
  //   label: "Assign Pilot",
  // },
  {
    id: "action",
    label: "Action",
  },
];
