import React, { useState, useEffect, useContext } from "react";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import { attendanceCardData } from "../../Utils/constants";
import DashboardCard from "../../Component/DashboardCard/DashboardCard";
import { token_api, base_url } from "../../Utils/network";
import styles from "./Attendance.module.css";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import CustomBatchChip from "../../Component/CutomBatchChip/CustomBatchChip";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomButton from "../../Component/CustomButton/CustomButton";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import CustomTable from "../../Component/CustomTable/CustomTable";
import { helperdayMonthYearFull, formatDate } from "../../Utils/helper";
import ControllableSwitch from "../../Component/ControllableSwitch/ControllableSwitch";
import DynamicTable from "../../Component/DynamicTable/DynamicTable";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TableForFlyingSlot from "../../Component/TableforFlyingSlot/TableForFlyingSlot";
import { useNavigate, Link } from "react-router-dom";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import axios from "axios";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CustomModal from "../../Component/CustomModal/CustomModal";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import { Button } from "@mui/material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Attendance = () => {
  let navigate = useNavigate();
  const [courseDashBoardData, setCourseDashBoardData] = useState({});
  const [courseListing, setCourseListing] = useState([]);
  const [filters, setfilters] = useState({ q: "", batch: null });
  const [typedValue, setTypedValue] = useState("");
  const [batchListing, setBatchListing] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [pageState, setPageState] = useState(1);
  const [bookingList, setBookingList] = useState([]);
  const { user_permissions } = useContext(UserCredsContext);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [exportData, setExportData] = useState({
    start_date: null,
    end_date: null,
  });

  // const [tabValue, setTabValue] = useState(1);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Attendance</div>,
  ];

  const handleCloseModal = () => {
    setExportData({
      start_date: null,
      end_date: null,
    });
    setOpenPopUp(false);
  };

  const getCourseAnalyticalData = async (finalFilters) => {
    try {
      const fetchData = await token_api.get(`course/course_batch_count`);

      if (fetchData?.status !== 200) {
        throw new Error("Error Fetching analytical data");
      }
      let dashBoardData = fetchData?.data?.data;
      setCourseDashBoardData(dashBoardData);
    } catch (error) {
      console.log(error);
    }
  };

  const getCourselisting = () => {
    token_api
      .get(`/course`)
      .then((response) => {
        if (response?.status == 200 || 201) {
          const newdata = response?.data?.data;
          let filterres = newdata?.map((info) => ({
            title: info?.name,
            id: info?.id,
            slug: info?.slug,
          }));
          setCourseListing(filterres);
        }
      })
      .catch((error) => console.log(error));
  };

  const getBatchListing = (valueitem = null, valueitemtwo = null) => {
    let apiEndpoint = "/course/batches";

    if (valueitem !== null && valueitemtwo === null) {
      apiEndpoint += `?q=${valueitem}`;
    } else if (valueitem === null && valueitemtwo !== null) {
      apiEndpoint += `?course=${valueitemtwo}`;
    }
    token_api
      .get(apiEndpoint)
      .then((response) => {
        setBatchListing(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleFilter = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };


  useEffect(() => {
    getCourseAnalyticalData();
    getCourselisting();
  }, []);

  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getBatchListing(typedValue);
      }, 500);
    } else {
      getBatchListing();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);

  const handleInputChange = (event, newValue) => {
    setTypedValue(newValue);
    setfilters((prev) => ({
      ...prev,
      batch: newValue,
    }));
  };

  function getAttendanceList(finalFilters) {
    const switchStatesObject = {};
    const attendanceComponents = [];

    token_api
      .get(`/course/student_attendance?slot=${value + 1}`, {
        params: { ...finalFilters },

        paramsSerializer: {
          indexes: null,
        },
      })
      .then((response) => {
        // console.log(response?.data, "userresponse");
        setPaginationData(response?.data);

        const apiResponse = response?.data?.data;
        setBookingList(apiResponse);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const timeoutDelay = filters?.q ? 600 : 0; // Set the delay to 600 only if filters?.q is present

    const timeout = setTimeout(() => {
      const finalFilters = {};
      finalFilters.page = pageState;

      if (filters?.q) {
        finalFilters.q = filters.q;
      }

      if (filters?.batch) {
        finalFilters.batch = filters?.batch?.id;

        getAttendanceList(finalFilters);
      }
      if (filters?.slug) {
        let slugid = filters?.slug[0]?.slug;
        getBatchListing(null, slugid);
      }
    }, timeoutDelay);

    return () => clearTimeout(timeout);
  }, [pageState, filters?.q, value, filters?.batch, filters?.slug]);


  // const getBatchListingUsingCourse = (valueitem) => {
  //   let apiEndpoint = "/course/batches";

  //   if (valueitem) {
  //     apiEndpoint += `?course=${valueitem}`;
  //   }

  //   token_api
  //     .get(apiEndpoint)
  //     .then((response) => {
  //       console.log(response?.data, "response");
  //       setBatchListing(response?.data?.data);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // useEffect(() => {
  //   if (filters?.slug?.length > 0) {
  //     let courseid = filters?.slug[0]?.id;
  //     getBatchListingUsingCourse(courseid);
  //   }
  // }, [filters?.slug]);


  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  //Export Logic....

  const handleExportData = async () => {
    try {
      if (!exportData?.start_date || !exportData?.end_date) {
        alert("Please select start date and end dates.");
      } else {
        const response = await axios.get(
          `${base_url}course/attendance_export?batch=${
            filters?.batch?.id
          }&from_date=${formatDate(
            exportData?.start_date
          )}&to_date=${formatDate(exportData?.end_date)}`
        );
        const excelUrl = response.data.data.data.excel_url;
        const link = document.createElement("a");
        link.href = excelUrl;
        link.setAttribute("download", `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();

        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Attendance file downloaded successfully!");
          setExportData({
            start_date: null,
            end_date: null,
          });
          setOpenPopUp(false);
          getCourselisting();
          getBatchListing();
        }
      }
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>
      <div className={styles.dashBoardCon}>
        <div style={{ width: "49%" }}>
          <CustomSelectChip
            label={"Filter by Course"}
            listArray={courseListing}
            name="slug"
            value={filters?.slug}
            multiple={false}
            minWidth={"175px"}
            onChange={handleFilter}
            onDelete={handleDeleteChip}
          />
        </div>
        <div style={{ width: "49%" }}>
          <CustomBatchChip
            label="Filter by Batch"
            value={filters?.batch}
            onChange={handleInputChange}
            listing={batchListing}
            typedValue={typedValue}
            setTypedValue={setTypedValue}
          />
        </div>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <FilterContainer
          style={{ maxHeight: "40px", marginTop: "40px" }}
          // className={`${styles.marginTopBox}`}
        >
          <CustomSearchFilter
            placeholder="Search by user name..."
            onChange={(e) => {
              setfilters({ ...filters, q: e });
            }}
          />

          {filters?.batch && filters?.batch?.id && (
            <CustomButton
              text={"Export"}
              maxWidth="110px"
              onClick={() => {
                setOpenPopUp(true);
              }}
            />
          )}
        </FilterContainer>
      </div>
      {filters?.batch?.id && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Class slot" {...a11yProps(0)} />
              <Tab label="Flying slot" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <DynamicTable
              data={bookingList}
              getAttendanceList={getAttendanceList}
              batch={filters?.batch?.id}
              pageState={pageState}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {/* <DroneBatteries /> */}
            <TableForFlyingSlot
              data={bookingList}
              getAttendanceList={getAttendanceList}
              batch={filters?.batch?.id}
              pageState={pageState}
            />
          </CustomTabPanel>
        </Box>
      )}

      <div style={{ marginTop: "15px" }}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>

      <CustomModal open={openPopUp} onClose={handleCloseModal}>
        <div>
          <h3 style={{ marginBottom: "10px", color: "red" }}>
            Please select start date and end date.
          </h3>
          <div style={{ marginTop: "20px" }}>
            <CustomDatePicker
              label="Start Date"
              value={exportData?.start_date}
              onChange={(newValue) =>
                setExportData({
                  ...exportData,
                  start_date: newValue,
                })
              }
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <CustomDatePicker
              label="End Date"
              value={exportData?.end_date}
              onChange={(newValue) =>
                setExportData({
                  ...exportData,
                  end_date: newValue,
                })
              }
            />
          </div>{" "}
          <Button
            variant="contained"
            onClick={handleExportData}
            style={{ marginTop: "20px" }}
          >
            Download
          </Button>
        </div>
      </CustomModal>
    </Container>
  );
};

export default Attendance;
