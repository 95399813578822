import React, { useState, useEffect } from "react";
import InputFields from "../InputFields/InputFields";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import CustomDate from "../../Component/CustomDate/CustomDate";
import { Button } from "@mui/material";
import { token_api } from "../../Utils/network";
import { useParams } from "react-router-dom";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import AddressBlock from "../../Component/AddressBlock/AddressBlock";
import dayjs from "dayjs";
import { formatDate } from "../../Utils/helper";

const CustomerDetails = ({
  handleEditClick,
  serviceData,
  getServiceBookingDetails,
}) => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    start_date: null,
    end_date: null,
  });
  const [selectedBlock, setSelectedBlock] = useState([]);

  const [formErrors, setFormErrors] = useState({});

  console.log(serviceData, "revathiServiceData");

  const handleChange = (e, type, selectName, multiple) => {
    console.log("TYTYY");
    if (type === "select" && selectName && multiple) {
      setFormData({
        ...formData,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setFormData({
        ...formData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDeleteChipforDrawer = (id, selectName) => {
    console.log("DELETE", id, selectName, formData?.[selectName]);
    setFormData({
      ...formData,
      [selectName]: formData?.[selectName]?.filter((chip) => chip?.id !== id),
    });
    let namefiled = formData[selectName];
    console.log(namefiled[0]?.title, "slehdgfdf");
  };

  const handleAutoCompleteChangeForBlock = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Block already included");
    } else {
      setSelectedBlock(selectedValues);
    }
  };

  console.log(serviceData, "serviceDatahatata");

  useEffect(() => {
    if (serviceData) {
      const {
        full_name,
        gst,
        contact_no,
        start_date,
        end_date,
        address,
        dealer_code,
        bookingaddonservice,
        area_in_acres,
        line_kms,
      } = serviceData;

      // Extracting address details
      const {
        line_1,
        line_2,
        plot_no,
        landmark,
        area,
        city,
        district,
        tehsil,
        state,
        pincode,
        country,
        block,
      } = address;

      setSelectedBlock([block]);

      const matchedFarm = farmdata?.filter(
        (info) =>
          info?.title?.toLocaleLowerCase() === line_2?.toLocaleLowerCase()
      );
      console.log(matchedFarm, "thisisline2");
      setFormData({
        full_name,
        gst,
        dealer_code,
        contact_no,
        start_date: dayjs(start_date),
        end_date: dayjs(end_date),
        line_1,
        line_2: matchedFarm,
        plot_no,
        landmark,
        area,
        city,
        district,
        tehsil,
        state,
        pincode,
        country,
        area_in_acres,
        line_kms,
      });
    }
  }, [serviceData]);

  const handleSave = () => {
    const {
      full_name,
      gst,
      start_date,
      end_date,
      dealer_code,
      line_2,
      contact_no,
      ...rest
    } = formData;

    const data = {
      address: {
        line_2: line_2[0]?.title,
        ...rest,
        ...(selectedBlock.length > 0 && {
          block_id: selectedBlock[0]?.id,
        }),
      },
      full_name,
      gst,
      dealer_code,
      start_date: formatDate(start_date),
      // end_date: formatDate(end_date),
      lat: "18.5861722895534100",
      long: "73.9643736423491700",
      contact_no,
    };

    token_api
      .patch(`/service/admin_booking/${id}/`, data)
      .then((response) => {
        console.log(response, "djfh");

        if (response?.status == 200) {
          handleEditClick(false, null);
          getServiceBookingDetails();
          setSelectedBlock([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(data, "formsubmitdata");
  };

  return (
    <>
      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="Full Name"
          required
          name="full_name"
          value={formData?.full_name || ""}
          onChange={handleChange}
          error={formErrors?.serial_no}
        />
      </div>

      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="Mobile"
          required
          name="contact_no"
          value={formData?.contact_no}
          onChange={handleChange}
          error={formErrors?.contact_no}
        />
      </div>

      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="GST"
          name="gst"
          value={formData?.gst}
          onChange={handleChange}
          error={formErrors?.gst}
        />
      </div>

      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="Dealer Code"
          required
          name="dealer_code"
          value={formData?.dealer_code}
          onChange={handleChange}
          error={formErrors?.dealer_code}
        />
      </div>

      <div style={{ marginTop: "8px" }}>
        {/* <CustomDate
          name="start_date"
          label="Service start date"
          handleChange={handleChange}
          value={formData?.start_date}
          error={formErrors?.start_date}
        /> */}

        <CustomDatePicker
          label="Service Date"
          value={formData?.start_date}
          onChange={(newValue) =>
            setFormData({
              ...formData,
              start_date: newValue,
            })
          }
          error={formErrors?.start_date}
        />
      </div>

      <div style={{ marginTop: "0px" }}>
        {/* <CustomDate
          name="end_date"
          label="Service end date"
          handleChange={handleChange}
          value={formData?.end_date}
          error={formErrors?.end_date}
        /> */}

        {/* <CustomDatePicker
          label="Service End Date"
          value={formData?.end_date}
          onChange={(newValue) =>
            setFormData({
              ...formData,
              end_date: newValue,
            })
          }
          error={formErrors?.end_date}
        /> */}
      </div>

      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="Pincode"
          name="pincode"
          value={formData?.pincode}
          onChange={handleChange}
          error={formErrors?.pincode}
        />
      </div>

      <div style={{ marginTop: "10px" }}>
        <AddressBlock
          value={selectedBlock}
          onChange={handleAutoCompleteChangeForBlock}
        />
      </div>
      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="Area In Acres"
          name="area_in_acres"
          value={formData?.area_in_acres}
          onChange={handleChange}
          error={formErrors?.area_in_acres}
        />
      </div>
      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="Line In KMs"
          name="line_kms"
          value={formData?.line_kms}
          onChange={handleChange}
          error={formErrors?.line_kms}
        />
      </div>

      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="Plot No"
          name="line_1"
          value={formData?.line_1}
          onChange={handleChange}
          error={formErrors?.line_1}
        />
      </div>

      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="Land Area (In Hectares)"
          name="area"
          value={formData?.area}
          onChange={handleChange}
          error={formErrors?.area}
        />
      </div>

      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="Street Name"
          name="landmark"
          value={formData?.landmark}
          onChange={handleChange}
          error={formErrors?.landmark}
        />
      </div>

      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="Tehsil"
          name="tehsil"
          value={formData?.tehsil}
          onChange={handleChange}
          error={formErrors?.tehsil}
        />
      </div>

      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="District"
          name="district"
          value={formData?.district}
          onChange={handleChange}
          error={formErrors?.district}
        />
      </div>

      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="City/Village"
          name="city"
          value={formData?.city}
          onChange={handleChange}
          error={formErrors?.city}
        />
      </div>

      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="State"
          name="state"
          value={formData?.state}
          onChange={handleChange}
          error={formErrors?.state}
        />
      </div>

      <div style={{ marginTop: "8px" }}>
        <InputFields
          label="Country"
          name="country"
          value={formData?.country}
          onChange={handleChange}
          error={formErrors?.country}
        />
      </div>

      <div>
        <CustomSelectChip
          multiple={false}
          label="Farm type"
          name="line_2"
          onChange={handleChange}
          onDelete={handleDeleteChipforDrawer}
          value={formData?.line_2}
          listArray={farmdata}
          error={formErrors?.line_2}
        />
      </div>

      <div>
        <Button variant="contained" onClick={handleSave}>
          {serviceData?.id ? "Update" : "Add"}
        </Button>
      </div>
    </>
  );
};

export default CustomerDetails;

const farmdata = [
  {
    title: "building",
    id: "1",
  },
  {
    title: "farm",
    id: "2",
  },
];
