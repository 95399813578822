import React from 'react'
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const CustomBrdChip = ({
  value,
  onChange,
  listing,
  error,
  typedValue,

  setTypedValue,
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      const filteredOptions = listing.filter((option) => {
        const searchTerm = typedValue.toLowerCase();
        return (
          // option?.id?.toLowerCase().includes(searchTerm) ||
          option?.title?.toLowerCase().includes(searchTerm) ||
          option?.city?.includes(searchTerm)
        );
      });
      setOptions(filteredOptions);
    }
  }, [open, typedValue, listing]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: "100%" }}
      size="small"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, selectedValue) =>
        option?.id === selectedValue?.id ||
        option?.title === selectedValue?.title ||
        option?.city === selectedValue?.city
      }
      getOptionLabel={(option) => {
        const id = option?.id || "";
        const title = option?.title || "";
        const city = option?.city|| "";

        return `${title} `.trim();
      }}
      options={options}
      loading={loading}
      value={value}
      inputValue={typedValue}
      onInputChange={(event, newInputValue) => setTypedValue(newInputValue)}
      onChange={(event, newValue) => {
        onChange && onChange(event, newValue);
        setTypedValue(newValue?.id || "");
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Details*"
          error={Boolean(error)}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <div {...props}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography>{option.title}</Typography>
            {/* <Typography variant="body2" style={{ color: "gray" }}>
              Email: {option?.title}
              <br />
              Phone: {option?.city}
            </Typography> */}
          </div>
        </div>
      )}
    />
  )
}

export default CustomBrdChip