import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { token_api } from "../../Utils/network";
import { helperdayMonthYearFull } from "../../Utils/helper";

const DronePilot = ({ onChange, value }) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleChange = (event, newValue) => {
    onChange && onChange(newValue);
  };

  useEffect(() => {
    setLoading(true);

    let apiEndpoint = `service/blocks?page_size=1000`;

    if (inputValue) {
      apiEndpoint += `&q=${encodeURIComponent(inputValue)}`;
    }

    token_api
      .get(apiEndpoint)
      .then((response) => {
        const data = response?.data?.data?.map((info) => ({
          id: info?.id,
          block_name: info?.block_name,
          taluka: info?.taluka,
          district: info?.district,
          state: info?.state,
          block_code: info?.block_code,
        }));
        setOptions(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [inputValue]);

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      multiple
      id="tags-outlined"
      options={options}
      getOptionLabel={(option) => `${option?.block_name} `}
      value={value}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label="Blocks" placeholder="Search for blocks" />
      )}
      renderOption={(props, option, { inputValue }) => {
        const label = `Block name: ${option?.block_name} `;
        const sublabel = `Taluka: ${option?.taluka} | District: ${option?.district} | State: ${option?.state}`;

        return (
          <li {...props}>
            <div style={{ width: "100%" }}>
              <div>
                <div style={{ fontWeight: "bold" }}>{label}</div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>{sublabel}</div>
                </div>
              </div>
            </div>
          </li>
        );
      }}
    />
  );
};

export default DronePilot;
