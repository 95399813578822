// RoomCustomChip.js

import React from "react";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { helperdayMonthYearFull } from "../../Utils/helper";

const RoomCustomChip = ({
  multiple = true,
  label,
  onChange,
  onDelete,
  name,
  type = "select",
  value,
  listArray,
  disabled,
  error,
}) => {
  return (
    <FormControl sx={{ width: "100%" }} size="small" error={error}>
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        label={label}
        disabled={disabled}
        size="small"
        multiple={multiple}
        value={value || []}
        onChange={(e) => onChange(e.target.value, type, name, multiple)}
        name={name}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected?.map((value) => (
              <Chip
                label={`${value?.room_no} (${value?.occupancy_type_str})`}
                key={value}
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                onDelete={() => onDelete(value?.id, name)}
              />
            ))}
          </Box>
        )}
      >
        {listArray?.map((item, key) => (
          <MenuItem key={key} value={item}>
            {`${item?.room_no} (${item?.occupancy_type_str})`}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default RoomCustomChip;
