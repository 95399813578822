import React, { useEffect, useState } from "react";
import { TextField, Autocomplete } from "@mui/material";

const ControllableStates = ({
  options,
  property = "label",
  label,
  _value,
  _setValue,
  ...props
}) => {
  const [value, setValue] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    _setValue(newValue);
  };

  useEffect(() => {
    setValue(_value);
  }, [_value]);
  return (
    <div style={{ width: 300, margin: "0 auto" }}>
      <Autocomplete
        value={value}
        onChange={handleChange}
        options={options}
        getOptionLabel={(option) => option[property]}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="outlined" />
        )}
      />
    </div>
  );
};

export default ControllableStates;
