import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { helperdayMonthYearFull } from "../../Utils/helper";
import { token_api } from "../../Utils/network";

const TableForFlyingSlot = ({ data,getAttendanceList, batch, pageState  }) => {
  const [tableHeader, setTableheader] = useState([]);
  const [localSwitchStates, setLocalSwitchStates] = useState({});

  useEffect(() => {
    const uniqueDates = Array.from(
      new Set(data.flatMap((user) => user.attendance.map((item) => item.date)))
    );
    const sortedDates = uniqueDates.sort((a, b) => new Date(a) - new Date(b));
  
    setTableheader(sortedDates);
  }, [data]);
  

  useEffect(() => {
    const initialSwitchStates = data.reduce((acc, student) => {
      student.attendance.forEach((attendanceItem) => {
        acc[`${student.id}-${attendanceItem.date}`] = attendanceItem.present;
      });
      return acc;
    }, {});
    setLocalSwitchStates(initialSwitchStates);
  }, [data]);

  const handleSwitchChange = (studentId, date) => (event) => {
    const isChecked = event.target.checked;
    const newSwitchStates = {
      ...localSwitchStates,
      [`${studentId}-${date}`]: isChecked,
    };
    setLocalSwitchStates(newSwitchStates);

    const selectedStudent = data.find((student) => student.id === studentId);

    if (selectedStudent?.id) {
      const attendanceItem = selectedStudent?.attendance?.find(
        (info) => info?.date === date
      );

      token_api
        .patch(`/course/attendance/${attendanceItem?.id}/`, {
          present: isChecked,
        })
        .then((response) => {
          if (response?.status == 200 || response?.status == 201) {

            const finalFilters = {};
            finalFilters.page = pageState;

            if (batch) {
              finalFilters.batch = batch;

              getAttendanceList(finalFilters);
            }
      
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{ backgroundColor: "#E95E33" }}>
          <TableRow>
            <TableCell
              style={{ textAlign: "center", color: "white", fontWeight: "500" }}
            >
              Student ID
            </TableCell>
            <TableCell
              style={{ textAlign: "center", color: "white", fontWeight: "500" }}
            >
              User Name
            </TableCell>
            <TableCell
              style={{ textAlign: "center", color: "white", fontWeight: "500" }}
            >
              Average Present
            </TableCell>
            {tableHeader?.map((date, index) => (
              <TableCell
                key={date}
                align="center"
                style={{
                  textAlign: "center",
                  color: "white",
                  fontWeight: "500",
                }}
              >
                {helperdayMonthYearFull(date)}
                <div style={{ textAlign: "center", margin: "0px" }}>
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    {" "}
                    Flying
                  </p>
                  {/* <p style={{textAlign:"center", margin:"0px"}} >{slotChoices[index]}</p> */}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((student) => (
            <TableRow key={student.id}>
              <TableCell style={{ textAlign: "center" }}>
                {student?.id}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {student?.user_name}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {student?.average_present}
              </TableCell>
              {tableHeader?.map((date) => (
                <TableCell key={date} align="center">
                  {student.attendance.some((item) => item.date === date) ? (
                    <Switch
                      style={{ textAlign: "center" }}
                      checked={
                        localSwitchStates[`${student?.id}-${date}`] || false
                      }
                      onChange={handleSwitchChange(student?.id, date)}
                    />
                  ) : (
                    "NA"
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableForFlyingSlot;
