import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
// import styles from "./MainLayout.module.css";
import Sidebar from "../Component/Sidebar/Sidebar";
import { Box } from "@mui/material";
import Navbar from "../Component/Navbar/Navbar";
import Footer from "../Component/Footer/Footer";
import { useSelector } from "react-redux";

function MainLayout(props) {
  const location = useLocation();
  const isAuth = useSelector((state) => state.auth.access_token);
  const [rightfullWidth, setRightFullWidth] = useState(false);

  // const locationCheck = window.location.href;
  // const tabValue = locationCheck.split("/")
  // console.log("APPROUTERLOCATON", tabValue);
  // useEffect(() => {

  // }, [locationCheck]);

  // useEffect(() => {
  //   const pathCheck = location.pathname;
  //   console.log("PAthCheck", pathCheck, isAuth);
  // }, [location]);

  console.log("MainLayoutExecutes");
  const handleEhangeWidth = () => {
    console.log("drawerOpened", rightfullWidth);

    setRightFullWidth(!rightfullWidth);
  };

  return (
    <>
      <Box
        display={"flex"}
        width="100%"
        height="100%"
        // style={{ overflowX: "hidden" }}
      >
        <Sidebar drawerWidth="300px" handleEhangeWidth={handleEhangeWidth} />
        <Box
          style={{
            width: !rightfullWidth
              ? "calc(100% - 302px)"
              : "calc(100% - 100px)",
          }}
        >
          <Navbar />
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default MainLayout;
