import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { pesticideListDummy } from "../../Utils/constants";
import crpstg1 from "../../Assets/listIcon/crpstg1.png";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomAutoComplete = ({
  options,
  label,
  handleChange,
  name,
  value,
  multiple = false,
  componentType = "simple",
  disableCloseOnSelect = false,
  disabled,
}) => {
  // const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState("");
  // const handleChange = (selectValue) => {
  //   setValue(selectValue);
  // };
  const handleInputChange = (inpVal) => {
    setInputValue(inpVal);
  };

  return (
    <>
      <Autocomplete
        size="small"
        multiple={multiple}
        onChange={(event, newValue) =>
          handleChange(newValue, "autocomplete", name)
        }
        onInputChange={(event, newInputValue) => {
          handleInputChange(newInputValue);
        }}
        name={name}
        value={value}
        disabled={disabled}
        id="checkboxes-tags-demo"
        options={options || top100Films}
        disableCloseOnSelect={disableCloseOnSelect}
        getOptionLabel={(option) => option.title}
        renderOption={(props, option, { selected }) => {

          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option?.icon && <img src={option?.icon} alt="icon" />}

              <div style={{ marginLeft: "10px" }}>
                <div className="font-14"> {option.title}</div>

                {option?.sub_topic && (
                  <div className="font-12 gray-1">{option?.sub_topic}</div>
                )}
              </div>
            </li>
          );
        }}
        style={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </>
  );
};

export default CustomAutoComplete;

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
];
