export const Validate = (value, pilotSelected,serviceBookingSeleted) => {
  return new Promise((resolve, reject) => {
    const {
      acres_done_today,
      city,
      country,
      crop,
      customer_name,
      customer_email,
      customer_phone,
      date,
      district,
      line_1,
      line_2,
      liquid_used,
      pincode,
      plot_no,
      remark,
      spray_start_time,
      spray_end_time,
      tafget_acres_today,
    } = value;
    

    const errors = { error: false };

    if (!acres_done_today) {
      errors.acres_done_today = "Please enter acre done today";
      errors.error = true;
    }
    if (!city) {
      errors.city = "Please enter city";
      errors.error = true;
    }
    if (!country) {
      errors.country = "Please enter country";
      errors.error = true;
    }

    if (!crop) {
      errors.crop = "Please enter crop";
      errors.error = true;
    }
    if (!customer_name) {
      errors.customer_name = "Please enter customer name";
      errors.error = true;
    }
    if (!customer_email) {
      errors.customer_email = "Please enter customer email";
      errors.error = true;
    }
    if (!customer_phone) {
      errors.customer_phone = "Please enter customer phone";
      errors.error = true;
    }

    if (!date) {
      errors.date = "Please fill date";
      errors.error = true;
    }
    if (!district) {
      errors.district = "Please enter district";
      errors.error = true;
    }
    if (!line_1) {
      errors.line_1 = "Please enter line_1";
      errors.error = true;
    }
    if (!line_2) {
      errors.line_2 = "Please enter line_2";
      errors.error = true;
    }
    if (!liquid_used) {
      errors.liquid_used = "Please enter liquid used";
      errors.error = true;
    }

    if (!pincode) {
      errors.pincode = "Please enter pincode";
      errors.error = true;
    }
    if (!plot_no) {
      errors.plot_no = "Please enter plot no.";
      errors.error = true;
    }

    if (!remark) {
      errors.remark = "Please enter remarks";
      errors.error = true;
    }

    if (!spray_start_time) {
      errors.spray_start_time = "Please enter spray start time";
      errors.error = true;
    }

    if (!spray_end_time) {
      errors.spray_end_time = "Please enter spray end time";
      errors.error = true;
    }

    if (!tafget_acres_today) {
      errors.tafget_acres_today = "Please enter target acres today";
      errors.error = true;
    }
    if (!pilotSelected || !pilotSelected[0]) {
      errors.pilotSelected = "Please select pilot";
      errors.error = true;
    }
    if (!serviceBookingSeleted || !serviceBookingSeleted[0]) {
      errors.serviceBookingSeleted = "Please select service booking";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
