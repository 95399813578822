import React from "react";

const DashboardCard = ({
  cardTitle = "Service Request",
  cardIcon,
  primaryGardient,
  secondaryGradient,
  value,
  hint,
  countvalue,
  eclipseIcon,
  present = false,
}) => {
  return (
    <div
      className=""
      style={{
        borderRadius: "10px",
        flex: "1",
        background: primaryGardient,
        position: "relative",
      }}
    >
      <div
        style={{
          borderRadius: "0px 30px 10px 10px",
          background: secondaryGradient,
          marginTop: "25px",
          padding: "15px 15px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: "12px",
          }}
        >
          <div>
            <img src={cardIcon} alt="icon" />
          </div>
          <div style={{ marginLeft: "20px" }}>
            {present && (
              <h1
                style={{
                  marginBottom: "8px",
                  color: "white",
                  fontSize: "40px",
                  fontWeight: "700",
                }}
              >
                {countvalue[hint]}
              </h1>
            )}
            <p
              style={{
                marginTop: "0px",
                color: "white",
                fontWeight: "700",
                fontSize: "24px",
              }}
            >
              {cardTitle}
            </p>
          </div>
        </div>
      </div>
      <img
        src={eclipseIcon}
        style={{ position: "absolute", bottom: "0px", right: "0px" }}
      />
    </div>
  );
};

export default DashboardCard;
