import "./App.css";
import AppRouter from "./Component/AppRouter";
import UserCredsContextProvider from "./ContextApi/UserCredsContext/UserCredsContext";

function App() {
  return (
    <div className="App">
      <UserCredsContextProvider>
        <AppRouter />
      </UserCredsContextProvider>
    </div>
  );
}

export default App;
