import { open_api } from "../../Utils/network";
import { loginFailed, loginStart, loginSuccess } from "../reducers/authSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";

const loginApi = async (credentials) => {
  try {
    const loginResponse = await open_api.post(`auth/login/`, credentials);
    if (loginResponse?.status >= 400 && loginResponse?.status < 500) {
      throw new Error("Password Incorect");
    }
    const { data } = loginResponse?.data;
    return data;
  } catch (error) {
    throw error;
  }
};

// this is an action , when this code runs , above function will be called
export const login = (credentials) => async (dispatch) => {

  dispatch(loginStart())
  try {
    const data = await loginApi(credentials);
    dispatch(loginSuccess(data));
  } catch (error) {
    dispatch(loginFailed())
    throw error;
  }
};
