import React, { useState, useEffect } from "react";
import { formatDroneMasterList } from "../../Pages/DroneMaster/droneUtility";
import { token_api } from "../../Utils/network";
import CustomTable from "../CustomTable/CustomTable";

const DroneTabforDashboard = () => {
  const [droneMasterList, setDroneMasterList] = useState([]);

  const handleEditDrone = (editObject) => {};

  const handleDeleteDrone = () => {};
  const getDroneMasterListing = async () => {
    try {
      const fetchDroneMaster = await token_api.get(`drone`);
      if (fetchDroneMaster.status !== 200) {
        throw new Error("Error in fetching");
      }

      const modifiedData = formatDroneMasterList(
        fetchDroneMaster?.data?.data,
        handleEditDrone,
        handleDeleteDrone
      );
      setDroneMasterList(modifiedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDroneMasterListing();
  }, []);

  return (
    <div  style={{ height: "300px", overflow: "auto" }}>
      <CustomTable
        columns={tableColumns}
        data={droneMasterList}
        // handleClick={handleRedirect}
        // tableFor=""
      />
    </div>
  );
};

export default DroneTabforDashboard;

const tableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "drone_name",
    label: "Drone",
  },
  {
    id: "drone_category",
    label: "Category",
  },
  {
    id: "is_sale",
    label: "Available for Sale",
  },
  {
    id: "certificate",
    label: "Certificate",
  },
  {
    id: "drone_qty",
    label: "Quantity",
  },
  {
    id: "model_no",
    label: "Model No.",
  },
  {
    id: "drone_price",
    label: "Price",
  },
  {
    id: "drone_status",
    label: "Status",
  },
//   {
//     id: "actions",
//     label: "",
//   },
];
