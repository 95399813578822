import React, { useEffect, useState, useRef, useContext } from "react";
import Container from "../../Component/Container/Container";
import styles from "./Instructor.module.css";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import { Button, useTheme } from "@mui/material";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import InputFields from "../../Component/InputFields/InputFields";
import MyMap from "../../Component/MyMap/MyMap";
import { base_url, token_api } from "../../Utils/network";
import editIcon from "../../Assets/editIcon.png";
import TextEditor from "../../Component/TextEditor/TextEditor";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { Validate } from "./Utility";
import dragDrop from "../../Assets/drag_drop.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import delete_icon from "../../Assets/delete_icon.svg";
import { useNavigate, Link } from "react-router-dom";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

import {
  BannerAndMediaService,
  mediaDataForApi,
} from "../Courses/courseUtility";

const Instructors = () => {
  const { access_token } = useSelector((state) => state.auth);
  const { user_permissions } = useContext(UserCredsContext);
  console.log(user_permissions, "instructorUserPermissions");

  const theme = useTheme();
  let navigate = useNavigate();
  const { palette } = theme;
  const fileInput = useRef(null);
  const bannerInput = useRef(null);
  const [crsMediaPreview, setCrsMediaPreview] = useState([]);
  const [courseMedia, setCourseMedia] = useState([]);
  const [banners, setBanners] = useState("");
  const [bannersPreview, setBannersPreview] = useState("");
  const [filters, setfilters] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("add");
  const [instructorList, setInstructorList] = useState([]);
  const descriptionRef = useRef(null);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    about: "",
    qualification: "",
    experience: "",
    gender: [],
    language: [],
    image: "",
  });

  console.log(formData, "hariFormDataaaaaa");
  const [formErrors, setFormErrors] = useState({});
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [loading, setLoading] = useState(false);
  const [instructorId, setInstructorId] = useState(null);
  const [existedMobile, setExistedMobile] = useState("");
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Instructor</div>,
  ];

  function handleDrop(e, type) {
    e.preventDefault();
    if (e?.target?.files) {
      // simple drop
      // type=c_media or banner
      if (type === "c_media") {
        // mediaService
        const mediaService = new BannerAndMediaService(
          e.target.files,
          ""
        ).addCourseMedia();
        setCrsMediaPreview((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgsPReview);
          return prevCopy;
        });
        setCourseMedia((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgFiles);
          return prevCopy;
        });
      } else {
        // banner
        const imageItem = e?.target?.files[0];
        setBanners(imageItem);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setBannersPreview(imageUrl);
      }
    } else if (e?.dataTransfer?.files) {
      // dragged
      // type=c_media or banner
      if (type === "c_media") {
        const mediaService = new BannerAndMediaService(
          e?.dataTransfer?.files,
          ""
        ).addCourseMedia();
        setCrsMediaPreview((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgsPReview);
          return prevCopy;
        });
        setCourseMedia((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgFiles);
          return prevCopy;
        });
      } else {
        const imageItem = e?.dataTransfer?.files[0];
        setBanners(imageItem);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setBannersPreview(imageUrl);
      }
    }
  }

  console.log(banners, "harBanners");
  const handleClick = (type) => {
    if (type === "c_media") {
      fileInput.current.click();
    } else {
      bannerInput.current.click();
    }
  };
  function handleDragOver(e) {
    e.preventDefault();
  }
  const getInstructorList = async (filter) => {
    try {
      let fetchedInstructorData = await token_api.get(`course/instructor`, {
        params: { ...filter },
      });
      if (fetchedInstructorData?.status !== 200) {
        throw new Error("Error While Fething Instructors Data!");
      }
      setPaginationData(fetchedInstructorData?.data);
      let modifiedArray = fetchedInstructorData?.data?.data.map(
        (item, index) => {
          return {
            instructorId: item?.id,
            name: item?.user?.first_name + " " + item?.user?.last_name,
            gender: item?.gender_str,
            experience: item?.experience,
            qualification: item?.qualification,
            avatar: (
              <div
                className={styles.languageCon}
                style={{ textAlign: "center" }}
              >
                <div>
                  <img
                    src={item?.image ? item?.image : item?.default_avatar}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "100%",
                    }}
                  />
                </div>
              </div>
            ),
            courses: (
              <div>
                {item?.courses?.length > 0 ? (
                  item.courses.map((course, index) => (
                    <React.Fragment key={course.id}>
                      <div
                        style={{
                          marginBottom:
                            index !== item.courses.length - 1 ? "2px" : "0",
                        }}
                      >
                        {course?.course__name}
                        {index !== item.courses.length - 1 && ","}
                      </div>
                    </React.Fragment>
                  ))
                ) : (
                  <div>NA</div>
                )}
              </div>
            ),
            batches: (
              <div>
                {item?.batches?.length > 0 ? (
                  item.batches.map((batchInfo, index) => (
                    <React.Fragment key={batchInfo.id}>
                      <div
                        style={{
                          marginBottom:
                            index !== item.batches.length - 1 ? "2px" : "0",
                        }}
                      >
                        {batchInfo?.name}
                        {index !== item.batches.length - 1 && ","}
                      </div>
                    </React.Fragment>
                  ))
                ) : (
                  <div>NA</div>
                )}
              </div>
            ),

            action: (
              <div className={styles.languageCon}>
                {user_permissions?.update && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleEditDrawer(e, item)}
                  >
                    <img src={editIcon} />
                  </div>
                )}

                {user_permissions?.delete && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDeleteorg(item?.id)}
                  >
                    <img src={delete_icon} />
                  </div>
                )}
              </div>
            ),
          };
        }
      );
      setInstructorList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      let filterObject = {};
      if (pageState) {
        filterObject.page = pageState;
      }
      if (filters.gender) {
        filterObject.gender = filters?.gender[0]?.id;
      }
      if (filters?.q) {
        filterObject.q = filters?.q;
      }

      if (filters?.experience) {
        filterObject.experience = filters?.experience[0]?.id;
      }

      getInstructorList(filterObject);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pageState, filters?.gender, filters?.q, filters?.experience]);

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setFormData({
        ...formData,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setFormData({
        ...formData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      if (name === "experience" && isNaN(Number(value))) {
        return;
      }
      if (name === "experience" && isNaN(Number(value))) {
        return;
      }

      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  console.log(formData, "hariGadiForm");
  const handleDeleteChipforDrawer = (id, selectName) => {
    setFormData({
      ...formData,
      [selectName]: formData?.[selectName]?.filter((chip) => chip?.id !== id),
    });
    let namefiled = formData[selectName];
  };

  const handleAddDrawer = () => {
    const newAddInstructor = {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      about: "",
      qualification: "",
      experience: "",
      gender: [],
      language: [],
    };
    setBannersPreview("");

    setFormErrors({});
    setFormData(newAddInstructor);
    setOpenDrawer(true);
    setDrawerState("add");
    setInstructorId(null);
  };

  const handleEditDrawer = (e, info) => {
    const {
      id,
      user,
      gender,
      image,
      language,
      experience,
      qualification,
      about,
    } = info;
    let genderdata = genderList?.filter((info) => info?.id == gender);
    let languageData = languageList?.filter((info) => info?.id == language);

    setFormData((prev) => ({
      ...prev,
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      mobile: user?.mobile,
      gender: genderdata,
      language: languageData,
      experience,
      qualification,
      about,
    }));
    setBannersPreview(image);
    setFormErrors({});
    setExistedMobile(user?.mobile);
    setOpenDrawer(true);
    setDrawerState("edit");
    setInstructorId(id);
  };

  const handleAddInstructor = async () => {
    let isDescriptionsEmpty;

    if (descriptionRef.current) {
      const content = descriptionRef.current.getContent();
      formData.about = content;

      // Set a boolean value based on whether the content is empty or not
      isDescriptionsEmpty = !content.trim();
    }

    const data = {
      user_details: {
        first_name: formData?.first_name,
        last_name: formData?.last_name,
        email: formData?.email,
        mobile: formData?.mobile,
        // ...(existedMobile !== formData?.mobile && { mobile: formData?.mobile }),
      },

      name: formData?.first_name + " " + formData?.last_name,
      about: formData?.about,
      qualification: formData?.qualification,
      experience: formData?.experience,
      gender: formData?.gender[0]?.id,
      language: formData?.language[0]?.id,
      // image : banners
    };

    try {
      Validate(formData)
        .then(async (response) => {
          // if (response === "success") {
          if (instructorId) {
            const response = await token_api.patch(
              `course/instructor/${instructorId}/`,
              data
            );

            if (response?.status === 400) {
              alert(response?.data?.error?.fields[0]?.message?.mobile[0]);
              setFormErrors({});
              setOpenDrawer(false);
              getInstructorList();
              setBanners("");
            }
            if (response?.status !== 200) {
              // console.log("Hari Gadi")
              // alert("Instructor not created");
              throw new Error("Instructor details not updated!");
            }
            if (banners) {
              let instructorMedia = new FormData();
              instructorMedia.append("image", banners);

              await axios({
                method: "patch",
                url: `${base_url}course/instructor/${response?.data?.data?.id}/`,
                data: instructorMedia,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            alert("Instructor Details Updated Successfully!");

            setFormErrors({});
            setOpenDrawer(false);
            getInstructorList();
            setBanners("");
          } else {
            const newInstructorResponse = await token_api.post(
              `course/instructor/`,
              data
            );
            if (newInstructorResponse?.status === 400) {
              alert(
                newInstructorResponse?.data?.error?.fields[0]?.message
                  ?.mobile[0]
              );
              setFormErrors({});
              setOpenDrawer(false);
              getInstructorList();
              setBanners("");
            }
            if (newInstructorResponse?.status !== 201) {
              // console.log("Hari Gadi")
              // alert("Instructor not created");
              throw new Error("Instructor not created");
            }

            if (banners) {
              let instructorMedia = new FormData();
              instructorMedia.append("image", banners);

              await axios({
                method: "patch",
                url: `${base_url}course/instructor/${newInstructorResponse?.data?.data?.id}/`,
                data: instructorMedia,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            alert("New Instructor Created Successfully!");
            setFormErrors({});
            setOpenDrawer(false);
            getInstructorList();
            setBanners("");
          }
        })
        .catch((error) => {
          setFormErrors(error);
        });
    } catch (error) {
      console.log(error, "divyaError");
    }
  };
  const handleDeleteorg = (deleteid) => {
    token_api
      .delete(`/course/instructor/${deleteid}/`)
      .then((response) => {
        if (response?.status == 204 || response?.status == 201) {
          getInstructorList();
        }
      })
      .catch((error) => {});
  };

  const handleFilter = (e, type, selectName, multiple) => {
    console.log("HANDLERSS", e, type);
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  return (
    <div>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>
        {user_permissions?.write && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button variant="contained" onClick={handleAddDrawer}>
              {"Add Instructor"}
            </Button>
          </div>
        )}
        <FilterContainer
          className={styles.filterContainer}
          // className={`${styles.marginTopBox}`}
        >
          <CustomSearchFilter
            placeholder="Search by instructor name..."
            onChange={(e) => {
              setPageState(1);
              setfilters({ ...filters, q: e });
            }}
          />

          <CustomSelectChip
            multiple={false}
            label="Filter By Gender"
            name="gender"
            onChange={handleFilter}
            onDelete={handleDeleteChip}
            value={filters?.gender}
            listArray={genderLisiting}
          />

          <CustomSelectChip
            multiple={false}
            label="Filter By Experience"
            name="experience"
            onChange={handleFilter}
            onDelete={handleDeleteChip}
            value={filters?.experience}
            listArray={experienceLevels}
          />

          {/* <CustomSelect
            label={"Filter by Location"}
            listArray={[]}
            minWidth={"175px"}
          /> */}

          {/* <CustomButton
            text={"ADVANCE FILTERS"}
            btnIcon={<img src={filterIcon} alt="adv-filter" />}
            // maxWidth="170px"
          /> */}
          {/* <CustomButton
            text={"Export"}
            maxWidth="110px"
            onClick={() => setOpenDrawer(true)}
          /> */}
        </FilterContainer>
        <div className={styles.tableContainer}>
          <CustomTable
            columns={tableColumns}
            data={instructorList}
            // handleClick={handleRedirect}
            tableFor="locationList"
          />
        </div>
        <div className={styles.paginationContainer}>
          <CustomPagination max_pages={max_pages} setPageState={setPageState} />
        </div>
      </Container>
      <DrawerComp
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
          setOpenDrawer("");
        }}
        anchor="right"
      >
        <DrawerContainer>
          <DrawerTitle
            text={drawerState === "add" ? "Add Instructor" : "Edit Instructor"}
            handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <div>
              <InputFields
                label="First Name"
                name="first_name"
                value={formData?.first_name}
                onChange={handleChange}
                error={formErrors?.first_name}
                helperText={formErrors?.first_name}
              />
            </div>
            <div>
              <InputFields
                label="Last Name"
                name="last_name"
                value={formData?.last_name}
                onChange={handleChange}
                error={formErrors?.last_name}
                helperText={formErrors?.last_name}
              />
            </div>{" "}
            <div>
              <InputFields
                label="Email"
                name="email"
                value={formData?.email}
                onChange={handleChange}
                error={formErrors?.email}
                helperText={formErrors?.email}
              />
            </div>{" "}
            <div>
              <InputFields
                label="Mobile Number"
                name="mobile"
                type="number"
                value={formData?.mobile}
                onChange={handleChange}
                maxLength={10}
                error={formErrors?.mobile}
                helperText={formErrors?.mobile}
              />
            </div>
            <div className={`${styles.formContainer} mt-20`}>
              <p>About Instructor</p>
              <TextEditor
                editorRef={descriptionRef}
                initialValue={formData?.about}
              />
              <small style={{ marginTop: "5px", color: "red" }}>
                {formErrors?.about && "Please enter about the instrutor"}
              </small>
            </div>
            <div>
              <InputFields
                label="Qualification"
                name="qualification"
                style={{ width: "100%" }}
                value={formData?.qualification}
                onChange={handleChange}
                error={formErrors?.qualification}
                helperText={formErrors?.qualification}
              />
            </div>
            <div>
              <InputFields
                label="Experience"
                name="experience"
                style={{ width: "100%" }}
                value={formData?.experience}
                onChange={handleChange}
                error={formErrors?.experience}
                helperText={formErrors?.experience}
              />
            </div>
            <div>
              <CustomSelectChip
                multiple={false}
                label="Gender"
                name="gender"
                onChange={handleChange}
                onDelete={handleDeleteChipforDrawer}
                value={formData?.gender}
                listArray={genderList}
                error={formErrors?.gender}
                helperText={formErrors?.gender}
              />
            </div>
            <div>
              <CustomSelectChip
                multiple={false}
                label="Language"
                name="language"
                onChange={handleChange}
                onDelete={handleDeleteChipforDrawer}
                value={formData?.language}
                listArray={languageList}
                error={formErrors?.language}
                helperText={formErrors?.language}
              />
            </div>
            <div className={`${styles.formContainer} mt-20`}>
              <div
                style={{ color: palette?.primary[500] }}
                className={`${styles.titleStyles}`}
              >
                Profile Image
              </div>

              <div style={{ marginTop: "20px" }}>
                <div
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "banner")}
                  className={styles.dropBoxStyles}
                >
                  <div
                    onClick={() => handleClick("banner")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p>
                      <img src={dragDrop} alt="dradanddrop" />
                    </p>
                    <p>Drag & Drop the File or click here</p>
                    <input
                      ref={bannerInput}
                      type="file"
                      // id="myfile"
                      // name="myfile"
                      // multiple
                      onChange={(e) => handleDrop(e, "banner")}
                      style={{ display: "none" }}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            {bannersPreview && (
              <div className={`${styles.formContainer} mt-20`}>
                <div className={styles.commonFieldsBox}>
                  <div>
                    <p
                      style={{ color: palette?.primary[500] }}
                      className={`${styles.titleStyles}`}
                    >
                      Profile Preview
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div
                        className={`${styles.flexBetween} ${styles.imgCardBox} `}
                      >
                        {" "}
                        <img
                          src={bannersPreview}
                          alt="banner"
                          width={200}
                          height={140}
                        />
                        <div onClick={() => handleClick("banner")}>
                          <Button
                            variant="contained"
                            // onClick={() => {
                            //   handleMediaDelete("image");
                            // }}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div>
              <Button variant="contained" onClick={handleAddInstructor}>
                {drawerState === "add" ? "ADD" : "UPDATE"}{" "}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>
    </div>
  );
};

export default Instructors;

const tableColumns = [
  {
    id: "instructorId",
    label: "Instructor ID",
  },

  {
    id: "name",
    label: "Instructor Name",
  },
  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "experience",
    label: "Experience",
  },
  // {
  //   id: "language",
  //   label: "Language",
  // },
  {
    id: "qualification",
    label: "Qualification",
  },

  {
    id: "avatar",
    label: "Profile",
  },
  { id: "courses", label: "Courses assigned" },
  { id: "batches", label: "Batches assigned" },

  {
    id: "action",
    label: "Action",
  },
];

const genderList = [
  {
    id: 1,
    title: "Male",
  },
  {
    id: 2,
    title: "Female",
  },
];

const languageList = [
  {
    id: 1,
    title: "English",
  },
  {
    id: 2,
    title: "Hindi",
  },
];

const genderLisiting = [
  {
    id: "1",
    title: "Male",
  },
  {
    id: "2",
    title: "Female",
  },
];

const experienceLevels = [
  { id: 1, title: "1 year and > 1" },
  { id: 2, title: "2 and >  than 2" },
  { id: 3, title: "3 and  > than 3" },
  { id: 4, title: "4 and  > than 4" },
  { id: 5, title: "5 and  > than 5" },
  { id: 10, title: "10 and  > than 10" },
  { id: 15, title: "15 and  > than 15" },
  { id: 20, title: "20 and  > than 20" },
];
