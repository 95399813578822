import React, { useState } from "react";
import styles from "./index.module.css";
import { Button, IconButton, InputAdornment } from "@mui/material";
import InputFields from "../Component/InputFields/InputFields";
import { open_api } from "../Utils/network";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../state/actions/authAction";
import { saveToLocal } from "../Utils/helper";
const EmailLogin = ({ isDisabled, loginStates, setLoginStates }) => {
  const loginStatesRedux = useSelector((state) => state.auth);
  const { isLoading } = loginStatesRedux;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { username, password } = loginStates;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginStates({
      ...loginStates,
      [name]: value,
    });
  };

  const handleEmailLogin = async () => {
    let validateResults = validateInputs(loginStates);
    setFormErrors({ ...validateResults });
    if (validateResults?.error) return;
    setLoginStates({
      ...loginStates,
      isLoading: true,
    });
    try {
      await dispatch(login({ username, password }));
      saveToLocal("activeTab", "services");
      navigate("/bookings/course-bookings");
    } catch (error) {
      setFormErrors({
        ...formErrors,
        password: error?.message,
      });
    }

    // open_api
    //   .post(`auth/login/`, { username, password })
    //   .then((response) => {
    //     // if api success navigate to admin panel route
    //     navigate("/services");
    //   })
    //   .catch((err) => {
    //     setLoginStates({
    //       ...loginStates,
    //       isLoading: false,
    //     });
    //     alert("Error");
    //     console.log(err);
    //   });
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <div
          style={{ color: " rgba(241, 97, 54, 1)" }}
          className={`${styles.enterLine}`}
        >
          Enter your email id
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "15px",
          display: "flex",
          flexDirection: "column",
          rowGap: "15px",
        }}
      >
        <InputFields
          name="username"
          value={username}
          onChange={handleChange}
          type="text"
          error={formErrors?.username}
          helperText={formErrors?.username}
          label="Email Id"
        />
        <InputFields
          name="password"
          value={password}
          onChange={handleChange}
          type={showPassword ? "text" : "password"}
          error={formErrors?.password}
          helperText={formErrors?.password}
          label="Password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword((prev) => !prev)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      <div style={{ width: "100%", textAlign: "center", marginTop: "5px" }}>
        {/* <CustomButton width="92%" text={"Login"} /> */}
        <Button
          disabled={isLoading || !username || !password}
          sx={{ width: "95%", color: isDisabled ? "#ffff" : "" }}
          variant="contained"
          onClick={handleEmailLogin}
        >
          Login
        </Button>
      </div>
      {/* <div>
        <small>
          <p>
            New to Drone Destination?{" "}
            <span style={{ color: "#F16136", cursor: "pointer" }}>
              Create account
            </span>{" "}
          </p>
        </small>
      </div> */}
    </>
  );
};

export default EmailLogin;

const validateInputs = (value) => {
  let errors = {
    error: false,
  };

  if (!value?.username) {
    errors.username = "Please enter user name.";
    errors.error = true;
  }

  if (!value?.password) {
    errors.password = "Please enter password.";
    errors.error = true;
  }

  return errors;
};
