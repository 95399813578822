import styled from "@emotion/styled";
import { Box } from "@mui/material";

const Container = styled(Box)({
  padding: "12px",
  // height: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: "5px",
});

export default Container;
