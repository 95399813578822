export const Validate = (value) => {
  return new Promise((resolve, reject) => {
    const {
      organizationName,
      pincode,
      // line_1,
      // line_2,
      // area,
      tehsil,
      district,
      state,
      city,
      country,
      since,
      boarding,
      // about,
    } = value;

    const errors = { error: false };
    // if (!accForm?.location) {
    //   errors.location = "Please select location for this organization";
    //   errors.location = true;
    // }
    if (!organizationName) {
      errors.organizationName = "Please enter organization name";
      errors.error = true;
    }

    if (!since) {
      errors.since = "Please enter since";
      errors.error = true;
    }
    if (!boarding) {
      errors.boarding = "Please enter boarding";
      errors.error = true;
    }
    // if (!about) {
    //   errors.about = "Please enter about";
    //   errors.error = true;
    // }
    // if (!line_1) {
    //   errors.line_1 = "Please enter line_1";
    //   errors.error = true;
    // }
    // if (!line_2) {
    //   errors.line_2 = "Please enter line_2";
    //   errors.error = true;
    // }

    // if (!area) {
    //   errors.area = "Please enter area";
    //   errors.error = true;
    // }
    if (!city) {
      errors.city = "Please enter city";
      errors.error = true;
    }
    if (!pincode) {
      errors.pincode = "Please enter pincode";
      errors.error = true;
    }

    if (!state) {
      errors.state = "Please enter state";
      errors.error = true;
    }
    if (!tehsil) {
      errors.tehsil = "Please enter tehsil";
      errors.error = true;
    }
    if (!district) {
      errors.district = "Please enter district";
      errors.error = true;
    }
    if (!country) {
      errors.country = "Please enter country";
      errors.error = true;
    }
    // if (pilotSelected.length === 0) {
    //   errors.pilotSelected = "Please select at least one location";
    //   errors.error = true;
    // }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
