import React, { useEffect, useRef, useState } from "react";
import styles from "./PilotProfile.module.css";
import dragDrop from "../../Assets/drag_drop.svg";
import cancellogo from "../../Assets/cancel.svg";
import { Button } from "@mui/material";
import { validateUser } from "./PilotProfileValidation";
import axios from "axios";
import { base_url } from "../../Utils/network";
import { useSelector } from "react-redux";

const EditUserpicModel = ({ user, userFetchDatanew, handleCloseModal }) => {
  const profileInput = useRef(null);
  const [profilePic, setProfilePic] = useState({
    avatar: "",
  });
  const [selectedFile, setSelectedFile] = useState({
    avatar: "",
  });
  const { access_token } = useSelector((state) => state.auth);
  const [formErrors, setFormErrors] = useState({});
  console.log(user, "userModelopne");
  const handleFileChange = (e, type) => {
    const file = e.target.files[0];

    // console.log(e.target.files[0], type, "chossejdfi767");
    if (file) {
      setProfilePic((prevState) => ({
        ...prevState,
        [type]: URL.createObjectURL(file),
      }));
      setSelectedFile((prevState) => ({
        ...prevState,
        [type]: file,
      }));
    }
  };
  const deleteFile = (type) => {
    // setSelectedFile(null)
    setProfilePic((prevState) => ({
      ...prevState,
      [type]: null,
    }));
  };

  function handleDrop(e, type) {
    e.preventDefault();
    // console.log("FGF", e, type);
    const file = e.dataTransfer.files[0];
    if (e?.target?.file) {
      // simple drop

      if (file) {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setProfilePic((prevState) => ({
          ...prevState,
          [type]: imageUrl,
        }));
        setSelectedFile((prevState) => ({
          ...prevState,
          [type]: file,
        }));
      } else {
        // setSelectedFile(e?.target?.file[0])
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setProfilePic((prevState) => ({
          ...prevState,
          [type]: imageUrl,
        }));
        setSelectedFile((prevState) => ({
          ...prevState,
          [type]: file,
        }));
      }
    } else if (e?.dataTransfer?.file) {
      // drag
    }

    // if (file) {
    //     setSelectedFile(prevState => ({
    //         ...prevState,
    //         [type]: file
    //     }));
    // }
  }

  const handleClick = (type) => {
    // console.log("refd", type);
    if (type === "avatar") {
      // fileInput.current.click();
      profileInput.current.click();
    }
  };
  function handleDragOver(e) {
    e.preventDefault();
  }

  useEffect(() => {
    // debugger;
    if (user?.id) {
      const { avatar } = user;

      setProfilePic({
        ...profilePic,
        avatar: user?.avatar,
      });
    }
  }, [user?.id]);

  const saveProfile = () => {
    // console.log(bDetails, "handleAddBanner");
    validateUser(profilePic)
      .then((res) => {
        if (res === "success") {
          confirmValidate();
        }
      })
      .catch((err) => {
        console.log(err, "iamurerror");
        setFormErrors(err);
      });
  };

  const confirmValidate = async () => {
    const editAvatar = {
      avatar: selectedFile?.avatar,
    };

    let uplodedata = new FormData();

    if (editAvatar?.avatar) {
      uplodedata.append("avatar", editAvatar?.avatar);
    }

    try {
      const postbanners = await axios({
        method: "patch",
        url: `${base_url}users/profile/pilot/${user?.id}/`,
        data: uplodedata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (
        postbanners?.status === 200 ||
        postbanners?.status === 201 ||
        postbanners?.status === 204
      ) {
        userFetchDatanew();
        handleCloseModal();
        // handleCancelDrw();
        // getBannerData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className={styles.modalContainer}>
        <div
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, "avatar")}
          className={styles.dropBoxStyles}
        >
          <div
            onClick={() => handleClick("avatar")}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p> Drop an Image File here or click </p>
            <p>
              <img src={dragDrop} alt="dradanddrop" />
            </p>

            <input
              ref={profileInput}
              type="file"
              // id="myfile"
              // name="myfile"
              // multiple
              // onChange={(e) => handleDrop(e, "banner")}
              onChange={(e) => handleFileChange(e, "avatar")}
              style={{ display: "none" }}
            ></input>
          </div>
        </div>
        {profilePic?.avatar ? (
          <div className={styles.chooseimgConatiner}>
            <img
              onClick={() => deleteFile("avatar")}
              className={styles.cancelbtn}
              src={cancellogo}
            />
            <img
              width={200}
              height={100}
              className={styles.displayimg}
              src={profilePic?.avatar}
              alt="selectedFile"
            />
            {/* <p>{bDetails?.name}</p> */}
          </div>
        ) : (
          <>
            <p className={styles.errorfieldtext}>{formErrors?.avatar}</p>
          </>
        )}
      </div>

      <Button variant="contained" onClick={saveProfile}>
        Save
      </Button>
    </>
  );
};

export default EditUserpicModel;
