export const validateUser = (values) => {
    return new Promise((resolve, reject) => {
        console.log(values, "validateUser");

        const {
            avatar
        } = values

        const errors = { error: false };
        
        if (!avatar) {
            errors.avatar = "Select Your Profile Picture to display";
            errors.error = true;
        }
       
        if (errors.error) {
            reject(errors);
        } else {
            resolve("success");
        }
    })
}