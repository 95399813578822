import React, { useState, useEffect } from "react";
import InputFields from "../InputFields/InputFields";
import styles from "../../Pages/Quotation/quotation.module.css";
import { Button } from "@mui/material";
import { handleKeyPress } from "../../Utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";
import { token_api } from "../../Utils/network";
import AddressBlock from "../../Component/AddressBlock/AddressBlock";

const AddAddress = ({
  handleDrawerClose,
  userListGet,
  selectedUser,
  handleUserSet,
  activeAddress = null,
  activeaddressid,
}) => {
  const { user, access_token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [addressObj, setAddressObj] = useState({
    line_1: "",
    line_2: "",
    area_in_acres: "",
    pincode: "",
    area: "",
    streetName: "",
    tehsil: "",
    district: "",
    city: "",
    state: "",
    country: "",
  });
  const [selectedBlock, setSelectedBlock] = useState([]);

  useEffect(() => {
    // Set addressObj based on activeAddress when it changes
    if (activeAddress?.id) {
      setAddressObj({
        line_1: activeAddress.line_1 || "",
        line_2: activeAddress.line_2 || "",
        pincode: activeAddress.pincode || "",
        area: activeAddress.area || "",
        streetName: activeAddress.streetName || "",
        tehsil: activeAddress.tehsil || "",
        district: activeAddress.district || "",
        city: activeAddress.city || "",
        state: activeAddress.state || "",
        country: activeAddress.country || "",
        area_in_acres: activeAddress?.area_in_acres || "",
      });
      setSelectedBlock([activeAddress?.block]);
    }
  }, [activeAddress?.id]);

  useEffect(() => {
    const fetchData = async () => {
      if (addressObj.pincode.length === 6) {
        try {
          setLoading(true);

          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${addressObj?.pincode}`
          );

          if (
            response.data.length !== 0 &&
            response.data[0].PostOffice.length !== 0
          ) {
            const pinData = response.data[0].PostOffice[0];
            const { District, Division, State, Name, Region, Country } =
              pinData;

            setAddressObj({
              ...addressObj,
              tehsil: Division,
              state: State,
              district: District,
              city: District,
              country: Country,
            });

            // setFormData({
            //   ...formData,
            //   district: Name,
            //   city: Region,
            //   state: State,
            //   country: Country,
            //   pincode: pin,
            // });
            // setShowOther(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [addressObj.pincode]);

  const [formErrors, setFormErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddressObj((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const loctnFieldDisable = true;

  const handleAutoCompleteChangeForBlock = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Block already included");
    } else {
      setSelectedBlock(selectedValues);
    }
  };

  const handleaddsubmit = () => {
    const addressdata = {
      user_id: selectedUser?.creator?.id,
      line_1: addressObj?.line_1,
      area_in_acres: addressObj?.area_in_acres,
      line_2: addressObj?.line_2,
      landmark: addressObj?.streetName,
      area: addressObj?.area,
      city: addressObj?.city,
      state: addressObj?.state,
      pincode: addressObj?.pincode,
      country: addressObj?.country,
      district: addressObj?.district,
      tehsil: addressObj?.tehsil,
      ...(selectedBlock.length > 0 && {
        block_id: selectedBlock[0]?.id,
      }),
    };

    // Remove fields with empty strings
    const filteredAddressData = {};
    for (const [key, value] of Object.entries(addressdata)) {
      if (value !== "") {
        filteredAddressData[key] = value;
      }
    }
    if (activeAddress?.id) {
      token_api
        .patch(`/users/address/${activeAddress?.id}/`, filteredAddressData)
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            handleDrawerClose();
            userListGet();
            setSelectedBlock([]);

            handleUserSet(response?.data?.data);
          } else {
            throw new Error("error in fields");
          }
          // Handle response
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      token_api
        .post(`/users/admin/address/`, filteredAddressData)
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            handleDrawerClose();
            userListGet();
            setSelectedBlock([]);

            handleUserSet(response?.data?.data);
          } else {
            throw new Error("error in fields");
          }
          // Handle response
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleClose = () => {
    handleDrawerClose();
  };

  return (
    <div>
      <div className={`${styles.flexStContainer}`}>
        <div>
          <InputFields
            label="Pincode"
            name="pincode"
            style={{ width: "100%" }}
            value={addressObj?.pincode}
            onChange={handleChange}
            error={formErrors?.pincode}
            helperText={formErrors?.pincode}
          />
        </div>

        <div style={{ marginTop: "10px" }}>
          <AddressBlock
            value={selectedBlock}
            onChange={handleAutoCompleteChangeForBlock}
          />
          {formErrors?.selectedBlock && (
            <p style={{ color: "red" }}>{formErrors?.selectedBlock}</p>
          )}
        </div>

        <div>
          <InputFields
            label="Address Line 1"
            name="line_1"
            style={{ width: "100%" }}
            value={addressObj?.line_1}
            onChange={handleChange}
            error={formErrors?.line_1}
            helperText={formErrors?.line_1}
          />
        </div>
        <div>
          <InputFields
            label="Address Line 2"
            name="line_2"
            style={{ width: "100%" }}
            value={addressObj?.line_2}
            onChange={handleChange}
            error={formErrors?.line_2}
            helperText={formErrors?.line_2}
          />
        </div>

        <div>
          <InputFields
            label="Area"
            name="area"
            style={{ width: "100%" }}
            value={addressObj?.area}
            onChange={handleChange}
            error={formErrors?.area}
            helperText={formErrors?.area}
          />
        </div>
        <div>
          <InputFields
            label="Area In Acres"
            type="number"
            name="area_in_acres"
            style={{ width: "100%" }}
            value={addressObj?.area_in_acres}
            onChange={handleChange}
            error={formErrors?.area_in_acres}
            helperText={formErrors?.area_in_acres}
          />
        </div>

        <div>
          <InputFields
            label="City"
            name="city"
            style={{ width: "100%" }}
            value={addressObj?.city}
            onChange={handleChange}
            error={formErrors?.city}
            helperText={formErrors?.city}
          />
        </div>
        <div>
          <InputFields
            // disabled={loctnFieldDisable}
            label="State"
            name="state"
            style={{ width: "100%" }}
            value={addressObj?.state}
            onChange={handleChange}
            error={formErrors?.state}
            helperText={formErrors?.state}
          />
        </div>

        <div>
          <InputFields
            // disabled={loctnFieldDisable}
            label="Tehsil"
            name="tehsil"
            style={{ width: "100%" }}
            value={addressObj?.tehsil}
            onChange={handleChange}
            error={formErrors?.tehsil}
            helperText={formErrors?.tehsil}
          />
        </div>

        <div>
          <InputFields
            // disabled={loctnFieldDisable}
            label="District"
            name="district"
            style={{ width: "100%" }}
            value={addressObj?.district}
            onChange={handleChange}
            error={formErrors?.district}
            helperText={formErrors?.district}
          />
        </div>

        <div>
          <InputFields
            // disabled={loctnFieldDisable}
            label="Country"
            name="country"
            style={{ width: "100%" }}
            value={addressObj?.country}
            onChange={handleChange}
            error={formErrors?.country}
            helperText={formErrors?.country}
          />
        </div>

        <div className={styles.stDrwBtnFlextwo}>
          {loading ? (
            <div>
              <Button variant="contained">Loading....</Button>
            </div>
          ) : (
            <div>
              <Button variant="contained" onClick={handleaddsubmit}>
                {activeaddressid ? "Update Address" : "Add Address"}
              </Button>
            </div>
          )}
          <div>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAddress;
