import React, { useEffect, useState } from "react";
import styles from "./ChargerInventory.module.css";
import FilterContainer from "../FitlerContainer/FilterContainer";
import CustomTable from "../CustomTable/CustomTable";
import CustomPagination from "../CustomPagination/CustomPagination";
import CustomSearchFilter from "../CustomSearch/CustomSearchFilter";
import { token_api, base_url } from "../../Utils/network";
import dayjs from "dayjs";

import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import { Validate } from "./Utility";
import DrawerComp from "../DrawerComp/DrawerComp";
import DrawerContainer from "../DrawerContainer/DrawerContainer";
import DrawerTitle from "../DrawerTitle/DrawerTitle";
import { Button } from "@mui/material";
import CustomSelectChip from "../CustomSelectChip/CustomSelectChip";
import InputFields from "../InputFields/InputFields";
import DronePilot from "../../Component/DronePilot/DronePilot";
import CustomCard from "../../Component/CustomCard/CustomCard";
import pilotUser from "../../Assets/pilotUser.svg";
import dronePilot from "../../Assets/dronePilot.svg";
import chargerInv from "../../Assets/chargerInv.svg";
import droneInventoryBook from "../../Assets/droneInventoryBook.svg";
import CustomModal from "../CustomModal/CustomModal";
import { formatDate } from "../../Utils/helper";
import CustomDatePicker from "../MaterialDate/MaterialDate";
import { getStatusStyles } from "../../Utils/Utils";
import blackArrow from "../../Assets/breadCrumbBack.svg";
import DroneInventoryComp from "../DroneInventoryComp/DroneInventoryComp";
import CustomButton from "../CustomButton/CustomButton";
import axios from "axios";

const ChargerInventory = () => {
  const [chargerInventoryList, setChargerInventoryList] = useState([]);
  const [filters, setfilters] = useState({});
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [chargerForm, setChargerForm] = useState({
    id: null,
    drone_inventory_id: [],
    charger_id: [],
    status: [],
    serial_no: "",
  });
  const [pilotSelected, setPilotSelected] = useState([]);

  const [assignPilot, setAssignPilot] = useState({
    charger_inventory_id: null,
    pilot_id: null,
    assigned_from: null,
    assigned_to: null,
  });
  const [openDailog, setOpenDailog] = useState(false);
  const [open, setOpen] = useState(false);
  const [formErrors, setformErrors] = useState({});
  const [droneList, setDroneList] = useState([]);
  const [chargerList, setChargerList] = useState([]);

  const [cardData, setCardData] = useState({
    assign: { name: "Assigned", value: null },
    not_assign: { name: "Not Assigned", value: null },
    working: { name: "Working", value: null },
    damaged: { name: "Damaged", value: null },
    sold: { name: "Sold", value: null },
    repair: { name: "Under Repair", value: null },
    at_base: { name: "At Base", value: null },
    on_field: { name: "On Field", value: null },
    training: { name: "Training", value: null },
    survey_service: { name: "Survey Service", value: null },
    agri_service: { name: "Agri Service", value: null },
    no_category_assign: { name: "No Application Assigned", value: null },
  });

  const [assignInventoryObj, setAssignInventoryObj] = useState({});
  const [chargerAssignPilotHistory, setChargerAssignPilotHistory] = useState(
    []
  );

  const [assetAssignOpen, setAssetAssignOpen] = useState(false);
  const [chargerAssetAssign, setChargerAssetAssign] = useState({
    id: null,
    assigned_from: null,
    assigned_to: null,
    pilot: [],
  });
  const handleAdd = () => {
    setOpen(true);
    setChargerForm({
      ...chargerForm,
      id: null,
      drone_inventory_id: [],
      charger_id: [],
      serial_no: "",
      status: [],
    });
    setformErrors({});
    setPilotSelected([]);
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Working";
      case 2:
        return "Sold";
      case 3:
        return "Damaged";
      case 4:
        return "Under Repair";
      default:
        return "Unknown";
    }
  };
  const handleOpenAssignPilot = (inventoryId) => {
    setOpenDailog(true);
    setAssignPilot({
      ...assignPilot,
      charger_inventory_id: inventoryId,
      assigned_from: null,
      assigned_to: null,
    });
    setPilotSelected([]);
  };
  const handleEditCharger = (item) => {
    const { id, drone_inventory, charger, inv_status, serial_no } = item;

    setChargerForm({
      ...chargerForm,
      id: id,

      drone_inventory_id: [drone_inventory]?.map((info) => ({
        title: info?.drone?.title,
        id: info?.id,
        drone_name: info?.drone_name,
      })),
      charger_id: [charger]?.map((info) => ({
        title: info?.name,
        id: info?.id,
      })),
      status: statusOptions?.filter((info) => info?.id === inv_status),
      serial_no: serial_no,
    });

    setOpen(true);
    setformErrors({});
  };

  const handleDeleteCharger = (chargerId) => {
    token_api
      .delete(`drone/charger/inventory/${chargerId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Charger Inventory Deleted Successfully!");
          getChargerInventoryList();
          setPilotSelected([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getChargerInventoryList = async (filterobject) => {
    try {
      const fetchChargerInventoryList = await token_api.get(
        `drone/charger/inventory`,
        {
          params: { ...filterobject },
        }
      );

      if (fetchChargerInventoryList.status !== 200) {
        throw new Error("Error in fetching");
      }
      setPaginationData(fetchChargerInventoryList?.data);
      let modifiedArray = fetchChargerInventoryList?.data?.data?.map((item) => {
        const droneStatusStyles = getStatusStyles(item?.inv_status);

        return {
          id: (
            <p
              onClick={() => {
                handleToShowAssignList(item);
              }}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              {item?.id}
            </p>
          ),
          serial_no: item?.serial_no,

          drone_inventory: <p>{item?.drone_inventory?.drone_name || "N.A"}</p>,
          charger_master: <p>{item?.charger?.name || "N.A"}</p>,
          // pilot_id: <p>{item?.created_by}</p>,

          assign: (
            <Button
              variant="contained"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleOpenAssignPilot(item?.id);
              }}
            >
              Assign
            </Button>
          ),
          inv_status: (
            <span
              style={{
                padding: "5px",
                borderRadius: "50px",
                ...droneStatusStyles,
                minWidth: "60%",
                font: "bold",
                display: "inline-block",
                width: "100px",
              }}
            >
              {getStatusText(item?.inv_status)}
            </span>
          ),
          action: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => handleEditCharger(item)}
              >
                <img src={editIcon} />
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteCharger(item?.id)}
              >
                <img src={deleteIcon} />
              </div>
            </div>
          ),
        };
      });
      setChargerInventoryList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      let filtersObject = {};
      if (pageState) {
        filtersObject.page = pageState;
      }
      if (filters?.q) {
        filtersObject.q = filters?.q;
      }
      if (filters?.serial_no) {
        filtersObject.q = filters?.serial_no;
      }
      if (filters?.drone_inventory) {
        const ids = filters?.drone_inventory?.map((info) => info?.id);
        filtersObject.drone_inventory = ids[0];
      }
      if (filters?.charger) {
        const ids = filters?.charger?.map((info) => info?.id);
        filtersObject.charger = ids[0];
      }
      if (filters?.status) {
        const ids = filters?.status?.map((info) => info?.id);
        filtersObject.inv_status = ids[0];
      }

      getChargerInventoryList(filtersObject);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [
    pageState,
    filters?.q,
    filters?.drone_inventory,
    filters?.charger,
    filters?.status,
    filters?.serial_no,
  ]);

  const handleText = (e) => {
    const { name, value } = e.target;
    setChargerForm({
      ...chargerForm,
      [name]: value,
    });
  };

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setChargerForm({
        ...chargerForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setChargerForm({
        ...chargerForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setChargerForm({
        ...chargerForm,
        [name]: value,
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setChargerForm({
      ...chargerForm,
      [selectName]: chargerForm?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
  };

  const handleAutocompleteChangeforPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setPilotSelected(selectedValues);
    }
  };

  const handleAutocompleteChangefordroneInventory = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Drone inventory already included");
    } else {
      setChargerForm({
        ...chargerForm,
        ["drone_inventory_id"]: selectedValues,
      });
    }
  };

  const handleAddChargerInventory = () => {
    let data = {
      drone_inventory_id: chargerForm?.drone_inventory_id[0]?.id,
      inv_status: chargerForm?.status[0]?.id,
      charger_id: chargerForm?.charger_id[0]?.id,
      serial_no: chargerForm?.serial_no,
    };

    Validate(chargerForm)
      .then((response) => {
        if (response == "success") {
          if (chargerForm?.id) {
            token_api
              .patch(`/drone/charger/inventory/${chargerForm?.id}/`, data)
              .then((res) => {
                if (
                  res.status == 200 ||
                  res.status == 201 ||
                  res.status == 204
                ) {
                  alert("Charger Inventory Updated Successfully!");
                  getChargerInventoryList();
                  setChargerForm({
                    id: null,
                    drone_inventory_id: [],
                    charger_id: [],
                    status: [],
                    serial_no: "",
                  });

                  setOpen(false);
                  setPilotSelected([]);
                }
              })
              .catch((err) => console.log(err));
          } else {
            token_api
              .post(`/drone/charger/inventory/`, data)
              .then((res) => {
                if (res.status == 200 || res.status == 201) {
                  alert("Charger Inventory Created Successfully!");
                  getChargerInventoryList();
                  setChargerForm({
                    id: null,
                    drone_inventory_id: [],
                    charger_id: [],
                    status: [],
                    serial_no: "",
                  });

                  setOpen(false);
                  setPilotSelected([]);
                }
              })
              .catch((err) => console.log(err));
          }
        }
      })
      .catch((error) => {
        setformErrors(error);
      });
  };

  const getDroneList = () => {
    token_api
      .get(`drone/inventory?page_size=500`)
      .then((res) => {
        if (res.data.data) {
          let newres = res?.data?.data.map((info) => ({
            title: info?.drone_name,
            id: info?.id,
          }));
          setDroneList(newres);
        }
      })
      .catch((err) => console.log(err));
  };

  const getChargerList = () => {
    token_api
      .get(`drone/chargers`)
      .then((res) => {
        if (res.data.data) {
          let newres = res?.data?.data.map((info) => ({
            title: info?.name,
            id: info?.id,
          }));

          setChargerList(newres);
        }
      })
      .catch((err) => console.log(err));
  };

  const getDashboardCount = () => {
    token_api
      .get(`drone/charger_inventory/dashboard_count`)
      .then((res) => {
        if (res.data.data) {
          const {
            assign,
            not_assign,
            damaged,
            sold,
            working,
            under_repair,
            agri_service,
            no_category_assign,
            survey_service,
            training,
          } = res.data.data;
          const _data = {
            assign: { name: "Assigned", value: assign },
            not_assign: { name: "Not Assigned", value: not_assign },
            damaged: { name: "Damaged", value: damaged },
            working: { name: "Working", value: working },
            sold: { name: "Sold", value: sold },
            repair: { name: "Under Repair", value: under_repair },
            at_base: { name: "At Base", value: 0 },
            on_field: { name: "On Field", value: 0 },
            training: { name: "Training", value: training },
            survey_service: { name: "Survey Service", value: survey_service },
            agri_service: { name: "Agri Service", value: agri_service },
            no_category_assign: {
              name: "No Application Assigned",
              value: no_category_assign,
            },
          };
          setCardData(_data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleAssignPilot = () => {
    let data = {
      charger_inventory_id: assignPilot?.charger_inventory_id,
      pilot_id: pilotSelected[0]?.id,
      assigned_from: formatDate(assignPilot?.assigned_from),
      assigned_to: formatDate(assignPilot?.assigned_to),
    };

    token_api
      .post(`drone/pilot/charger_assign/`, data)
      .then((res) => {
        if (res.status == 400) {
          alert(res?.data?.error?.fields[0]?.message[0]);
          setOpenDailog(false);
          // getChargerInventoryList();
          setAssignPilot({
            charger_inventory_id: null,
            pilot_id: null,
            assigned_from: null,
            assigned_to: null,
          });
          setPilotSelected([]);
        }
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          alert("Pilot Assigned Successfully!");
          setOpenDailog(false);
          // getChargerInventoryList();
          setAssignPilot({
            charger_inventory_id: null,
            pilot_id: null,
            assigned_from: null,
            assigned_to: null,
          });
          setPilotSelected([]);
          if (assignInventoryObj?.id) {
            setPageState(1);
            getInventoryAssignedList();
          } else {
            setPageState(1);
            getChargerInventoryList();
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCloseModal = () => {
    setOpenDailog(false);
    setAssignPilot({
      charger_inventory_id: null,
      pilot_id: null,
      assigned_from: null,
      assigned_to: null,
    });
  };
  useEffect(() => {
    // getDroneList();
    getChargerList();
    getDashboardCount();
  }, []);

  const handleChangeacc = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setfilters({
        ...filters,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleDeleteChipacc = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleToShowAssignList = (inventoryobj) => {
    setPageState(1);
    setAssignInventoryObj(inventoryobj);
  };

  const getInventoryAssignedList = async (filterobject) => {
    try {
      const fethLaptopInventoryAssignedList = await token_api.get(
        `drone/pilot/charger_assign?charger_inventory=${assignInventoryObj?.id}`,
        {
          params: { ...filterobject },
        }
      );

      if (fethLaptopInventoryAssignedList.status !== 200) {
        throw new Error("Error in fetching");
      }
      setPaginationData(fethLaptopInventoryAssignedList?.data);
      let modifiedArray = fethLaptopInventoryAssignedList?.data?.data?.map(
        (item) => {
          return {
            assigned_to: `${item?.pilot?.user?.first_name} ${item?.pilot?.user?.last_name}`,
            assigned_from: item?.assigned_from || "N.A",
            assigned_till: item?.assigned_to || "N.A",
            handover_accepted_on: "N.A",
            handover_accepted_by: "N.A",
            updated_on: "N.A",
            modified_by: item?.modified_by || "N.A",
            assign_by: item?.assign_by || "N.A",
            created_on: item?.created_on || "N.A",

            // assign: (
            //   <Button
            //     variant="contained"
            //     style={{ cursor: "pointer" }}
            //     onClick={() => {
            //       handleOpenAssignPilot(assignInventoryObj?.id);
            //     }}
            //   >
            //     Assign
            //   </Button>
            // ),
            action: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleEditChargerAssignPilot(item)}
                >
                  <img src={editIcon} />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteChargerAssignPilot(item?.id)}
                >
                  <img src={deleteIcon} />
                </div>
              </div>
            ),
          };
        }
      );
      setChargerAssignPilotHistory([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditChargerAssignPilot = (item) => {
    setChargerAssetAssign({
      ...chargerAssetAssign,
      id: item?.id,
      assigned_from: dayjs(item?.assigned_from),
      assigned_to: dayjs(item?.assigned_to),
      pilot: [item?.pilot],
    });
    setAssetAssignOpen(true);
  };

  const handleUpdateChargerAssetAssign = () => {
    let data = {
      pilot_id: chargerAssetAssign?.pilot[0]?.id,
      assigned_from: formatDate(chargerAssetAssign?.assigned_from),
      assigned_to: formatDate(chargerAssetAssign?.assigned_to),
    };
    token_api
      .patch(`drone/pilot/charger_assign/${chargerAssetAssign?.id}/`, data)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Charger Asset Assign Details updated");
          setChargerAssetAssign({
            id: null,
            assigned_from: null,
            assigned_to: null,
            pilot: [],
          });
          setAssetAssignOpen(false);
          getInventoryAssignedList();
        }
      });
  };
  const handleDeleteChargerAssignPilot = (assignId) => {
    token_api
      .delete(`drone/pilot/charger_assign/${assignId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Charger Inventory Assign Deleted Successfully!");
          setAssetAssignOpen(false);
          getInventoryAssignedList();
        }
      });
  };

  useEffect(() => {
    if (assignInventoryObj?.id) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};

        if (pageState) {
          filtersObject.page = pageState;
        }

        getInventoryAssignedList(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [pageState, assignInventoryObj?.id]);

  const handleHideAssignHistory = () => {
    // setPageState(1);
    setAssignInventoryObj({});
    getChargerInventoryList();
  };

  const handleSearchDroneInventory = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Drone Inventory already included");
    } else {
      setfilters((prevFilters) => ({
        ...prevFilters,
        drone_inventory: selectedValues,
      }));
    }
  };

  const handleAutocompleteChangeforVehiclePilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setChargerAssetAssign({
        ...chargerAssetAssign,
        ["pilot"]: selectedValues,
      });
    }
  };

  //Export Logic....

  const handleExportData = async () => {
    try {
      const response = await axios.get(
        `${base_url}drone/export/charger_inventory`
      );
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };
  return (
    <div>
      <div
        className={styles.pilotDash}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          // flexWrap: "wrap",
        }}
      >
        <div
          style={{
            minWidth: "30%",
            minHeight: "150px",
            margin: "0px 10px 0px 0px",
            alignSelf: "stretch",
          }}
        >
          <CustomCard
            cardIcon={pilotUser}
            data={{
              assign: cardData.assign,
              not_assign: cardData.not_assign,
            }}
            background={`linear-gradient(to left, rgb(81, 79, 255), rgb(87, 194, 255))`}
          />
        </div>
        <div
          style={{
            minWidth: "30%",
            minHeight: "150px",
            margin: "0px 10px 0px 10px",
            alignSelf: "stretch",
          }}
        >
          <CustomCard
            cardIcon={chargerInv}
            data={{
              working: cardData.working,
              damaged: cardData.damaged,
              sold: cardData.sold,
              repair: cardData.repair,
            }}
            background={` linear-gradient(103.23deg, #57B67A -2.03%, #0B7754 96.35%)`}
          />
        </div>
        <div
          style={{
            minWidth: "30%",
            minHeight: "150px",
            margin: "0px 0px 0px 10px",
            alignSelf: "stretch",
          }}
        >
          <CustomCard
            cardIcon={droneInventoryBook}
            data={{
              at_base: cardData.at_base,
              on_field: cardData.on_field,
            }}
            background={`linear-gradient(to left, rgba(132, 98, 251, 1), rgba(235, 137, 235, 1))`}
          />
        </div>
      </div>
      {!assignInventoryObj?.id ? (
        <div>
          <FilterContainer
            style={{ maxHeight: "40px", marginTop: "20px" }}
            // className={`${styles.marginTopBox}`}
          >
            {/* <CustomSearchFilter
              onChange={(e) => {
                setPageState(1);
                setfilters({ ...filters, q: e });
              }}
            /> */}

            <InputFields
              label="Filter By Serial Number"
              name="serial_no"
              value={filters?.serial_no}
              onChange={handleChangeacc}
            />
            {/* <CustomSelectChip
              multiple={false}
              label="Filter By Drone Inventory"
              name="drone_inventory"
              listArray={droneList}
              onChange={handleChangeacc}
              onDelete={handleDeleteChipacc}
              value={filters?.drone_inventory}
            /> */}

            <DroneInventoryComp
              value={filters?.drone_inventory}
              onChange={handleSearchDroneInventory}
            />

            <CustomSelectChip
              multiple={false}
              label="Filter By Charger"
              name="charger"
              onChange={handleChangeacc}
              onDelete={handleDeleteChipacc}
              value={filters?.charger}
              listArray={chargerList}
            />

            {/* <CustomSelectChip
    multiple={false}
    label="Filter By Status"
    name="status"
    onChange={handleChangeacc}
    onDelete={handleDeleteChipacc}
    value={filters?.status}
    listArray={statusOptions}
  /> */}
            <CustomButton
              text={"Export"}
              maxWidth="110px"
              onClick={handleExportData}
            />

            <Button
              variant="contained"
              onClick={handleAdd}
              sx={{ height: "53px" }}
            >
              Add
            </Button>
          </FilterContainer>

          <div className={styles.tableContainer}>
            <CustomTable
              columns={tableColumns}
              data={chargerInventoryList}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <img
                src={blackArrow}
                onClick={handleHideAssignHistory}
                style={{ cursor: "pointer" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.id}</h4>
                <p>Charger Inventory ID</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.charger?.name || "N.A"}</h4>
                <p>Charger Name</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.charger?.model || "N.A"}</h4>
                <p>Charger Model</p>
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpenAssignPilot(assignInventoryObj?.id);
                }}
              >
                Assign
              </Button>
            </div>
          </div>

          <div className={styles.tableContainer}>
            <CustomTable
              columns={assignPilotColumns}
              data={chargerAssignPilotHistory}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      )}

      <DrawerComp open={open} onClose={() => setOpen(false)} anchor="right">
        <DrawerContainer>
          <DrawerTitle
            text={
              chargerForm?.id
                ? "Update Charger Inventory"
                : "Add Charger Inventory"
            }
            handleCancelDrw={() => setOpen((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <CustomSelectChip
              multiple={false}
              label="Select Charger"
              name="charger_id"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={chargerForm?.charger_id}
              listArray={chargerList}
              error={formErrors?.charger_id}
            />

            {/* <CustomSelectChip
              multiple={false}
              label="Select Drone Inventory"
              name="drone_inventory_id"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={chargerForm?.drone_inventory_id}
              listArray={droneList}
              error={formErrors?.drone_inventory_id}
            /> */}

            <DroneInventoryComp
              value={chargerForm?.drone_inventory_id}
              onChange={handleAutocompleteChangefordroneInventory}
            />
            {formErrors?.drone_inventory_id && (
              <p style={{ color: "red" }}>{formErrors?.drone_inventory_id}</p>
            )}

            <InputFields
              label="Serial Number"
              name="serial_no"
              value={chargerForm?.serial_no || ""}
              onChange={handleText}
              error={formErrors?.serial_no}
              helperText={formErrors?.serial_no}
            />

            <CustomSelectChip
              multiple={false}
              label="Inventory Status"
              name="status"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={chargerForm?.status}
              listArray={statusOptions}
              error={formErrors?.status}
            />
            {/* <DronePilot
              value={pilotSelected}
              onChange={handleAutocompleteChangeforPilot}
            />
            {formErrors?.pilotSelected && (
              <p style={{ color: "red" }}>{formErrors?.pilotSelected}</p>
            )} */}

            <div>
              <Button variant="contained" onClick={handleAddChargerInventory}>
                {chargerForm?.id ? "Update" : "Add"}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>

      <DrawerComp
        open={assetAssignOpen}
        onClose={() => setAssetAssignOpen(false)}
        anchor="right"
      >
        <DrawerContainer>
          <DrawerTitle
            text={"Update Pilot Assign Details"}
            handleCancelDrw={() => setAssetAssignOpen((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <DronePilot
              value={chargerAssetAssign?.pilot}
              onChange={handleAutocompleteChangeforVehiclePilot}
            />

            <CustomDatePicker
              label="Assigned From"
              onChange={(newValue) => {
                setChargerAssetAssign({
                  ...chargerAssetAssign,
                  assigned_from: newValue,
                });
              }}
              value={chargerAssetAssign?.assigned_from}
            />

            <CustomDatePicker
              label="Assigned Till"
              onChange={(newValue) => {
                setChargerAssetAssign({
                  ...chargerAssetAssign,
                  assigned_to: newValue,
                });
              }}
              value={chargerAssetAssign?.assigned_to}
            />

            <div>
              <Button
                variant="contained"
                onClick={handleUpdateChargerAssetAssign}
              >
                {"Update"}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>

      <CustomModal open={openDailog} onClose={handleCloseModal}>
        <div>
          <h3 style={{ marginBottom: "10px" }}>Assigned Pilot</h3>
          <div style={{ marginTop: "10px" }}>
            <CustomDatePicker
              label="Assigned From"
              value={assignPilot?.assigned_from}
              onChange={(newValue) =>
                setAssignPilot({
                  ...assignPilot,
                  assigned_from: newValue,
                })
              }
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <CustomDatePicker
              label="Assigned Till"
              value={assignPilot?.assigned_to}
              onChange={(newValue) =>
                setAssignPilot({
                  ...assignPilot,
                  assigned_to: newValue,
                })
              }
            />
          </div>{" "}
          <div style={{ margin: "10px 0px" }}>
            <DronePilot
              value={pilotSelected}
              onChange={handleAutocompleteChangeforPilot}
            />
          </div>
          <Button variant="contained" onClick={handleAssignPilot}>
            Submit
          </Button>
        </div>
      </CustomModal>
    </div>
  );
};

export default ChargerInventory;

const tableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "serial_no",
    label: "Serial No",
  },
  {
    id: "drone_inventory",
    label: "Drone Inventory",
  },
  {
    id: "charger_master",
    label: "Charger Master",
  },
  // {
  //   id: "pilot_id",
  //   label: "Pilot",
  // },
  {
    id: "assign",
    label: "Assign",
  },
  {
    id: "inv_status",
    label: "Status",
  },

  {
    id: "action",
    label: "Action",
  },
];

const tableBodyData = [
  {
    id: 1,
    drone_inventory: 101,
    charger_master: "AP31V3123",
    pilot_id: 201,
    status: "Active",
  },
  {
    id: 2,
    drone_inventory: 102,
    charger_master: "AP31V3123",
    pilot_id: 202,
    status: "Active",
  },
  {
    id: 3,
    drone_inventory: 103,
    charger_master: "AP31V3123",
    pilot_id: 203,
    status: "Active",
  },
  {
    id: 4,
    drone_inventory: 104,
    charger_master: "AP31V3123",
    pilot_id: 204,
    status: "Active",
  },
  {
    id: 5,
    drone_inventory: 105,
    charger_master: "AP31V3123",
    pilot_id: 205,
    status: "Inctive",
  },
];

const statusOptions = [
  { title: "Working", id: 1 },
  { title: "Sold", id: 2 },
  { title: "Damaged", id: 3 },
  { title: "Under Repair", id: 4 },
];

const assignPilotColumns = [
  {
    id: "assigned_to",
    label: "Assigned To",
  },
  {
    id: "assigned_from",
    label: "Assigned From",
  },
  {
    id: "assigned_till",
    label: "Assigned Till",
  },
  {
    id: "handover_accepted_on",
    label: "Handover Accepted On",
  },
  {
    id: "handover_accepted_by",
    label: "Handover Accepted By",
  },
  {
    id: "updated_on",
    label: "Updated On",
  },
  {
    id: "modified_by",
    label: "Updated By",
  },
  {
    id: "assign_by",
    label: "Created By",
  },
  {
    id: "created_on",
    label: "Created On",
  },
  // {
  //   id: "assign",
  //   label: "Assign Pilot",
  // },
  {
    id: "action",
    label: "Action",
  },
];
