const Truck = {
    name: String,
    assignedOrderId: Array,
  };
  
  const Order = {
    id: String,
    from: String,
    to: String,
  };
  
  const APIData = {
    trucks: Array,
    orders: Array,
  };
  
  const MockAPIComponent = {
    getData: function () {
      return Promise.resolve();
    },
  };
  
  module.exports = {
    Truck,
    Order,
    APIData,
    MockAPIComponent,
  };
  