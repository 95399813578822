import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const CustomSelect = ({
  listArray,
  label,
  onChange,
  value,
  name,
  width = "100%",
  maxWidth,
  multiple = "false",
  error,
  selectType = "normal",
  index,
}) => {
  const [selectedVal, setSelectedVal] = useState([]);

  useEffect(() => {
    console.log("EXecuted", value);
    let seVal = [];

    value?.map((item) => {
      // return item?.id;
      seVal.push(item?.id);
      // setSelectedVal([...selectedVal, item?.id]);
    });
    // value.forEach((element) => {
    //   seVal.push(element?.id);
    // });

    setSelectedVal([...seVal]);
  }, [value]);

  console.log("SelctValue", selectedVal);

  return (
    <FormControl sx={{ width: width }} size="small" error={error}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value || []}
        label={label}
        onChange={(e) => onChange(e.target.value, index)}
        name={name}
        multiple={multiple}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected?.map((value) => {
              return <Chip label={value?.title || value?.city || ""} key={value.id} />;
            })}
          </Box>
        )}
        error={""}
      >
        {/* <MenuItem value="">
          <em>Clear All</em>s
        </MenuItem> */}
        {selectType === "normal" &&
          listArray?.map((item, i) => {
            return <MenuItem value={item.id}>{item.title || item.city}</MenuItem>;
          })}

        {selectType === "checkbox" &&
          listArray?.map((item) => {
            return (
              <MenuItem
                className="flex"
                sx={{ columnGap: "5px" }}
                key={item?.id}
                value={item}
              >
                <Checkbox
                  checked={selectedVal.includes(item?.id)}
                  // onChange={onChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                {item?.icon && <img src={item?.icon} alt="crop_stage" />}

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <div>{item?.title}</div>
                    <div>{item?.sub_topic}</div>
                  </div>
                  <div>
                    <div
                      style={{
                        padding: "7px 25px",
                        borderRadius: "25px",
                        backgroundColor: "#CAE6BD",
                        color: "#1E7026",
                      }}
                    >
                      Available
                    </div>
                  </div>
                </div>
              </MenuItem>
            );
          })}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default CustomSelect;

const dummyData = [
  {
    id: 1,
    title: "Data",
  },
  {
    id: 2,
    title: "Data2",
  },
  {
    id: 3,
    title: "Data3",
  },
];
