import React from "react";
import styles from "./PilotServiceBooking.module.css";
import orderDrone from "../../Assets/serviceOrdDrone.png";
import { formatDate } from "../../Utils/Utils";

const PilotServiceBooking = (props) => {
  const { data, handleClick } = props;
  return (
    <div>
      {data.map((item, index) => {
        return (
          <div className={styles.mainContainer} key={index}>
            <div className={styles.topContainer}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "20px",
                }}
              >
                <img src={orderDrone} className={styles.droneImg} />
                <div>
                  <h4 className={styles.title}>{item?.service_name}</h4>
                  <div className={styles.bookingCon}>
                    {item?.status_str === "scheduled" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          padding: "10px",
                          borderRadius: "8px",
                          backgroundColor: "rgba(255, 215, 8, 0.45)",
                          marginRight: "8px",
                        }}
                      >
                        <img
                        //   src={item?.statusIcon.src}
                        //   style={{ width: "20px", height: "20px" }}
                        />
                        <p
                          style={{
                            color: "rgba(150, 118, 6, 1)",
                            margin: "0px",
                          }}
                        >
                          {item?.status_str}
                        </p>{" "}
                      </div>
                    )}
                    {item?.status_str === "New Request" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          padding: "10px",
                          borderRadius: "8px",
                          backgroundColor: "rgba(252, 185, 176, 1)",
                          marginRight: "8px",
                        }}
                      >
                        <img
                        //   src={item?.statusIcon.src}
                        //   style={{ width: "20px", height: "20px" }}
                        />
                        <p
                          style={{
                            color: "rgba(188, 70, 7, 1)",
                            margin: "0px",
                          }}
                        >
                          {item?.status_str}
                        </p>{" "}
                      </div>
                    )}
                    {item?.status_str === "Completed" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          padding: "10px",
                          borderRadius: "8px",
                          backgroundColor: "rgba(211, 251, 171, 1)",
                          marginRight: "8px",
                        }}
                      >
                        <img
                        //   src={item?.statusIcon.src}
                        //   style={{ width: "20px", height: "20px" }}
                        />
                        <p
                          style={{
                            color: "rgba(60, 147, 8, 1)",
                            margin: "0px",
                          }}
                        >
                          {item?.status_str}
                        </p>{" "}
                      </div>
                    )}
                    {/* |
                        <p className={styles.bookText} style={{ marginLeft: "8px" }}>
                          Booking ID: <span>{item?.bookId}</span>
                        </p> */}
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  handleClick(item?.id);
                }}
              >
                <button className={styles.viewandmanagebtn}>
                  View & Manage Order
                </button>
              </div>
            </div>
            <div className={styles.bottomContainer}>
              <div style={{ paddingLeft: "20px", width: "100%" }}>
                <div className={styles.miniCon}>
                  <p className={styles.bookId}>Booking Id</p> :{" "}
                  <p className={styles.bookSpan}>{item?.id}</p>
                </div>
                <div className={styles.miniCon}>
                  <p className={styles.bookId}>Booking Date</p>:
                  <p className={styles.bookSpan}>{formatDate(item?.created)}</p>
                </div>
              </div>
              <div className={styles.amountCon}>
                <p className={styles.amountVal}>{item?.total_amt || "N.A"}</p>
                <p className={styles.amountName}>Amount</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PilotServiceBooking;
