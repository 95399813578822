import React, { useEffect, useState, useReducer } from "react";
import Container from "../../Component/Container/Container";
import styles from "./quotation.module.css";
import IosSwitch from "../../Component/IosSwitch/IosSwitch";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, Button } from "@mui/material";
import InputFields from "../../Component/InputFields/InputFields";
import CustomAutoCompleteName from "../../Component/CustomAutoComplete/CustomAutoComplete";
import CustomAutoComplete from "../../Component/CustomAutoforQuote/CustomAutoforQuote";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import AddUser from "../../Component/AddUser/AddUser";
import AddAddress from "../../Component/AddAddress/AddAddress";
import { serviceDummy } from "../../Utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../state/actions/serviceActions";
import deleteIcon from "../../Assets/deleteIcon.png";
import { open_api, token_api } from "../../Utils/network";
import {
  getAddressSimple,
  quotationCalculation,
  quotationTotalCalculation,
} from "../../Utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import { quotationReducer } from "./quotationReducer";
import dayjs, { Dayjs } from "dayjs";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import AddUserAddress from "../../Component/AddUserAddress/AddUserAddress";
const initialState = {
  count: 0,
};

const Quotation = () => {
  const [componentState, componentDispatch] = useReducer(
    quotationReducer,
    initialState
  );
  const navigate = useNavigate();
  const uniqueDate = new Date();
  console.log("UNiqueDate", uniqueDate);
  const dispatch = useDispatch();
  const locationState = useLocation();
  console.log(
    "QUOATELOCATOINSTATE",
    locationState?.state?.serviceBookingDetails?.creator
  );
  const allServices = useSelector((state) => state.service.all_services);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState(1);
  const [serviceSelectionDropDown, setServiceSelectionDropDown] = useState([]);
  const [singleServiceSelect, setSingleServcieSelect] = useState({});
  const [fnlSectdSrvc, setfnlSectdSrvc] = useState([]);
  const [usersList, setUsersList] = useState(null);
  const [userDetails, setUserDetails] = useState({
    title: "",
    gst: "",
  });
  const [productDetails, setProductDetails] = useState({});
  const [addressList, setAddressList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [userId, setUserId] = useState(null);
  const [finalCalculations, setFinalCalculations] = useState({});
  const [finalQuantitiesCount, setFinalQuantitiesCount] = useState(null);
  const handeAddressSelect = () => {};
  const handleChangeProduct = () => {};
  const handleAddAction = () => {
    setfnlSectdSrvc([...fnlSectdSrvc, singleServiceSelect]);
    // setSingleServcieSelect({})
  };

  console.log(fnlSectdSrvc,finalCalculations, "harifinalCalculations");
  const [loading, setLoading] = useState(false);
  const handleDeleteSelected = (id) => {
    setfnlSectdSrvc((prev) => [...prev.filter((item) => item.id !== id)]);
  };
  const [addressObj, setAddressObj] = useState({});

  const handleChange = (e) => {
    console.log("Agricultural Spary Usin Drones Selected");
    if (e?.target?.name === "qty") {
      // const calcObj = quotationCalculation("qty", singleServiceSelect, e?.target?.value);
      const newTotal = e?.target?.value * singleServiceSelect?.price;
      setSingleServcieSelect({
        ...singleServiceSelect,
        qty: e.target.value,
        total: newTotal,
      });
    } else if (e?.target?.name === "price") {
      const newTotal = singleServiceSelect?.qty * e.target.value;
      setSingleServcieSelect((prev) => ({
        ...prev,
        price: e.target.value,
        total: newTotal,
      }));
    } else {
      // Handle other cases
      setSingleServcieSelect((prev) => ({
        ...prev,
        ...e,
        qty: "1",
        total: e?.price,
      }));
    }
  };

  const handleChangedata = (e) => {
    const { name, value } = e.target;
    setAddressObj((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  console.log(addressObj, "singleServiceSelect");

  const handleChangeAutoComplete = () => {};

  const getUsersList = () => {
    // let userResponse = await open_api.get(`users/`);
    // console.log("RESPONSEUSER", userResponse);

    token_api
      .get(`users/`)
      .then((response) => {
        console.log("WWWWRRR", response);
        setUsersList(response.status);
      })
      .catch((err) => {});
  };

  const setServiceForApi = (servArray) => {
    console.log("CGQTID", servArray);
    const newArray = servArray.map((item) => {
      return {
        service_id: item?.id,
        service_amt: item?.price,
        service_qty: item?.qty,
        discount_type: 1,
        discount: 0,
      };
    });
    return newArray;
  };

  // const setBreakDownForApi = (finalCalculations)=>{}
  // api call to create quotation
  const handleCreateQuotation = () => {
    setLoading(true);

    const data = {
      address_id: userDetails?.address_details[0]?.id,
      service_details: setServiceForApi(fnlSectdSrvc),
      breakdown: {
        amount: finalCalculations?.subTotal || 0,
        cgst_percent: finalCalculations?.cgst || 0,
        sgst_utgst_percent: finalCalculations?.sgst || 0,
        igst_percent: finalCalculations?.igst || 0,
        discount_percent: 0,
        flat_discount: 0,
        final_amount: finalCalculations?.total,
        advanced_amt: addressObj?.line_2,
      },
      booking_service: locationState?.state?.serviceBookingDetails?.id,
      quote_no: `DD${Math.random().toString().slice(-4)}`,
    };
    console.log("DSADAAD", data);

    token_api
      .post(`service/quotation/`, data)
      .then((response) => {
        console.log("QUatoationResponse", response);
        // is success navigate to service booking page
        //show success message

        if (response?.status !== 201) {
          throw new Error("Error in creating quotation");
        }

        let quoteId = response?.data?.data?.id;

        token_api.get(`service/quotation_pdf/${quoteId}/`).then((response) => {
          if (response?.status == 200) {
            setLoading(false);

            navigate(
              `/bookings/service-booking-details/${locationState?.state?.serviceBookingDetails?.id}`
            );
          }
        });

        console.log(quoteId, "quoyeid");
      })
      .catch((err) => {
        alert(err);
        setLoading(true);
        console.log("QUOTEERROE", err);
      });
  };

  const getFinalQuantitiesCount = (serviceQuantities) => {
    return serviceQuantities.reduce((accumulator, currentValue) => {
      return parseInt(accumulator) + parseInt(currentValue?.qty || 0);
    }, 0);
  };
  console.log("STate", finalCalculations);

  useEffect(() => {
    dispatch(getServices());
    getUsersList();
  }, []);

  useEffect(() => {
    setAddressObj((prev) => ({
      ...prev,
      line_2: Math.floor(finalCalculations?.total * 0.5),
    }));
  }, [finalCalculations?.total]);

  console.log(finalCalculations?.total, "singleServiceSelectabc");

  useEffect(() => {
    console.log(
      "DataForQuoration",
      locationState?.state?.serviceBookingDetails
    );
    if (locationState?.state?.serviceBookingDetails) {
      const bookingDetailsTemporary =
        locationState?.state?.serviceBookingDetails;
      setUserDetails({
        ...userDetails,
        title: { title: bookingDetailsTemporary?.full_name },
        gst: bookingDetailsTemporary?.gst,
        address_details: [
          {
            title: getAddressSimple(bookingDetailsTemporary?.address),
            id: bookingDetailsTemporary?.address?.id,
          },
        ],
      });

      if (bookingDetailsTemporary?.bookingaddonservice?.length > 0) {
        let data = bookingDetailsTemporary?.bookingaddonservice.map((item) => {
          console.log("ITEMSDUM", item);
          const { id, title, price, hsn_code } = item?.services;
          return {
            id,
            title,
            qty: "1",
            total: price,
            price,
            hsn_code,
          };
        });
        setfnlSectdSrvc(data);
      }
    }
  }, [locationState?.state?.serviceBookingDetails]);

  useEffect(() => {
    if (allServices?.data?.length > 0) {
      let selectOptions = allServices?.data?.map((item) => {
        return {
          id: item?.id || null,
          title: item?.title || "",
          price: item?.price || "N.A",
          hsn_code: item?.hsn_code,
        };
      });
      setServiceSelectionDropDown([...selectOptions]);
    }
  }, [allServices?.data]);

  useEffect(() => {
    const finalCalc = quotationTotalCalculation(fnlSectdSrvc);
    console.log("FInalCalcultion", finalCalc);
    setFinalCalculations({ ...finalCalc });

    const finalQuantity = getFinalQuantitiesCount(fnlSectdSrvc);
    setFinalQuantitiesCount(finalQuantity);
  }, [fnlSectdSrvc]);

  function getUserAddress(id) {
    token_api
      .get(`/users/address?creator=${id}`)
      .then((response) => {
        console.log(response?.data?.data, "ifnoaddress");

        const responseaddress = response?.data?.data.map((info) => ({
          title: getAddressSimple(info),
          id: info?.id,
        }));

        console.log("useraddres", responseaddress);
        setAddressList(responseaddress);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    if (locationState?.state?.serviceBookingDetails?.address?.creator) {
      getUserAddress(
        locationState?.state?.serviceBookingDetails?.address?.creator
      );
    }
  }, [locationState?.state?.serviceBookingDetails?.address?.creator]);

  console.log("ALlServicie", locationState);

  const handleDeleteChip = (id, selectName) => {
    setUserDetails((prevFilters) => ({
      ...prevFilters,
      // [selectName]: prevFilters?.[selectName]?.filter((chip) => chip?.id !== id),
    }));
  };

  const handleFilter = (e, type, selectName, multiple) => {
    console.log("HANDLERSS", e, type);
    if (type === "select" && selectName && multiple) {
      setUserDetails((prevFilters) => ({
        ...prevFilters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      }));
    } else if (type === "select" && selectName && !multiple) {
      setUserDetails((prevFilters) => ({
        ...prevFilters,
        [selectName]: [e],
      }));
    }
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleGoBack = () => {
    navigate(-1); // Navigates back one step
  };

  console.log(fnlSectdSrvc, finalQuantitiesCount, "fnlSectdSrvcHari");
  return (
    <Container>
      {/* <div className={`${styles.pageTitle}`}>Create Quotation</div> */}

      <div className={styles.outerContainer}>
        <p className={`font-22 bold-600`} style={{ textAlign: "center" }}>
          Create Quotation
        </p>
        <div className={styles.container}>
          <div className={styles.firstItemHeader}>
            {/* <div className={`${styles.headComm}`}>
            <div>
              <div>Invoice No.</div>
              <div>
                <InputFields />
              </div>
            </div>
          </div> */}
            <div className={`${styles.headComm}`}>
              <div>
                <div>Quotation Date</div>
                {/* <div>
                <CustomDate
                  // label="Start Date"
                  value={invoiceDate}
                  style={{ width: "100%" }}
                  // handleChange={handleChangeB}
                  name="start_date"
                  // required
                  // error={startDateCk}
                  // helperText={startDateCk ? "Enter start date" : ""}
                />
              </div> */}
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      // label="From"
                      disabled
                      inputFormat="DD/MM/YYYY"
                      value={dayjs(uniqueDate)}
                      // onChange={(newValue) => handleChangeDate(newValue)}
                      renderInput={(params) => (
                        <TextField
                          // disabled={true}
                          size="small"
                          {...params}
                          // error={formErrors?.start}
                          // helperText={formErrors?.start}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className={`${styles.headComm}`}>
              <div>
                <div>Reverse Charge</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <span>Yes</span>
                  <span style={{ marginLeft: "15px" }}>
                    {" "}
                    <IosSwitch checked={true} switchState={() => {}} />
                  </span>
                  <span>No</span>
                </div>
              </div>
            </div>
            <div className={`${styles.headComm}`}>
              <div>
                <div>State</div>
                <div>Delhi</div>
              </div>
            </div>
          </div>

          <div className={`${styles.secondRow}`}>
            <div className={`${styles.billToPartyBox}`}>
              <div>Bill to Party</div>
              <div className={`${styles.marginTop15}`}>
                <div className={`${styles.billPartyFlexCeter}`}>
                  <span>Name</span>
                  <span>
                    {/* <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setOpenDrawer((prev) => !prev);
                        setDrawerType(1);
                      }}
                    >
                      {" "}
                      Add User
                    </Button> */}
                  </span>
                </div>
                <div className={`${styles.marginTop15}`}>
                  {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                options={UserList}
                // value={value}
                onInputChange={(event, newInputValue) => {
                  setInputValueUser(newInputValue);
                }}
                onChange={(event, newValue) => handleOnChangeUser(newValue)}
                getOptionLabel={(option) =>
                  `${option?.title}, ${option?.phone}, ${option?.email}`
                }
                renderInput={(params) => (
                  <TextField
                    // error={formErrors?.subjectCheck}
                    // helperText={formErrors?.subjectCheck}
                    {...params}
                    label="Search by Name, Mobile No., Email"
                    error={formErrors?.user}
                    helperText={formErrors?.user}
                  />
                )}
              /> */}
                  {/* <CustomAutoCompleteName
                    value={userDetails?.title}
                    handleChange={handleChangeAutoComplete}
                  /> */}
                  {userDetails?.title?.title && (
                    <p
                      style={{
                        border: "1px solid #d3d3d3",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      {userDetails?.title?.title}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.marginTop15}`}>
                <div className={`${styles.billPartyFlexCeter}`}>
                  <span>Address</span>
                  <span>
                    {" "}
                    <Button
                      variant="contained"
                      size="small"
                      // disabled={userId ? false : true}
                      onClick={() => {
                        setOpenDrawer((prev) => !prev);
                        setDrawerType(2);
                      }}
                    >
                      {" "}
                      Add Address
                    </Button>
                  </span>
                </div>
                <div className={`${styles.marginTop15}`}>
                  {/* <CustomSelect
                    value={userDetails?.address_details}
                    disabled={userId ? false : true}
                    onChange={(e) => handeAddressSelect(e)}
                    listarray={addressList}
                    label="Select billing address."
                    error={formErrors?.billadd}
                    size="small"
                  /> */}

                  <CustomSelectChip
                    label={"Select billing address."}
                    listArray={addressList}
                    minWidth={"175px"}
                    multiple={false}
                    name="address_details"
                    value={userDetails?.address_details}
                    onChange={handleFilter}
                    onDelete={handleDeleteChip}
                  />
                </div>
              </div>
              <div className={`${styles.marginTop15}`}>
                <div>GSTIN</div>
                <div>
                  <InputFields value={userDetails?.gst} />
                </div>
              </div>
            </div>
            <div className={`${styles.billToPartyBox}`}>
              <div>
                {/* <span>Ship to party</span> */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  {/* <span>Yes</span> */}
                  {/* <span style={{ marginLeft: "15px" }}>
                    {" "}
                    <IosSwitch />
                  </span> */}
                  {/* <span>Same as Bill to party</span> */}
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.thirdRow}`}>
            <div className={styles.selectProductBox}>
              <div style={{ width: "25%" }}>
                <div
                  className={`${styles.comProDuctBox}`}
                  style={{ width: "100%", maxWidth: "275px" }}
                >
                  <div>Description of service*</div>
                  <div className={`${styles.commonMarginTop}`}>
                    {/* <CustomAutoComplete /> */}
                    {/* <CustomSelect listArray={serviceDummy} /> */}
                    <CustomAutoComplete
                      options={serviceSelectionDropDown}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              {/* <div className={`${styles.commonMaxWidth}`}>
              <div>HSN</div>
              <div className={`${styles.commonMarginTop}`}>
                <InputFields />
              </div>
            </div> */}
              <div className={`${styles.qtyBoxFlex}`} style={{ width: "75%" }}>
                <div
                  className={`${styles.commonMaxWidth} ${styles.comProDuctBox} `}
                >
                  <div>HSN Code/SAC</div>
                  <div className={`${styles.commonMarginTop}`}>
                    <InputFields
                      disabled
                      onChange={handleChangeProduct}
                      name="hsn_code"
                      value={singleServiceSelect?.hsn_code}
                    />
                  </div>
                </div>

                <div
                  className={`${styles.commonMaxWidth} ${styles.comProDuctBox}`}
                >
                  <div>Rate*</div>
                  <div className={`${styles.commonMarginTop}`}>
                    <InputFields
                      value={singleServiceSelect?.price}
                      name="price"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.commonMaxWidth} ${styles.comProDuctBox}`}
                >
                  <div>{`Qty(Acres)*`}</div>
                  <div className={`${styles.commonMarginTop}`}>
                    <InputFields
                      name="qty"
                      onChange={handleChange}
                      value={singleServiceSelect?.qty}
                    />
                  </div>
                </div>

                <div
                  className={`${styles.commonMaxWidth} ${styles.comProDuctBox}`}
                >
                  <div>Total*</div>
                  <div className={`${styles.commonMarginTop}`}>
                    <InputFields disabled value={singleServiceSelect?.total} />
                  </div>
                </div>

                <div className={`${styles.commonMaxWidth} `}>
                  <div>Action</div>
                  <div className={`${styles.commonMarginTop}`}>
                    {/* <InputFields disabled value={productDetails?.total} /> */}
                    <Button variant="contained" onClick={handleAddAction}>
                      +
                    </Button>
                  </div>
                </div>
                {/* <div
                className={` ${styles.commonMaxWidth} ${styles.comProDuctBox}`}
              >
                <div>Disc code</div>
                <div className={`${styles.commonMarginTop} `}>
                  <CustomSelect
                    listarray={couponsAndVoucher}
                    onChange={handleSelectCoupons}
                    value={""}
                    size="small"
                  />
                </div>
              </div> */}
              </div>

              {/* <div className={`${styles.commonMaxWidth}`}>
              <div>
                IGST
                {` Amt(18%)`}
              </div>
              <div>
                <InputFields value={productDetails?.igst} />
              </div>
            </div> */}
            </div>
          </div>

          <div>
            {fnlSectdSrvc.length > 0 &&
              fnlSectdSrvc?.map((item) => {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "0px",
                      }}
                      className={`${styles.selectProductBox} ${styles.thirdRow}`}
                    >
                      <div
                        className={`${styles.borderRight}`}
                        style={{ width: "25%" }}
                      >
                        <div className={styles.comProDuctBox}>
                          {item?.title}
                        </div>
                      </div>
                      <div className={styles.qtyBoxFlex}>
                        <div
                          className={`${styles.comProDuctBox} ${styles.borderRight}`}
                        >
                          {item?.hsn_code || "N.A"}
                        </div>
                        <div
                          className={`${styles.comProDuctBox} ${styles.borderRight}`}
                        >
                          {item?.price}
                        </div>
                        <div
                          className={`${styles.comProDuctBox} ${styles.borderRight}`}
                        >
                          {item?.qty}
                        </div>
                        <div
                          className={`${styles.comProDuctBox} ${styles.borderRight}`}
                        >
                          {item?.total}
                        </div>
                        <div>
                          <img
                            onClick={() => handleDeleteSelected(item?.id)}
                            style={{ cursor: "pointer" }}
                            src={deleteIcon}
                            alt="delete"
                          />
                        </div>
                        {/* <div className={styles.comProDuctBox}>
                          <img
                            onClick={() => handleDeleteSelected(item?.id)}
                            style={{ cursor: "pointer" }}
                            src={deleteIcon}
                          
                            alt="delete"
                          />
                        </div> */}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>

          <div className={`${styles.fourthRow}`}>
            <div className={`${styles.totalSubBox} ${styles.borderRight} `}>
              <div
                className={`${styles.totBox} ${styles.borderRight} ${styles.padding10} `}
              >
                <div className={`${styles.txtAlignCenter}`}>
                  <div>Total Services</div>
                  <div>{fnlSectdSrvc?.length}</div>
                </div>
              </div>
              <div className={`${styles.totBox} ${styles.padding10} `}>
                <div className={`${styles.txtAlignCenter}`}>
                  <div>Total Quantity</div>
                  <div>{finalQuantitiesCount || "N.A"}</div>
                </div>
              </div>
            </div>
            <div className={`${styles.totalSubBox}  `}>
              <div className={`${styles.finalAmtBox}`}>
                <div style={{ flexGrow: "1", padding: "10px" }}>
                  <div>
                    Sub Total : {`${finalCalculations?.subTotal || "0"}`}
                  </div>
                  <div>{productDetails.subTotal}</div>
                </div>

                <div className={`${styles.gstBoxMain} ${styles.borderTop} `}>
                  <div className={`${styles.gstchild} ${styles.borderRight}`}>
                    <div
                      style={{ flexGrow: "1" }}
                      className={`${styles.padding10}`}
                    >
                      <div>CGST</div>
                      <div>{productDetails.cgst}</div>
                    </div>
                  </div>
                  <div className={`${styles.gstchild} ${styles.borderRight}`}>
                    <div
                      style={{ flexGrow: "1" }}
                      className={`${styles.padding10}`}
                    >
                      <div>SGST</div>
                      <div>{productDetails.sgst}</div>
                    </div>
                  </div>
                  <div className={`${styles.gstchild}`}>
                    <div
                      style={{ flexGrow: "1" }}
                      className={`${styles.padding10}`}
                    >
                      <div>IGST : {`${finalCalculations?.igst || "0"}`} </div>
                      <div>{productDetails.igst}</div>
                    </div>
                  </div>
                </div>

                <div
                  style={{ flexGrow: "1", padding: "10px" }}
                  className={`${styles.gstBoxMain} ${styles.borderTop} `}
                >
                  Total: {`Rs ${finalCalculations?.total || "0"}`}
                </div>

                <div
                  style={{ flexGrow: "1", padding: "10px" }}
                  className={`${styles.gstBoxMain} ${styles.borderTop} `}
                >
                  <InputFields
                    label="Advance amount"
                    name="line_2"
                    type="number"
                    style={{ width: "100%" }}
                    value={addressObj?.line_2}
                    onChange={handleChangedata}
                    error={formErrors?.line_2}
                    helperText={formErrors?.line_2}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              columnGap: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="mt-10 mb-10"
          >
            <Button
              variant="contained"
              onClick={handleCreateQuotation}
              className={` ${
                loading ? styles.loadingStyle : styles.saveandnextbtn
              }`}
              disabled={loading}
            >
              Save and submit to the client
            </Button>
            <div>
              <Button variant="contained" onClick={handleGoBack}>
                Back
              </Button>
            </div>
          </div>
        </div>
      </div>

      <DrawerComp
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        anchor={"right"}
        width="35%"
      >
        {/* <AddUser /> */}
        <AddUserAddress
          addressId={
            locationState?.state?.serviceBookingDetails?.address?.creator
          }
          handleCloseDrawer={handleClose}
          getUserAddress={getUserAddress}
        />
      </DrawerComp>
    </Container>
  );
};

export default Quotation;

const dummyService = [
  {
    id: 1,
    title: "Crop Harvesting",
  },
  {
    id: 2,
    title: "S2",
  },
];
