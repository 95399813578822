import dasboardIcon from "../Assets/sidebarSVG/dashboard.svg";
import serviceIcon from "../Assets/sidebarSVG/dashboard.svg";
import droneIcon from "../Assets/sidebarSVG/droneMain.svg";
import report from "../Assets/sidebarSVG/dashboard.svg";
import couponIcon from "../Assets/CouponsAcc.svg";
import courseIcon from "../Assets/sidebarSVG/dashboard.svg";
import systemIcon from "../Assets/dasboardAssets/systmUserIcon.png";
import txLed from "../Assets/dasboardAssets/txLedIcon.png";
import crpstg1 from "../Assets/listIcon/crpstg1.png";
import crpstg2 from "../Assets/listIcon/crpstg2.png";
import servCancelled from "../Assets/servCancelled.png";
import servCompleted from "../Assets/servCompleted.png";
import servScheduled from "../Assets/servScheduled.png";
import servReqIcon from "../Assets/serviceRequestIcon.png";
import sbook from "../Assets/sidebarSVG/courseBookings.svg";
import cbook from "../Assets/sidebarSVG/courseBookings.svg";
import dbook from "../Assets/sidebarSVG/droneBookings.svg";
import bookings from "../Assets/sidebarSVG/bookings.svg";
import lms from "../Assets/sidebarSVG/lmsSvg.svg";
import lmsCourse from "../Assets/sidebarSVG/lmsCourse.svg";
import ddservices from "../Assets/ddservices.png";
import lmsContent from "../Assets/sidebarSVG/lmsContent.svg";
import content from "../Assets/dasboardAssets/content.png";
import droneInv from "../Assets/sidebarSVG/droneInventory.svg";
import droneMaster from "../Assets/sidebarSVG/droneMaster.svg";
import droneBattery from "../Assets/sidebarSVG/droneBatteries.svg";
import usersMain from "../Assets/sidebarSVG/usersMain.svg";
import ddusers from "../Assets/sidebarSVG/ddUsers.svg";
import sysUser from "../Assets/sidebarSVG/sysUsers.svg";
import pilot from "../Assets/sidebarSVG/pilotSvg.svg";
import totalCourses from "../Assets/sidebarSVG/totalCourses.svg";
import totalBatches from "../Assets/sidebarSVG/totalBatch.svg";
import attendanceIcon from "../Assets/attendanceIcon.png";
import instructorIcon from "../Assets/instructorIcon.png";
import locationIcon from "../Assets/locationIcon.png";
import slotIcon from "../Assets/slotIcon.png";

import userBicon from "../Assets/userBicon.png";
import serviceBicon from "../Assets/serviceBicon.png";
import courseBicon from "../Assets/courseBicon.png";
import droneBicon from "../Assets/droneBicon.png";
import hotelIcon from "../Assets/hotelIcon.png";
import organizationIcon from "../Assets/organizationIcon.png";
import accomodation from "../Assets/accomodation.svg";
import activityReport from "../Assets/activityReport.svg";
import pilotSlot from "../Assets/pilotSlot.svg";
import pilotMap from "../Assets/pilomapside.svg";

import droneUsers from "../Assets/droneUsers.svg";
import droneReviews from "../Assets/droneReviews.svg";
import dronePilotMap from "../Assets/dronePilotMap.svg";
import droneEvents from "../Assets/droneEvents.svg";
import droneBookings from "../Assets/droneBookings.svg";
import droneAddPilot from "../Assets/droneAddPilot.svg";
import droneAccomodation from "../Assets/droneAccomodation.svg";

import whiteServiceRequest from "../Assets/whiteServiceRequests.svg";
import whiteServiceSchedule from "../Assets/whiteScheduledServices.svg";
import whiteServiceCancelled from "../Assets/whiteCancelledServices.svg";
import whiteServiceCompleted from "../Assets/whiteCompleteServices.svg";
import whiteEclipse from "../Assets/whiteEclipse.svg";
// all sidebar keys
import liveTracking from "../Assets/liveTracking.svg";
import droneMarker from "../Assets/droneMarker.png";
import dd_truck from "../Assets/running_van.svg";
import productsIcon from "../Assets/productsIon.svg";
import dealerIcon from "../Assets/DealerIcon.svg";
import pilot_attendance from "../Assets/Pilot_Attendance.svg";

export const showSideData = [
  {
    title: "Pilots",
    id: "pilot",
    icon: liveTracking,
    checked: true,
    style: { width: "20px" },
  },
  {
    title: "Vehicles",
    id: "vehicle",
    icon: dd_truck,
    checked: true,
    style: { width: "20px" },
  },
  //   {
  //     title: "Calendar",
  //     id: "calendar",
  //     icon: CalendarMonthIcon,
  //     checked: true,
  //     style: { width: "20px" },
  //   },
  {
    title: "Drone",
    id: "drone",
    icon: droneMarker,
    checked: true,
    style: { width: "20px" },
  },
];

export const sideNavItems = [
  {
    title: "Dashboard",
    id: 1,
    url: "dashboard",
    icon: dasboardIcon,
    key: 1,
  },
  {
    title: "Calendar",
    id: 2,
    url: "calendar",
    key: 8,
    icon: bookings,
    sub_list: [
      {
        id: 1,
        title: "Events",
        url: "calendar/events",
        icon: droneEvents,
        ifNested: true,
        key: 8,
      },
      {
        id: 1,
        title: "Block Calendar",
        url: "calendar/block-calendar",
        icon: droneEvents,
        ifNested: true,
        key: 8,
      },
      {
        id: 1,
        title: "Accommodation",
        url: "calendar/accommodation",
        icon: droneAccomodation,
        ifNested: true,
        key: 8,
      },
    ],
  },
  {
    key: 2,
    title: "Bookings",
    id: 2,
    url: "bookings",
    icon: droneBookings,
    sub_list: [
      {
        id: 1,
        title: "Service Bookings",
        url: "bookings/service-bookings",
        icon: sbook,
        ifNested: true,
        key: 2,
      },
      {
        id: 4,
        title: "Draft Bookings",
        url: "bookings/draft-bookings",
        icon: cbook,
        ifNested: true,
        key: 2,
      },
      {
        id: 2,
        title: "Drone Hub Bookings",
        url: "bookings/drone-hub-bookings",
        icon: dbook,
        ifNested: true,
        key: 2,
      },
      {
        id: 3,
        title: "Course Bookings",
        url: "bookings/course-bookings",
        icon: cbook,
        ifNested: true,
        key: 4,
      },
    ],
  },
  {
    title: "Pilots",
    key: 3,
    id: 1,
    url: "pilots",
    icon: pilot,
    sub_list: [
      {
        id: 1,
        title: "Pilot",
        url: "pilots/pilotslist",
        icon: pilot,
        ifNested: true,
        key: 3,
      },

      {
        id: 2,
        title: "Pilot Slots",
        url: "pilots/pilotslots",
        icon: droneAddPilot,
        ifNested: true,
        key: 3,
      },
      {
        id: 3,
        title: "Pilot Maps",
        url: "pilots/pilotmaps",
        icon: dronePilotMap,
        ifNested: true,
        key: 3,
      },
      {
        id: 4,
        title: "Pilot Attendance",
        url: "pilots/pilot-attendance",
        icon: pilot_attendance,
        ifNested: true,
        key: 3,
      },
    ],
  },

  {
    title: " Drones & Batteries",
    url: "drones",
    icon: droneIcon,
    sub_list: [
      {
        id: 1,
        title: "Master",
        url: "drones/drone-master",
        icon: droneMaster,
        ifNested: true,
      },
      {
        id: 2,
        title: "Inventory",
        url: "drones/drone-inventory",
        icon: droneInv,
        ifNested: true,
      },
      // {
      //   id: 3,
      //   title: "Drone Batteries",
      //   url: "drone-batteries",
      //   icon: droneBattery,
      //   ifNested: true,
      // },
    ],
  },

  {
    title: "Drone Services",
    key: 9,
    icon: ddservices,
    url: "droneservices",
    sub_list: [
      {
        id: 1,
        title: "Services",
        url: "droneservices/serviceslist",
        icon: ddservices,
        ifNested: true,
        key: 9,
      },

      {
        id: 2,
        title: "Activity",
        url: "droneservices/activity-report",
        icon: activityReport,
        ifNested: true,
        key: 9,
      },
      {
        id: 3,
        title: "Products",
        url: "droneservices/products",
        icon: productsIcon,
        ifNested: true,
        key: 9,
      },
    ],
  },

  {
    title: "Training",
    key: 10,
    icon: lms,
    url: "lms",
    sub_list: [
      {
        id: 1,
        title: "Courses",
        url: "lms/courses",
        icon: lmsCourse,
        ifNested: true,
        key: 10,
      },

      {
        id: 2,
        title: "Slot Master",
        url: "lms/slot-master",
        icon: slotIcon,
        ifNested: true,
        key: 10,
      },
      {
        id: 3,
        title: "Content",
        url: "lms/content",
        icon: lmsContent,
        ifNested: true,
        key: 10,
      },
      {
        id: 4,
        title: "Accommodation",
        url: "lms/hotel",
        icon: accomodation,
        ifNested: true,
        key: 10,
      },
      {
        id: 5,
        title: "Attendance",
        url: "lms/attendance",
        icon: attendanceIcon,
        ifNested: true,
        key: 10,
      },

      {
        id: 6,
        title: "Instructors",
        url: "lms/instructors",
        icon: instructorIcon,
        ifNested: true,
        key: 10,
      },

      {
        id: 7,
        title: "Locations",
        url: "lms/locations",
        icon: locationIcon,
        ifNested: true,
        key: 10,
      },
    ],
  },
  {
    title: "Users",
    icon: droneUsers,
    url: "users",
    sub_list: [
      {
        key: 7,
        id: 1,
        title: "DD Users",
        url: "users/dd-users",
        icon: ddusers,
        ifNested: true,
      },
      {
        key: 7,
        id: 2,
        title: "Dealers",
        url: "users/dealers",
        icon: dealerIcon,
        ifNested: true,
      },
      // {
      //   id: 2,
      //   title: "System Users",
      //   url: "users/system-users",
      //   icon: sysUser,
      //   ifNested: true,
      // },
      // {
      //   id: 3,
      //   title: "Accommodation",
      //   url: "users/hotel",
      //   icon: hotelIcon,
      //   ifNested: true,
      // },
      {
        key: 7,
        id: 3,
        title: "Organization",
        url: "users/organization",
        icon: organizationIcon,
        ifNested: true,
      },
      {
        id: 4,
        title: "Review",
        url: "users/review",
        icon: droneReviews,
        ifNested: true,
        key: 6,
      },
    ],
  },
  {
    key: 5,
    title: "Coupons",
    url: "coupons",
    icon: couponIcon,
  },
  {
    key: 5,
    title: "Banner",
    icon: txLed,
    url: "banner",
  },
];

// service order keys
export const serviceOrderKeys = {
  landDetails: [
    "Plot No.",
    "Pincode",
    "Address",
    "District",
    "Tehsil",
    "Service Type",
    "Service",
    "Crop Type",
    "Crop Stage",
    "Pesticides User",
    "Status",
    "Progress",
  ],
  droneDetails: ["Drone", "License No.", "Insurance Coverage"],
  pilotDetails: ["Pilot", "Pilot License No."],
  otherDetails: ["Amount", "Coupon Code", "Discount", "Final Amount"],
};

// book service initial state
export const bookServiceInitialState = {
  line_1: "",
  line_2: "",
  landmark: "",
  area: "",
  city: "",
  state: "",
  statecode: null,
  pincode: null,
  user_type: 1,
  country: "",
  active: true,
  lat: "",
  long: "",
  // crop_details: "",
  // crop_stage: "",
  // pesticide: "",
  drone_details: "",
  pilot_details: "",
  date: "",
  amount: "",
  coupon_code: "",
  discount: "",
  final_amt: "",
};

export const serviceDummy = [
  {
    id: 1,
    title: "Crop Insurance 1",
  },
  {
    id: 2,
    title: "Crop Insurance 2",
  },
  {
    id: 3,
    title: "Crop Insurance 3",
  },
];

export const serviceTypeDummy = [
  {
    id: 1,
    title: "Precision Agriculture 1",
  },
  {
    id: 2,
    title: "Precision Agriculture 2",
  },
  {
    id: 3,
    title: "Precision Agriculture 3",
  },
];

export const cropTypeDummy = [
  {
    id: 1,
    title: "Crop A",
  },
  {
    id: 2,
    title: "Crop B",
  },
];

export const cropStageListDummy = [
  {
    id: 1,
    icon: crpstg1,
    title: "Seeding",
    sub_topic: "0 to 30 days",
  },
  {
    id: 2,
    icon: crpstg2,
    title: "Vegetative",
    sub_topic: "0 to 30 days",
  },
];

export const pesticideListDummy = [
  {
    id: 1,
    title: "Pesticide A",
  },
  {
    id: 2,
    title: "Pesticide B",
  },
  {
    id: 3,
    title: "Pesticide C",
  },
];

export const droneDummy = [
  {
    id: 1,
    title: "Drone A",
    sub_topic: "B1233I7p | Training",
  },
  {
    id: 2,
    title: "Drone B",
    sub_topic: "B1233I7p | Training",
  },
];

export const pilotDummy = [
  {
    id: 1,
    title: "Pilot A",
    sub_topic: "BH1234",
  },
  {
    id: 2,
    title: "Pilot B",
    sub_topic: "BH1234",
  },
];

export const serviceStatus = [
  {
    id: 1,
    title: "Scheduled",
  },
  {
    id: 2,
    title: "Not Scheduled",
  },
];

// quatation Json Structure

const quotation = {
  id: "",
  user_id: "id",
  address_id: "id",
  date: "Date",
  services: [
    {
      id: "Service Id",
      rate: "",
      qty: "",
      discount_type: "1", // percentage
      discount: "20",
      total: "",
    },
    {
      id: "Service Id",
      rate: "",
      qty: "",
      discount_type: "1", // percentage
      discount: "20",
      total: "",
    },
  ],
  sub_total: "",
  cgst: "",
  sgst: "",
  igst: "", // shall be null if cgst and sgst is applicable
  final_total: "",
};

export const discountType = [
  {
    id: 1,
    title: "Percentage",
  },
  {
    id: 2,
    title: "Amount",
  },
];

// service orders cards constants

export const serviceOrderCard = [
  {
    title: "New Services",
    // primaryGradient:
    //   "linear-gradient(284.09deg, #D6C366 -0.73%, rgba(253, 250, 178, 0.77) 100%)",
    // secondarayGradient:
    //   "linear-gradient(280.13deg, #D2BF62 7.47%, rgba(210, 191, 98, 0.42) 100%)",
    primaryGradient:
      "linear-gradient(to left, rgba(81, 79, 255, 1), rgba(87, 194, 255, 1))",
    cardValue: "",
    // icon: servReqIcon,
    icon: whiteServiceRequest,
    hint: "new_request",
    acres_hint: "new_request_area_in_acres",
    amount_hint: "new_request_total_amount",
    eclipseIcon: whiteEclipse,
  },
  {
    title: "Scheduled Services",
    // primaryGradient:
    //   "linear-gradient(282.93deg, #127E8E 4.38%, rgba(228, 249, 252, 0.88) 100%)",
    // secondarayGradient:
    //   "linear-gradient(276.7deg, #097584 3.63%, rgba(58, 183, 201, 0.62) 98.95%)",
    primaryGradient:
      "linear-gradient(to left, rgba(246, 133, 100, 1), rgba(245, 199, 99, 1))",
    cardValue: "",
    // icon: servScheduled,
    icon: whiteServiceSchedule,

    hint: "scheduled",
    acres_hint: "scheduled_area_in_acres",
    amount_hint: "scheduled_total_amount",
    eclipseIcon: whiteEclipse,
  },

  {
    title: "Completed Services",
    // primaryGradient:
    //   "linear-gradient(283.07deg, #5F3598 3.71%, #E4D5F8 103.03%)",
    // secondarayGradient:
    //   "linear-gradient(279.83deg, #582F8F 7.98%, rgba(195, 161, 239, 0.91) 96.6%)",
    primaryGradient:
      "linear-gradient(to right, rgba(87, 182, 122, 1), rgba(11, 119, 84, 1))",
    cardValue: "",
    // icon: servCompleted,
    icon: whiteServiceCompleted,

    hint: "completed",
    acres_hint: "completed_area_in_acres",
    amount_hint: "completed_total_amount",
    eclipseIcon: whiteEclipse,
  },
  {
    title: "Cancelled Services",
    // primaryGradient:
    //   "linear-gradient(283.04deg, #C35C4E 2.71%, rgba(252, 185, 176, 0.53) 100.4%)",
    // secondarayGradient:
    //   "linear-gradient(279.39deg, #B75042 2.66%, rgba(242, 175, 165, 0) 97.33%)",
    primaryGradient:
      "linear-gradient(to left, rgba(132, 98, 251, 1), rgba(235, 137, 235, 1))",
    cardValue: "",
    // icon: servCancelled,
    icon: whiteServiceCancelled,

    hint: "cancelled",
    acres_hint: "cancelled_area_in_acres",
    amount_hint: "cancelled_total_amount",
    eclipseIcon: whiteEclipse,
  },
];

//banner info

export const bannerDetails = [
  {
    id: "1",
    title: "Total New Users",
    // primaryGradient:
    //   "linear-gradient(284.09deg, #D6C366 -0.73%, rgba(253, 250, 178, 0.77) 100%)",
    // secondarayGradient:
    //   "linear-gradient(280.13deg, #D2BF62 7.47%, rgba(210, 191, 98, 0.42) 100%)",
    primaryGradient:
      "linear-gradient(to left, rgba(81, 79, 255, 1), rgba(87, 194, 255, 1))",
    cardValue: "",
    icon: userBicon,
    hint: "users",
    eclipseIcon: whiteEclipse,
  },
  {
    id: "2",
    title: "Total Service Revenue",
    // primaryGradient:
    //   "linear-gradient(282.93deg, #127E8E 4.38%, rgba(228, 249, 252, 0.88) 100%)",
    // secondarayGradient:
    //   "linear-gradient(276.7deg, #097584 3.63%, rgba(58, 183, 201, 0.62) 98.95%)",
    primaryGradient:
      "linear-gradient(to left, rgba(246, 133, 100, 1), rgba(245, 199, 99, 1))",
    cardValue: "",
    icon: serviceBicon,
    hint: "service_booking",
    eclipseIcon: whiteEclipse,
  },
  {
    id: "3",
    title: "Total Training Revenue",
    // primaryGradient:
    //   "linear-gradient(283.04deg, #C35C4E 2.71%, rgba(252, 185, 176, 0.53) 100.4%)",
    // secondarayGradient:
    //   "linear-gradient(279.39deg, #B75042 2.66%, rgba(242, 175, 165, 0) 97.33%)",
    primaryGradient:
      "linear-gradient(to left, rgba(132, 98, 251, 1), rgba(235, 137, 235, 1))",
    cardValue: "",
    icon: courseBicon,
    hint: "course_booking",
    eclipseIcon: whiteEclipse,
  },
  // {
  //   id: "4",
  //   title: "Total Drone Shop Revenue",
  //   // primaryGradient:
  //   //   "linear-gradient(283.07deg, #5F3598 3.71%, #E4D5F8 103.03%)",
  //   // secondarayGradient:
  //   //   "linear-gradient(279.83deg, #582F8F 7.98%, rgba(195, 161, 239, 0.91) 96.6%)",
  //   primaryGradient:
  //     "linear-gradient(to right, rgba(87, 182, 122, 1), rgba(11, 119, 84, 1))",
  //   cardValue: "",
  //   icon: droneBicon,
  //   hint: "completed",
  // },
];

export const courseCardData = [
  {
    id: 1,
    title: "Total Courses",
    primaryGradient:
      "linear-gradient(to right, rgba(160, 181, 224, 0.77),rgba(124, 147, 195, 1))",
    secondarayGradient:
      "linear-gradient(to right, rgba(124, 147, 195, 0.42),rgba(88, 116, 174, 1))",
    cardValue: "07",
    icon: totalCourses,
    value: null,
    hint: "courses",
  },
  {
    id: 2,
    title: "Total Batches",
    primaryGradient:
      "linear-gradient( to right , rgba(167, 215, 172, 0.88),rgba(65, 171, 75, 1))",
    secondarayGradient:
      "linear-gradient(to right, rgba(89, 155, 95, 0.47),rgba(65, 141, 72, 1))",
    cardValue: "07",
    icon: totalBatches,
    value: null,
    hint: "batches",
  },
];

export const attendanceCardData = [
  {
    id: 1,
    title: "Total Courses",
    primaryGradient:
      "linear-gradient(to right, rgba(160, 181, 224, 0.77),rgba(124, 147, 195, 1))",
    secondarayGradient:
      "linear-gradient(to right, rgba(124, 147, 195, 0.42),rgba(88, 116, 174, 1))",
    cardValue: "07",
    icon: totalCourses,
    value: null,
    hint: "courses",
  },
  {
    id: 2,
    title: "Total Batches",
    primaryGradient:
      "linear-gradient( to right , rgba(167, 215, 172, 0.88),rgba(65, 171, 75, 1))",
    secondarayGradient:
      "linear-gradient(to right, rgba(89, 155, 95, 0.47),rgba(65, 141, 72, 1))",
    cardValue: "07",
    icon: totalBatches,
    value: null,
    hint: "batches",
  },
];
