import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

const CustomSlider = ({ min, max, step, defaultValue, onChange, primaryColor, label=""}) => {
  const marks = [
    {
      value: min,
      label: '',
    },
    {
      value: max,
      label: '',
    },
  ];

  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Box sx={{ width: 250 }}>
      <Slider
        marks={marks}
        step={step}
        value={value}
        valueLabelDisplay="auto"
        min={min}
        max={max}
        onChange={handleChange}
        sx={{
          '& .MuiSlider-thumb': {
            backgroundColor: primaryColor,
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: primaryColor,
          },
          '& .MuiSlider-markLabel': {
            color: primaryColor,
          },
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="body2"
          onClick={() => setValue(min)}
          sx={{ cursor: 'pointer' }}
        >
          {min}  {label }
        </Typography>
        <Typography
          variant="body2"
          onClick={() => setValue(max)}
          sx={{ cursor: 'pointer' }}
        >
          {max} {label}
        </Typography>
      </Box>
    </Box>
  );
};
export default CustomSlider;