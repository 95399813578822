import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomDate from "../../Component/CustomDate/CustomDate";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import CustomTable from "../../Component/CustomTable/CustomTable";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import InputFields from "../../Component/InputFields/InputFields";
import { token_api, base_url } from "../../Utils/network";
import styles from "../DroneMaster/drone.module.css";
import { formatDroneInventoryList, Validate } from "./utility";
import CustomTableForDrones from "../../Component/CustomTableForDrones/CustomTableForDrones";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";
import DronePilot from "../../Component/DronePilot/DronePilot";
import CustomModal from "../../Component/CustomModal/CustomModal";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import dayjs from "dayjs";
import { formatDate } from "../../Utils/helper";
import blackArrow from "../../Assets/breadCrumbBack.svg";
import AddressBlock from "../../Component/AddressBlock/AddressBlock";
import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import CustomButton from "../../Component/CustomButton/CustomButton";
import axios from "axios";

function Droneinventorylist() {
  const [droneInventoryList, setDroneInventoryList] = useState([]);
  const [typedValue, setTypedValue] = useState("");
  const [filters, setfilters] = useState({});
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [open, setOpen] = useState(false);
  const [locationListDraw, setLocationListDraw] = useState([]);
  const [openDailog, setOpenDailog] = useState(false);
  const [droneDetail, setDronDetails] = useState({
    id: null,
    drone_id: [],
    drone_name: "",
    stationed_at_id: [],
    registration_date: null,
    serial_no: "",
    status: [],
  });
  const [assignPilot, setAssignPilot] = useState({
    drone_inventory_id: null,
    pilot_id: null,
    assigned_from: null,
    assigned_to: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [droneList, setDroneList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [accForm, setaccForm] = useState({});
  const [pilotType, setPilotType] = useState("");
  const [pilotList, setpilotListing] = useState([]);
  const [pilotSelected, setPilotSelected] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [droneId, setDroneId] = useState(null);
  const [categoryList, setCategoryList] = useState([]);

  const [assignInventoryObj, setAssignInventoryObj] = useState({});
  const [droneAssignPilotHistory, setDroneAssignPilotHistory] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState([]);
  const [droneAssetAssign, setDroneAssetAssign] = useState({
    id: null,
    assigned_from: null,
    assigned_to: null,
    pilot: [],
  });
  const [assetAssignOpen, setAssetAssignOpen] = useState(false);

  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const handleAssignPilot = () => {
    let data = {
      drone_inventory_id: assignPilot?.drone_inventory_id,
      pilot_id: pilotSelected[0]?.id,
      assigned_from: formatDate(assignPilot?.assigned_from),
      assigned_to: formatDate(assignPilot?.assigned_to),
    };

    token_api
      .post(`drone/pilot/drone_assign/`, data)
      .then((res) => {
        console.log(res);

        if (res?.status === 400) {
          console.log(res?.data, "hariResssss");
          alert(res?.data?.error?.fields[0]?.message[0]);
          setOpenDailog(false);
          // getDroneInventoryListing();
          setAssignPilot({
            drone_inventory_id: null,
            pilot_id: null,
            assigned_from: null,
            assigned_to: null,
          });
          setPilotSelected([]);
        }
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          alert("Pilot Assigned Successfully!");
          setOpenDailog(false);
          // getDroneInventoryListing();
          setAssignPilot({
            drone_inventory_id: null,
            pilot_id: null,
            assigned_from: null,
            assigned_to: null,
          });
          setPilotSelected([]);
          if (assignInventoryObj?.id) {
            setPageState(1);
            getInventoryAssignedList();
          } else {
            setPageState(1);
            getDroneInventoryListing();
          }
        }
      })
      .catch((err) => console.log(err, "priyaError"));
  };

  const handleAutoCompleteChangeForBlock = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Block already included");
    } else {
      setSelectedBlock(selectedValues);
    }
  };

  const handleEditDrone = (editObject) => {
    console.log(editObject, "HHHHH");
    const {
      stationed_at,
      drone,
      pilot,
      id,
      registration_date,
      serial_no,
      status,
      block,
      drone_name,
      ...rest
    } = editObject;

    setDronDetails({
      id,
      drone_name,
      registration_date: dayjs(registration_date),
      serial_no,
      stationed_at_id: [stationed_at]?.map((info) => ({
        title: info?.city,
        id: info?.id,
      })),
      drone_id: [drone],

      status: statusOptions?.filter((info) => info?.id === status),
    });
    setOpen(true);
    setFormErrors({});
    setPilotSelected([pilot]);
    // setSelectedBlock([block]);

    console.log(stationed_at, "stationed_at");
  };

  // const getLocationList = (value) => {
  //   let apiEndpoint = "/course/city";

  //   if (value) {
  //     apiEndpoint += `?q=${value}`;
  //   }
  //   token_api
  //     .get(apiEndpoint)
  //     .then((res) => {
  //       if (res.data.data) {
  //         console.log(res.data.data, "newresdata");
  //         setLocationList(res?.data?.data);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  const getLocationList = (value) => {
    let apiEndpoint = "/course/location?page_size=100";

    if (value) {
      apiEndpoint += `&q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data.data, "newresdata");
          let userloc = res.data.data?.map((info) => ({
            id: info?.id,
            city_new_id: info?.city_new_id,
            title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
          }));
          setLocationList(userloc);
        }
      })
      .catch((err) => console.log(err));
  };

  console.log(locationList, "hariLOcationListttt");
  const handleText = (e, data, auto) => {
    // console.log(e, data, auto, "select");
    const { name, value } = e.target;
    setDronDetails({ ...droneDetail, [name]: value });
  };

  const handleChange = (e, type, selectName, multiple) => {
    console.log("TYTYY");
    if (type === "select" && selectName && multiple) {
      setDronDetails({
        ...droneDetail,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setDronDetails({
        ...droneDetail,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setDronDetails({
        ...droneDetail,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };
  console.log(droneDetail, "dronedetails");

  const handleDeleteChip = (id, selectName) => {
    console.log("DELETE", id, selectName, droneDetail?.[selectName]);
    setDronDetails({
      ...droneDetail,
      [selectName]: droneDetail?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
    let namefiled = droneDetail[selectName];
    console.log(namefiled[0]?.title, "slehdgfdf");
  };

  const handleDeleteDrone = () => {
    token_api
      .delete(`/drone/inventory/${droneId}/`)
      .then((response) => {
        if (
          response?.status == 200 ||
          response?.status == 201 ||
          response?.status == 202 ||
          response?.status == 204
        ) {
          alert("Drone Inventory Successfully Deleted!");
          getDroneInventoryListing();
          setIsModalOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDroneId(null);
  };

  const handleCloseModal1 = () => {
    setOpenDailog(false);
    setAssignPilot({
      drone_inventory_id: null,
      pilot_id: null,
      assigned_from: null,
      assigned_to: null,
    });
  };

  const handleOpenDeleteModal = (ItemId) => {
    console.log(ItemId, "hariItemId");
    setDroneId(ItemId);
    setIsModalOpen(true);
  };

  const handleChangeacc = (e, type, selectName, multiple) => {
    console.log("TYTYY");
    if (type === "select" && selectName && multiple) {
      setaccForm({
        ...accForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setaccForm({
        ...accForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setaccForm({
        ...accForm,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleDeleteChipacc = (id, selectName) => {
    console.log("DELETE", id, selectName, accForm?.[selectName]);
    setaccForm({
      ...accForm,
      [selectName]: accForm?.[selectName]?.filter((chip) => chip?.id !== id),
    });
    let namefiled = accForm[selectName];
    console.log(namefiled[0]?.title, "slehdgfdf");
  };

  const handleAddEvent = () => {
    console.log(droneDetail, "droneDetaildroneDetaildroneDetail");
    const {
      drone_id,
      stationed_at_id,
      id,
      status,
      registration_date,
      ...rest
    } = droneDetail;

    let data = {
      ...rest,

      registration_date: formatDate(registration_date),
      drone_id: drone_id[0]?.id,
      stationed_at_id: stationed_at_id[0]?.id,
      status: status[0]?.id,
      // pilot_id: null,
      // ...(pilotSelected.length > 0 && {
      //   pilot_id: pilotSelected[0]?.id,
      // }),
    };

    Validate(droneDetail)
      .then((response) => {
        if (response == "success") {
          if (id) {
            token_api
              .patch(`/drone/inventory/${id}/`, data)
              .then((res) => {
                console.log(res);
                if (
                  res.status == 200 ||
                  res.status == 201 ||
                  res.status == 204
                ) {
                  getDroneInventoryListing();
                  setDronDetails({
                    id: null,
                    drone_name: "",
                    drone_id: [],
                    stationed_at_id: [],
                    registration_date: null,
                    serial_no: "",
                    status: [],
                  });
                  setPilotSelected([]);
                  // setSelectedBlock([]);

                  setOpen(false);
                }
              })
              .catch((err) => console.log(err));
          } else {
            token_api
              .post(`/drone/inventory/`, data)
              .then((res) => {
                console.log(res);
                if (res.status == 200 || res.status == 201) {
                  getDroneInventoryListing();
                  setDronDetails({
                    id: null,
                    drone_id: [],
                    drone_name: "",

                    stationed_at_id: [],
                    registration_date: null,
                    serial_no: "",
                    status: [],
                  });
                  setPilotSelected([]);
                  setOpen(false);
                  // setSelectedBlock([]);
                }
              })
              .catch((err) => console.log(err));
          }
        }
      })
      .catch((error) => {
        setFormErrors(error);
      });
  };
  const handleOpenAssignPilot = (inventoryId) => {
    console.log(inventoryId, "hariInventoryIddd");
    setOpenDailog(true);
    setAssignPilot({
      ...assignPilot,
      drone_inventory_id: inventoryId,
      assigned_from: null,
      assigned_to: null,
    });
    setPilotSelected([]);
  };

  const handleToShowAssignList = (inventoryobj) => {
    setAssignInventoryObj(inventoryobj);
    setPageState(1);
  };
  const getDroneInventoryListing = async (filterobject) => {
    try {
      const fetchDroneInventory = await token_api.get(`drone/inventory`, {
        params: { ...filterobject },
        paramsSerializer: {
          indexes: null,
        },
      });
      if (fetchDroneInventory.status !== 200) {
        throw new Error("Error in fetching");
      }
      setPaginationData(fetchDroneInventory?.data);
      const modifiedData = formatDroneInventoryList(
        fetchDroneInventory?.data?.data,
        handleEditDrone,
        handleOpenDeleteModal,
        handleToShowAssignList,
        handleOpenAssignPilot
      );

      setDroneInventoryList(modifiedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = () => {
    setDronDetails({
      id: null,
      drone_id: [],
      stationed_at_id: [],
      registration_date: null,
      serial_no: "",
      status: [],
    });
    setPilotSelected([]);
    setFormErrors({});
    setOpen(true);
    setSelectedBlock([]);
  };

  const handleData = (e) => {
    console.log(e);
  };
  useEffect(() => {
    const timeOut = setTimeout(() => {
      let filtersObject = {};

      if (pageState) {
        filtersObject.page = pageState;
      }

      if (filters?.q) {
        filtersObject.q = filters?.q;
      }
      if (accForm?.location) {
        const ids = accForm?.location?.map((info) => info?.id);
        console.log(ids, "useriddxfj");
        filtersObject.location = ids;
      }

      if (accForm?.status) {
        const ids = accForm?.status?.map((info) => info?.id);
        filtersObject.status = ids;
      }

      if (accForm?.drone) {
        const ids = accForm?.drone?.map((info) => info?.slug);
        filtersObject.drone = ids;
      }
      if (accForm?.category) {
        const ids = accForm?.category?.map((info) => info?.id);
        filtersObject.category = ids;
      }

      getDroneInventoryListing(filtersObject);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [
    filters?.q,
    accForm?.location,
    accForm?.status,
    accForm?.drone,
    pageState,
    accForm?.category,
  ]);

  console.log(accForm, "hariAccfOrm");

  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getLocationList(typedValue);
      }, 500);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);

  console.log(pilotSelected, "selectlocation");

  const handleChangeforSearch = (event, newValue) => {
    setTypedValue(newValue);
    setaccForm((prev) => ({
      ...prev,
      location: [newValue],
    }));
  };

  const getLocationListforDrawer = () => {
    token_api
      .get(`course/location?page_size=100`)
      .then((res) => {
        if (res.data.data) {
          console.log(res?.data?.data, "responsedrtaa");
          let newres = res?.data?.data.map((info) => ({
            title: info?.city,
            id: info?.id,
          }));

          setLocationListDraw(newres);
        }
      })
      .catch((err) => console.log(err));
  };

  const getDroneList = () => {
    token_api
      .get(`drone`)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data?.data, "responsex");

          setDroneList(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  function getPilotList() {
    token_api
      .get(`users/profile/pilot`)
      .then((response) => {
        console.log(response.data.data, "hariResponseee");
        const responsedata = response?.data?.data?.map((info) => ({
          id: info?.id,
          title: `${info?.first_name ?? ""} ${info?.last_name ?? ""}`,
        }));
        setpilotListing(responsedata);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getCategoryList = () => {
    token_api
      .get(`drone/category`)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data?.data, "responsex");

          setCategoryList(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDroneList();
    getCategoryList();
    getLocationListforDrawer();
    getPilotList();
  }, []);

  console.log(pilotList, "pilotList");
  const handleAutocompleteChangeforPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setPilotSelected(selectedValues);
    }
  };

  console.log(locationList, "accfrom");

  const getInventoryAssignedList = async (filterobject) => {
    try {
      const fethLaptopInventoryAssignedList = await token_api.get(
        `drone/pilot/drone_assign?drone_inventory=${assignInventoryObj?.id}`,
        {
          params: { ...filterobject },
        }
      );

      if (fethLaptopInventoryAssignedList.status !== 200) {
        throw new Error("Error in fetching");
      }
      setPaginationData(fethLaptopInventoryAssignedList?.data);
      let modifiedArray = fethLaptopInventoryAssignedList?.data?.data?.map(
        (item) => {
          return {
            assigned_to: `${item?.pilot?.user?.first_name} ${item?.pilot?.user?.last_name}`,
            assigned_from: item?.assigned_from || "N.A",
            assigned_till: item?.assigned_to || "N.A",
            handover_accepted_on: "N.A",
            handover_accepted_by: "N.A",
            updated_on: "N.A",
            modified_by: item?.modified_by || "N.A",
            assign_by: item?.assign_by || "N.A",
            created_on: item?.created_on || "N.A",
            // assign: (
            //   <Button
            //     variant="contained"
            //     style={{ cursor: "pointer" }}
            //     onClick={() => {
            //       handleOpenAssignPilot(assignInventoryObj?.id);
            //     }}
            //   >
            //     Assign
            //   </Button>
            // ),
            actions: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEditDroneAssetAssign(item)}
                >
                  <img src={editIcon} />
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteDroneAssetAssign(item?.id)}
                >
                  <img src={deleteIcon} />
                </span>
              </div>
            ),
          };
        }
      );
      setDroneAssignPilotHistory([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditDroneAssetAssign = (item) => {
    setDroneAssetAssign({
      ...droneAssetAssign,
      id: item?.id,
      assigned_from: dayjs(item?.assigned_from),
      assigned_to: dayjs(item?.assigned_to),
      pilot: [item?.pilot],
    });
    setAssetAssignOpen(true);
  };

  const handleDeleteDroneAssetAssign = (assignId) => {
    token_api
      .delete(`drone/pilot/drone_assign/${assignId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Drone Inventory Assign Deleted Successfully!");
          setAssetAssignOpen(false);
          getInventoryAssignedList();
        }
      });
  };

  useEffect(() => {
    if (assignInventoryObj?.id) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};
        // if (assignInventoryObj?.id) {
        //   filtersObject.drone_inventory = assignInventoryObj?.id;
        // }
        if (pageState) {
          filtersObject.page = pageState;
        }

        getInventoryAssignedList(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [pageState, assignInventoryObj?.id]);

  console.log(
    assignInventoryObj,
    droneAssignPilotHistory,
    "hariLaptopAssignHistory"
  );

  const handleHideAssignHistory = () => {
    setAssignInventoryObj({});
    getDroneInventoryListing();
  };

  const handleUpdateDroneAssetAssign = () => {
    let data = {
      pilot_id: droneAssetAssign?.pilot[0]?.id,
      assigned_from: formatDate(droneAssetAssign?.assigned_from),
      assigned_to: formatDate(droneAssetAssign?.assigned_to),
    };
    token_api
      .patch(`drone/pilot/drone_assign/${droneAssetAssign?.id}/`, data)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Drone Asset Assign Details updated");
          setDroneAssetAssign({
            id: null,
            assigned_from: null,
            assigned_to: null,
            pilot: [],
          });
          setAssetAssignOpen(false);
          getInventoryAssignedList();
        }
      });
  };

  const handleAutocompleteChangeforDronePilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setDroneAssetAssign({
        ...droneAssetAssign,
        ["pilot"]: selectedValues,
      });
    }
  };

  //Export Logic....

  const handleExportData = async () => {
    try {
      const response = await axios.get(
        `${base_url}drone/export/drone_inventory`
      );
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };
  return (
    <div>
      {!assignInventoryObj?.id ? (
        <div style={{ marginTop: "20px" }}>
          <FilterContainer
            style={{ maxHeight: "40px", marginTop: "20px" }}
            // className={`${styles.marginTopBox}`}
          >
            <CustomSearchFilter
              placeholder="Search by drone inventory name, serial no, category..."
              onChange={(e) => {
                setPageState(1);
                setfilters({ ...filters, q: e });
              }}
            />
            <CustomSelectChip
              multiple={false}
              label="Filter By Drone"
              name="drone"
              listArray={droneList}
              onChange={handleChangeacc}
              onDelete={handleDeleteChipacc}
              value={accForm?.drone}
            />
            <CustomSelectChip
              multiple={false}
              label="Filter By Category"
              name="category"
              listArray={categoryList}
              onChange={handleChangeacc}
              onDelete={handleDeleteChipacc}
              value={accForm?.category}
            />

            <SearchAndDropdown
              label="Filter by Location"
              value={accForm?.location}
              onChange={handleChangeforSearch}
              listing={locationList}
              typedValue={typedValue}
              setTypedValue={setTypedValue}
            />

            <CustomSelectChip
              multiple={false}
              label="Filter By Status"
              name="status"
              onChange={handleChangeacc}
              onDelete={handleDeleteChipacc}
              value={accForm?.status}
              listArray={statusOptions}
            />
            <CustomButton
              text={"Export"}
              maxWidth="110px"
              onClick={handleExportData}
            />

            <Button
              variant="contained"
              onClick={() => handleAdd()}
              sx={{ height: "53px" }}
            >
              Add
            </Button>
          </FilterContainer>

          <div className={styles.tableContainer}>
            <CustomTableForDrones
              columns={tableColumns}
              data={droneInventoryList}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <img
                src={blackArrow}
                onClick={handleHideAssignHistory}
                style={{ cursor: "pointer" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.id}</h4>
                <p>Drone Inventory ID</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.drone_name || "N.A"}</h4>
                <p>Drone Name</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.pdrl_id || "N.A"}</h4>
                <p>PDRL ID</p>
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpenAssignPilot(assignInventoryObj?.id);
                }}
              >
                Assign
              </Button>
            </div>
          </div>

          <div className={styles.tableContainer}>
            <CustomTable
              columns={assignPilotColumns}
              data={droneAssignPilotHistory}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      )}

      <DrawerComp open={open} onClose={() => setOpen(false)} anchor="right">
        <DrawerContainer>
          <DrawerTitle
            text={
              droneDetail?.id ? "Update Drone Inventory" : "Add Drone Inventory"
            }
            handleCancelDrw={() => setOpen((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <CustomSelectChip
              multiple={false}
              label="Select drone"
              name="drone_id"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={droneDetail?.drone_id}
              listArray={droneList}
              error={formErrors?.drone_id}
            />
            <InputFields
              label="Drone Name"
              name="drone_name"
              value={droneDetail?.drone_name || ""}
              onChange={handleText}
              error={formErrors?.drone_name}
              helperText={formErrors?.drone_name}
            />
            <InputFields
              label="Serial Number"
              name="serial_no"
              value={droneDetail?.serial_no || ""}
              onChange={handleText}
              error={formErrors?.serial_no}
              helperText={formErrors?.serial_no}
            />

            <CustomSelectChip
              multiple={false}
              label="Location"
              name="stationed_at_id"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={droneDetail?.stationed_at_id}
              listArray={locationListDraw}
              // error={formErrors?.stationed_at_id}
            />

            {/* <div style={{ marginTop: "10px" }}>
              <AddressBlock
                value={selectedBlock}
                onChange={handleAutoCompleteChangeForBlock}
              />
              {formErrors?.selectedBlock && (
                <p style={{ color: "red" }}>{formErrors?.selectedBlock}</p>
              )}
            </div> */}

            {/* <SearchAndDropdown
          label="Filter by Location"
          value={accForm?.location}
          onChange={handleChangeforSearch}
          listing={locationList}
          typedValue={typedValue}
          setTypedValue={setTypedValue}
        /> */}

            {/* <CustomDate
              name="registration_date"
              label="Registered On"
              handleChange={handleText}
              // handleChange={(e) => handleText(e, "registration_date")}
              value={droneDetail?.registration_date}
              error={formErrors?.registration_date}
            /> */}
            <CustomDatePicker
              label="Registered On"
              onChange={(newValue) => {
                setDronDetails({
                  ...droneDetail,
                  registration_date: newValue,
                });
              }}
              value={droneDetail?.registration_date}
              error={formErrors?.registration_date}
            />

            <CustomSelectChip
              multiple={false}
              label="Drone Status"
              name="status"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={droneDetail?.status}
              listArray={statusOptions}
              error={formErrors?.status}
            />

            {/* <CustomSelectChip
              multiple={false}
              label="Select Pilot"
              name="pilot_id"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={droneDetail?.pilot_id}
              listArray={pilotList}
              error={formErrors?.pilot_id}
            /> */}

            {/* <DronePilot
              value={pilotSelected}
              onChange={handleAutocompleteChangeforPilot}
            />
            {formErrors?.pilotSelected && (
              <p style={{ color: "red" }}>{formErrors?.pilotSelected}</p>
            )} */}

            <div>
              <Button variant="contained" onClick={handleAddEvent}>
                {droneDetail?.id ? "Update" : "Add"}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>

      <DrawerComp
        open={assetAssignOpen}
        onClose={() => setAssetAssignOpen(false)}
        anchor="right"
      >
        <DrawerContainer>
          <DrawerTitle
            text={"Update Pilot Assign Details"}
            handleCancelDrw={() => setAssetAssignOpen((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <DronePilot
              value={droneAssetAssign?.pilot}
              onChange={handleAutocompleteChangeforDronePilot}
            />

            <CustomDatePicker
              label="Assigned From"
              onChange={(newValue) => {
                setDroneAssetAssign({
                  ...droneAssetAssign,
                  assigned_from: newValue,
                });
              }}
              value={droneAssetAssign?.assigned_from}
            />

            <CustomDatePicker
              label="Assigned Till"
              onChange={(newValue) => {
                setDroneAssetAssign({
                  ...droneAssetAssign,
                  assigned_to: newValue,
                });
              }}
              value={droneAssetAssign?.assigned_to}
            />

            <div>
              <Button
                variant="contained"
                onClick={handleUpdateDroneAssetAssign}
              >
                {"Update"}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>

      <CustomModal open={isModalOpen} onClose={handleCloseModal}>
        <div className={styles.deleteConfirmCon}>
          <p className={styles.reallyText}>
            Do you really want to delete this drone inventory?
          </p>
          <div className={styles.bottomCon}>
            <h1 className={styles.yesText} onClick={handleDeleteDrone}>
              Yes
            </h1>
            <h1 className={styles.noText} onClick={handleCloseModal}>
              No
            </h1>
          </div>
        </div>
      </CustomModal>
      <CustomModal open={openDailog} onClose={handleCloseModal1}>
        <div>
          <h3 style={{ marginBottom: "10px" }}>Assigned Pilot</h3>
          <div style={{ marginTop: "10px" }}>
            <CustomDatePicker
              label="Assigned From"
              value={assignPilot?.assigned_from}
              onChange={(newValue) =>
                setAssignPilot({
                  ...assignPilot,
                  assigned_from: newValue,
                })
              }
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <CustomDatePicker
              label="Assigned Till"
              value={assignPilot?.assigned_to}
              onChange={(newValue) =>
                setAssignPilot({
                  ...assignPilot,
                  assigned_to: newValue,
                })
              }
            />
          </div>{" "}
          <div style={{ margin: "10px 0px" }}>
            <DronePilot
              value={pilotSelected}
              onChange={handleAutocompleteChangeforPilot}
            />
          </div>
          <Button variant="contained" onClick={handleAssignPilot}>
            Submit
          </Button>
        </div>
      </CustomModal>
    </div>
  );
}

export default Droneinventorylist;
const tableColumns = [
  {
    id: "id",
    label: "Drone Inventory ID",
  },
  {
    id: "drone_name",
    label: "Drone Name",
  },
  {
    id: "serial_no",
    label: "Serial No.",
  },
  {
    id: "pilot_name",
    label: "Pilot Name",
  },
  {
    id: "pilot_no",
    label: "Pilot No.",
  },
  {
    id: "location",
    label: "Current Location (Block)",
  },
  {
    id: "stationed_at",
    label: "Stationed At",
  },
  {
    id: "area_covered",
    label: "Area Covered in acres (last 24 hours)",
  },
  {
    id: "time_covered",
    label: "Flight Time(last 24 hours)",
  },

  {
    id: "drone_status",
    label: "Status",
  },
  {
    id: "registered_on",
    label: "Registered On",
  },
  {
    id: "drone",
    label: "Drone",
  },

  {
    id: "drone_category",
    label: "Category",
  },

  {
    id: "updated_on",
    label: "Updated On",
  },
  {
    id: "assign",
    label: "Assign",
  },
  {
    id: "updated_by",
    label: "Updated By",
  },
  // {
  //   id: "id",
  //   label: "ID",
  // },
  // {
  //   id: "pdrl_id",
  //   label: "PDRL ID",
  // },

  // {
  //   id: "is_available",
  //   label: "Available For Sale",
  // },
  {
    id: "actions",
    label: "Actions",
  },
];

const statusOptions = [
  { title: "Working", id: 1 },
  { title: "Sold", id: 2 },
  { title: "Damaged", id: 3 },
  { title: "UnderRepair", id: 4 },
];

const assignPilotColumns = [
  {
    id: "assigned_to",
    label: "Assigned To",
  },
  {
    id: "assigned_from",
    label: "Assigned From",
  },
  {
    id: "assigned_till",
    label: "Assigned Till",
  },
  {
    id: "handover_accepted_on",
    label: "Handover Accepted On",
  },
  {
    id: "handover_accepted_by",
    label: "Handover Accepted By",
  },
  {
    id: "updated_on",
    label: "Updated On",
  },
  {
    id: "modified_by",
    label: "Updated By",
  },
  {
    id: "assign_by",
    label: "Created By",
  },

  {
    id: "created_on",
    label: "Created On",
  },
  // {
  //   id: "assign",
  //   label: "Assign Pilot",
  // },
  {
    id: "actions",
    label: "Actions",
  },
];
