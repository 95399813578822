import React, { useEffect,useState } from "react";
import CustomTable from "../CustomTable/CustomTable";
import { token_api } from "../../Utils/network";
import { extractDateTime, getTooltipComponent } from "../../Utils/helper";
import { helperdayMonthYearFull } from "../../Utils/Utils";
import { Link, useNavigate } from "react-router-dom";
import styles from "./ServiceTabforDashboard.module.css"

const ServiceTableforDashboard = () => {
    const navigate = useNavigate();
  const [bookingList, setBookingList] = useState([]);
  const [paginationData, setPaginationData] = useState({});

  const getAllServiceBookings = () => {
    token_api
      .get(`service/booking`)
      .then((response) => {
        setPaginationData(response?.data);
        let modifiedArray = response?.data?.data?.map((item) => {
          // const { bookingaddonservice } = item;

          return {
            // checkBox: (
            //   <Checkbox
            //     checked={false}
            //     // onChange={handleChange}
            //     inputProps={{ "aria-label": "controlled" }}
            //   />
            // ),
            id: (
              <div
                onClick={() => handleRedirect(item?.id)}
                style={{ cursor: "pointer" }}
              >
                {item?.id}
              </div>
            ),
            name: (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleRedirect(item?.id)}
              >
                <div>{`${item?.full_name}`}</div>
                <div>{`${item?.contact_no}`}</div>
              </div>
            ),
            // name: item?.full_name + +item?.contact_no,
            date: item?.date || "N.A",
            time: item?.time || "N.A",
            location: item?.address?.city || "N.A",
            created: (
              <div>
                <div>{helperdayMonthYearFull(item?.created)}</div>
                <div>{extractDateTime(item?.created)}</div>
              </div>
            ),
            serviceDate:
              (item?.serviceDate &&
                helperdayMonthYearFull(item?.serviceDate)) ||
              "N.A",
            category:
              getTooltipComponent(item?.bookingaddonservice, "category") ||
              "N.A",
            service:
              getTooltipComponent(item?.bookingaddonservice, "services") ||
              "N.A",
            drone: item?.drone || "N.A",
            pilot: item?.pilot || "N.A",

            start_date:
              (item?.start_date && helperdayMonthYearFull(item?.start_date)) ||
              "N.A",

            end_date:
              (item?.end_date && helperdayMonthYearFull(item?.end_date)) ||
              "N.A",
          };
        });
        setBookingList([...modifiedArray]);
      })
      .catch((err) => {
        console.log("BookingErr", err);
      });
  };
  
  const handleRedirect = (id) => {
    navigate(`/bookings/service-booking-details/${id}`);
  };

  useEffect(() => {
    getAllServiceBookings();
  }, []);

  return (
<div style={{ height: "300px", overflow: "auto", scrollbarWidth: "thin", scrollbarColor: "#333333 #f0f0f0" }}>
      <CustomTable
        columns={tableColumns}
        data={bookingList}
        handleClick={handleRedirect}
        tableFor="serviceOrders"
      />
    </div>
  );
};

export default ServiceTableforDashboard;

const tableColumns = [
  // {
  //   id: "checkBox",
  //   label: "",
  // },
  {
    id: "id",
    label: "Service ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "created",
    label: "Service Created",
  },
  {
    id: "serviceDate",
    label: "Service Date",
  },
  // {
  //   id: "time",
  //   label: "Time",
  // },
  {
    id: "location",
    label: "Location(City)",
  },
  {
    id: "category",
    label: "Category",
  },
  {
    id: "service",
    label: "Service",
  },
  {
    id: "drone",
    label: "Drone",
  },
  {
    id: "pilot",
    label: "Pilot",
  },

  {
    id: "start_date",
    label: "Start date",
  },
  {
    id: "end_date",
    label: "End date",
  },
];
