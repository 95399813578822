import React, { useState } from "react";
import styles from "./PersonalDetails.module.css";
import Profileimg from "../../../Assets/agmUserProfile/profile.png";
import editBtnIcon from "../../../Assets/userProfile/editIcon.svg";
import details1 from "../../../Assets/userProfile/fname.svg";
import details2 from "../../../Assets/userProfile/mobNo.svg";
import details3 from "../../../Assets/userProfile/location.svg";
import details4 from "../../../Assets/userProfile/onBdate.svg";
import details5 from "../../../Assets/userProfile/gender.svg";
import details6 from "../../../Assets/userProfile/mail.svg";
import details7 from "../../../Assets/userProfile/gst.svg";
import details8 from "../../../Assets/userProfile/lastlogin.svg";
import verified from "../../../Assets/userProfile/verified.svg";
import notverified from "../../../Assets/userProfile/notVerified.svg";
import dealerCode from "../../../Assets/dealerCode.svg";
import dealerAcres from "../../../Assets/dealerAcres.svg";
import dealerCompany from "../../../Assets/dealerCompany.svg";

const PersonalDetails = ({ dealer, handleOpenDrawer }) => {
  return (
    <div className={styles.mainContainer}>
      {/* <div className={styles.leftProfileimgContainer}>
                <img src={user?.avatar ? user?.avatar : user?.default_avatar} className={styles.profilepic}  alt='profileimg' />
                <img onClick={opneProfileModel} src={editBtnIcon} className={styles.editprofilepic} alt='edit-icon' />
            </div> */}
      <div className={styles.rightProfiledetaile}>
        <div className={styles.topbar}>
          <div className={styles.cardheading}>
            <h3>Profile</h3>
            <p>Basic Info</p>
          </div>
          <div className={styles.editBtn} style={{ cursor: "pointer" }}>
            <img
              src={editBtnIcon}
              onClick={() => {
                handleOpenDrawer(dealer, "profile");
              }}
              alt="edit-Icon"
            />
          </div>
        </div>
        <div className={styles.besicDetailsWrap}>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={details1} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}> {dealer?.full_name || "N.A"}</h4>
              <p className={styles.detailsHeading}> Full name</p>
            </div>
          </div>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={details2} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}>
                {" "}
                {dealer?.user?.mobile || "N.A"}
                {dealer?.user?.mobile &&
                  (dealer?.user?.mobile_verified ? (
                    <span className={styles.Vstatus}>
                      {" "}
                      <img src={verified} /> verified{" "}
                    </span>
                  ) : (
                    <span className={styles.NVstatus}>
                      {" "}
                      <img src={notverified} /> Not verified{" "}
                    </span>
                  ))}
                {/* <span className={styles.Vstatus}> <img src={verified} /> verified </span> */}
              </h4>
              <p className={styles.detailsHeading}> Mobile No.</p>
            </div>
          </div>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={dealerCompany} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}>
                {" "}
                {dealer?.establishment_name || "N.A"}
              </h4>
              <p className={styles.detailsHeading}> Establishment Name</p>
            </div>
          </div>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={details4} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}>
                {" "}
                {dealer?.joining_date || "N.A"}
              </h4>
              <p className={styles.detailsHeading}> Joining Date</p>
            </div>
          </div>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={details5} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}> {dealer?.gender_str || "N.A"}</h4>
              <p className={styles.detailsHeading}> Gender</p>
            </div>
          </div>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={details6} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detailemail}>
                {" "}
                {dealer?.user?.email || "N.A"}
                {dealer?.user?.email &&
                  (dealer?.user?.email_verified ? (
                    <span className={styles.Vstatus}>
                      {" "}
                      <img src={verified} /> Verified{" "}
                    </span>
                  ) : (
                    <span className={styles.NVstatus}>
                      {" "}
                      <img src={notverified} /> Not verified{" "}
                    </span>
                  ))}
                {/* <span className={styles.NVstatus}> <img src={notverified} /> Not Verified </span> */}
              </h4>
              <p className={styles.detailsHeading}> Email Id</p>
            </div>
          </div>

          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={dealerCode} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}> {dealer?.dealer_code || "N.A"}</h4>
              <p className={styles.detailsHeading}> Dealer Code</p>
            </div>
          </div>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              <img src={dealerAcres} alt="" />
            </div>
            <div className={styles.detailsText}>
              <h4 className={styles.detail}>
                {" "}
                {dealer?.total_target_acres || "N.A"}
              </h4>
              <p className={styles.detailsHeading}> Total Target Acres</p>
            </div>
          </div>
          <div className={styles.detailsBox}>
            <div className={styles.detailimgContainer}>
              {/* <img src={details8} alt='' /> */}
            </div>
            <div className={styles.detailsText}>
              <h4
                className={`${styles.status} ${
                  dealer?.active == true ? styles.active : styles.inActive
                }`}
              >
                {dealer?.active == true ? "Active" : "Inactive"}{" "}
              </h4>
              <p className={styles.detailsHeading}> Status</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;

const user = {
  name: "Hari Gadi",
  location: "Vizag",
  date_joined: "07-06-2024",
  mobile: "6304113067",
  mobile_verified: true,
  gender_str: "Male",
  email: "haridhonigadi0707@gmail.com",
  email_verified: true,
  last_login: "",
  gst: "123123",
  status: 1,
};
