import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { useLocation, useNavigate } from "react-router-dom";

function CustomBreadCrumbs({ breadcrumbs }) {
  let navigate = useNavigate();
  let location = useLocation();

  function handleClick(event) {
    // event.preventDefault();
    console.info("You clicked a breadcrumb.", event);
  }

  // const breadcrumbs = [
  //   <Link
  //     underline="hover"
  //     key="1"
  //     color="inherit"
  //     href="/"
  //     onClick={handleClick}
  //   >
  //     Home
  //   </Link>,
  //   <Link
  //     underline="hover"
  //     key="2"
  //     color="inherit"
  //     //   href={crumb.middle == "Profile" ? "institute-details" : ""}
  //     onClick={handleClick}
  //   >
  //     Past Papers
  //   </Link>,
  //   <Typography key="3" color="text.primary">
  //     Prelims
  //   </Typography>,
  // ];

  // const breadcrumbs = [<div>Home</div>, <div>Services</div>];

  return (
    <div>
      <Breadcrumbs
        separator={<div className="orange-1">/</div>}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </div>
  );
}

export default CustomBreadCrumbs;
