import { AppBar, Box, Button, Toolbar } from "@mui/material";
import React from "react";
import FlexBetween from "../FlexBetween/FlexBetween";
import CustomMenu from "../CustomMenu/CustomMenu";

const Navbar = () => {
  return (
    <AppBar
      sx={{
        position: "static",
        background: "#000",
        boxShadow: "none",
        zIndex: "1500",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <FlexBetween></FlexBetween>
        <FlexBetween>
          <Button>ENG</Button>
          <CustomMenu />
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
