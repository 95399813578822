export const Validate = (value, edit) => {
  return new Promise((resolve, reject) => {
    const {
      dduser,
      course,
      batch,
      guestroom,
      slot, //this one is for id of selected flying slot
      classslot, // this one is for flying class slot
      hotel,
      mode,
      start_date,
      end_date,
      couponCode,
      paymentMode,
      paymentStatus,
      purchaseStatus,
    } = value;

    const errors = { error: false };

    if (!dduser?.id) {
      errors.dduser = "Enter user name";
      errors.error = true;
    }

    if (!course[0]) {
      errors.course = "Enter course details";
      errors.error = true;
    }

    if (!batch[0]) {
      errors.batch = "Enter batch details";
      errors.error = true;
    }

    if (!edit && !paymentMode[0]) {
      errors.paymentMode = "Enter payment mode";
      errors.error = true;
    }
    if (!paymentStatus[0]) {
      errors.paymentStatus = "Enter payment status";
      errors.error = true;
    }

    if (!purchaseStatus[0]) {
      errors.purchaseStatus = "Enter purchase status";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};

// Example usage:
const valuesToValidate = {
  // ... your values here ...
};

// validateBatch(valuesToValidate)
//   .then((successMessage) => {
//     console.log(successMessage);
//   })
//   .catch((validationErrors) => {
//     console.log("Validation failed. Errors:", validationErrors);
//   });

export const validateMedia = (value) => {
  let errorCheck = false;
  const validData = value?.map((item) => {
    if (!item?.headline) {
      item.headline_error = "Add Headline";
      errorCheck = true;
    }
    if (!item?.tagline) {
      item.tagline_error = "Add Tagline";
      errorCheck = true;
    }
    if (!item?.index || item?.index === 0) {
      item.index_error = "Add Index";
      errorCheck = true;
    }

    return item;
  });

  return {
    errorCheck,
    validData,
  };
};
