export const Validate = (value, selectedBlock) => {
  return new Promise((resolve, reject) => {
    const {
      name,
      number,
      code,
      status,
      joining_date,
      gender,
      city,
      district,
      state,
      pincode,
      country,
      line_1,
      line_2,
      area,
      establishment_name,
      total_target_acres,
    } = value;

    const errors = { error: false };
    const mobileRegex = /^\d{10}$/;
    if (!name) {
      errors.name = "Please enter dealer name";
      errors.error = true;
    }
    if (!total_target_acres) {
      errors.total_target_acres = "Please enter total target acres";
      errors.error = true;
    }
    if (!establishment_name) {
      errors.establishment_name = "Please enter establishment name";
      errors.error = true;
    }
    if (!code) {
      errors.code = "Please enter dealer code";
      errors.error = true;
    }
    if (!line_1) {
      errors.line_1 = "Please enter line_1";
      errors.error = true;
    }
    if (!line_2) {
      errors.line_2 = "Please enter line_2";
      errors.error = true;
    }
    if (!area) {
      errors.area = "Please enter area";
      errors.error = true;
    }
    if (!city) {
      errors.city = "Please enter city";
      errors.error = true;
    }
    if (!pincode) {
      errors.pincode = "Please enter pincode";
      errors.error = true;
    }
    if (!district) {
      errors.district = "Please enter district";
      errors.error = true;
    }
    if (!state) {
      errors.state = "Please enter state";
      errors.error = true;
    }
    if (!country) {
      errors.country = "Please enter country";
      errors.error = true;
    }

    if (!number || !mobileRegex.test(number)) {
      errors.number = "Please enter a valid 10-digit mobile number";
      errors.error = true;
    }

    if (!status[0]) {
      errors.status = "Please select status";
      errors.error = true;
    }
    if (!gender[0]) {
      errors.gender = "Please select gender";
      errors.error = true;
    }
    if (!selectedBlock[0]) {
      errors.selectedBlock = "Please select block";
      errors.error = true;
    }
    if (!joining_date) {
      errors.joining_date = "Please enter joining date";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
