import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { token_api } from "../../Utils/network";
import { helperdayMonthYearFull } from "../../Utils/helper";

const DroneInventoryComp = ({ onChange, value }) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleChange = (event, newValue) => {
    onChange && onChange(newValue);
  };

  useEffect(() => {
    setLoading(true);

    let apiEndpoint = `/drone/inventory`;

    if (inputValue) {
      apiEndpoint += `?q=${encodeURIComponent(inputValue)}`;
    }

    token_api
      .get(apiEndpoint)
      .then((response) => {
        const data = response?.data?.data?.map((info) => ({
          id: info?.id,
          drone_name: info?.drone_name,
          serial_no: info?.serial_no,
          drone_title: info?.drone?.title,
        }));
        setOptions(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [inputValue]);


  return (
    <Autocomplete
      sx={{ width: "100%" }}
      multiple
      id="tags-outlined"
      options={options}
      getOptionLabel={(option) =>
        `${option?.drone_name}`
      }
      value={value}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label="Drone Inventory" placeholder="Search for drone inventory" />
      )}
      renderOption={(props, option, { inputValue }) => {
        const label = `Drone Name: ${option?.drone_name}`;
        const sublabel = `ID: ${option?.id} | Serial No: ${
          option?.serial_no
        } | Drone Master: ${option?.drone_title}`;

        return (
          <li {...props}>
            <div style={{ width: "100%" }}>
              <div>
                <div style={{ fontWeight: "bold" }}>{label}</div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>{sublabel}</div>
{/* 
                  <div>
                    {option.is_available ? (
                      <div
                        style={{
                          padding: "7px 25px",
                          borderRadius: "25px",
                          backgroundColor: "#CAE6BD",
                          color: "#1E7026",
                          minWidth: "145px",
                          textAlign: "center",
                        }}
                      >
                        Available
                      </div>
                    ) : (
                      <div
                        style={{
                          padding: "7px 25px",
                          borderRadius: "25px",
                          color: "rgb(158, 40, 40)",
                          backgroundColor: "rgb(255, 164, 145)",
                        }}
                      >
                        Not available
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </li>
        );
      }}
    />
  );
};

export default DroneInventoryComp;
