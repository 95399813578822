import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import styles from "./SearchAndDropdown.module.css";

export default function SearchAndDropdown({
  value,
  onChange,
  listing,
  error,
  typedValue,
  setTypedValue,
  label,
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    } else if (typedValue) {
      let newListing = listing.filter((info) =>
        info?.title?.toLowerCase().includes(typedValue.toLowerCase())
      );
      setOptions(newListing);
    } else {
      setOptions(listing);
    }
  }, [open, typedValue, listing]);

  return (
    <Autocomplete
      className={styles.searchCon}
      id="asynchronous-demo"
      sx={{ width: "100%" }}
      size="small"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      getOptionLabel={(option) => option.title}
      loading={loading}
      value={value && value.title ? value : null} // Set value to null if not available
      inputValue={typedValue}
      onInputChange={(event, newInputValue) => {
        setTypedValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        onChange && onChange(event, newValue);
        setTypedValue(newValue?.title || typedValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={Boolean(error)}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <div {...props}>
          <Typography>{option.title}</Typography>
        </div>
      )}
    />
  );
}
