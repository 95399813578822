import React, { useState } from 'react';
import { styled } from '@mui/system';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  // The styling remains the same as your original component
  // ...

}));

const CustomizedSwitches = ({ initialValue = false, label, onChange }) => {
  const [checked, setChecked] = useState(initialValue);

  const handleSwitchChange = () => {
    const newValue = !checked;
    setChecked(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<CustomSwitch checked={checked} onChange={handleSwitchChange} />}
        label={label}
      />
    </FormGroup>
  );
};

export default CustomizedSwitches;
