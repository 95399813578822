import { helperdayMonthYearFull } from "../../Utils/helper";
import edit_icon from "../../Assets/edit_icon.svg";
import delete_icon from "../../Assets/delete_icon.svg";
import dayjs from "dayjs";

export const modifySlotData = (
  data,
  handleEditSlot,
  handleDeleteSlot,
  user_permissions
) => {

  console.log(user_permissions, "jaysyayays")
  const modifiedData = data?.map((item) => {
    const {
      id,
      course: { name },
      location,
      type,
      end_date,
      start_date,
    } = item;
    return {
      slot_id: id,
      course_name: name,
      slot_type: slotTypes[type - 1],
      slot_location: (
        <p>
          {item?.location?.line_1},{item?.location?.line_2},
          {item?.location?.area},{item?.location?.city},{item?.location?.state},
          {item?.location?.country},{item?.location?.pincode}{" "}
        </p>
      ),
      start_date: helperdayMonthYearFull(start_date),
      end_date: helperdayMonthYearFull(end_date),
      actions: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            justifyContent: "center",
          }}
        >
          {user_permissions?.update && (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleEditSlot(item)}
            >
              <img width={40} height={40} src={edit_icon} />
            </span>
          )}
          {user_permissions?.delete && (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteSlot(item?.id)}
            >
              <img width={40} height={40} src={delete_icon} />
            </span>
          )}
        </div>
      ),
    };
  });
  return modifiedData;
};

export const formatEditState = (data) => {
  const {
    course: { id, name, slug },
    type,
    location: {
      id: locationId,
      city,
      state,
      line_1,
      line_2,
      area,
      country,
      pincode,
    },
    descriptions,
    start_date,
    end_date,
  } = data;
  return {
    course_id: [{ id: id, title: name }],
    type: [slotTypesObj[type - 1]],
    location_id: [
      {
        id: locationId,
        title: `${line_1}, ${line_2},${area}, ${city},${state}, ${country}, ${pincode}`,
      },
    ],
    descriptions,
    start_date: dayjs(start_date),
    end_date: dayjs(end_date),
  };
};

export const slotTypes = ["Online Class", "Offline Class", "Flying Class"];

const slotTypesObj = [
  { id: 1, title: "Online Class" },
  { id: 2, title: "Offline Class" },
  { id: 3, title: "Flying Class" },
];
