import React, { useEffect, useRef, useState } from "react";
import styles from "./drag.module.css";
import dragIcon from "../../Assets/dragupload.png";

const DragAndDrop = ({ bannerState, setBannerState }) => {
  const fileInput = useRef(null);
  //   const [bannerState, setBannerState] = useState([]);
  const [bannerPreview, setBannerPreview] = useState("");
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e) => {
    e.preventDefault();

    let file;
    if (e?.target?.files) {
      //   setFileName([...fileName, e.target.files[0]]);
      file = e.target.files[0];
    } else if (e?.dataTransfer?.files) {

      //   setFileName([...fileName, e.dataTransfer.files[0]]);
      file = e.dataTransfer.files[0];
    }
    setBannerPreview(URL.createObjectURL(file));
  };
  const handleClick = () => {
    fileInput.current.click();
  };

  useEffect(() => {
    if (bannerPreview) {
      setBannerState([...bannerState, bannerPreview]);
    }
  }, [bannerPreview]);


  return (
    <div style={{ marginTop: "10px" }}>
      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className={styles.dropBoxStyles}
      >
        <div
          onClick={handleClick}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p>
            <img src={dragIcon} alt="dradanddrop" />
          </p>
          <p>Drag & Drop the File or click here</p>
          <input
            ref={fileInput}
            type="file"
            // id="myfile"
            name="myfile"
            onChange={handleDrop}
            style={{ display: "none" }}
          ></input>
        </div>
      </div>
    </div>
  );
};

export default DragAndDrop;
