import React, { useState } from "react";
import { format, addWeeks, subWeeks, startOfWeek, addDays } from "date-fns";
import styled from "styled-components";
import rightIcon from "../../Assets/rightIcon.png";
import leftIcon from "../../Assets/leftIcon.png";

const WeekCalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
`;

const NavButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  outline: none;
`;

const DaysContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 10px 0;
  background-color: #f8f8f8;
  border-radius: 10px;
`;

const Day = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  color: ${(props) => (props.isToday ? "white" : "#f2711c")};
  background-color: ${(props) => (props.isToday ? "#f2711c" : "transparent")};
  border-radius: 5px;
  cursor: pointer;
  width: 40px;
`;

const DayLabel = styled.div`
  font-size: 12px;
`;

const DayNumber = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const WeekDayView = ({ setDate, ...props }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const startDate = startOfWeek(currentDate, { weekStartsOn: 1 });
  const days = Array.from({ length: 7 }).map((_, index) =>
    addDays(startDate, index)
  );

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setDate(format(date, "yyyy-MM-dd"));
    console.log("Selected Date:", format(date, "yyyy-MM-dd")); // Log the selected date
  };

  const isToday = (date) => {
    // return format(date, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd");
    return format(date, "yyyy-MM-dd") === format(selectedDate, "yyyy-MM-dd");
  };

  return (
    <WeekCalendarContainer>
      <Header>
        <NavButton onClick={() => setCurrentDate(subWeeks(currentDate, 1))}>
          <img src={leftIcon} />
        </NavButton>
        <div>{format(currentDate, "MMMM yyyy")}</div>
        <NavButton onClick={() => setCurrentDate(addWeeks(currentDate, 1))}>
          <img src={rightIcon} />
        </NavButton>
      </Header>
      <DaysContainer>
        {days.map((day, index) => (
          <Day
            key={index}
            isToday={isToday(day)}
            onClick={() => handleDateClick(day)}
          >
            <DayLabel>{format(day, "E")}</DayLabel>
            <DayNumber>{format(day, "d")}</DayNumber>
          </Day>
        ))}
      </DaysContainer>
    </WeekCalendarContainer>
  );
};

export default WeekDayView;
