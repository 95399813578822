import React from 'react'
import styles from "./profile.module.css";

 const Profile = () => {
  return (
    <div>Profile</div>
  )
}


export default Profile