import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./pilot.module.css";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import CustomTabs3 from "../../Component/CustomTabsNew/CustomTabs3";
import CustomButton from "../../Component/CustomButton/CustomButton";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import CustomDate from "../../Component/CustomDate/CustomDate";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { Checkbox } from "@mui/material";
import filterIcon from "../../Assets/filterIcon.png";
import pilotIcon from "../../Assets/pilotIcon.svg";
import androidIcon from "../../Assets/android.svg";
import appleIcon from "../../Assets/apple.svg";
import windowsIcon from "../../Assets/windows.svg";
import { token_api, base_url } from "../../Utils/network";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import InputFields from "../../Component/InputFields/InputFields";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { Button, useTheme } from "@mui/material";
import { Validate } from "./Utility";
import deleteIcon from "../../Assets/delete.png";
import editIcon from "../../Assets/editIcon.png";
import dragDrop from "../../Assets/drag_drop.svg";

import {
  convertDateFormat,
  formatDate,
  handleKeyPress,
  helperdayMonthYearFull,
} from "../../Utils/helper";
import MyMap from "../../Component/MyMap/MyMap";
import axios from "axios";
import delete_icon from "../../Assets/delete_icon.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import dayjs from "dayjs";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import { useSelector } from "react-redux";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

const PilotList = () => {
  const { user_permissions } = useContext(UserCredsContext);

  console.log(user_permissions, "pilotListUserPermissions");
  let navigate = useNavigate();
  let location = useLocation();

  const theme = useTheme();
  const { palette } = theme;
  const { access_token } = useSelector((state) => state.auth);

  const [filters, setfilters] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [pilotList, setPilotList] = useState([]);
  const [loc, updLoc] = useState({ lat: 20.5937, lng: 78.9629 });
  const [accForm, setaccForm] = useState({});
  const [formData, setFormData] = useState({
    license_no: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    location: "",
    dob: null,
    expiry_date: null,
    joining_date: null,
    active: [],
    verified: [],
    gender: [],
    is_available: [],
    pincode: "",
    line_1: "",
    line_2: "",
    area: "",
    city: "",
    state: "",
    tehsil: "",
    district: "",
    country: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [updateId, setupdateId] = useState(null);
  const [loading, setLoading] = useState(false);
  const bannerInput = useRef(null);
  const [banners, setBanners] = useState("");
  const [bannersPreview, setBannersPreview] = useState("");

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Pilots</div>,
  ];

  function handleDrop(e, type) {
    e.preventDefault();
    if (e?.target?.files) {
      const imageItem = e?.target?.files[0];
      setBanners(imageItem);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setBannersPreview(imageUrl);
    } else if (e?.dataTransfer?.files) {
      const imageItem = e?.dataTransfer?.files[0];
      setBanners(imageItem);
      const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
      setBannersPreview(imageUrl);
    }
  }

  const handleClick = (type) => {
    bannerInput.current.click();
  };
  function handleDragOver(e) {
    e.preventDefault();
  }

  const handleRedirect = (id) => {
    console.log("IFIDCOME", id);
  };

  const handleEditclick = (e, info) => {
    console.log(info, "hariInfooo");
    const {
      id,
      is_available,
      verified,
      active,
      gender,
      license: { license_no, expiry_date },
      mobile,
      first_name,
      last_name,
      dob,
      joining_date,
      avatar,
      address: {
        lat,
        long,
        line_1,
        line_2,
        state,
        country,
        tehsil,
        district,
        city,
        area,
        pincode,
      },
      user: { email, date_joined },
    } = info;
    let available = isAvailable?.filter((info) => info?.id == is_available);
    let isverified = isVerified?.filter((info) => info?.id == verified);
    let isactive = statusListing?.filter((info) => info?.id == active);
    let genderdata = genderList?.filter((info) => info?.id == gender);
    // let converteddatejoined = convertDateFormat(date_joined);

    // const [loc, updLoc] = useState({ lat: 20.5937, lng: 78.9629 });

    updLoc((prev) => ({
      ...prev,
      lat: lat,
      lng: long,
    }));

    setFormData((prev) => ({
      ...prev,
      active: isactive,
      verified: isverified,
      is_available: available,
      gender: genderdata,
      license_no,
      expiry_date: dayjs(expiry_date),
      mobile,
      first_name,
      last_name,
      email,
      joining_date: dayjs(joining_date),
      dob: dayjs(dob),
      line_1,
      line_2,
      state,
      country,
      tehsil,
      district,
      city,
      area,
      pincode,
    }));
    setFormErrors({});
    setupdateId(id);
    setOpenDrawer(true);
    setBannersPreview(avatar);
    console.log(info, "infouser");
  };

  const handleNaigateDetails = (pilotId) => {
    navigate(`/pilots/pilotslist/${pilotId}`);
  };
  const getPilotData = async (filterobject) => {
    try {
      const fetchList = await token_api.get(`users/profile/pilot`, {
        params: { ...filterobject },
        paramsSerializer: {
          indexes: null,
        },
      });
      console.log(fetchList?.data?.data, "tableBodyddd");
      if (fetchList?.status !== 200) {
        throw new Error("Network Error");
      }
      setPaginationData(fetchList?.data);

      let modifiedArray = fetchList?.data?.data?.map((item) => {
        console.log(item?.license, "itemmm");
        // const { bookingaddonservice } = item;

        const isActive = item.active === true;
        const statusBackground = isActive
          ? "rgba(110, 210, 126, 1)"
          : "rgba(252, 185, 176, 1)";
        const statusColor = isActive
          ? "rgba(63, 88, 49, 1)"
          : "rgba(188, 70, 7, 1)";

        return {
          id: (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleNaigateDetails(item?.id);
              }}
            >
              {item?.id}
            </div>
          ),

          name: (
            <div
              className={styles.profileCon}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleNaigateDetails(item?.id);
              }}
            >
              <img
                src={item?.avatar ? item?.avatar : item?.default_avatar}
                alt="pilot_image"
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "100%",
                  objectFit: "cover",
                }}
              />
              <p>
                {item?.first_name} {item?.middle_name} {item?.last_name}
              </p>
            </div>
          ),
          mobile: item?.mobile,
          email: item?.user?.email,
          location: <p>{item?.address?.city || "N.A"}</p>,
          gender: item?.gender === "1" ? "Male" : "Female",
          license: item?.license?.license_no,
          // status: item?.active === true ? "Active" : "Inactive",
          is_available: (
            <div
              style={{
                backgroundColor: `${
                  item?.is_available
                    ? "rgba(110, 210, 126, 1)"
                    : "rgba(252, 185, 176, 1)"
                }`,
                color: `${
                  item?.is_available
                    ? "rgba(63, 88, 49, 1)"
                    : "rgba(188, 70, 7, 1)"
                }`,
                padding: "5px 10px",
                borderRadius: "5px",
              }}
            >
              {item?.is_available ? "Available" : "Not Available"}
            </div>
          ),

          status: (
            <div
              style={{
                backgroundColor: statusBackground,
                color: statusColor,
                padding: "5px 10px",
                borderRadius: "5px",
              }}
            >
              {isActive ? "Active" : "Inactive"}
            </div>
          ),

          onboarding: helperdayMonthYearFull(item?.joining_date),
          // device: (
          //   <div>
          //     {/* <img src={item?.device} /> */}
          //     NA
          //   </div>
          // ),

          // login: (
          //   <div
          //     style={{ display: "flex", justifyContent: "center" }}
          //     className={styles.languageCon}
          //   >
          //     {/* <p className = {styles.loginDate}>{item?.login?.date}</p>
          //     <p className = {styles.loginTime}>({item?.login?.time})</p> */}
          //     <p style={{ textAlign: "center" }}>NA</p>
          //   </div>
          // ),
          action: (
            <div className={styles.languageCon}>
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleEditclick(e, item)}
                >
                  <img src={editIcon} />
                </div>
              )}

              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteorg(item?.id)}
                >
                  <img src={delete_icon} />
                </div>
              )}
            </div>
          ),
        };
      });

      setPilotList([...modifiedArray]);
    } catch (error) {
      console.log("FFFFFFsSS", error);
    }
  };

  const handleChange = (e, type, selectName, multiple) => {
    console.log("TYTYY");
    if (type === "select" && selectName && multiple) {
      setFormData({
        ...formData,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setFormData({
        ...formData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      if (name === "pincode") {
        if (value.length > 6) {
          return;
        }
      }
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDeleteChipforDrawer = (id, selectName) => {
    console.log("DELETE", id, selectName, formData?.[selectName]);
    setFormData({
      ...formData,
      [selectName]: formData?.[selectName]?.filter((chip) => chip?.id !== id),
    });
    let namefiled = formData[selectName];
    console.log(namefiled[0]?.title, "slehdgfdf");
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      let filtersObject = {};
      if (pageState) {
        filtersObject.page = pageState;
      }

      if (filters?.q) {
        filtersObject.q = filters?.q;
      }

      if (accForm?.available) {
        const ids = accForm?.available?.map((info) => info?.id);
        filtersObject.is_available = ids;
      }

      getPilotData(filtersObject);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [pageState, filters?.q, accForm?.available]);

  const handleAddforPilot = (e) => {
    e.preventDefault();

    const {
      license_no,
      expiry_date,
      active,
      verified,
      gender,
      is_available,
      line_1,
      line_2,
      area,
      city,
      state,
      tehsil,
      district,
      country,
      pincode,
      first_name,
      last_name,
      email,
      mobile,
      dob,
      joining_date,
    } = formData;

    const data = {
      first_name,
      last_name,
      email,
      mobile,
      dob: formatDate(dob),
      joining_date: formatDate(joining_date),
      active: active[0]?.id,
      verified: verified[0]?.id,
      gender: gender[0]?.id,
      is_available: is_available[0]?.id,
      license_details: {
        license_no,
        expiry_date: formatDate(expiry_date),
      },
      address_details: {
        line_1,
        line_2,
        area,
        city,
        state,
        tehsil,
        district,
        country,
        lat: loc?.lat,
        long: loc?.lng,
        pincode,
      },
    };

    Validate(formData)
      .then(async (response) => {
        if (response == "success") {
          if (!updateId) {
            const newPilotResponse = await token_api.post(
              `users/profile/pilot/`,
              data
            );
            if (newPilotResponse?.status === 400) {
              alert(newPilotResponse?.data?.error?.fields[0]?.message[0]);
              // alert("This mobile number is already registered as a pilot.");
              setOpenDrawer(false);
              getPilotData();
            } else if (newPilotResponse?.status === 403) {
              alert(newPilotResponse?.data?.error?.message);
              // alert("This mobile number is already registered as a DD user.");
              setOpenDrawer(false);
              getPilotData();
            }

            if (newPilotResponse?.status !== 201) {
              throw new Error("Pilot not created");
            }

            if (banners) {
              let pilotMedia = new FormData();
              pilotMedia.append("avatar", banners);

              await axios({
                method: "patch",
                url: `${base_url}users/profile/pilot/${newPilotResponse?.data?.data?.id}/`,
                data: pilotMedia,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            alert("Pilot Created Successfully!");

            setOpenDrawer(false);
            getPilotData();
            setFormData({});
            setFormErrors({});
            setBanners("");
            setBannersPreview("");
          } else {
            const newPilotResponse = await token_api.patch(
              `users/profile/pilot/${updateId}/`,
              data
            );

            if (newPilotResponse?.status === 400) {
              alert(newPilotResponse?.data?.error?.fields[0]?.message[0]);
              setOpenDrawer(false);
              getPilotData();
            } else if (newPilotResponse?.status === 403) {
              alert(newPilotResponse?.data?.error?.fields[0]?.message[0]);
              setOpenDrawer(false);
              getPilotData();
            }

            if (newPilotResponse?.status !== 200) {
              throw new Error("Pilot not created");
            }
            if (banners) {
              let pilotMedia = new FormData();
              pilotMedia.append("avatar", banners);

              await axios({
                method: "patch",
                url: `${base_url}users/profile/pilot/${updateId}/`,
                data: pilotMedia,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            alert("Pilot Updated Successfully!");

            setOpenDrawer(false);
            getPilotData();
            setFormData({});
            setFormErrors({});
            setBanners("");
            setBannersPreview("");
          }
        }
      })
      .catch((error) => {
        console.log(error, "kahsysys");
        setFormErrors(error);
      });
  };

  console.log(formErrors, "firstnameiserror");

  const handleAddnewPilot = () => {
    const clearedFormData = {
      license_no: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      location: "",
      dob: dayjs(),
      expiry_date: dayjs(),
      joining_date: dayjs(),
      active: [],
      verified: [],
      gender: [],
      is_available: [],
    };
    setFormData(clearedFormData);
    setFormErrors({});
    setOpenDrawer(true);
    setupdateId(null);
    setBanners("");
    setBannersPreview("");
  };

  const handleChangeacc = (e, type, selectName, multiple) => {
    console.log("TYTYY");
    if (type === "select" && selectName && multiple) {
      setaccForm({
        ...accForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setaccForm({
        ...accForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setaccForm({
        ...accForm,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleDeleteChipacc = (id, selectName) => {
    console.log("DELETE", id, selectName, accForm?.[selectName]);
    setaccForm({
      ...accForm,
      [selectName]: accForm?.[selectName]?.filter((chip) => chip?.id !== id),
    });
    let namefiled = accForm[selectName];
    console.log(namefiled[0]?.title, "slehdgfdf");
  };

  useEffect(() => {
    const fetchData = async () => {
      if (formData?.pincode && formData?.pincode?.length === 6) {
        try {
          setLoading(true);

          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${formData?.pincode}`
          );

          if (
            response.data.length !== 0 &&
            response.data[0].PostOffice.length !== 0
          ) {
            const pinData = response.data[0].PostOffice[0];
            const { District, Division, State, Name, Region, Country } =
              pinData;

            setFormData({
              ...formData,
              tehsil: Division,
              state: State,
              district: District,
              city: District,
              country: Country,
            });

            // setFormData({
            //   ...formData,
            //   district: Name,
            //   city: Region,
            //   state: State,
            //   country: Country,
            //   pincode: pin,
            // });
            // setShowOther(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [formData.pincode]);

  console.log(formErrors?.email, "userform");

  const handleDeleteorg = (deleteid) => {
    token_api
      .delete(`/users/profile/pilot/${deleteid}/`)
      .then((response) => {
        if (response?.status == 204 || response?.status == 201) {
          getPilotData();
        }
      })
      .catch((error) => {});
  };

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  //Export logic...

  const handleExportData = async () => {
    try {
      const response = await axios.get(`${base_url}users/profile/pilot/export`);
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };
  return (
    <div>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>
        {user_permissions?.write && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button variant="contained" onClick={handleAddnewPilot}>
              {"Add Pilot"}
            </Button>
          </div>
        )}
        <div className={styles.tabContainer}>
          <CustomTabs3 tabHead={tabData} currentTab={1} />
        </div>
        <FilterContainer
          className={styles.filterContainer}
          // className={`${styles.marginTopBox}`}
        >
          <CustomSearchFilter
          placeholder="Search by name, mobile number..."
            onChange={(e) => {
              setPageState(1);
              setfilters({ ...filters, q: e });
            }}
          />

          <CustomSelectChip
            multiple={false}
            label="Filter By availability"
            name="available"
            onChange={handleChangeacc}
            onDelete={handleDeleteChipacc}
            value={accForm?.available}
            listArray={isAvailable}
          />

          {/* <CustomSelect label={"Date"} listArray={[]} minWidth={"150px"} /> */}
          {/* <CustomDate /> */}

          {/* <CustomSelect
            label={"Filter by Model"}
            listArray={[]}
            minWidth={"195px"}
          /> */}
          {/* <CustomButton
            text={"ADVANCE FILTERS"}
            // btnIcon={<img src={filterIcon} alt="adv-filter" />}
            // maxWidth="170px"
          /> */}
          <CustomButton
            text={"Export"}
            maxWidth="110px"
            onClick={handleExportData}
          />
        </FilterContainer>
        <div className={styles.tableContainer}>
          <CustomTable
            columns={tableColumns}
            data={pilotList}
            handleClick={handleRedirect}
            tableFor="pilotList"
          />
        </div>
        <div className={styles.paginationContainer}>
          <CustomPagination max_pages={max_pages} setPageState={setPageState} />
        </div>
      </Container>
      <DrawerComp
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
      >
        <DrawerContainer>
          <DrawerTitle
            text={updateId ? "Update Pilot" : "Add Pilot"}
            handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <MyMap
                width="30vw"
                height="40vh"
                search={formData?.pincode}
                loc={loc}
                updLoc={updLoc}
              />
            </div>

            <div>
              <InputFields
                label="Pincode"
                name="pincode"
                value={formData?.pincode || ""}
                onChange={handleChange}
                error={formErrors?.pincode}
                helperText={formErrors?.pincode}
                maxLength={6}
                type="number"
              />
            </div>

            <div>
              <InputFields
                label="Address Line 1"
                name="line_1"
                style={{ width: "100%" }}
                value={formData?.line_1}
                onChange={handleChange}
                error={formErrors?.line_1}
                helperText={formErrors?.line_1}
              />
            </div>
            <div>
              <InputFields
                label="Address Line 2"
                name="line_2"
                style={{ width: "100%" }}
                value={formData?.line_2}
                onChange={handleChange}
                error={formErrors?.line_2}
                helperText={formErrors?.line_2}
              />
            </div>

            <div>
              <InputFields
                label="Area"
                name="area"
                style={{ width: "100%" }}
                value={formData?.area}
                onChange={handleChange}
                error={formErrors?.area}
                helperText={formErrors?.area}
              />
            </div>

            <div>
              <InputFields
                label="City"
                name="city"
                style={{ width: "100%" }}
                value={formData?.city}
                onChange={handleChange}
                error={formErrors?.city}
                helperText={formErrors?.city}
              />
            </div>
            <div>
              <InputFields
                // disabled={loctnFieldDisable}
                label="State"
                name="state"
                style={{ width: "100%" }}
                value={formData?.state}
                onChange={handleChange}
                error={formErrors?.state}
                helperText={formErrors?.state}
              />
            </div>

            <div>
              <InputFields
                // disabled={loctnFieldDisable}
                label="Tehsil"
                name="tehsil"
                style={{ width: "100%" }}
                value={formData?.tehsil}
                onChange={handleChange}
                error={formErrors?.tehsil}
                helperText={formErrors?.tehsil}
              />
            </div>

            <div>
              <InputFields
                // disabled={loctnFieldDisable}
                label="District"
                name="district"
                style={{ width: "100%" }}
                value={formData?.district}
                onChange={handleChange}
                error={formErrors?.district}
                helperText={formErrors?.district}
              />
            </div>

            <div>
              <InputFields
                // disabled={loctnFieldDisable}
                label="Country"
                name="country"
                style={{ width: "100%" }}
                value={formData?.country}
                onChange={handleChange}
                error={formErrors?.country}
                helperText={formErrors?.country}
              />
            </div>

            <div>
              <InputFields
                label="First Name"
                name="first_name"
                value={formData?.first_name || ""}
                onChange={handleChange}
                error={formErrors?.first_name}
                helperText={formErrors?.first_name}
              />
            </div>

            <div>
              <InputFields
                label="Last Name"
                name="last_name"
                value={formData?.last_name || ""}
                onChange={handleChange}
                error={formErrors?.last_name}
                helperText={formErrors?.last_name}
              />
            </div>

            <div>
              <InputFields
                label="Email"
                name="email"
                value={formData?.email || ""}
                onChange={handleChange}
                error={formErrors?.email}
                helperText={formErrors?.email}
              />
            </div>

            <div>
              <InputFields
                label="Mobile no"
                name="mobile"
                value={formData?.mobile || ""}
                onChange={handleChange}
                maxLength={10}
                error={formErrors?.mobile}
                helperText={formErrors?.mobile}
              />
            </div>

            <div>
              <CustomSelectChip
                multiple={false}
                label="Gender"
                name="gender"
                onChange={handleChange}
                onDelete={handleDeleteChipforDrawer}
                value={formData?.gender}
                listArray={genderList}
                error={formErrors?.gender}
                helperText={formErrors?.gender}
              />
            </div>

            <div>
              <InputFields
                label="License no"
                required
                name="license_no"
                value={formData?.license_no || ""}
                onChange={handleChange}
                error={formErrors?.license_no}
                helperText={formErrors?.license_no}
              />
            </div>

            <div>
              {/* <CustomDate
                name="expiry_date"
                label="Expiry Date"
                handleChange={handleChange}
                value={formData?.expiry_date}
                error={formErrors?.expiry_date}
                helperText={formErrors?.expiry_date}
              /> */}
              <CustomDatePicker
                label="Expiry Date"
                value={formData?.expiry_date}
                onChange={(newValue) =>
                  setFormData({
                    ...formData,
                    expiry_date: newValue,
                  })
                }
                error={formErrors?.expiry_date}
              />
            </div>

            <div>
              {/* <CustomDate
                name="joining_date"
                label="Joining_date"
                handleChange={handleChange}
                value={formData?.joining_date}
                error={formErrors?.joining_date}
                helperText={formErrors?.joining_date}
              /> */}
              <CustomDatePicker
                label="Joining Date"
                value={formData?.joining_date}
                onChange={(newValue) =>
                  setFormData({
                    ...formData,
                    joining_date: newValue,
                  })
                }
                error={formErrors?.joining_date}
              />
            </div>

            <div>
              {/* <CustomDate
                name="dob"
                label="Date of birth"
                handleChange={handleChange}
                value={formData?.dob}
                error={formErrors?.dob}
                helperText={formErrors?.dob}
              /> */}

              <CustomDatePicker
                label="Date of Birth"
                value={formData?.dob}
                onChange={(newValue) =>
                  setFormData({
                    ...formData,
                    dob: newValue,
                  })
                }
                error={formErrors?.dob}
              />
            </div>

            <div>
              <CustomSelectChip
                multiple={false}
                label="Pilot Status"
                name="active"
                onChange={handleChange}
                onDelete={handleDeleteChipforDrawer}
                value={formData?.active}
                listArray={statusListing}
                error={formErrors?.active}
                helperText={formErrors?.active}
              />
            </div>

            <div>
              <CustomSelectChip
                multiple={false}
                label="Available"
                name="is_available"
                onChange={handleChange}
                onDelete={handleDeleteChipforDrawer}
                value={formData?.is_available}
                listArray={isAvailable}
                error={formErrors?.is_available}
              />
            </div>

            <div>
              <CustomSelectChip
                multiple={false}
                label="Verified"
                name="verified"
                onChange={handleChange}
                onDelete={handleDeleteChipforDrawer}
                value={formData?.verified}
                listArray={isVerified}
                error={formErrors?.verified}
              />
            </div>

            <div className={`${styles.formContainer} mt-20`}>
              <div
                style={{ color: palette?.primary[500] }}
                className={`${styles.titleStyles}`}
              >
                Profile Image
              </div>

              <div style={{ marginTop: "20px" }}>
                <div
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "banner")}
                  className={styles.dropBoxStyles}
                >
                  <div
                    onClick={() => handleClick("banner")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p>
                      <img src={dragDrop} alt="dradanddrop" />
                    </p>
                    <p>Drag & Drop the File or click here</p>
                    <input
                      ref={bannerInput}
                      type="file"
                      // id="myfile"
                      // name="myfile"
                      // multiple
                      onChange={(e) => handleDrop(e, "banner")}
                      style={{ display: "none" }}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            {bannersPreview && (
              <div className={`${styles.formContainer} mt-20`}>
                <div className={styles.commonFieldsBox}>
                  <div>
                    <p
                      style={{ color: palette?.primary[500] }}
                      className={`${styles.titleStyles}`}
                    >
                      Profile Preview
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div
                        className={`${styles.flexBetween} ${styles.imgCardBox} `}
                      >
                        {" "}
                        <img
                          src={bannersPreview}
                          alt="banner"
                          width={200}
                          height={140}
                        />
                        <div onClick={() => handleClick("banner")}>
                          <Button
                            variant="contained"
                            // onClick={() => {
                            //   handleMediaDelete("image");
                            // }}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div>
              <Button variant="contained" onClick={handleAddforPilot}>
                {updateId ? "Update" : "Add"}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>
    </div>
  );
};

export default PilotList;

const tabData = [
  {
    id: 1,
    title: "Pilots ",
  },
  // {
  //   id: 2,
  //   title: "Inactive",
  // },
];

const tableColumns = [
  {
    id: "checkBox",
    label: "",
  },
  {
    id: "id",
    label: "User ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "mobile",
    label: "Mobile No.",
  },
  {
    id: "email",
    label: "Email",
  },
  // {
  //   id: "time",
  //   label: "Time",
  // },
  {
    id: "location",
    label: "Location",
  },

  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "license",
    label: "License",
  },
  {
    id: "is_available",
    label: "Pilot Availability",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "onboarding",
    label: "Onboarding Date",
  },
  // {
  //   id: "device",
  //   label: "Device",
  // },
  // {
  //   id: "login",
  //   label: "Last Login",
  // },

  {
    id: "action",
    label: "Action",
  },
];

const tableBodyData = [
  {
    userId: "SP12000",
    name: {
      profile: pilotIcon,
      title: "Yogin Patil",
    },
    mobile: 6304113067,
    email: "yoginP@gmail.com",
    location: "Mumbai",
    gender: "Male",
    license: "VXWD12123",
    status: {
      color: "rgba(60, 147, 8, 1)",
      bgColor: "rgba(211, 251, 171, 1)",
      title: "Completed",
    },
    onboarding: "21st Sept 2023",
    device: androidIcon,
    login: {
      date: "21st Sept 2023",
      time: "07:32 AM",
    },
  },
  {
    userId: "SP12001",
    name: {
      profile: pilotIcon,
      title: "Hari Gadi",
    },
    mobile: 6304113068,
    email: "harig@gmail.com",
    location: "Mumbai",
    gender: "Male",
    license: "VXWD12123",
    status: {
      color: "rgba(188, 70, 7, 1)",
      bgColor: "rgba(252, 185, 176, 1)",
      title: "Pending",
    },
    onboarding: "21st Sept 2023",
    device: appleIcon,
    login: {
      date: "21st Sept 2023",
      time: "07:32 AM",
    },
  },
  {
    userId: "SP12002",
    name: {
      profile: pilotIcon,
      title: "Sandesh Sakhare",
    },
    mobile: 6304113067,
    email: "sandeshS@gmail.com",
    location: "Mumbai",
    gender: "Male",
    license: "VXWD12123",
    status: {
      color: "rgba(150, 118, 6, 1)",
      bgColor: "rgba(255, 215, 8, 0.45)",
      title: "In Progress",
    },
    onboarding: "21st Sept 2023",
    device: windowsIcon,
    login: {
      date: "21st Sept 2023",
      time: "07:32 AM",
    },
  },
];

const statusListing = [
  {
    id: true,
    title: "Active",
  },
  {
    id: false,
    title: "Inactive",
  },
];

const isAvailable = [
  {
    id: true,
    title: "Available",
  },
  {
    id: false,
    title: "Not Available",
  },
];

const isVerified = [
  {
    id: true,
    title: "Verified",
  },
  {
    id: false,
    title: "Not verified",
  },
];

const genderList = [
  {
    id: 1,
    title: "Male",
  },
  {
    id: 2,
    title: "Female",
  },
];
