import React, { useEffect,useState } from 'react'
import {formatDroneBatteryList} from "../../Pages/DroneInventory/utility"
import CustomTable from '../CustomTable/CustomTable';
import { token_api } from '../../Utils/network';

const BatteryInventoryTab = () => {
    const [droneInventoryList, setDroneInventoryList] = useState([]);

    
    const handleEditDrone = () =>{}
    const handleDeleteDrone =() =>{}

    const getDroneInventoryListing = async (filterobject) => {
        try {
          const fetchDroneInventory = await token_api.get(
            `/drone/battery/inventory`);
          if (fetchDroneInventory.status !== 200) {
            throw new Error("Error in fetching");
          }
        //   setPaginationData(fetchDroneInventory?.data);
          const modifiedData = formatDroneBatteryList(
            fetchDroneInventory?.data?.data,
            handleEditDrone,
            handleDeleteDrone
          );
          setDroneInventoryList(modifiedData);
        } catch (error) {
          console.log(error);
        }
      };

      useEffect(() =>{
        getDroneInventoryListing()
      },[])
  return (
    <div  style={{ height: "300px", overflow: "auto" }}>
         <CustomTable
          columns={tableColumns}
          data={droneInventoryList}
          // handleClick={handleRedirect}
          // tableFor=""
        />
    </div>
  )
}

export default BatteryInventoryTab


const tableColumns = [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "name",
      label: "Battery Name",
    },
    {
      id: "serial_no",
      label: "Serial No",
    },
    {
      id: "drone",
      label: "Drone",
    },
    {
      id: "power",
      label: "Power",
    },
  
    {
      id: "quantity",
      label: "Quantity",
    },
    {
      id: "location",
      label: "Location",
    },
    {
      id: "registered_date",
      label: "Registration Date",
    },
    {
      id: "updated_on",
      label: "Updated On",
    },
    {
      id: "updated_by",
      label: "Updated By",
    },
    {
      id: "status",
      label: "Status",
    },
    // {
    //   id: "actions",
    //   label: "Actions",
    // },
  ];
  
  const statusListing = [
    {
      id: true,
      title: "Active",
    },
    {
      id: false,
      title: "Inactive",
    },
  ];
  