import L from "leaflet";
import dd_truck from "../../Assets/idle_van.svg";

export default L.icon({
  iconSize: [40, 40],
  iconAnchor: [10, 41],
  popupAnchor: [2, -20],
  iconUrl: dd_truck,
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
});
