import React, { useEffect, useContext } from "react";
import { sideNavItems } from "../../Utils/constants";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Collapse,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import droneIcon from "../../Assets/sideBarDDBig.png";
import { saveToLocal } from "../../Utils/helper";
import { logDOM } from "@testing-library/react";
import upArrow from "../../Assets/dasboardAssets/upvector.png";
import downArrow from "../../Assets/dasboardAssets/downvector.png";
import CustomAccordion from "../CustomAccordion/CustomAccordion";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import orangeArrow from "../../Assets/orangeCross.svg";
import sideDD from "../../Assets/sideDD.svg";
import droneBig from "../../Assets/sideBarDDBig.png";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { useSelector } from "react-redux";

const Sidebar = ({ drawerWidth, handleEhangeWidth }) => {
  const { user_permissions, setUserPermissions } = useContext(UserCredsContext);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const activeFromLocal = localStorage.getItem("activeTab");
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const [userPersmissionsData, setUserPermissionsData] = useState([]);
  useEffect(() => {
    let permissionsData = user.group_permissions.flatMap((item) => item.module);
    setUserPermissionsData(permissionsData);
  }, [user]);

  const theme = useTheme();
  const {
    palette: { white },
  } = theme;

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "black",
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "black",

    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 80px) !important`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 80px) !important`,
    },
  });
  const [active, setActive] = useState("");
  const [ifCollapse, setIfCollapse] = useState(false);
  const navigate = useNavigate();

  const handleClickSideBar = (
    lcText,
    url,
    sub_list,
    index,
    ifNested,
    RouteModule
  ) => {

    if (sub_list && sub_list?.length > 0) {
      setSelectedIndex(index);
      setOpen((prev) => !prev);
    } else if (ifNested) {
      navigate(`/${url}`, { state: { RouteModule } });
      setUserPermissions(RouteModule);
      // localStorage.setItem("route_module", JSON.stringify(RouteModule));
    } else {
      setSelectedIndex(index);
      navigate(`/${url}`, { state: { RouteModule } });
      setUserPermissions(RouteModule);

      // localStorage.setItem("route_module", JSON.stringify(RouteModule));
    }

    setActive(lcText);
    saveToLocal("activeTab", lcText || "");
  };

  useEffect(() => {
    if (activeFromLocal) {
      setActive(JSON.parse(activeFromLocal));
    }
  }, [activeFromLocal]);

  const renderListItem = (item, index) => {
    const RouteModule =
      userPersmissionsData.length > 0 &&
      userPersmissionsData.find((a) => a.key === item.key);


    if (RouteModule && !RouteModule?.read) {
      return null;
    }

    const { title, url, icon, sub_list, ifNested, key } = item;
    const lcText = title.split(" ").join("-").toLowerCase();
    return (
      <div key={index}>
        <ListItem
          sx={{
            width: "90%",
            margin: "auto",
            padding: "0px",
          }}
          // button
          onClick={() =>
            handleClickSideBar(
              lcText,
              url,
              sub_list,
              index,
              ifNested,
              RouteModule
            )
          }
        >
          <Box
            autoFocus={false}
            // disableFocusRipple={true}
            // disableRipple={true}
            // onClick={() => {
            //   handleClickSideBar(lcText, url, sub_list);
            // }}
            sx={{
              backgroundColor: active === lcText ? "#FFFBFB4D" : "",
              borderRadius: active === lcText ? "5px" : "",
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "10px 15px",
              cursor: "pointer",

              // color:
              //   active === lcText
              //     ? theme.palette.primary
              //     : theme.palette.secondary,
            }}
          >
            <ListItemIcon>
              {/* {icon || ""} */}

              <img src={icon} alt="icon" width={30} height={30} />
            </ListItemIcon>
            {/* <ListItemText
              sx={{
                color: white[500],
                fontSize: "18px",
                // fontWeight: active === lcText ? "700" : "500",
                fontWeight: "500",
                lineHeight: "21px",
              }}
              primary={item.title}
            /> */}
            <Typography
              sx={{
                color: white[500],
                fontSize: "18px",
                // fontWeight: active === lcText ? "700" : "500",
                fontWeight: "500",
                lineHeight: "21px",
              }}
              // style={{ opacity: isCollapsed ? 1 : 0 }}
              style={{ display: isCollapsed ? "block" : "none" }}
            >
              {item.title}
            </Typography>
          </Box>

          {item?.sub_list &&
            (selectedIndex === index && open ? (
              <ExpandLess style={{ fill: "white" }} />
            ) : (
              <ExpandMore style={{ fill: "white" }} />
            ))}
        </ListItem>
        {item?.sub_list?.length > 0 && item?.sub_list && (
          <Collapse
            in={index === selectedIndex && open}
            timeout="auto"
            unmountOnExit
          >
            <List sx={{ marginLeft: "15px" }} component="div" disablePadding>
              {item?.sub_list.map(renderListItem)}
            </List>
          </Collapse>
        )}
      </div>
    );
  };

  const handleDrawerOpen = () => {
    setIsCollapsed(true);
    handleEhangeWidth();
  };
  const handleDrawerClose = () => {
    setIsCollapsed(false);
    handleEhangeWidth();
  };

  return (
    <>
      <Box component="nav">
        <Drawer
          open={true}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: white[500],
              // backgroundColor: "black",
              boxSixing: "border-box",
              // borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },

            ...(isCollapsed && {
              ...openedMixin(theme),
              "& .MuiDrawer-paper": openedMixin(theme),
            }),
            ...(!isCollapsed && {
              ...closedMixin(theme),
              "& .MuiDrawer-paper": closedMixin(theme),
            }),
          }}
        >
          <Box width="100%" style={{ color: "black" }}>
            <Box m="20px">
              {/* <FlexBetween color={theme.palette.secondary.main}> */}
              <Box
                display="flex"
                alignItems="center"
                // paddingLeft="66px"
                justifyContent="space-between"
              >
                {isCollapsed && (
                  <img
                    src={droneBig}
                    alt="dronIcon"
                    style={{ width: "120px" }}
                    // style={{ width: `${!isCollapsed ? "50%" : "auto"}` }}
                  />
                )}

                {!isCollapsed && <img src={sideDD} alt="drone_small" />}
                {!isCollapsed ? (
                  <ChevronRightIcon
                    onClick={handleDrawerOpen}
                    style={{ cursor: "pointer", fill: "white" }}
                  />
                ) : (
                  <ChevronLeftIcon
                    onClick={handleDrawerClose}
                    style={{ cursor: "pointer", fill: "white" }}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <List>{sideNavItems.map(renderListItem)}</List>
        </Drawer>
      </Box>
    </>
  );

  // return (
  //   <Box component="nav">
  //     <Drawer
  //       open={true}
  //       variant="persistent"
  //       anchor="left"
  //       sx={{
  //         width: drawerWidth,
  //         "& .MuiDrawer-paper": {
  //           color: white[500],
  //           backgroundColor: "#000000",
  //           boxSixing: "border-box",
  //           // borderWidth: isNonMobile ? 0 : "2px",
  //           width: drawerWidth,
  //         },
  //       }}
  //     >
  //       <Box width="100%">
  //         <Box m="20px">
  //           {/* <FlexBetween color={theme.palette.secondary.main}> */}
  //           <Box display="flex" alignItems="center" paddingLeft="66px">
  //             <img src={droneIcon} alt="dronIcon" />
  //           </Box>
  //         </Box>
  //         <List>
  //           {sideNavItems.map(({ title, icon, url, sub_list }) => {
  //             if (!icon) {
  //               return (
  //                 <Typography key={title} sx={{ m: "2.25rem 0 1rem 3rem" }}>
  //                   {title}
  //                 </Typography>
  //               );
  //             }
  //             const lcText = title.split(" ").join("-").toLowerCase();

  //             console.log("Lctscc", lcText);

  //             return (
  //               <ListItem
  //                 key={title}
  //                 disablePadding
  //                 sx={{
  //                   width: "90%",
  //                   margin: "auto",
  //                 }}
  //               >
  //                 <Box
  //                   autoFocus={false}
  //                   // disableFocusRipple={true}
  //                   // disableRipple={true}
  //                   onClick={() => {
  //                     handleClickSideBar(lcText, url, sub_list);
  //                   }}
  //                   sx={{
  //                     backgroundColor: active === lcText ? "#FFFBFB4D" : "",
  //                     borderRadius: active === lcText ? "5px" : "",
  //                     width: "100%",
  //                     display: "flex",
  //                     padding: "10px 15px",
  //                     cursor: "pointer",

  //                     // color:
  //                     //   active === lcText
  //                     //     ? theme.palette.primary
  //                     //     : theme.palette.secondary,
  //                   }}
  //                 >
  //                   <ListItemIcon>
  //                     {/* {icon || ""} */}

  //                     <img src={icon} alt="icon" />
  //                   </ListItemIcon>

  //                   <Typography
  //                     sx={{
  //                       color: white[500],
  //                       fontSize: "18px",
  //                       fontWeight: active === lcText ? "700" : "500",
  //                       lineHeight: "21px",
  //                     }}
  //                   >
  //                     {title}
  //                   </Typography>
  //                 </Box>
  //               </ListItem>
  //             );
  //           })}
  //         </List>
  //       </Box>
  //     </Drawer>
  //   </Box>
  // );
};

export default Sidebar;
