import styled from "@emotion/styled";
import { Box } from "@mui/material";

const FilterContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  //   alignItems: "center",
  maxHeight: "100% !important",
  columnGap: "10px",
});

export default FilterContainer;
