export const Validate = (value) => {
  return new Promise((resolve, reject) => {
    const {
      title,
      description,
      code,
      from_date,
      to_date,
      discount_type,
      discount,
      upto,
      usage_count,
      batch,
      course,
      drone,
      service,
      user,
    } = value;

    const errors = { error: false };
    if (!title) {
      errors.title = "Title is required";
      errors.error = true;
    }

    if (!description) {
      errors.description = "Description is required";
      errors.error = true;
    }
    if (!discount) {
      errors.discount = "Discount is required";
      errors.error = true;
    }
    if (!upto) {
      errors.upto = "Upto is required";
      errors.error = true;
    }
    if (!code) {
      errors.code = "Code is required";
      errors.error = true;
    }
    if (!usage_count) {
      errors.usage_count = "Usage Count is required";
      errors.error = true;
    }
    if (!from_date) {
      errors.from_date = "Enter from date";
      errors.error = true;
    }
    if (!to_date) {
      errors.to_date = "Enter to date";
      errors.error = true;
    }

    // if (!batch || Object.keys(batch).length === 0) {
    //   errors.batch = "Batch is required";
    //   errors.error = true;
    // }
    if (!discount_type || Object.keys(discount_type).length === 0) {
      errors.discount_type = "Discount Type is required";
      errors.error = true;
    }

    // if (!service || Object.keys(service).length === 0) {
    //   errors.service = "Service is required";
    //   errors.error = true;
    // }
    // if (!drone || Object.keys(drone).length === 0) {
    //   errors.drone = "Drone is required";
    //   errors.error = true;
    // }
    // if (!course || Object.keys(course).length === 0) {
    //   errors.course = "Course is required";
    //   errors.error = true;
    // }
    // if (!user || Object.keys(user).length === 0) {
    //   errors.user = "User is required";
    //   errors.error = true;
    // }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
