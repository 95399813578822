import React from "react";
import Container from "../../Component/Container/Container";

const Drones = () => {
  return (
    <Container>
      <h1>Coming Soon...</h1>
    </Container>
  );
};

export default Drones;
