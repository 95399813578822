import React, { useEffect, useState, useContext } from "react";
import Container from "../../Component/Container/Container";
import { base_url, token_api } from "../../Utils/network";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import CustomDate from "../../Component/CustomDate/CustomDate";
import CustomButton from "../../Component/CustomButton/CustomButton";
import styles from "./index.module.css";
import filterIcon from "../../Assets/filterIcon.png";
import female_fill from "../../Assets/users/defaultFemale.png";
import male_fill from "../../Assets/users/defaultMale.png";
import webD from "../../Assets/users/devicewed.png";
import androidD from "../../Assets/users/deviceandroid.png";
import apppleD from "../../Assets/users/deviceapple.png";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { useNavigate, Link } from "react-router-dom";
import { helperdayMonthYearFull } from "../../Utils/Utils";
import { Button } from "@mui/material";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import InputFields from "../../Component/InputFields/InputFields";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { Validate } from "./Utility";
import edit from "../../Assets/edit.png";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import OrganizationDropDown from "../../Component/OrganizationDropDown/OrganizationDropDown";
import dayjs from "dayjs";
import { formatDate } from "../../Utils/helper";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import verifiedImg from "../../Assets/verifiedSvg.svg";
import notVerifiedImg from "../../Assets/notVerifiedSvg.svg";
import axios from "axios";
import { saveAs } from "file-saver";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

const DDUsers = () => {
  let navigate = useNavigate();
  const { user_permissions } = useContext(UserCredsContext);

  console.log(user_permissions, "DDusersUserPermissions");
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">DD-Users</div>,
  ];
  const [singleObj, setSingleObj] = useState({});
  const [ddUsers, setDdUsers] = useState([]);
  const [filters, setfilters] = useState({});
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [updateId, setupdateId] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    name: "",
    dob: null,
    gender: [],
    mobile_verified: [],
    email_verified: [],
    gst: "",
    blood_group: "",
    height: "",
    weight: "",
    organization: null,
    organizationfilter: null,
  });
  const [orgList, setOrgList] = useState([]);

  const { page, previous_page, max_pages, total_count, count } = paginationData;

  const handleRedirect = (userid) => {
    // redirect and api call in nest page
    navigate(`/users/dd-users/profile/${userid}`);
  };

  const handleEditClick = (item) => {
    const newItem = item?.organization?.id ? item?.organization : null;

    setSingleObj(item);
    setOpenDrawer(true);
    setFormErrors({});
    const { creator, id } = item;
    setupdateId(id);
    setFormData((prev) => ({
      ...prev,
      first_name: creator?.first_name,
      last_name: creator?.last_name,
      mobile: creator?.mobile,
      email: creator?.email,
      dob: item?.dob ? dayjs(item?.dob) : null,
      gender: genderList?.filter((info) => info?.id == item?.gender),
      mobile_verified: statusOptions?.filter(
        (info) => info?.id == item?.creator?.mobile_verified
      ),
      email_verified: statusOptions?.filter(
        (info) => info?.id == item?.creator?.email_verified
      ),

      gst: item?.gst,
      blood_group: item?.blood_group,
      height: item?.height,
      weight: item?.weight,
      organization: newItem?.id ? newItem : null,
    }));

    console.log(item, "userfirmdata");
  };

  console.log(formData, "hariFormData");

  console.log(formData, "hariFormData");

  const getAllddUsers = async (filters) => {
    try {
      const fetchUsers = await token_api.get(`users/dd_profiles`, {
        params: { ...filters },
        paramsSerializer: {
          indexes: null,
        },
      });
      console.log(fetchUsers, "FETCHUSERS");
      if (fetchUsers.status !== 200) {
        throw new Error("Error while fetching users");
      }
      setPaginationData(fetchUsers?.data);

      const modifiedArray = fetchUsers?.data?.data?.map((item) => {
        return {
          id: (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleRedirect(item?.creator?.id)}
            >
              {item?.id}
            </div>
          ),
          name: (
            <div
              className={styles.profileCon}
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                // justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleRedirect(item?.creator?.id)}
            >
              <img
                src={item?.avatar ? item?.avatar : male_fill}
                width={25}
                height={25}
                style={{ borderRadius: "50%" }}
              />
              <div>
                <div>{`${item?.name || ""}`}</div>
                <div>{`${item?.creator?.mobile || ""}`}</div>
              </div>
            </div>
          ),
          gender: `${genderArray[item?.gender - 1] || "N.A"}`,
          mobile: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>{item?.creator?.mobile ? item?.creator?.mobile : "N.A"}</p>
              {item?.creator?.mobile ? (
                item?.creator?.mobile_verified ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      padding: "10px",
                      boxSizing: "border-box",
                      marginTop: "6px",
                      width: "150px",

                      borderRadius: "10px",
                      background: "rgba(212, 236, 195, 1)",
                    }}
                  >
                    <img src={verifiedImg} />
                    <p style={{ color: "rgba(9, 141, 6, 1)" }}>
                      {" "}
                      Verified
                    </p>{" "}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      padding: "10px",
                      boxSizing: "border-box",
                      borderRadius: "10px",
                      background: "rgba(255, 145, 111, 0.5)",
                      marginTop: "6px",
                      width: "150px",
                    }}
                  >
                    <img src={notVerifiedImg} />
                    <p style={{ color: "rgba(174, 47, 7, 1)" }}>
                      {" "}
                      Not Verified
                    </p>{" "}
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          ),
          email: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>{item?.creator?.email ? item?.creator?.email : "N.A"}</p>
              {item?.creator?.email ? (
                item?.creator?.email_verified ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      padding: "10px",
                      boxSizing: "border-box",
                      marginTop: "6px",

                      borderRadius: "10px",
                      background: "rgba(212, 236, 195, 1)",
                      width: "150px",
                    }}
                  >
                    <img src={verifiedImg} />
                    <p style={{ color: "rgba(9, 141, 6, 1)" }}>
                      {" "}
                      Verified
                    </p>{" "}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      padding: "10px",
                      boxSizing: "border-box",
                      borderRadius: "10px",
                      background: "rgba(255, 145, 111, 0.5)",
                      marginTop: "6px",
                      width: "150px",
                    }}
                  >
                    <img src={notVerifiedImg} />
                    <p style={{ color: "rgba(174, 47, 7, 1)" }}>
                      {" "}
                      Not Verified
                    </p>{" "}
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          ),
          dob: `${item?.dob ? helperdayMonthYearFull(item?.dob) : "N.A"}`,
          status: `${statusArray[item?.status - 1] || "N.A"}`,
          joined_date: `${
            helperdayMonthYearFull(item?.creator?.date_joined) || "N.A"
          }`,
          organization: `${item?.organization?.name || "N.A"}`,
          // last_login: "N.A",

          Action: (
            <div>
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEditClick(item)}
                >
                  <img src={edit} />
                </div>
              )}
            </div>
          ),
        };
      });

      setDdUsers(modifiedArray);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("USERS", ddUsers, pageState);
  useEffect(() => {
    const timeout = setTimeout(() => {
      let filterObject = {};
      if (pageState) {
        filterObject.page = pageState;
      }
      if (filters?.q) {
        filterObject.q = filters?.q;
      }

      if (formData?.organizationfilter?.id) {
        let ids = formData?.organizationfilter?.id;
        filterObject.organization = ids;
      }

      getAllddUsers(filterObject);
    }, 500);

    return () => clearTimeout(timeout);
  }, [pageState, filters?.q, formData?.organizationfilter]);

  const handleAddnewUser = () => {
    setOpenDrawer(true);
    setupdateId(null);
    setSingleObj({});
    setFormErrors({});
    setFormData((prev) => ({
      ...prev,
      first_name: "",
      last_name: "",
      mobile: "",
      email: "",
      name: "",
      dob: dayjs(),
      gender: [],
      gst: "",
      blood_group: "",
      height: "",
      weight: "",
    }));
    setFormData({});
  };

  const handleChange = (e, type, selectName, multiple) => {
    console.log("TYTYY");
    if (type === "select" && selectName && multiple) {
      setFormData({
        ...formData,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setFormData({
        ...formData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDeleteChipforDrawer = (id, selectName) => {
    console.log("DELETE", id, selectName, formData?.[selectName]);
    setFormData({
      ...formData,
      [selectName]: formData?.[selectName]?.filter((chip) => chip?.id !== id),
    });
    let namefiled = formData[selectName];
    console.log(namefiled[0]?.title, "slehdgfdf");
  };

  const handleSave = () => {
    const data = {
      user_details: {
        first_name: formData?.first_name,
        last_name: formData?.last_name,
        ...(singleObj?.creator?.mobile != formData?.mobile && {
          mobile: formData?.mobile,
        }),
        ...(formData?.email && { email: formData?.email }),
        ...(formData?.email_verified &&
          formData?.email_verified[0] && {
            email_verified: formData?.email_verified[0]?.id,
          }),

        mobile_verified:
          formData?.mobile_verified?.length > 0
            ? formData?.mobile_verified[0]?.id
            : [],
      },
      name: `${formData?.first_name} ${formData?.last_name}`,
      ...(formData?.dob && { dob: formatDate(formData?.dob) }),

      // dob: formatDate(formData?.dob),
      gender: formData?.gender?.length > 0 ? formData?.gender[0]?.id : [],
      ...(formData?.gst && { gst: formData?.gst }),
      ...(formData?.blood_group && { blood_group: formData?.blood_group }),
      ...(formData?.height && { height: formData?.height }),
      ...(formData?.weight && { weight: formData?.weight }),
      ...(formData?.organization?.id && {
        organization_id: formData?.organization?.id,
      }),
    };
    // formData?.organization
    Validate(formData)
      .then((response) => {
        if (response == "success") {
          if (!updateId) {
            token_api
              .post(`/users/profile/admin_dduser/`, data)
              .then((response) => {
                if (response?.status == 200 || response?.status == 201) {
                  setOpenDrawer(false);
                  getAllddUsers();
                } else if (response?.status == 400) {
                  alert(response?.data?.error?.fields[0]?.message?.mobile[0]);
                  setOpenDrawer(false);
                  setFormData({
                    ...formData,
                    first_name: "",
                    last_name: "",
                    mobile: "",
                    email: "",
                    name: "",
                    dob: null,
                    gender: [],
                    gst: "",
                    blood_group: "",
                    height: "",
                    weight: "",
                    organization: null,
                    organizationfilter: null,
                  });
                  getAllddUsers();
                }
              })
              .catch((error) => {
                console.log(error, "hariError");
                // alert(error?.message);
              });
          } else {
            token_api
              .patch(`/users/profile/admin_dduser/${updateId}/`, data)
              .then((response) => {
                if (response?.status == 200 || response?.status == 201) {
                  setOpenDrawer(false);
                  getAllddUsers();
                } else if (response?.status == 400) {
                  // alert(response?.data?.error?.fields[0]?.message?.mobile[0]);
                  alert(response?.data?.error?.fields[0]?.message?.mobile[0]);
                  setOpenDrawer(false);
                  setFormData({
                    ...formData,
                    first_name: "",
                    last_name: "",
                    mobile: "",
                    email: "",
                    name: "",
                    dob: null,
                    gender: [],
                    gst: "",
                    blood_group: "",
                    height: "",
                    weight: "",
                    organization: null,
                    organizationfilter: null,
                  });
                  getAllddUsers();
                }
              })
              .catch((error) => {
                console.log(error);
                // alert(error?.message);
              });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setFormErrors(error);
      });

    console.log(formErrors, "datanewuser");
  };

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  const handleOrganization = (selectedValues, actionType) => {
    console.log(selectedValues, "selectvalues");

    if (actionType == "filter") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        organizationfilter: selectedValues,
      }));
    } else if (actionType == "select") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        organization: selectedValues,
      }));
    }
  };

  console.log(formErrors, "sfdhjshd");

  // const handleExportData = async () => {
  //   try {
  //     const responeData = await axios
  //       .get(`${base_url}users/profile/pilot/export`, {
  //         method: "GET",
  //         responseType: "blob", // important
  //       })
  //       .then((response) => {
  //         const url = window.URL.createObjectURL(new Blob([response.data]));

  //         const link = document.createElement("a");
  //         link.href = url;

  //         link.setAttribute("download", `${Date.now()}.xlsx`);

  //         document.body.appendChild(link);
  //         link.click();
  //       });
  //   } catch (error) {
  //     console.log(error, "exportError");
  //   }
  // };

  const handleExportData = async () => {
    try {
      const response = await axios.get(`${base_url}users/dd_profiles/export`);
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>{" "}
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>
      {user_permissions?.write && (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button variant="contained" onClick={handleAddnewUser}>
            {"Add User"}
          </Button>
        </div>
      )}
      <FilterContainer
        className={styles.filterContainer}
        // className={`${styles.marginTopBox}`}
      >
        <CustomSearchFilter
        placeholder="Search by name, mobile, email...."
          onChange={(e) => {
            setPageState(1);
            setfilters({ ...filters, q: e });
          }}
        />
        <OrganizationDropDown
          value={formData?.organizationfilter}
          onChange={(selectedValues) =>
            handleOrganization(selectedValues, "filter")
          }
        />

        <CustomButton
          text={"Export"}
          maxWidth="110px"
          onClick={handleExportData}
        />
      </FilterContainer>

      <div className={styles.tableContainer}>
        <CustomTable
          columns={tableColumns}
          data={ddUsers}
          tableFor="systemUsers"
        />
      </div>
      <div className={styles.paginationContainer}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>

      <DrawerComp
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
      >
        <DrawerContainer>
          <DrawerTitle
            text={updateId ? "Update User" : "Add User"}
            handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
          />
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="First Name"
              required
              name="first_name"
              value={formData?.first_name || ""}
              onChange={handleChange}
              error={formErrors?.first_name}
              helperText={formErrors?.first_name}
            />
          </div>

          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Last Name"
              required
              name="last_name"
              value={formData?.last_name || ""}
              onChange={handleChange}
              error={formErrors?.last_name}
              helperText={formErrors?.last_name}
            />
          </div>

          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Mobile"
              required
              name="mobile"
              value={formData?.mobile || ""}
              onChange={handleChange}
              maxLength={10}
              error={formErrors?.mobile}
              helperText={formErrors?.mobile}
            />
          </div>

          <div className={styles.inputContainer}>
            <CustomSelectChip
              multiple={false}
              label="Mobile Number Verified"
              name="mobile_verified"
              onChange={handleChange}
              onDelete={handleDeleteChipforDrawer}
              value={formData?.mobile_verified}
              listArray={statusOptions}
              error={formErrors?.mobile_verified}
              helperText={formErrors?.mobile_verified}
            />
          </div>

          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Email"
              // required
              name="email"
              value={formData?.email || ""}
              onChange={handleChange}
              // error={formErrors?.email}
              // helperText={formErrors?.email}
            />
          </div>

          <div className={styles.inputContainer}>
            <CustomSelectChip
              multiple={false}
              label="Email Verified"
              name="email_verified"
              onChange={handleChange}
              onDelete={handleDeleteChipforDrawer}
              value={formData?.email_verified}
              listArray={statusOptions}
              // error={formErrors?.email_verified}
              // helperText={formErrors?.email_verified}
            />
          </div>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            {/* <CustomDate
              name="dob"
              label="Date of Birth"
              handleChange={handleChange}
              value={formData?.dob}
              error={formErrors?.dob}
              helperText={formErrors?.dob}
            /> */}
            <div style={{ width: "90%" }}>
              <CustomDatePicker
                label="Date of Birth"
                value={formData?.dob}
                onChange={(newValue) =>
                  setFormData({
                    ...formData,
                    dob: newValue,
                  })
                }
                // error={formErrors?.dob}
              />
            </div>

            <div style={{ width: "90%" }}>
              <CustomSelectChip
                multiple={false}
                label="Gender *"
                name="gender"
                onChange={handleChange}
                onDelete={handleDeleteChipforDrawer}
                value={formData?.gender}
                listArray={genderList}
                error={formErrors?.gender}
                helperText={formErrors?.gender}
              />
            </div>
          </div>
          {/* 
          <div style={{ marginTop: "10px" }}>
          
          </div> */}

          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="gst"
              name="gst"
              value={formData?.gst || ""}
              onChange={handleChange}
              error={formErrors?.gst}
              helperText={formErrors?.gst}
            />
          </div>

          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Blood group"
              name="blood_group"
              value={formData?.blood_group || ""}
              onChange={handleChange}
              error={formErrors?.blood_group}
              helperText={formErrors?.blood_group}
            />
          </div>

          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Height"
              name="height"
              value={formData?.height || ""}
              onChange={handleChange}
              error={formErrors?.height}
              helperText={formErrors?.height}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <InputFields
              label="Weight"
              name="weight"
              value={formData?.weight || ""}
              onChange={handleChange}
              error={formErrors?.weight}
              helperText={formErrors?.weight}
            />
          </div>

          <div style={{ marginTop: "10px" }}>
            <OrganizationDropDown
              value={formData?.organization}
              onChange={(selectedValues) =>
                handleOrganization(selectedValues, "select")
              }
            />
          </div>

          <div style={{ marginTop: "10px" }}>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </div>
        </DrawerContainer>
      </DrawerComp>
    </Container>
  );
};

export default DDUsers;

const tableColumns = [
  {
    id: "id",
    label: "User ID",
  },
  {
    id: "name",
    label: "Name",
  },

  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "mobile",
    label: "Mobile No.",
  },
  {
    id: "email",
    label: "Email",
  },

  {
    id: "dob",
    label: "Date of Birth",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "joined_date",
    label: "Joined Date",
  },
  {
    id: "organization",
    label: "Organization Name",
  },
  // {
  //   id: "last_login",
  //   label: "Last Login",
  // },

  {
    id: "Action",
    label: "Action",
  },
];

const genderArray = ["M", "F"];
const genderAvatar = [male_fill, female_fill];
const statusArray = ["Active", "Inactive", "Deleted"];

const genderList = [
  {
    id: 1,
    title: "Male",
  },
  {
    id: 2,
    title: "Female",
  },
];

const statusOptions = [
  {
    id: true,
    title: "Verified",
    city: true,
  },
  {
    id: false,
    title: "Not Verified",
    city: false,
  },
];
