import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { token_api } from "../../Utils/network";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomAutoComplete = ({
  options,
  label,
  handleChange,
  name,
  value,
  multiple = false,
  disableCloseOnSelect = false,
  disabled,
  error = false,
}) => {
  const [internalOption, setInternalOption] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (_, newInputValue) => {
    setInputValue(newInputValue);
  };

  // useEffect(() => {
  //   if (inputValue) {
  //     const delay = setTimeout(() => {
  //       token_api
  //         .get(`/service?q=${inputValue}`)
  //         .then((response) => {
  //           const selectOptions = response?.data?.data?.map((item) => ({
  //             id: item?.id || null,
  //             title: item?.title || "",
  //             price: item?.price || "N.A",
  //             hsn_code: item?.hsn_code,
  //             slug: item?.slug,
  //           }));

  //           setInternalOption(selectOptions);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     }, 500);

  //     return () => clearTimeout(delay); // Clear the timeout if the component unmounts or inputValue changes before 500ms
  //   }
  // }, [inputValue]);


  useEffect(() => {
    if (inputValue) {
      const delay = setTimeout(() => {
        token_api
          .get(`/service?q=${inputValue}`)
          .then((response) => {
            const selectOptions = response?.data?.data?.map((item) => ({
              id: item?.id || null,
              title: item?.title || "",
              price: item?.price || "N.A",
              hsn_code: item?.hsn_code,
              slug: item?.slug,
            }));

            setInternalOption(selectOptions);
          })
          .catch((err) => {
            console.log(err);
          });

        return () => clearTimeout(delay);
      }, 500);
    } else {
      // If inputValue is not present, make a normal API call
      token_api
        .get('/service')
        .then((response) => {
          const selectOptions = response?.data?.data?.map((item) => ({
            id: item?.id || null,
            title: item?.title || "",
            price: item?.price || "N.A",
            hsn_code: item?.hsn_code,
            slug: item?.slug,
          }));

          setInternalOption(selectOptions);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [inputValue]); 




  useEffect(() => {
    if (options) setInternalOption(options);
  }, [options]);

  return (
    <>
      <Autocomplete
        size="small"
        multiple={multiple}
        onChange={(event, newValue) =>
          handleChange(newValue, "autocomplete", name)
        }
        onInputChange={handleInputChange}
        name={name}
        value={value}
        disabled={disabled}
        id="checkboxes-tags-demo"
        options={internalOption || []}
        disableCloseOnSelect={disableCloseOnSelect}
        getOptionLabel={(option) => option.title}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option?.icon && <img src={option?.icon} alt="icon" />}
            <div style={{ marginLeft: "10px" }}>
              <div className="font-14"> {option.title}</div>
              {option?.sub_topic && (
                <div className="font-12 gray-1">{option?.sub_topic}</div>
              )}
            </div>
          </li>
        )}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            helperText={error || ""}
            error={error || false}
          />
        )}
      />
    </>
  );
};

export default CustomAutoComplete;
