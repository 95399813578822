export const Validate = (value) => {
  return new Promise((resolve, reject) => {
    const { serial_no, charging_hub_id, manufacturer_serial_no, status } =
      value;

    const errors = { error: false };

    if (!serial_no) {
      errors.serial_no = "Enter serial number";
      errors.error = true;
    }
    if (!manufacturer_serial_no) {
      errors.manufacturer_serial_no = "Enter manufacturer serial number";
      errors.error = true;
    }

    if (!charging_hub_id || Object.keys(charging_hub_id).length === 0) {
      errors.charging_hub_id = "Select charging hub";
      errors.error = true;
    }

    if (!status || Object.keys(status).length === 0) {
      errors.status = "Status is required";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
