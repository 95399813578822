import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isError: null,
  all_services: null,
  service_category_list: null,
  crop_stage_list: [],
  crop_stage_service_list: null,
  crop_type_list: null,
  crop_type_service_list: null,
  drone_service_list: null,
  pesticide_service_list: null,
  pesticide_list: [],
  service_reviews_list: null,
  service_slot_list: null,
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    apiCallStart: (state, action) => {},
    apiFailed: (state, action) => {
      state.isError = action?.payload?.message;
    },
    getCropStageSuccess: (state, action) => {
      let modifiedArray = action?.payload?.data?.map((item) => {
        return {
          id: item?.id,
          title: item?.title,
          sub_topic: item?.details,
          icon: item?.icon,
        };
      });
      state.crop_stage_list = modifiedArray || [];
    },
    getAllServicesSuccess: (state, action) => {
      state.all_services = action?.payload;
    },

    serviceCategories: (state, action) => {
      state.service_category_list = action?.payload?.data;
    },

    cropStageServiceList: (state, action) => {

      let modifiedArray = action?.payload?.data?.map((item) => {
        return {
          id: item?.id,
          title: item?.title,
        };
      });
      state.crop_stage_service_list = modifiedArray || [];
    },
    cropTypeList: (state, action) => {
      state.crop_type_list = action?.payload?.data;
    },
    cropTypeServiceList: (state, action) => {
      state.crop_type_service_list = action?.payload?.data;
    },
    droneServiceList: (state, action) => {
      state.drone_service_list = action?.payload?.data;
    },

    pesticideList: (state, action) => {
      let modifiedArray = action?.payload?.data?.map((item) => {
        return {
          id: item?.id,
          title: item?.title,
        };
      });
      state.pesticide_list = modifiedArray || [];
    },

    pesticideServiceList: (state, action) => {
      state.pesticide_service_list = action?.payload?.data;
    },
    serviceReviewList: (state, action) => {
      state.service_reviews_list = action?.payload?.data;
    },
    serviceSlotList: (state, action) => {
      state.service_slot_list = action?.payload?.data;
    },
  },
});

export const {
  getAllServicesSuccess,
  pesticideList,
  apiCallStart,
  apiFailed,
  getCropStageSuccess,
  cropTypeList,
  cropStageServiceList,
  cropTypeServiceList,
  pesticideServiceList,
  serviceCategories
} = serviceSlice.actions;
export default serviceSlice.reducer;
