import React from 'react';

const BoxWithShadow = ({ children }) => {
    const boxStyle = {
        width: '100%', 
        boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
        height: "100%"
   
      };
    
  return (
    <div style={boxStyle}>
      {children}
    </div>
  );
};

export default BoxWithShadow;
